import { handleResponseData } from '../utils';
import { ActionType } from '@scfe-common/chain-tracker';
export default [
    {
        name: 'SaleOutboundCheckingChain',
        status: {
            scanStationTime: 0,
            scanCheckingSkuTime: 0,
            hasScanStation: false,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/checking\/bind_station(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.hasScanStation = true;
                        this.status.scanStationTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/checking\/search_checking_possible_sku_list(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanCheckingSkuTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/checking\/search_checking_possible_sku_list(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.scanCheckingSkuTime) {
                            sendDataFn({
                                pointId: 'sale_outbound_checking_per_sku',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.scanCheckingSkuTime,
                            });
                        }
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/checking\/add_weight(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            if (this.status.hasScanStation && this.status.scanStationTime) {
                                this.status.hasScanStation = false;
                                sendDataFn({
                                    pointId: 'sale_outbound_checking_task',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.scanStationTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'SaleOutboundPackingChain',
        status: {
            scanPackingTime: 0,
            inputStationTime: 0,
            hasInputStation: false,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/packing\/scan_packing_record(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanPackingTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/packing\/scan_packing_record(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.scanPackingTime) {
                            sendDataFn({
                                pointId: 'sale_outbound_packing',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.scanPackingTime,
                            });
                        }
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/packing\/check_station(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.inputStationTime = Date.now();
                        this.status.hasInputStation = true;
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/packing\/complete_packing_task(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            if (this.status.hasInputStation && this.status.inputStationTime) {
                                this.status.hasInputStation = false;
                                sendDataFn({
                                    pointId: 'sale_outbound_packing_task',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.inputStationTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'SaleOutboundPickingChain',
        status: {
            getDetailTime: 0,
            hasScanPickingId: false,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/picking\/get_task_detail(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.getDetailTime = Date.now();
                        this.status.hasScanPickingId = true;
                    },
                },
                // {
                //   type: ActionType.REQUEST,
                //   rule: {
                //     url: new RegExp('/api/v2/apps/process/outbound/picking/check_scan_sku'),
                //     state: 'after',
                //   } as any,
                //   handler: (trackData: any, sendDataFn) => {
                //     const response = handleResponseData(trackData);
                //     if (response?.retcode === 0) {
                //       sendDataFn({
                //         pointId: 'sale_outbound_picking',
                //         customData: { ...this.status },
                //         duration: Date.now() - this.status.getDetailTime,
                //       });
                //     }
                //   },
                // },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/picking\/scan_record(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            if (this.status.hasScanPickingId && this.status.getDetailTime) {
                                this.status.hasScanPickingId = false;
                                sendDataFn({
                                    pointId: 'sale_outbound_picking',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.getDetailTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'SaleOutboundShippingChain',
        status: {
            scanLmtTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/shipping\/validate_shipping_lm_tracking(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanLmtTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/shipping\/create_shipping_order(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.scanLmtTime) {
                            sendDataFn({
                                pointId: 'sale_outbound_shipping',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.scanLmtTime,
                            });
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'SaleOutboundSortingChain',
        status: {
            hasScanStation: false,
            scanStationTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/scan_sorting_station_id(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.hasScanStation = true;
                        this.status.scanStationTime = Date.now();
                    },
                },
                // {
                //   type: ActionType.REQUEST,
                //   rule: {
                //     url: new RegExp(
                //       '/api/v2/apps/process/outbound/sorting/get_possible_sku_list'
                //     ),
                //     state: 'before',
                //   } as any,
                //   handler: () => {
                //     this.status.scanSortingSkuTime = Date.now();
                //   },
                // },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/get_possible_sku_list(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        if (trackData.response?.data?.retcode === '0') {
                            if (this.status.hasScanStation && this.status.scanStationTime) {
                                this.status.hasScanStation = false;
                                sendDataFn({
                                    pointId: 'sale_outbound_sorting',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.scanStationTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
];
