import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
let User = class User extends VuexModule {
    constructor() {
        super(...arguments);
        this.from = {};
        this.to = {};
    }
    setRouteFromAndTo(data = {}) {
        this.from = data.from;
        this.to = data.to;
    }
    async getRouteFromAndTo(route) {
        return route;
    }
};
__decorate([
    Mutation
], User.prototype, "setRouteFromAndTo", null);
__decorate([
    Action({ commit: 'setRouteFromAndTo' })
], User.prototype, "getRouteFromAndTo", null);
User = __decorate([
    Module
], User);
export default User;
