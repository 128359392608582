import { handleResponseData } from '../utils';
import { ActionType } from '@scfe-common/chain-tracker';
export default [
    {
        name: 'InventoryCcCountPerSkuChain',
        status: {
            operationTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inventory\/cyclecount\/cctask\/cycle_count_scan_location(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.operationTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inventory\/cyclecount\/cctask\/save_sku_location_quantity(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.operationTime) {
                            sendDataFn({
                                pointId: 'inventory_cc_count',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.operationTime,
                            });
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'InventoryRTMassPutawayChain',
        status: {
            operationTime: 0,
            hasScanDevice: false,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/rtmassputaway\/search_device_id(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.operationTime = Date.now();
                        this.status.hasScanDevice = true;
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/rtmassputaway\/submit_mass_putaway(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            if (this.status.hasScanDevice && this.status.operationTime) {
                                this.status.hasScanDevice = false;
                                sendDataFn({
                                    pointId: 'inventory_rt_mass_putaway',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.operationTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'InventoryRTPickingChain',
        status: {
            hasScanPicking: false,
            scanPickingTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inventory\/racktransfer\/get_rack_transfer_order_step(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanPickingTime = Date.now();
                        this.status.hasScanPicking = true;
                    },
                },
                // {
                //   type: ActionType.REQUEST,
                //   rule: {
                //     url: new RegExp(
                //       '/api/v2/apps/process/inventory/racktransfer/twostep/two_step_pc_picking_scan_code'
                //     ),
                //     state: 'before',
                //   } as any,
                //   handler: () => {
                //     this.status.scanSkuTime = Date.now();
                //     console.log('扫描 SKU', this.status.scanSkuTime);
                //   },
                // },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inventory\/racktransfer\/twostep\/two_step_pc_picking_scan_code_quantity_unit_id_save(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            if (this.status.hasScanPicking && this.status.scanPickingTime) {
                                this.status.hasScanPicking = false;
                                sendDataFn({
                                    pointId: 'inventory_rt_picking',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.scanPickingTime,
                                });
                            }
                            // console.log('结束', Date.now(), Date.now() - this.status.scanSkuTime);
                            // sendDataFn({
                            //   pointId: 'inventory_rt_picking_per_sku',
                            //   customData: { ...this.status },
                            //   duration: Date.now() - this.status.scanSkuTime,
                            // });
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'InventoryRTPutawayChain',
        status: {
            operationTime: 0,
            hasScanDevice: false,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/rtstandardputaway\/search_device_id(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.operationTime = Date.now();
                        this.status.hasScanDevice = true;
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/rtstandardputaway\/submit_standard_putaway(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            if (this.status.hasScanDevice && this.status.operationTime) {
                                this.status.hasScanDevice = false;
                                sendDataFn({
                                    pointId: 'inventory_rt_putaway',
                                    customData: { ...this.status },
                                    duration: Date.now() - this.status.operationTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
];
