import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
let ThreePL = class ThreePL extends VuexModule {
    constructor() {
        super(...arguments);
        this.laneCodeList = [];
    }
    // laneCodeMap: any = {};
    setThreePL(list) {
        this.laneCodeList = list || [];
        // list.forEach((item: any) => {
        //   const { value, label } = item;
        //   this.laneCodeMap[value] = label;
        // });
    }
    threePlSearch(resp) {
        return resp;
    }
};
__decorate([
    Mutation
], ThreePL.prototype, "setThreePL", null);
__decorate([
    Action({ commit: 'setThreePL' })
], ThreePL.prototype, "threePlSearch", null);
ThreePL = __decorate([
    Module
], ThreePL);
export default ThreePL;
