import request from '@/utils/request';
export const searchAsnQuotaPlan = (params) => {
    return request.get('/api/v2/apps/config/asnquota/search_asn_quota_plan', {
        params,
    });
};
export const enableAsnQuotaPlan = (params) => {
    return request.post('/api/v2/apps/config/asnquota/enable_asn_quota_plan', {
        ...params,
    });
};
export const disenableAsnQuotaPlan = (params) => {
    return request.post('/api/v2/apps/config/asnquota/disable_asn_quota_plan', {
        ...params,
    });
};
export const updateAsnQuotaPlan = (params) => {
    return request.post('/api/v2/apps/config/asnquota/update_asn_quota_plan', {
        ...params,
    });
};
export const searchAsnQuotaConfig = (params) => {
    return request.get('/api/v2/apps/config/asnquota/search_asn_quota_config', {
        params,
    });
};
export const getAvgMaxUsedQty = (params) => {
    return request.get('/api/v2/apps/config/asnquota/get_avg_max_used_qty', {
        params,
    });
};
export const updateAsnQuotaConfig = (params) => {
    return request.post('/api/v2/apps/config/asnquota/update_asn_quota_config', params);
};
export const switchAsnQuotaMode = (params) => {
    return request.post(
    /* PermissionKey: PC.RuleCenter.QuotaConfig.ASNQuotaConfiguration.Switch */
    '/api/v2/apps/config/asnquota/switch_asn_quota_mode', params, {
        noError: true,
    });
};
export const getMaxCartonQty = () => {
    return request.get(
    /* PermissionKey: PC.RuleCenter.QuotaConfig.ASNQuotaConfiguration.Edit */
    '/api/v2/apps/config/asnquota/get_carton_used_qty');
};
export const searchInboundShift = async () => {
    return request.get('/api/v2/apps/conf/inboundshift/search_inbound_shift');
};
export const massUpdateAsnQuotaPlan = async (params) => {
    return request.post('/api/v2/apps/config/asnquota/mass_update_asn_quota_plan', params);
};
