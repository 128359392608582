import request from '@/utils/request';
export default {
    login(data) {
        return request.post('/api/v2/apps/system/user/user_login', {
            ...data,
        }, {
            noErrorCode: [-224003],
        });
    },
    getCaptcha(name) {
        return request.get('/api/v2/apps/system/user/get_captcha', {
            params: { name },
        });
    },
    googleLogin(redirectUrl) {
        return request.get('/api/v2/apps/system/user/user_google_auth', {
            params: {
                redirect_url: redirectUrl,
            },
        });
    },
    sendEmail(email) {
        return request.post('/api/v2/apps/system/user/user_forgot', {
            email,
        }, {
            noErrorCode: [-224005],
        });
    },
    resetPassword(passwd, token, user_id) {
        return request.post('/api/v2/apps/system/user/user_password_reset', {
            passwd,
            token,
            user_id,
        });
    },
    getModifyPasswordDeadline() {
        /* PermissionKey: ALL */
        return request.get('/api/v2/apps/system/user/simple_password_deadline');
    },
    enableTobLogin() {
        /* PermissionKey: ALL */
        return request.get('/api/v2/config/adminconfig/get_admin_config', {
            params: {
                configKeyList: 'enable_tob_login',
            },
        });
    },
    tobUserLogin(params) {
        /* PermissionKey: ALL */
        return request.get('/api/v2/apps/system/user/tob_user_login', {
            params,
        }, {
            noError: true,
        });
    },
};
