const inventoryMap = {
    path: '/inventorymap',
    name: 'inventoryMap',
    meta: {
        title: 'Inventory Map',
        icon: 'Inventory Map',
    },
    redirect: '/inventorymap/historical',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'historical',
            name: 'inventoryMap.historicaInventoryMap',
            meta: {
                title: 'Historical Inventory Map',
            },
            component: () => import('../../views/inventory-map/historical-inventory-map/index.vue'),
        },
        {
            path: 'available',
            name: 'inventoryMap.availableInventoryMap',
            meta: {
                title: 'Available Inventory Map',
                filterSave: true,
            },
            component: () => import('../../views/inventory-map/available-inventory-map/index.vue'),
        },
        {
            path: 'available/detail:sku_id',
            name: 'inventoryMap.availableInventoryMap.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryMap',
                        title: 'Inventory Map',
                    },
                    {
                        routeName: 'inventoryMap.availableInventoryMap',
                        title: 'Available Inventory Map',
                    },
                ],
            },
            component: () => import('../../views/wms-basic/sku-items/detail.vue'),
        },
        {
            path: 'onhand',
            name: 'inventoryMap.onHandInventoryMap',
            meta: {
                title: 'On-Hand Inventory Map',
                filterSave: true,
            },
            component: () => import('@/views/inventory-map/on-hand-inventory-map/index.vue'),
        },
        {
            path: 'onhand/detail/:sku_id',
            name: 'inventoryMap.onHandInventoryMap.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryMap',
                        title: 'Inventory Map',
                    },
                    {
                        routeName: 'inventoryMap.onHandInventoryMap',
                        title: 'On-Hand Inventory Map',
                    },
                ],
            },
            component: () => import('../../views/wms-basic/sku-items/detail.vue'),
        },
        {
            path: 'expiry',
            name: 'inventoryMap.expiryAgingInventoryMap',
            meta: {
                title: 'Expiry & Aging Inventory map',
                filterSave: true,
            },
            component: () => import('@/views/inventory-map/expiry-aging-inventory-map/index.vue'),
        },
        {
            path: 'expiry/detail/:sku_id',
            name: 'inventoryMap.expiryAgingInventoryMap.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'inventoryMap',
                        title: 'Inventory Map',
                    },
                    {
                        routeName: 'inventoryMap.expiryAgingInventoryMap',
                        title: 'Expiry & Aging Inventory Map',
                    },
                ],
            },
            component: () => import('../../views/wms-basic/sku-items/detail.vue'),
        },
        {
            path: 'transactionInventoryMap',
            name: 'inventoryMap.transactionInventoryMap',
            meta: {
                title: 'Transaction Inventory Map',
            },
            component: () => import('../../views/inventory-map/transaction-inventory-map/index.vue'),
        },
        {
            path: 'blockInventoryMap',
            name: 'inventoryMap.blockInventoryMap',
            meta: {
                title: 'Block Inventory Map',
            },
            component: () => import('../../views/inventory-map/block-inventory-map/index.vue'),
        },
        {
            path: 'unblockInventoryMap',
            name: 'inventoryMap.unblockInventoryMap',
            meta: {
                title: 'Unblock Log',
            },
            component: () => import('../../views/inventory-map/unblock-inventory-map/index.vue'),
        },
        {
            path: 'instransitInventoryMap',
            name: 'inventoryMap.intransitInventoryMap',
            meta: {
                title: 'In-Transit Inventory Map',
            },
            component: () => import('@/views/inventory-map/intransit-map/index.vue'),
        },
        {
            path: 'zone_inventory_map',
            name: 'inventoryMap.zoneinventorymap',
            meta: {
                title: 'Zone Inventory Map',
            },
            component: () => import('@/views/inventory-map/zone-inventory-map/index.vue'),
        },
        /* {
          path: 'available/:sku_id',
          name: 'availableInventoryMapDetail',
          meta: {
            title: 'detail',
            icon: '',
            menuHide: true,
            navList: [
              {
                routeName: 'inventoryMap',
                title: 'Inventory Map',
              },
              {
                routeName: 'availableInventoryMap',
                title: 'Available Inventory Map',
              },
            ],
          },
          component: () =>
            import(
              '@/views/inventory-map/available-inventory-map/detail/index.vue'
            ),
          }
        }, */
    ],
};
export default inventoryMap;
