import { __decorate } from "tslib";
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-property-decorator';
import './index.scss';
let SFilterPart = class SFilterPart extends Vue {
    render(h) {
        const temp = this.actionList.slice(2);
        const dropdownItemList = temp.map((item) => {
            const { data: { on }, } = item;
            if (on && on.click) {
                // 删除原来 node 节点上的事件，防止2个事件同时进行，导致冲突
                const click = on.click;
                delete item.data.on.click;
                return h('s-dropdown-item', {
                    on: {
                        // Fix：处理 item vue node 的容器宽度不够，点击到dropdown-item其他区域没有触发click事件
                        click: (event) => {
                            click(event);
                            event.preventDefault();
                        },
                    },
                }, [item]);
            }
            else {
                return h('s-dropdown-item', {}, [item]);
            }
        });
        return (<s-dropdown trigger="hover">
        <span class="dropdown-action-slice"/>
        <span class="dropdown-action-text more-btn">{this.$i18n('More')}</span>
        <s-dropdown-menu slot="dropdown">{dropdownItemList}</s-dropdown-menu>
      </s-dropdown>);
    }
};
__decorate([
    Prop()
], SFilterPart.prototype, "actionList", void 0);
SFilterPart = __decorate([
    Component
], SFilterPart);
export default SFilterPart;
