const fullStockCount = {
    path: 'fsc',
    name: 'inventoryManagement.fullStockCount',
    meta: {
        title: 'Full Stock Count',
    },
    redirect: '/inventorymanage/fsc/fscorder',
    component: () => import('@/views/layout/subPage.vue'),
    children: [
        {
            path: 'fscorder',
            name: 'inventoryManagement.fullStockCount.fscOrder',
            meta: {
                title: 'FSC Order',
                filterSave: true,
            },
            component: () => import('@/views/inventory-management/full-stock-count/order'),
            children: [
                {
                    path: 'view',
                    name: 'inventoryManagement.fullStockCount.fscOrder.view',
                    meta: {
                        title: 'Order Detail',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/inventory-management/full-stock-count/order/view'),
                },
            ],
        },
        {
            path: 'fsctask',
            name: 'inventoryManagement.fullStockCount.fscTask',
            meta: {
                title: 'FSC Task',
                filterSave: true,
            },
            component: () => import('@/views/inventory-management/full-stock-count/task'),
            children: [
                {
                    path: 'count',
                    name: 'inventoryManagement.fullStockCount.fscTask.count',
                    meta: {
                        title: 'Count',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/inventory-management/full-stock-count/task/count'),
                },
                {
                    path: ':task_id',
                    name: 'inventoryManagement.fullStockCount.fscTask.view',
                    meta: {
                        title: 'Task Detail',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/inventory-management/full-stock-count/task/view'),
                },
            ],
        },
        {
            path: 'fscdifforder',
            name: 'inventoryManagement.fullStockCount.fscDiffOrder',
            meta: {
                title: 'FSC Diff Order',
                filterSave: true,
            },
            component: () => import('@/views/inventory-management/full-stock-count/diff-order'),
            children: [
                {
                    path: 'done4thCount',
                    name: 'inventoryManagement.fullStockCount.fscDiffOrder.done4thCount',
                    meta: {
                        title: 'Next Count Mass Creation',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/inventory-management/full-stock-count/diff-order/done4thCount'),
                },
                {
                    path: ':order_id/approve',
                    name: 'inventoryManagement.fullStockCount.fscDiffOrder.approve',
                    meta: {
                        title: 'Approve',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/inventory-management/full-stock-count/diff-order/detail'),
                },
                {
                    path: ':order_id',
                    name: 'inventoryManagement.fullStockCount.fscDiffOrder.view',
                    meta: {
                        title: 'View',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/inventory-management/full-stock-count/diff-order/detail'),
                },
            ],
        },
    ],
};
export default fullStockCount;
