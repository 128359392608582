export const SelectionLabelFixed = {
    ['On Going']: 'OnGoing',
    ['One Step']: 'One-Step',
    ['Two Step']: 'Two-Step',
    ['Three Step']: 'Three-Step',
    ['Rearrangement Report']: 'Re-arrangement Report',
    Pc: 'PC',
    Qc: 'QC',
    ['Non-qc Device']: 'Non-QC Device',
    ['No Block']: 'N',
    Block: 'Y',
    ['Sum  Lwh']: 'Sum LWH',
    ['Sync  From  Pms']: 'Sync  From  PMS',
    ['Buyer Never Request R/r But Wh Still Decide To Process Offline']: 'Buyer never request R/R but WH still decide to process offline',
    ['Ri Order Has Never Been Created (but Buyer Has Requested R/r)']: 'RI order has never been created (but buyer has requested R/R)',
    ['Ri Order Has Never Been Created (failed Delivery)']: 'RI order has never been created (failed delivery)',
    ['Un Arrived']: 'Unarrived',
    Aql: 'AQL',
    Asn: 'ASN',
    YES: 'Yes',
    NO: 'No',
    Sa: 'SA',
    ['Hv-high Value']: 'HV-High Value',
    ['Tc-temperature Control']: 'TC-Temperature Control',
    ['RI  RTS  Order  Report( Pii  For  Awb)']: 'RI RTS Order Report(PII For AWB)',
    ['RTB  Order  Report( Pii  For  Awb)']: 'RTB Order Report(PII For AWB)',
    ['Supplier  List  With  Pii']: 'Supplier List With  PII',
    ['Tc- Temperature  Control']: 'Temperature Control',
    ['Hv- High  Value']: 'High Value',
    ['Re- Inbound- Others']: 'Re-Inbound - Others',
    ['Re- Inbound- RTS']: 'Re-Inbound - RTS',
};
import capitalize, { capitalizeSingle } from '@/utils/capitalize';
import store from '@/store/index';
import { i18n } from '@/i18n/index';
import { getObjKeyByValue } from '@/utils/tools';
// 修复了safeGet工具函数原来返回undefined的逻辑不正确的问题
export const safeGet = (data, path) => {
    if (!path)
        return undefined;
    const paths = path.split('.');
    let res = data;
    while (paths.length) {
        const index = paths.shift();
        if (index === undefined)
            return {};
        res = res[index];
        if (Object.prototype.toString.call(res) !== '[object Object]')
            return res;
    }
    return res;
};
export const checkPerm = function (state, perm, dataPath = 'perm.api_perms') {
    const list = safeGet(state, dataPath) || [];
    return list.includes(perm);
};
export const getSelectOptions = function (state, dataPath = '', filterValue) {
    const list = safeGet(state, dataPath) || {};
    if (filterValue || filterValue === 0) {
        Object.keys(list).forEach((item) => {
            if (list[item] === filterValue) {
                delete list[item];
            }
        });
    }
    return Object.keys(list).map((item) => ({
        label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
        value: list[item],
    }));
};
export const getSafeSelectOptions = function (state, dataPath = '', filterValue) {
    let list = safeGet(state, dataPath) || {};
    if (filterValue || filterValue === 0) {
        list = Object.keys(list).reduce((result, curItem) => {
            if (list[curItem] !== filterValue) {
                result[curItem] = list[curItem];
            }
            return result;
        }, {});
    }
    return Object.keys(list).map((item) => ({
        label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
        value: list[item],
    }));
};
export const getSelectOptionsFirstWord = function (state, dataPath = '', filterValue) {
    const list = safeGet(state, dataPath) || {};
    if (filterValue || filterValue === 0) {
        Object.keys(list).forEach((item) => {
            if (list[item] === filterValue) {
                delete list[item];
            }
        });
    }
    return Object.keys(list)
        .map((item) => ({
        label: SelectionLabelFixed[capitalizeSingle(item)] ||
            capitalizeSingle(item),
        value: list[item],
    }))
        .sort((a, b) => a.value - b.value);
};
export const getSelectOptionsWithoutCapitalize = function (state, dataPath = '', filterValue) {
    const list = safeGet(state, dataPath) || {};
    if (filterValue || filterValue === 0) {
        Object.keys(list).forEach((item) => {
            if (list[item] === filterValue) {
                delete list[item];
            }
        });
    }
    return Object.keys(list).map((item) => ({
        label: item,
        value: list[item],
    }));
};
// 同 getSelectOptions，只是返回的数组处理为对象
export const getSelectOptionsObj = function (state, dataPath = '', filterValue) {
    const list = safeGet(state, dataPath) || {};
    if (filterValue || filterValue === 0) {
        Object.keys(list).forEach((item) => {
            if (list[item] === filterValue) {
                delete list[item];
            }
        });
    }
    return Object.keys(list)
        .map((item) => ({
        label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
        value: list[item],
    }))
        .reduce((accu, item) => {
        const key = item.label;
        accu[key] = item.value;
        return accu;
    }, {});
};
// 不使用 delete 方式处理
export const getSafeSelectOptionsObj = function (state, dataPath = '', filterValue) {
    let list = safeGet(state, dataPath) || {};
    if (filterValue || filterValue === 0) {
        list = Object.keys(list).reduce((result, curItem) => {
            if (list[curItem] !== filterValue) {
                result[curItem] = list[curItem];
            }
            return result;
        }, {});
    }
    return Object.keys(list)
        .map((item) => ({
        label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
        value: list[item],
    }))
        .reduce((accu, item) => {
        const key = item.label;
        accu[key] = item.value;
        return accu;
    }, {});
};
export const getRenderValue = function (state, dataPath = '', value) {
    const options = safeGet(state, dataPath) || {};
    const index = Object.values(options).indexOf(value);
    const formatLabel = SelectionLabelFixed[capitalize(Object.keys(options)[index])] ||
        capitalize(Object.keys(options)[index]);
    try {
        return index === -1 ? '' : i18n(formatLabel);
    }
    catch {
        return index === -1 ? '' : formatLabel;
    }
};
export const getRenderValueWithoutCapitalize = function (state, dataPath = '', value) {
    const options = safeGet(state, dataPath) || {};
    const index = Object.values(options).indexOf(value);
    const formatLabel = SelectionLabelFixed[Object.keys(options)[index]] ||
        Object.keys(options)[index];
    try {
        return index === -1 ? '' : i18n(formatLabel);
    }
    catch {
        return index === -1 ? '' : formatLabel;
    }
};
export const getRenderValueWithoutUnderline = function (state, dataPath = '', value, exception) {
    const options = safeGet(state, dataPath) || {};
    const index = Object.values(options).indexOf(value);
    return index === -1
        ? ''
        : Object.keys(options)
        /* eslint-disable */
        [index].split('_')
            .map((element) => {
            if (element === exception)
                return element;
            return element
                .toLowerCase()
                .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        })
            .join(' ');
    /* eslint-enable */
};
export const getRenderValueByOptions = function (options, value) {
    const target = options.filter((option) => option.value === value)[0];
    return target && target.label;
};
export const getSelectOptionsWithoutUnderline = function (state, dataPath = '', exception) {
    const list = safeGet(state, dataPath) || {};
    return Object.keys(list).map((item) => ({
        label: item
            .split('_')
            .map((element) => {
            if (element === exception)
                return element;
            return element
                .toLowerCase()
                .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        })
            .join(' '),
        value: list[item],
    }));
};
export const getSelectOptionsWithoutUnderlineWithoutQuoteSpace = function (state, dataPath = '', exception) {
    const list = safeGet(state, dataPath) || {};
    return Object.keys(list).map((item) => ({
        label: item
            .split('_')
            .map((element) => {
            if (element === exception)
                return element;
            return element
                .toLowerCase()
                .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        })
            .join(''),
        value: list[item],
    }));
};
export const getSelectOptionsWithoutUnderlineNoSort = function (state, dataPath = '', exception) {
    const list = safeGet(state, dataPath) || {};
    return Object.keys(list).map((item) => ({
        label: item
            .split('_')
            .map((element) => {
            if (element === exception)
                return element;
            return element
                .toLowerCase()
                .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        })
            .join(' '),
        value: list[item],
    }));
};
export const enumsName = function (enumsKey, value) {
    const _obj = safeGet(store.state.enums, `systemEnums.${enumsKey}`) || {};
    const key = getObjKeyByValue(value, _obj);
    if (SelectionLabelFixed[capitalize(key)]) {
        return i18n(SelectionLabelFixed[capitalize(key)]);
    }
    return capitalize(i18n(key));
};
export const getRenderValueWithoutI18n = function (state, dataPath = '', value) {
    const options = safeGet(state, dataPath) || {};
    const index = Object.values(options).indexOf(value);
    const formatLabel = SelectionLabelFixed[capitalize(Object.keys(options)[index])] ||
        capitalize(Object.keys(options)[index]);
    return index === -1 ? '' : formatLabel;
};
export function filterParams(params, autoTrim = true, filters = ['', null, undefined, [], {}]) {
    if (!Array.isArray(filters))
        throw new Error('filters must be array');
    const filterParams = {};
    const filtersJson = filters.map((item) => JSON.stringify(item));
    Object.keys(params).forEach((key) => {
        let value = params[key];
        if (typeof value === 'string') {
            value = autoTrim ? value.trim() : value;
        }
        if (!filtersJson.includes(autoTrim ? JSON.stringify(value) : JSON.stringify(value)))
            filterParams[key] = value;
    });
    return filterParams;
}
