//建议所有页面的路由增加dmi 前缀防止和vue的路由产生冲突
const subPageRoutes = {
    path: '/dmi',
    name: 'subPage',
    meta: {
        title: 'rulecenter',
        menuHide: true,
    },
    //  children: [demoRoutes, ruleCenterRoutes],
};
const routes = [subPageRoutes];
export default routes;
