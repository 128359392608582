import { LazyExoticComponent, ComponentType } from 'react';
// eslint-disable-next-line react/no-deprecated
import { render } from 'react-dom';
import Wrapper from '../components/Wrapper';

type LazyFn = () => LazyExoticComponent<ComponentType<any>>;

export const renderReactComponent = (
  lazyFn: LazyFn,
  rootNode: HTMLElement
): void => {
  if (!lazyFn) {
    throw new Error('renderReactComponent: invalid lazyFn');
  } else if (!rootNode) {
    throw new Error('renderReactComponent: invalid rootNode');
  }

  try {
    const Com = lazyFn();

    render(
      <Wrapper>
        <Com />
      </Wrapper>,
      rootNode
    );
  } catch (e) {
    console.error('renderReactComponent error', e);
  }
};
