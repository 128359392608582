import request from '@/utils/request';
export default {
    searchWarehouse(params) {
        return request.get('/api/v2/apps/basic/location/search_warehouse/', {
            params,
        });
    },
    createWarehouse(params) {
        return request.post('/api/v2/apps/basic/location/create_warehouse/', {
            ...params,
        });
    },
    detailWarehouse(whs_id) {
        return request.get('/api/v2/apps/basic/location/get_warehouse_detail', {
            params: {
                whs_id,
            },
        });
    },
    editWarehouse(params) {
        return request.get('/api/v2/apps/basic/location/update_warehouse/', {
            params,
        });
    },
    getCountryList() {
        return request.get('/api/v2/apps/basic/location/get_country_list', {});
    },
};
