import logger from '@/utils/logger';
import store from '@/store';
export const threePlApiReport = (config) => {
    // 传参字符串
    const paramsJson = JSON.stringify(config?.params || config?.data);
    if (!(paramsJson?.includes('lane_code') && paramsJson?.includes('channel_id')))
        return;
    // 仓库配置
    const is_use_channel = store.state.warehouseDetail.warehouse.is_use_channel;
    // 是否为补货业务 api
    const is_Replenishment = config?.url?.includes('inventory/replenishment');
    // 上报错误信息
    const errorMessage = `threePlErrorReport: ${is_use_channel}-${config.url}-${paramsJson}`;
    // 应该传channel但传了lane code的情况上报
    if (paramsJson?.includes('lane_code') &&
        is_use_channel &&
        !is_Replenishment) {
        logger.error(errorMessage);
    }
    // 应该传lane code但传了channel的情况上报
    if (paramsJson?.includes('channel_id') &&
        (!is_use_channel || is_Replenishment)) {
        logger.error(errorMessage);
    }
};
