import { DataCollectorInstance, } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';
export default [
    {
        name: 'SLASaleOutboundShippingChain',
        status: {
            useUploadParcelBtn: 0, // 是否使用了 Upload Parcel Btn
        },
        collector: {},
        UploadParcelBtnName: 'custom/SaleOutboundShipping/mass_upload_shipping_order',
        getPointList: function () {
            return [
                // Shipping开始：验证LMTN
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/shipping\/validate_shipping_lm_tracking(\?.*)?$/,
                        state: RequestState.Before,
                    },
                    handler: () => {
                        this.collector = DataCollectorInstance.createTransactionCollector();
                    },
                },
                // 使用了 Upload Parcel Btn
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/shipping\/mass_upload_shipping_order(\?.*)?$/,
                        state: RequestState.Before,
                    },
                    handler: () => {
                        this.status.useUploadParcelBtn = 1;
                        this.collector.startTrack({
                            name: this.UploadParcelBtnName,
                        });
                    },
                },
                // 结束任务
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/outbound\/shipping\/complete_shipping_task(\?.*)?$/,
                        state: RequestState.After,
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            const results = this.collector.endTrackByName({
                                name: this.UploadParcelBtnName,
                                isSuccess: true,
                                endAll: true,
                                extraData: {
                                    useUploadParcelBtn_n: this.status.useUploadParcelBtn,
                                },
                            });
                            results.forEach((result) => {
                                sendDataFn({
                                    pointId: result.name,
                                    customData: { ...result, type: SLA_CHAINS_TYPE },
                                });
                            });
                        }
                        DataCollectorInstance.removeTransactionCollector({
                            traceId: this.collector.traceId,
                        });
                    },
                },
            ];
        },
    },
];
