import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getDevConf, getDevConfList } from '@/api/basic/displaySetting';
import { getCookie } from '@/utils/cookie';
import { Judge } from '@/ConstEnums';
let System = class System extends VuexModule {
    constructor() {
        super(...arguments);
        this.lang = 'en'; // 当前 i18n lang
        this.staff_name_display_switch = 0;
        this.weak_network_switch = 0;
        this.weak_network_show_time_again = 30;
        this.weak_network_time_threshold = 3;
        this.weak_network_count_threshold = 3;
        this.weak_network_rtt_threshold = 300;
        this.weak_network_ping_threshold = 100;
        this.weak_network_ping_domain = '';
        this.weak_network_api_whiteList = '';
        this.weak_network_router_blackList = '';
        this.isOnPrintDANFEButton = false;
        this.isOnCheckingPrintDANFE = false;
        /** 是否开启履约线开关 */
        this.enableFulfilChain = false;
        /** 是否使用旧的Rule center inbound putaway 页面 */
        this.EnableFulfillChainInboundDiversionPutawayRule = false;
        /** 是否展示的 Storage Area Setting Zone/Pathway/Cell 页面的Hot Level */
        this.CellHotLevelMgmt = false;
        /** 是否开启补货开关 */
        this.enableDynamicReplenishment = false;
        this.showZoneColor = false;
    }
    setCurrentLang(data = {}) {
        this.lang = data.lang;
    }
    /* @Action({ commit: 'setCurrentLang' })
    async getCurrentLang(route: any) {
      return route;
    } */
    setStaffNameDisplaySwitch(data) {
        this.staff_name_display_switch = data;
    }
    async loadStaffSwitch() {
        const res = await getDevConf({
            conf_key: 'Is_staffname_display',
            whs_id: getCookie('setting_whs_v2') || '',
        });
        return Number(res.data.conf_value) || 0;
    }
    setDisplaySettings(data = {}) {
        this.weak_network_switch = Number(data[0].conf_value) || 0;
        this.weak_network_show_time_again = Number(data[1].conf_value) || 0;
        this.weak_network_time_threshold = Number(data[2].conf_value) || 0;
        this.weak_network_count_threshold = Number(data[3].conf_value) || 0;
        this.weak_network_rtt_threshold = Number(data[4].conf_value) || 0;
        this.weak_network_ping_threshold = Number(data[5].conf_value) || 0;
        this.weak_network_ping_domain = data[6].conf_value;
        this.weak_network_api_whiteList = data[7].conf_value;
        this.weak_network_router_blackList = data[8].conf_value;
        this.isOnPrintDANFEButton = Number(data[9].conf_value) === 1;
        this.isOnCheckingPrintDANFE = Number(data[10].conf_value) === 1;
        this.enableFulfilChain =
            (Number(data[11].conf_value) || 0) === Judge.Truthy;
        this.EnableFulfillChainInboundDiversionPutawayRule =
            (Number(data[12].conf_value) || 0) === Judge.Falsy;
        this.CellHotLevelMgmt = (Number(data[13].conf_value) || 0) === Judge.Truthy;
        this.enableDynamicReplenishment =
            (Number(data[14].conf_value) || 0) === Judge.Truthy;
        this.showZoneColor = Number(data[15].conf_value) === 1;
        // 若有一个配置关闭，则要关闭此功能
        if (!this.weak_network_show_time_again ||
            !this.weak_network_time_threshold ||
            !this.weak_network_count_threshold ||
            !this.weak_network_rtt_threshold ||
            !this.weak_network_ping_threshold ||
            !this.weak_network_ping_domain) {
            this.weak_network_switch = 0;
        }
    }
    async loadDisplaySettings() {
        const res = await getDevConfList({
            conf_key: [
                'weak_network_switch',
                'weak_network_show_time_again',
                'weak_network_time_threshold',
                'weak_network_count_threshold',
                'weak_network_rtt_threshold',
                'weak_network_ping_threshold',
                'weak_network_ping_domain',
                'weak_network_api_whiteList',
                'weak_network_router_blackList',
                'print_danfe_button_switch',
                'print_danfe_button_switch_checking',
                'Enable_Fulfill_Chain',
                'Enable_Fulfill_Chain_Inbound_Diversion_Putaway_Rule',
                'Cell_hotlevel_mgmt',
                'Enable_Dynamic_Replenishment',
                'zone_by_color',
            ],
            whs_id: getCookie('setting_whs_v2') || '',
        });
        return res.data.conf_list || {};
    }
};
__decorate([
    Mutation
], System.prototype, "setCurrentLang", null);
__decorate([
    Mutation
], System.prototype, "setStaffNameDisplaySwitch", null);
__decorate([
    Action({ commit: 'setStaffNameDisplaySwitch' })
], System.prototype, "loadStaffSwitch", null);
__decorate([
    Mutation
], System.prototype, "setDisplaySettings", null);
__decorate([
    Action({ commit: 'setDisplaySettings' })
], System.prototype, "loadDisplaySettings", null);
System = __decorate([
    Module
], System);
export default System;
