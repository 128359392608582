import request from '@/utils/request';
export const searchOutboundInProcessTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_inprocess_tracking', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchOutboundCompletedTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_inprocess_completed_tracking', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchOutboundPlotTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_inprocess_tracking_plot', {
        params: {
            ...params,
            time: Date.now(),
        },
    });
    return res;
};
export const downloadOutboundInProcessTracking = '/api/v2/apps/dashboard/outbounddashboard/download_inprocess_tracking';
export const downloadOutboundCompletedTracking = '/api/v2/apps/dashboard/outbounddashboard/download_inprocess_completed_tracking';
export const searchOutboundTaskAssignmentPickingData = async (params = {}) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_process_picking_task', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchOutboundOrderStructureData = async (params = {}) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_order_structure', {
        params: {
            ...params,
            time: Date.now(),
        },
    });
    return res;
};
export const addFavorite = async (params) => {
    const res = await request.post('/api/v2/apps/dashboard/outbounddashboard/add_to_favorite', {
        ...params,
    });
    return res;
};
export const downloadOrderStructure = async () => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/download_order_structure');
    return res;
};
export const getFavorite = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/get_favorite', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchBacklogOrder = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_backlog_order', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchEfficient = async () => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_efficient');
    return res;
};
export const searchInProcessTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_in_process_tracking', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchInProcessTrackingPlot = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_in_process_tracking_plot', {
        params: {
            ...params,
        },
    });
    return res;
};
// export const searchOrderStructure = async () => {
//   const res = await request.get(
//     '/api/v2/apps/dashboard/outbounddashboard/search_order_structure'
//   );
//   return res;
// };
// export const searchProcessPickingTask = async () => {
//   const res = await request.get(
//     '/api/v2/apps/dashboard/outbounddashboard/search_process_picking_task'
//   );
//   return res;
// };
export const searchRealTimeUrgentOrder = async () => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_real_time_urgent_order');
    return res;
};
export const updateFavorite = async (params) => {
    const res = await request.post('/api/v2/apps/dashboard/outbounddashboard/update_favorite', {
        ...params,
    });
    return res;
};
export const downloadInProcessCompletedTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/download_in_process_completed_tracking', {
        params: {
            ...params,
        },
    });
    return res;
};
export const downInProcessTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/down_in_process_tracking', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchBacklogOrderPlot = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_backlog_order_plot', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchBacklogOrderTab = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_backlog_order_tab', {
        params: {
            ...params,
            time: Date.now(),
        },
    });
    return res;
};
export const searchInprocessCompletedTracking = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_inprocess_completed_tracking', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchInProcessTrackingTab = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_inprocess_tracking_tab', {
        params: {
            ...params,
            time: Date.now(), // 防止重复发被拦截
        },
    });
    return res;
};
export const searchOrder3pl = async (params) => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_order3pl', {
        params: {
            ...params,
            time: Date.now(),
        },
    });
    return res;
};
export const searchOrderStructure = async () => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_order_structure');
    return res;
};
export const searchProcessPickingTask = async () => {
    const res = await request.get('/api/v2/apps/dashboard/outbounddashboard/search_process_picking_task');
    return res;
};
