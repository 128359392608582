/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Suspense, FC } from 'react';
import { ConfigProvider } from 'ssc-ui-react';
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import styles from './index.module.less';
import I18nProvider from '@/react-app/utils/i18n/provider';

const Wrapper: React.FC = ({ children }) => {
  return (
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    <Suspense fallback={''}>
      <I18nProvider>
        <ConfigProvider prefixCls="ssc-react" rcPrefixCls="ssc-react-rc">
          <div className={styles.root} id="react-wrapper">
            {children}
          </div>
        </ConfigProvider>
      </I18nProvider>
    </Suspense>
  );
};

export default Wrapper;
