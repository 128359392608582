import Vue from 'vue';
import store from '../store/index';
function tips(vnode, binding) {
    Vue.prototype.$message({
        message: 'Manual is not permitted, please Scan the Label!',
        type: 'warning',
        duration: 2000,
    });
    const express = binding.expression;
    const format = express.split('.');
    switch (format.length) {
        case 1:
            vnode.context[format[0]] = '';
            break;
        case 2:
            vnode.context[format[0]][format[1]] = '';
            break;
    }
}
function handleInput(interval = 200, vnode, binding) {
    let time = null;
    return {
        inputHandler: () => {
            time = +new Date();
        },
        enterHandler: (e) => {
            if (!time || e.keyCode !== 13)
                return;
            const now = +new Date();
            if (now - time > interval) {
                e.preventDefault();
                e.stopPropagation();
                tips(vnode, binding);
                time = null;
                console.log('is hand');
            }
            else {
                console.log('is scan');
            }
        },
    };
}
const onlyScanInput = {
    bind: function (el, binding, vnode) {
        const input = el.querySelector('input');
        let interval = (binding.value || {}).interval;
        const isSubmit = (binding.value || {}).isSubmit;
        if (typeof interval === 'string') {
            interval = store.state.permission.userPermission.includes(interval)
                ? false
                : 200;
        }
        if (!input || !interval)
            return;
        const obj = handleInput(interval, vnode, binding.value);
        input.addEventListener('paste', (e) => {
            Vue.prototype.$message({
                message: 'Manual is not permitted, please Scan the Label!',
                type: 'warning',
                duration: 2000,
            });
            e.preventDefault(); // 禁用粘贴功能
        });
        input.addEventListener('drop', (e) => {
            Vue.prototype.$message({
                message: 'Manual is not permitted, please Scan the Label!',
                type: 'warning',
                duration: 2000,
            });
            e.preventDefault(); // 禁用拖动录入功能
        });
        input.addEventListener('input', obj.inputHandler);
        if (isSubmit) {
            input.addEventListener('keypress', obj.enterHandler, false);
        }
        else {
            input.addEventListener('keyup', obj.enterHandler, false);
        }
    },
};
export { onlyScanInput };
