export const tabs = [
    {
        label: 'WHS Planning',
        url: 'PC.WMSBasic.StorageAreaSetting.WHSPlanning.View',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.WHSPlanning.View',
        path: '/wmsbasic/whsplanning',
    },
    {
        label: 'Zone Cluster',
        url: 'PC.WMSBasic.StorageAreaSetting.ZoneCluster.View',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.ZoneCluster.View',
        path: '/wmsbasic/zonecluster',
    },
    {
        label: 'Park',
        url: 'PC.WMSBasic.StorageAreaSetting.Park.View',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.Park.View',
        path: '/wmsbasic/park',
    },
    {
        label: 'Zone',
        url: 'PC.WMSBasic.StorageAreaSetting.Zone.View',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.Zone.View',
        path: '/wmsbasic/zone',
    },
    {
        label: 'Pathway',
        url: 'PC.WMSBasic.StorageAreaSetting.Pathway.view',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.Pathway.view',
        path: '/wmsbasic/pathway',
    },
    {
        label: 'Cell',
        url: 'PC.WMSBasic.StorageAreaSetting.Cell.View',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.Cell.View',
        path: '/wmsbasic/cell',
    },
    /* {
      label: 'Inbound Staging Location',
      url: 'PC.WMSBasic.StorageAreaSetting.InboundStagingLocation',
      permissionName: 'PC.WMSBasic.StorageAreaSetting.InboundStagingLocation',
    }, */
    {
        label: 'Outbound Staging Location',
        url: 'PC.WMSBasic.StorageAreaSetting.OutboundStagingLocation',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.OutboundStagingLocation',
        path: '/wmsbasic/outboundStagingLocation',
    },
    {
        label: 'RT Staging Location',
        url: 'PC.WMSBasic.StorageAreaSetting.RTStagingLocation',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.RTStagingLocation',
        path: '/wmsbasic/rtStagingLocation',
    },
    {
        label: 'Storey',
        url: 'PC.WMSBasic.StorageAreaSetting.Storey',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.Storey',
        path: '/wmsbasic/storey',
    },
    {
        label: 'Logic Area',
        url: 'PC.WMSBasic.StorageAreaSetting.LogicArea',
        permissionName: 'PC.WMSBasic.StorageAreaSetting.LogicArea',
        path: '/wmsbasic/logicArea',
    },
];
