import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';
const FulfillmentChainTurnoverDaysRule = 'PC.RuleCenter.FulfillmentChainTurnoverDaysRule';
export const RuleCenterRouter = {
    FulfillmentChainTurnoverDaysRule: {
        name: FulfillmentChainTurnoverDaysRule,
        View: {
            name: `${FulfillmentChainTurnoverDaysRule}.View`,
        },
        Edit: {
            name: `${FulfillmentChainTurnoverDaysRule}.Edit`,
        },
        Create: {
            name: `${FulfillmentChainTurnoverDaysRule}.Create`,
        },
        Delete: {
            name: `${FulfillmentChainTurnoverDaysRule}.Delete`,
        },
    },
};
const fulfillmentChainTurnoverDaysRuleNavList = [
    {
        routeName: 'ruleCenter',
        title: 'Rule Center',
    },
    {
        routeName: 'ruleCenter.fulfillmentchainturnoverdaysrule',
        title: 'Fulfillment Chain Turnover Days Rule',
    },
];
const ruleCenter = {
    path: '/rulecenter',
    name: 'ruleCenter',
    meta: {
        title: 'Rule Center',
        icon: 'Rule Center',
    },
    redirect: '/rulecenter/rulegroup',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'skuAttributesValidation',
            name: 'ruleCenter.skuattributesvalidation',
            meta: {
                title: 'SKU Attributes Validation',
            },
            redirect: 'skuAttributesValidation/firstin',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'firstin',
                    name: 'ruleCenter.skuAttributesValidation.firstin',
                    meta: {
                        title: 'First in',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.skuAttributesValidation',
                                title: 'SKU Attributes Validation',
                            },
                        ],
                    },
                    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                    component: () => import('@/views/rule-center/sku-attributes-validation/sku-attributes-validation.vue'),
                },
                {
                    path: 'recheck',
                    name: 'ruleCenter.skuAttributesValidation.recheck',
                    meta: {
                        title: 'Re-Check',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.skuAttributesValidation',
                                title: 'SKU Attributes Validation',
                            },
                        ],
                    },
                    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
                    component: () => import('@/views/rule-center/sku-attributes-validation/sku-attributes-validation.vue'),
                },
            ],
        },
        {
            path: 'batchrule',
            name: 'PC.RuleCenter.RuleGroup.BatchRule.View',
            meta: {
                title: 'Rule Group',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/rule-group/batch-rule.vue'),
        },
        {
            path: 'batchrule/add',
            name: 'ruleCenter.batchRule.add',
            meta: {
                title: 'Batch Rule Add',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.RuleGroup.BatchRule.View',
                        title: 'Rule Group',
                    },
                ],
            },
            component: () => import('@/views/rule-center/rule-group/batch-rule-add.vue'),
        },
        {
            path: 'batchrule/detail',
            name: 'ruleCenter.batchRule.detail',
            meta: {
                title: 'Batch Rule Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.RuleGroup.BatchRule.View',
                        title: 'Rule Group',
                    },
                ],
            },
            component: () => import('@/views/rule-center/rule-group/batch-rule-add.vue'),
        },
        {
            path: 'batchrule/edit',
            name: 'ruleCenter.batchRule.edit',
            meta: {
                title: 'Batch Rule Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.RuleGroup.BatchRule.View',
                        title: 'Rule Group',
                    },
                ],
            },
            component: () => import('@/views/rule-center/rule-group/batch-rule-add.vue'),
        },
        {
            path: 'uidrule',
            name: 'PC.RuleCenter.RuleGroup.UIDRule.View',
            meta: {
                title: 'Rule Group',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/rule-group/uid-rule.vue'),
        },
        {
            path: 'rulegrouptask',
            name: 'ruleCenter.ruleGroup',
            meta: {
                title: 'Rule Group',
            },
            redirect: '/rulecenter/rulegroup',
        },
        {
            path: 'rulegroup',
            name: 'PC.RuleCenter.RuleGroup.RuleGroup.View',
            meta: {
                title: 'Rule Group',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/rule-group/rule-group.vue'),
        },
        {
            path: 'rulegroup/add',
            name: 'ruleCenter.ruleGroupAdd',
            meta: {
                title: 'Rule Group Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.RuleGroup.RuleGroup.View',
                        title: 'Rule Group',
                    },
                ],
            },
            component: () => import('@/views/rule-center/rule-group/rule-group-detail.vue'),
        },
        {
            path: 'rulegroup/edit',
            name: 'ruleCenter.ruleGroupEdit',
            meta: {
                title: 'Rule Group Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.RuleGroup.RuleGroup.View',
                        title: 'Rule Group',
                    },
                ],
            },
            component: () => import('@/views/rule-center/rule-group/rule-group-detail.vue'),
        },
        {
            path: 'rulegroup/detail',
            name: 'ruleCenter.ruleGroupDetail',
            meta: {
                title: 'Rule Group Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.RuleGroup.RuleGroup.View',
                        title: 'Rule Group',
                    },
                ],
            },
            component: () => import('@/views/rule-center/rule-group/rule-group-detail.vue'),
        },
        {
            path: 'qcruleconfigtask',
            name: 'ruleCenter.qcruleconfig',
            meta: {
                title: 'QC Rule',
            },
            redirect: '/rulecenter/qcruleconfig',
        },
        {
            path: 'qcruleconfig',
            name: 'PC.RuleCenter.QCRule.QCRuleConfig.View',
            meta: {
                title: 'QC Rule',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/qc-rule/qc-rule-config.vue'),
        },
        {
            path: 'qcruleconfig/create',
            name: 'ruleCenter.qcruleconfigcreate',
            meta: {
                title: 'QC Rule Config Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.QCRuleConfig.View',
                        title: 'QC Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-config-create.vue'),
        },
        {
            path: 'qcruleconfig/edit',
            name: 'ruleCenter.qcruleconfigedit',
            meta: {
                title: 'QC Rule Config Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.QCRuleConfig.View',
                        title: 'QC Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-config-create.vue'),
        },
        {
            path: 'qcruleconfig/view',
            name: 'ruleCenter.qcruleconfigview',
            meta: {
                title: 'QC Rule Config Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.QCRuleConfig.View',
                        title: 'QC Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-config-create.vue'),
        },
        {
            path: 'rmsruleconfig',
            name: 'PC.RuleCenter.QCRule.ReturnInboundRuleConfig',
            meta: {
                title: 'Return Inbound Rule Config',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/qc-rule/return-inbound-rule-config.vue'),
        },
        {
            path: 'qcchecklist',
            name: 'PC.RuleCenter.QCRule.QCChecklist.View',
            meta: {
                title: 'Inbound QC Checklist Config',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/qc-rule/qc-check-list.vue'),
        },
        {
            path: 'qcchecklist/create',
            name: 'ruleCenter.qcchecklistcreate',
            meta: {
                title: 'QC Check List Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.QCChecklist.View',
                        title: 'Inbound QC Checklist Config',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-check-list-view.vue'),
        },
        {
            path: 'qcchecklist/edit',
            name: 'ruleCenter.qcchecklistedit',
            meta: {
                title: 'QC Check List Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.QCChecklist.View',
                        title: 'QC Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-check-list-view.vue'),
        },
        {
            path: 'qcchecklist/view',
            name: 'ruleCenter.qcchecklistview',
            meta: {
                title: 'QC Check List Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.QCChecklist.View',
                        title: 'QC Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-check-list-view.vue'),
        },
        {
            path: 'qcsuppliersetting',
            name: 'PC.RuleCenter.QCRule.SupplierSetting.View',
            meta: {
                title: 'Supplier Setting',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/qc-rule/supplier-setting.vue'),
        },
        {
            path: 'qcsuppliersetting/edit',
            name: 'ruleCenter.qcsuppliersettingedit',
            meta: {
                title: 'Supplier Setting Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.SupplierSetting.View',
                        title: 'QC Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/supplier-setting-edit.vue'),
        },
        {
            path: 'qc-checklist-config',
            name: 'PC.RuleCenter.QCRule.ReturnQCChecklistConfig.View',
            meta: {
                title: 'QC Checklist Config',
                filterSave: true,
                menuHide: true,
            },
            component: () => import('@/views/rule-center/qc-rule/qc-checklist-config.vue'),
        },
        {
            path: 'qc-checklist-config-create',
            name: 'ruleCenter.qcchecklistconfigcreate',
            meta: {
                title: 'QC Checklist Config Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.ReturnQCChecklistConfig.View',
                        title: 'QC Checklist Config',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-checklist-config-update.vue'),
        },
        {
            path: 'qc-checklist-config-edit',
            name: 'ruleCenter.qcchecklistconfigedit',
            meta: {
                title: 'QC Checklist Config Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.QCRule.ReturnQCChecklistConfig.View',
                        title: 'QC Checklist Config',
                    },
                ],
            },
            component: () => import('@/views/rule-center/qc-rule/qc-checklist-config-update.vue'),
        },
        {
            path: 'lifecyclerules',
            name: 'ruleCenter.lifeCycleRules',
            meta: {
                title: 'LifeCycle Rule',
            },
            component: () => import('@/views/rule-center/lifecycle-rule/lifecycle.vue'),
        },
        {
            path: 'lifecyclerules/detail',
            name: 'ruleCenter.lifeCycleRules.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.lifeCycleRules',
                        title: 'LifeCycle Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/lifecycle-rule/lifecycle-detail.vue'),
        },
        {
            path: 'putawayrule',
            name: 'ruleCenter.putawayRule',
            meta: {
                title: 'Putaway Rule',
            },
            redirect: '/rulecenter/putawayrule/inbound',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'putawayrule/inbound',
            name: 'ruleCenter.putawayRule.inbound',
            meta: {
                menuHide: true,
                title: 'Inbound',
            },
            component: () => import('@/views/rule-center/putaway-rule/putaway-rule-v4.vue'),
        },
        {
            path: 'putawayrule/inbound/create',
            name: 'ruleCenter.putawayRule.inbound.create',
            meta: {
                menuHide: true,
                title: 'Inbound',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.putawayRule.inbound.create',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/put-away-rule/inboundEdit')),
            },
        },
        {
            path: 'putawayrule/inbound/edit/:id',
            name: 'ruleCenter.putawayRule.inbound.edit',
            meta: {
                menuHide: true,
                title: 'Inbound',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.putawayRule.inbound.edit',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/put-away-rule/inboundEdit')),
            },
        },
        {
            path: 'putawayrule/inbound/view/:id',
            name: 'ruleCenter.putawayRule.inbound.view',
            meta: {
                menuHide: true,
                title: 'Inbound',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.putawayRule.inbound.view',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/put-away-rule/inboundEdit')),
            },
        },
        // {
        //   path: 'putawayrule/racktransfer',
        //   name: 'ruleCenter.putawayRule.rackTransfer',
        //   meta: {
        //     menuHide: true,
        //     title: 'Rack Transfer',
        //   },
        //   component: () =>
        //     import('@/views/rule-center/putaway-rule/putaway-rule-v3.vue'),
        // },
        {
            path: 'putawaydisplayconfig',
            name: 'ruleCenter.putawayDisplayConfig',
            meta: {
                title: 'Putaway Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/putaway-rule/putaway-display-config.vue'),
        },
        {
            path: 'putawayLocationDefinition',
            name: 'ruleCenter.putawayLocationDefinition',
            meta: {
                title: 'Putaway Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/putaway-rule/putaway-location-definition.vue'),
        },
        // {
        //   path: 'putawayrule/detail',
        //   name: 'ruleCenter.putawayRule.detail',
        //   meta: {
        //     title: 'Detail',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'ruleCenter',
        //         title: 'Rule Center',
        //       },
        //       {
        //         routeName: 'ruleCenter.putawayRule',
        //         title: 'Putaway Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '@/views/rule-center/putaway-rule/putaway-detail/putawayDetail.vue'
        //     ),
        // },
        // {
        //   path: 'putawayrule/edit',
        //   name: 'ruleCenter.putawayRule.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'ruleCenter',
        //         title: 'Rule Center',
        //       },
        //       {
        //         routeName: 'ruleCenter.putawayRule',
        //         title: 'Putaway Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '@/views/rule-center/putaway-rule/putaway-detail/putawayDetail.vue'
        //     ),
        // },
        {
            path: 'putaway/task/assignmentrules',
            name: 'ruleCenter.putawayTaskAssignmentRules',
            meta: {
                title: 'Task Assignment Rule',
            },
            redirect: '/rulecenter/putawaytaskassignmentrules',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'putawaytaskassignmentrules',
            name: 'PC.RuleCenter.PutawayTaskAssignmentRule.InboundPutaway.View',
            meta: {
                menuHide: true,
                title: 'Putaway Task Assignment Rule',
            },
            component: () => import('@/views/rule-center/putaway-task-assignment-rule/assignment-rule.vue'),
        },
        {
            path: 'putawaytaskhighprioritytaskrule',
            name: 'PC.RuleCenter.PutawayTaskAssignmentRule.InboundPutawayHighPriorityRule.View',
            meta: {
                menuHide: true,
                title: 'High Priority Task Rule',
            },
            component: () => import('@/views/rule-center/putaway-task-assignment-rule/high-priority-task-rule.vue'),
        },
        {
            path: 'cyclecounttaskassignmentrule',
            name: 'PC.RuleCenter.PutawayTaskAssignmentRule.CycleCountTaskAssignmentRule.View',
            meta: {
                menuHide: true,
                title: 'Cycle Count Task Assignment Rule',
            },
            component: () => import('@/views/rule-center/putaway-task-assignment-rule/cycle-count-task-assignment-rule.vue'),
        },
        {
            path: 'putawaytaskracktransferassignmentrule',
            name: 'PC.RuleCenter.PutawayTaskAssignmentRule.RackTransferAssignmentRule.View',
            meta: {
                menuHide: true,
                title: 'Rack Transfer Assignment Rule',
            },
            component: () => import('@/views/rule-center/putaway-task-assignment-rule/rack-transfer-assignment-rule.vue'),
        },
        {
            path: 'putaway/task/racktransferassignmentrule',
            name: 'ruleCenter.racktransferassignmentrule',
            meta: {
                title: 'Rack Transfer Assignment Rule',
            },
            redirect: '/rulecenter/racktransferassignmentrule',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'allocateRule',
            name: 'ruleCenter.allocateRule',
            meta: {
                title: 'Allocate Rule',
            },
            redirect: 'allocateRule/sales',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'sales',
                    name: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                    meta: {
                        title: 'Outbound Allocate Rule',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/index.vue'),
                },
                {
                    path: 'prehitcreate',
                    name: 'ruleCenter.allocateRule.sales.prehitCreate',
                    meta: {
                        title: 'Pre-hit Rule Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                            {
                                routeName: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                                title: 'Outbound Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/components/sales-outbound/components/preHitRuleTab/create.vue'),
                },
                {
                    path: 'prehitedit',
                    name: 'ruleCenter.allocateRule.sales.prehitEdit',
                    meta: {
                        title: 'Pre-hit Rule Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                            {
                                routeName: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                                title: 'Outbound Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/components/sales-outbound/components/preHitRuleTab/edit.vue'),
                },
                {
                    path: 'prehitview',
                    name: 'ruleCenter.allocateRule.sales.prehitView',
                    meta: {
                        title: 'Pre-hit Rule View',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                            {
                                routeName: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                                title: 'Outbound Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/components/sales-outbound/components/preHitRuleTab/view.vue'),
                },
                {
                    path: 'edit',
                    name: 'ruleCenter.allocateRule.sales.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                            {
                                routeName: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                                title: 'Outbound Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/components/sales-outbound/components/allocateRuleEdit.vue'),
                },
                {
                    path: 'detail',
                    name: 'ruleCenter.allocateRule.sales.detail',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                            {
                                routeName: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                                title: 'Outbound Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/components/sales-outbound/components/allocateRuleEdit.vue'),
                },
                {
                    path: 'add',
                    name: 'ruleCenter.allocateRule.sales.add',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                            {
                                routeName: 'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
                                title: 'Outbound Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/components/sales-outbound/components/allocateRuleEdit.vue'),
                },
                {
                    path: 'mt',
                    name: 'PC.RuleCenter.AllocateRule.MTOutbound',
                    meta: {
                        title: 'MT Allocate Rule',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/index.vue'),
                },
                {
                    path: 'rts',
                    name: 'PC.RuleCenter.AllocateRule.RTSOutbound',
                    meta: {
                        title: 'RTS Allocate Rule',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/index.vue'),
                },
                {
                    path: 'replenishment',
                    name: 'PC.RuleCenter.AllocateRule.Replenishment',
                    meta: {
                        menuHide: true,
                        title: 'Reverse Replenishment Rule',
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.allocateRule',
                                title: 'Allocate Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/allocate-rule/index.vue'),
                },
            ],
        },
        {
            path: 'waverule',
            name: 'ruleCenter.waveRule',
            meta: {
                title: 'Wave Rule',
            },
            component: () => import('../../views/layout/subPage.vue'),
            redirect: '/rulecenter/waverule',
            children: [
                {
                    path: '/',
                    name: 'ruleCenter.waveRule.waveRule',
                    meta: {
                        title: 'Wave Rule',
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/wave-rule/wave-rule.vue'),
                },
                {
                    path: 'waverule/create',
                    name: 'ruleCenter.waveRule.waveRule.create',
                    meta: {
                        title: 'Create Wave Rule',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.waveRule',
                                title: 'Wave Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/wave-rule/wave-rule-create.vue'),
                },
                {
                    path: 'waverule/edit/:id',
                    name: 'ruleCenter.waveRule.waveRule.edit',
                    meta: {
                        title: 'Edit Wave Rule',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.waveRule',
                                title: 'Wave Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/wave-rule/wave-rule-create.vue'),
                },
                {
                    path: 'waveRuleGroup',
                    name: 'ruleCenter.waveRule.waveRuleGroup',
                    meta: {
                        title: 'Wave Rule Group',
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/wave-group/wave-rule-group.vue'),
                },
                {
                    path: 'waveRuleGroup/create',
                    name: 'ruleCenter.waveRule.waveRuleGroup.create',
                    meta: {
                        title: 'Add New Group',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.waveRule.waveRuleGroup',
                                title: 'Wave Rule Group',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/wave-group/wave-group-create.vue'),
                },
                {
                    path: 'waveRuleGroup/edit/:id',
                    name: 'ruleCenter.waveRule.waveRuleGroup.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.waveRule.waveRuleGroup',
                                title: 'Wave Rule Group',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/wave-group/wave-group-create.vue'),
                },
                // spwms-40002 去掉自定义波次，
                // {
                //   path: 'customWaveRule',
                //   name: 'ruleCenter.waveRule.customWaveRule',
                //   meta: {
                //     title: 'Custom Wave Rule',
                //   },
                //   component: () =>
                //     import(
                //       '@/views/rule-center/wave-rule/components/custom-wave/custom-wave-rule.vue'
                //     ),
                // },
                // {
                //   path: 'customWaveRule/detail/:id',
                //   name: 'ruleCenter.waveRule.customWaveRule.detail',
                //   meta: {
                //     title: 'View',
                //     menuHide: true,
                //     navList: [
                //       {
                //         routeName: 'ruleCenter',
                //         title: 'Rule Center',
                //       },
                //       {
                //         routeName: 'ruleCenter.waveRule.customWaveRule',
                //         title: 'Custom Wave Rule',
                //       },
                //     ],
                //   },
                //   component: () =>
                //     import(
                //       '@/views/rule-center/wave-rule/components/custom-wave/custom-wave-detail.vue'
                //     ),
                // },
                // {
                //   path: 'customWaveRule/create',
                //   name: 'ruleCenter.waveRule.customWaveRule.create',
                //   meta: {
                //     title: 'Create',
                //     menuHide: true,
                //     navList: [
                //       {
                //         routeName: 'ruleCenter',
                //         title: 'Rule Center',
                //       },
                //       {
                //         routeName: 'ruleCenter.waveRule.customWaveRule',
                //         title: 'Custom Wave Rule',
                //       },
                //     ],
                //   },
                //   component: () =>
                //     import(
                //       '@/views/rule-center/wave-rule/components/custom-wave/custom-wave-create.vue'
                //     ),
                // },
                // SPWMS-31616 新增 Group Rule 界面
                {
                    path: 'groupingRule',
                    name: 'ruleCenter.waveRule.groupRule',
                    meta: {
                        title: 'Grouping Rule',
                    },
                    component: () => import('@/views/rule-center/wave-rule/components/grouping-rule/index.vue'),
                },
                {
                    path: 'storeGroupTemplate',
                    meta: {
                        title: 'Store Group Template',
                    },
                    name: 'ruleCenter.waveRule.storegrouptemplate',
                    component: reactContainerFactory({
                        name: 'ruleCenter.waveRule.storeGroupTemplate',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rule-center/wave-rule/store-group-template')),
                    },
                },
            ],
        },
        {
            path: 'pickingrule',
            name: 'ruleCenter.pickingRule',
            meta: {
                title: 'Picking Rule',
            },
            component: () => import('../../views/layout/subPage.vue'),
            redirect: '/rulecenter/pickingrule/multiPicker',
            children: [
                {
                    path: 'pickingrule',
                    name: 'ruleCenter.pickingRule.pickingRule',
                    meta: {
                        title: 'Picking Rule',
                        // SPWMS-49153
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/index.vue'),
                },
                {
                    path: 'pickingRule/create',
                    name: 'ruleCenter.pickingRule.pickingRule.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.pickingRule',
                                title: 'Picking Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-rule/create.vue'),
                },
                {
                    path: 'pickingRule/edit/:rule_id',
                    name: 'ruleCenter.pickingRule.pickingRule.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.pickingRule',
                                title: 'Picking Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-rule/create.vue'),
                },
                {
                    path: 'mtpickingrule',
                    name: 'ruleCenter.pickingRule.MTpickingRule',
                    meta: {
                        title: 'Picking Rule',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/index.vue'),
                },
                {
                    path: 'mtpickingRule/create',
                    name: 'ruleCenter.pickingRule.MTpickingRule.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.MTpickingRule',
                                title: 'Picking Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-rule/mt-create.vue'),
                },
                {
                    path: 'mtpickingRule/edit/:rule_id',
                    name: 'ruleCenter.pickingRule.MTpickingRule.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.MTpickingRule',
                                title: 'Picking Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-rule/mt-create.vue'),
                },
                {
                    path: 'pickingskills',
                    name: 'ruleCenter.pickingRule.pickingSkills',
                    meta: {
                        title: 'Picking Skill Management',
                        // SPWMS-49153
                        menuHide: true,
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-skills/index.vue'),
                },
                {
                    path: 'pickingSkills/create',
                    name: 'ruleCenter.pickingRule.pickingSkills.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.pickingSkills',
                                title: 'Picking Skills Management',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-skills/create.vue'),
                },
                {
                    path: 'pickingSkills/edit/:user_id',
                    name: 'ruleCenter.pickingRule.pickingSkills.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.pickingSkills',
                                title: 'Picking Skills Management',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-skills/create.vue'),
                },
                {
                    path: 'mtpickingskills',
                    name: 'ruleCenter.pickingRule.MTpickingSkills',
                    meta: {
                        menuHide: true,
                        title: 'Picking Skill Management',
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-skills/index.vue'),
                },
                {
                    path: 'mtpickingSkills/create',
                    name: 'ruleCenter.pickingRule.MTpickingSkills.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.MTpickingSkills',
                                title: 'Picking Skills Management',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-skills/create-mt-outbound.vue'),
                },
                {
                    path: 'mtpickingSkills/edit/:user_id',
                    name: 'ruleCenter.pickingRule.MTpickingSkills.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.pickingRule.MTpickingSkills',
                                title: 'Picking Skills Management',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/picking-skills/create-mt-outbound.vue'),
                },
                {
                    path: 'multiPicker',
                    name: 'ruleCenter.pickingRule.multiPickerRule',
                    meta: {
                        title: 'Multi-Picker Rule',
                    },
                    component: () => import('@/views/rule-center/picking-rule/components/multi-picker/index.vue'),
                },
                {
                    path: 'MTDestinationPriority',
                    name: 'ruleCenter.pickingRule.mtDestinationPriority.view',
                    meta: {
                        title: 'MT Destination Warehouse Priority',
                    },
                    component: () => import('@/views/rule-center/picking-rule/mt-destination-priority.vue'),
                },
                {
                    path: 'zoneColor',
                    name: 'ruleCenter.pickingRule.zonecolorconfig',
                    meta: {
                        title: 'Zone Color Config',
                    },
                    component: () => import('@/views/rule-center/picking-rule/zone-color-config/index.vue'),
                },
            ],
        },
        {
            path: 'sortingrule',
            name: 'ruleCenter.sortingrule',
            meta: {
                title: 'Sorting Rule',
                menuHide: true,
            },
            component: () => import('../../views/layout/subPage.vue'),
            redirect: '/rulecenter/sortingrule',
            children: [
                {
                    path: '/',
                    name: 'ruleCenter.sortingrule.sortingrule',
                    meta: {
                        title: 'Sorting Rule',
                    },
                    component: () => import('@/views/rule-center/sorting-rule/sorting-rule/index.vue'),
                },
                {
                    path: 'sortingrule/create',
                    name: 'ruleCenter.sortingrule.sortingrule.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sortingrule',
                                title: 'Sorting Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/sorting-rule/sorting-rule/create.vue'),
                },
                {
                    path: 'sortingrule/edit/:rule_id',
                    name: 'ruleCenter.sortingrule.sortingrule.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sortingrule',
                                title: 'Sorting Rule',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/sorting-rule/sorting-rule/create.vue'),
                },
                {
                    path: 'sortingskillmanagement',
                    name: 'ruleCenter.sortingrule.sortingskillmanagement',
                    meta: {
                        title: 'Sorting Skill Management',
                    },
                    component: () => import('@/views/rule-center/sorting-rule/sorting-skill-management/index.vue'),
                },
                {
                    path: 'sortingskillmanagement/create',
                    name: 'ruleCenter.sortingrule.sortingskillmanagement.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sortingrule.sortingskillmanagement',
                                title: 'Sorting Skill Management',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/sorting-rule/sorting-skill-management/create.vue'),
                },
                {
                    path: 'sortingskillmanagement/edit/:id',
                    name: 'ruleCenter.sortingrule.sortingskillmanagement.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'ruleCenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sortingrule.sortingskillmanagement',
                                title: 'Sorting Skill Management',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/sorting-rule/sorting-skill-management/create.vue'),
                },
            ],
        },
        {
            path: 'shippingrule',
            name: 'ruleCenter.shippingRule',
            meta: {
                title: 'Shipping Rule',
            },
            redirect: '/rulecenter/shippingrule/groupShipping',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'shippingrule/groupShipping',
            name: 'ruleCenter.shippingRule.groupShipping',
            meta: {
                title: 'Group Shipping',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/shipping-rule/index.vue'),
        },
        {
            path: 'shippingrule/oneawb',
            name: 'ruleCenter.shippingRule.oneAWB',
            meta: {
                title: 'One AWB',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/shipping-rule/index.vue'),
        },
        {
            path: 'replenishmentrule',
            name: 'ruleCenter.replenishmentRule',
            meta: {
                title: 'Replenishment Rule',
            },
            redirect: '/rulecenter/dialySalesConfig',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'dialySalesConfig',
            name: 'PC.RuleCenter.ReplenishmentRule.DailySalesConfig.View',
            meta: {
                title: 'Replenishment Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/replenishment-rules/index.vue'),
        },
        {
            path: 'dialySalesConfig/edit/:id',
            name: 'ruleCenter.replenishmentRule.edit',
            meta: {
                title: 'Edit Daily Sales Config',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.ReplenishmentRule.DailySalesConfig.View',
                        title: 'Replenishment Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/replenishment-rules/components/dialy-sales-config/edit.vue'),
        },
        {
            path: 'dialySalesConfig/view/:id',
            name: 'ruleCenter.replenishmentRule.view',
            meta: {
                title: 'View Daily Sales Config',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.ReplenishmentRule.DailySalesConfig.View',
                        title: 'Replenishment Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/replenishment-rules/components/dialy-sales-config/edit.vue'),
        },
        {
            path: 'inventroyRoute',
            name: 'ruleCenter.inventoryroute',
            meta: {
                title: 'Inventory Route',
            },
            redirect: '/rulecenter/inventroyRoute/inbound',
        },
        {
            path: 'inventroyRoute/inbound',
            name: 'PC.RuleCenter.InventoryRoute.Inbound',
            meta: {
                title: 'Inventory Route',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/inventory-route/tabs.vue'),
        },
        {
            path: 'inventroyRoute/replenishment',
            name: 'PC.RuleCenter.InventoryRoute.Replenishment',
            meta: {
                title: 'Inventory Route',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/inventory-route/tabs.vue'),
        },
        {
            path: 'inventroyRoute/reverse',
            name: 'PC.RuleCenter.InventoryRoute.Reverse',
            meta: {
                title: 'Inventory Route',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/inventory-route/tabs.vue'),
        },
        {
            path: 'inventroyRoute/outboundDestination',
            name: 'PC.RuleCenter.InventoryRoute.OutboundTransferDestination',
            meta: {
                title: 'Outbound Transfer Destination',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/inventory-route/tabs.vue'),
        },
        {
            path: 'inventroyRoute/outboundDestination/create',
            name: 'ruleCenter.inventoryRoute.outboundTransferDestination.create',
            meta: {
                menuHide: true,
                title: 'Outbound Transfer Destination',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.inventoryRoute.outboundTransferDestination.create',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/outbound-destination-rule/edit')),
            },
        },
        {
            path: 'inventroyRoute/outboundDestination/view/:id',
            name: 'ruleCenter.inventoryRoute.outboundTransferDestination.view',
            meta: {
                menuHide: true,
                title: 'Outbound Transfer Destination',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.inventoryRoute.outboundTransferDestination.view',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/outbound-destination-rule/edit')),
            },
        },
        {
            path: 'inventroyRoute/outboundDestination/edit/:id',
            name: 'ruleCenter.inventoryRoute.outboundTransferDestination.edit',
            meta: {
                menuHide: true,
                title: 'Outbound Transfer Destination',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.inventoryRoute.outboundTransferDestination.edit',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/outbound-destination-rule/edit')),
            },
        },
        {
            path: 'skuConfig',
            name: 'PC.RuleCenter.ReplenishmentRule.SKUConfig.View',
            meta: {
                title: 'Replenishment Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/replenishment-rules/index.vue'),
        },
        {
            path: 'skuConfig/create',
            name: 'ruleCenter.skuConfig.create',
            meta: {
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'PC.RuleCenter.ReplenishmentRule.SKUConfig.View',
                        title: 'Replenishment Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/replenishment-rules/components/sku-config/sku-create.vue'),
        },
        {
            path: 'transferType',
            name: 'PC.RuleCenter.ReplenishmentRule.TransferType.View',
            meta: {
                title: 'Replenishment Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/replenishment-rules/index.vue'),
        },
        {
            path: 'quotaconfig',
            name: 'ruleCenter.quotaConfig',
            meta: {
                title: 'Quota Config',
            },
            component: () => import('../../views/layout/subPage.vue'),
            redirect: '/rulecenter/quotaconfig/quotaconfig',
            children: [
                {
                    path: 'quotaconfig',
                    name: 'ruleCenter.quotaConfig.asnQuotaList',
                    meta: {
                        title: 'ASN Quota List',
                    },
                    component: () => import('@/views/rule-center/quota-config/asn-quota-list-tab.vue'),
                },
                {
                    path: 'quotaconfig/massedit',
                    name: 'ruleCenter.quotaConfig.asnQuotaList.massEdit',
                    meta: {
                        title: 'Quota Config Mass Edit',
                        menuHide: true,
                    },
                    component: () => import('@/views/rule-center/quota-config/asn-quota-list-tab.vue'),
                },
                {
                    path: 'quotaconfig/edit',
                    name: 'ruleCenter.quotaConfig.asnQuotaList.edit',
                    meta: {
                        title: 'Quota Config Edit',
                        menuHide: true,
                    },
                    component: () => import('@/views/rule-center/quota-config/asn-quota-list-tab.vue'),
                },
                {
                    path: 'asnquotaconfiguration',
                    name: 'ruleCenter.quotaConfig.asnQuotaConfig',
                    meta: {
                        title: 'ASN Quota Configuration',
                    },
                    component: () => import('@/views/rule-center/quota-config/asn-quota-config-tab.vue'),
                },
                {
                    path: 'asnquotaconfiguration/edit/quotaMode=:quotaMode',
                    name: 'ruleCenter.quotaConfig.asnQuotaConfig.edit',
                    meta: {
                        title: 'ASN Quota Configuration Edit',
                        menuHide: true,
                    },
                    component: () => import('@/views/rule-center/quota-config/asn-quota-config.vue'),
                },
                {
                    path: 'rtsquotalist',
                    name: 'ruleCenter.quotaConfig.rtsQuotaList',
                    meta: {
                        title: 'RTS Quota List',
                    },
                    component: () => import('@/views/rule-center/quota-config/rts-quota-list.vue'),
                },
                {
                    path: 'rtsquotaconfiguration',
                    name: 'ruleCenter.quotaConfig.rtsQuotaConfig',
                    meta: {
                        title: 'RTS Quota Configuration',
                    },
                    component: () => import('@/views/rule-center/quota-config/rts-quota-config.vue'),
                },
                {
                    path: 'sotquota',
                    name: 'ruleCenter.quotaConfig.sotQuota',
                    meta: {
                        title: 'SOT Quota',
                    },
                    component: () => import('@/views/rule-center/quota-config/sot-quota.vue'),
                },
                {
                    path: 'sotquota/edit',
                    name: 'ruleCenter.quotaConfig.sotQuota.edit',
                    meta: {
                        title: 'SOT Quota Edit',
                        menuHide: true,
                    },
                    component: () => import('@/views/rule-center/quota-config/sot-quota.vue'),
                },
            ],
        },
        {
            path: 'asndeliveryslot',
            name: 'ruleCenter.asnDeliverySlot',
            meta: {
                title: 'ASN Delivery Slot',
            },
            component: () => import('@/views/rule-center/shift-config/shift-config.vue'),
        },
        {
            path: 'asndeliveryslot/add',
            name: 'ruleCenter.asnDeliverySlot.add',
            meta: {
                title: 'Add',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.asnDeliverySlot',
                        title: 'ASN Delivery Slot',
                    },
                ],
            },
            component: () => import('@/views/rule-center/shift-config/shift-config.vue'),
        },
        {
            path: 'asndeliveryslot/edit',
            name: 'ruleCenter.asnDeliverySlot.edit',
            meta: {
                title: 'Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.asnDeliverySlot',
                        title: 'ASN Delivery Slot',
                    },
                ],
            },
            component: () => import('@/views/rule-center/shift-config/shift-config.vue'),
        },
        {
            path: 'sizetype',
            name: 'ruleCenter.sizetype',
            meta: {
                title: 'Size Type',
            },
            component: () => import('../../views/layout/subPage.vue'),
            redirect: '/rulecenter/sizetype/taskSize',
            children: [
                {
                    path: 'taskSize',
                    name: 'ruleCenter.sizetype.taskSizeType',
                    meta: {
                        title: 'Task Size Type',
                        filterSave: true,
                    },
                    component: () => import('@/views/rule-center/size-type/task-size.vue'),
                },
                {
                    path: 'taskSize/create',
                    name: 'ruleCenter.sizetype.taskSizeType.Create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.taskSizeType',
                                title: 'Task Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/task-size-create.vue'),
                },
                {
                    path: 'taskSize/view/:type_id',
                    name: 'ruleCenter.sizetype.taskSizeType.View',
                    props: true,
                    meta: {
                        title: 'View',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.taskSizeType',
                                title: 'Task Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/task-size-create.vue'),
                },
                {
                    path: 'taskSize/edit/:type_id',
                    name: 'ruleCenter.sizetype.taskSizeType.Edit',
                    props: true,
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.taskSizeType',
                                title: 'Task Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/task-size-create.vue'),
                },
                {
                    path: 'cellsize',
                    name: 'ruleCenter.sizetype.cellsize',
                    meta: {
                        title: 'Cell Size Type',
                        filterSave: true,
                    },
                    component: () => import('@/views/rule-center/size-type/cell-size.vue'),
                },
                {
                    path: 'cellsize/create',
                    name: 'ruleCenter.sizetype.cellsize.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.cellsize',
                                title: 'Cell Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/cellSizeCreate.vue'),
                },
                {
                    path: 'cellsize/view',
                    name: 'ruleCenter.sizetype.cellsize.view',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.cellsize',
                                title: 'Cell Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/cellSizeCreate.vue'),
                },
                {
                    path: 'cellsize/edit',
                    name: 'ruleCenter.sizetype.cellsize.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.cellsize',
                                title: 'Cell Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/cellSizeCreate.vue'),
                },
                {
                    path: 'skusize',
                    name: 'ruleCenter.sizetype.skusize',
                    meta: {
                        title: 'SKU Size Type',
                        filterSave: true,
                    },
                    component: () => import('@/views/rule-center/size-type/sku-size.vue'),
                },
                {
                    path: 'skusize/create',
                    name: 'ruleCenter.sizetype.skusize.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.skusize',
                                title: 'SKU Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/skuSizeCreate.vue'),
                },
                {
                    path: 'skusize/view',
                    name: 'ruleCenter.sizetype.skusize.view',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.skusize',
                                title: 'SKU Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/skuSizeCreate.vue'),
                },
                {
                    path: 'skusize/edit',
                    name: 'ruleCenter.sizetype.skusize.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.skusize',
                                title: 'SKU Size Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/skuSizeCreate.vue'),
                },
                {
                    path: 'skuweight',
                    name: 'ruleCenter.sizetype.skuweight',
                    meta: {
                        title: 'SKU Weight Type',
                        filterSave: true,
                    },
                    component: () => import('@/views/rule-center/size-type/sku-weight.vue'),
                },
                {
                    path: 'skuweight/create',
                    name: 'ruleCenter.sizetype.skuweight.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.skuweight',
                                title: 'SKU Weight Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/skuWeightCreate.vue'),
                },
                {
                    path: 'skuweight/view',
                    name: 'ruleCenter.sizetype.skuweight.view',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.skuweight',
                                title: 'SKU Weight Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/skuWeightCreate.vue'),
                },
                {
                    path: 'skuweight/edit',
                    name: 'ruleCenter.sizetype.skuweight.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'rulecenter',
                                title: 'Rule Center',
                            },
                            {
                                routeName: 'ruleCenter.sizetype',
                                title: 'Size Type',
                            },
                            {
                                routeName: 'ruleCenter.sizetype.skuweight',
                                title: 'SKU Weight Type',
                            },
                        ],
                    },
                    component: () => import('@/views/rule-center/size-type/skuWeightCreate.vue'),
                },
            ],
        },
        // {
        //   path: 'weightvalidation',
        //   name: 'ruleCenter.weightValidation',
        //   meta: {
        //     title: 'Weight Validation',
        //   },
        //   component: () =>
        //     import('@/views/rule-center/weight-validation/index.vue'),
        // },
        {
            path: 'weightvalidation',
            name: 'ruleCenter.weightValidation',
            meta: {
                title: 'Weight Validation',
            },
            component: () => import('@/views/rule-center/weight-validation/index.vue'),
        },
        {
            path: 'abc',
            name: 'ruleCenter.abc',
            meta: {
                title: 'ABC Level',
            },
            component: () => import('@/views/rule-center/abc-level/index.vue'),
        },
        {
            path: 'highValue',
            name: 'ruleCenter.highValue',
            meta: {
                title: 'High Value',
            },
            component: () => import('@/views/rule-center/high-value/index.vue'),
        },
        {
            path: 'highValue/create',
            name: 'ruleCenter.highValue.create',
            meta: {
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.highValue',
                        title: 'High Value',
                    },
                ],
            },
            component: () => import('@/views/rule-center/high-value/edit.vue'),
        },
        {
            path: 'highValue/edit/:id',
            name: 'ruleCenter.highValue.edit',
            meta: {
                title: 'Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'wmsConfig',
                        title: 'WMS Config',
                    },
                    {
                        routeName: 'wmsConfig.highValue',
                        title: 'High Value',
                    },
                ],
            },
            component: () => import('@/views/rule-center/high-value/edit.vue'),
        },
        {
            path: 'ccconfig',
            name: 'ruleCenter.cyclecountconfig',
            meta: {
                title: 'Cycle Count Config',
            },
            component: () => import('@/views/rule-center/cycle-count-config/index.vue'),
        },
        {
            path: 'cctaskrule',
            name: 'ruleCenter.CycleCountTaskRule',
            meta: {
                title: 'Cycle Count Task Rule',
            },
            component: () => import('@/views/rule-center/cycle-count-task-rule/index.vue'),
        },
        {
            path: 'cctaskrule/create',
            name: 'ruleCenter.CycleCountTaskRule.create',
            meta: {
                menuHide: true,
                title: 'Create Cycle Count Task Rule',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.CycleCountTaskRule',
                        title: 'Cycle Count Task Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/cycle-count-task-rule/location-create.vue'),
        },
        {
            path: 'cctaskrule/view',
            name: 'ruleCenter.CycleCountTaskRule.view',
            meta: {
                menuHide: true,
                title: 'View Cycle Count Task Rule',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.CycleCountTaskRule',
                        title: 'Cycle Count Task Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/cycle-count-task-rule/location-detail.vue'),
        },
        {
            path: 'cctaskrule/edit',
            name: 'ruleCenter.CycleCountTaskRule.edit',
            meta: {
                menuHide: true,
                title: 'Edit Cycle Count Task Rule',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.CycleCountTaskRule',
                        title: 'Cycle Count Task Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/cycle-count-task-rule/location-detail.vue'),
        },
        {
            path: 'ccfrequency',
            name: 'ruleCenter.cycleCountFrequency',
            meta: {
                title: 'Cycle Count Frequency',
            },
            component: () => import('@/views/rule-center/cycle-count-frequency/index.vue'),
        },
        {
            path: 'individualTargetSetting',
            name: 'ruleCenter.individualTargetSetting',
            // 这里后面要改成tab形式，已经申请了权限点：PC.RuleCenter.IndividualTargetSetting.Picking
            meta: {
                title: 'Individual Target Setting',
            },
            component: () => import('@/views/rule-center/individual-target-setting/index.vue'),
        },
        {
            path: 'outboundProcess',
            name: 'ruleCenter.outboundprocess',
            meta: {
                title: 'Outbound Process',
            },
            component: () => import('@/views/rule-center/outbound-process/index.vue'),
        },
        {
            path: 'guideSetting',
            name: 'ruleCenter.outboundprocess',
            meta: {
                menuHide: true,
                title: 'Outbound Process',
            },
            component: () => import('@/views/rule-center/outbound-process/index.vue'),
        },
        {
            path: 'outbound-process-edit/edit=:isEdit/id=:id/:criteriaId',
            name: 'PC.RuleCenter.OutboundProcess.ProcessSettings.Edit',
            meta: {
                menuHide: true,
                title: 'Edit',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.outboundprocess',
                        title: 'Outbound Process',
                    },
                ],
            },
            component: () => import('@/views/rule-center/outbound-process/process-setting-action.vue'),
        },
        {
            path: 'outbound-process-create/edit=:isEdit/id=:id',
            name: 'PC.RuleCenter.OutboundProcess.ProcessSettings.Create',
            meta: {
                menuHide: true,
                title: 'Create',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.outboundprocess',
                        title: 'Outbound Process',
                    },
                ],
            },
            component: () => import('@/views/rule-center/outbound-process/process-setting-action.vue'),
        },
        {
            path: 'querydisplay',
            name: 'ruleCenter.QueryDisplay',
            meta: {
                title: 'Query Display',
            },
            redirect: '/rulecenter/querydisplay/sku',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'querydisplay/sku',
            name: 'ruleCenter.QueryDisplay.SKUQuery',
            meta: {
                menuHide: true,
                title: 'SKU Query',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.QueryDisplay',
                        title: 'Query Display',
                    },
                ],
            },
            component: () => import('../../views/rule-center/query-display/index.vue'),
        },
        {
            path: 'packagingRule',
            name: 'ruleCenter.packagingrule',
            meta: {
                title: 'Packaging Rule',
            },
            component: () => import('@/views/rule-center/packaging-rule/index.vue'),
        },
        {
            path: 'skuTag',
            name: 'ruleCenter.skutag',
            meta: {
                menuHide: false,
                title: 'SKU Tag',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                ],
            },
            component: () => import('@/views/rule-center/sku-tag/index.vue'),
        },
        {
            path: 'skuTag/create',
            name: 'ruleCenter.skutag.create',
            meta: {
                menuHide: true,
                title: 'Create',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.skuTag',
                        title: 'SKU Tag',
                    },
                ],
            },
            component: () => import('@/views/rule-center/sku-tag/edit.vue'),
        },
        {
            path: 'skuTag/edit/:tagId',
            name: 'ruleCenter.skutag.edit',
            meta: {
                menuHide: true,
                title: 'Edit',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.skutag',
                        title: 'Sku Tag',
                    },
                ],
            },
            component: () => import('@/views/rule-center/sku-tag/edit.vue'),
        },
        {
            path: 'skuTag/view/:tagId',
            name: 'ruleCenter.skutag.view',
            meta: {
                menuHide: true,
                title: 'Detail',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.skutag',
                        title: 'SKU Tag',
                    },
                ],
            },
            component: () => import('@/views/rule-center/sku-tag/edit.vue'),
        },
        {
            path: 'skuLocationPairingConfig',
            name: 'ruleCenter.putawayRule.skuLocationPairingConfig',
            meta: {
                title: 'Putaway Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/putaway-rule/sku-location-pairing-config/index.vue'),
        },
        {
            path: 'skuLocationPairingConfig/create',
            name: 'ruleCenter.putawayRule.skuLocationPairingConfig.create',
            meta: {
                title: 'SKU-Location Pairing Rule Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.putawayRule',
                        title: 'Putaway Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/putaway-rule/sku-location-pairing-config/create.vue'),
        },
        {
            path: 'skuLocationPairingConfig/edit/:id',
            name: 'ruleCenter.putawayRule.skuLocationPairingConfig.edit',
            meta: {
                title: 'SKU-Location Pairing Rule Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.putawayRule',
                        title: 'Putaway Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/putaway-rule/sku-location-pairing-config/edit.vue'),
        },
        {
            path: 'skuLocationPairingConfig/view/:id',
            name: 'ruleCenter.putawayRule.skuLocationPairingConfig.view',
            meta: {
                title: 'SKU-Location Pairing Rule Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.putawayRule',
                        title: 'Putaway Rule',
                    },
                ],
            },
            component: () => import('@/views/rule-center/putaway-rule/sku-location-pairing-config/edit.vue'),
        },
        {
            path: 'ibtHandlingConfiguration',
            name: 'ruleCenter.ibthandlingconfiguration',
            meta: {
                title: 'IBT Handling Configuration',
            },
            component: () => import('@/views/rule-center/ibt-handling/index.vue'),
        },
        {
            path: 'skillManagementRule',
            name: 'ruleCenter.skillManagementRule',
            meta: {
                title: 'Skill Management Rule',
            },
            component: () => import('../../views/layout/subPage.vue'),
            redirect: '/rulecenter/skillManagementRule/skillConfigRule/salesOutbound/picking',
            children: [
                {
                    path: 'skillConfigRule',
                    name: 'ruleCenter.skillManagementRule.skillConfigRule',
                    meta: {
                        title: 'Skill Config Rule',
                    },
                    redirect: '/rulecenter/skillManagementRule/skillConfigRule/salesOutbound/picking',
                    component: () => import('../../views/layout/subPage.vue'),
                    children: [
                        {
                            path: 'salesOutbound',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound',
                            meta: {
                                title: 'Sales Outbound',
                                menuHide: true,
                            },
                            component: () => import('../../views/layout/subPage.vue'),
                            redirect: '/rulecenter/skillManagementRule/skillConfigRule/salesOutbound/picking',
                        },
                        {
                            path: 'salesOutbound/picking',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound.picking',
                            meta: {
                                title: 'Picking',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'salesOutbound/picking/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound.picking.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/components/picking-rule/create.vue'),
                        },
                        {
                            path: 'salesOutbound/picking/edit/:rule_id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound.picking.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/components/picking-rule/create.vue'),
                        },
                        {
                            path: 'salesOutbound/sorting',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound.sorting',
                            meta: {
                                title: 'Sorting',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'salesOutbound/sorting/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound.sorting.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/components/sorting-rule/create.vue'),
                        },
                        {
                            path: 'salesOutbound/sorting/edit/:rule_id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.salesOutbound.sorting.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/components/sorting-rule/create.vue'),
                        },
                        {
                            path: 'moveTransfer/picking',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.moveTransfer.picking',
                            meta: {
                                title: 'Move Transfer',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'moveTransfer/picking/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.moveTransfer.picking.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/components/picking-rule/mt-create.vue'),
                        },
                        {
                            path: 'moveTransfer/picking/edit/:rule_id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.moveTransfer.picking.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/components/picking-rule/mt-create.vue'),
                        },
                        {
                            path: 'cycleCount',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.cycleCount',
                            meta: {
                                title: 'Cycle Count',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'cycleCount/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.cycleCount.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.cycleCount.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/cycle-count/cycleCountEdit')),
                            },
                        },
                        {
                            path: 'cycleCount/edit/:id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.cycleCount.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.cycleCount.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/cycle-count/cycleCountEdit')),
                            },
                        },
                        {
                            path: 'rackTransfer',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.rackTransfer',
                            meta: {
                                title: 'Rack transfer',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'rackTransfer/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.rackTransfer.create',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.rackTransfer.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/rack-transfer/rackTransferEdit')),
                            },
                        },
                        {
                            path: 'rackTransfer/edit/:id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.rackTransfer.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.rackTransfer.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/rack-transfer/rackTransferEdit')),
                            },
                        },
                        {
                            path: 'inbound/putaway',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.inbound.putaway',
                            meta: {
                                title: 'Inbound',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'inbound/putaway/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.inbound.putaway.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.inbound.putaway.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/inbound/putaway/edit')),
                            },
                        },
                        {
                            path: 'inbound/putaway/edit/:id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.inbound.putaway.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.inbound.putaway.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/inbound/putaway/edit')),
                            },
                        },
                        {
                            path: 'replenishmentReturn',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.replenishmentReturn',
                            meta: {
                                title: 'Replenishment/Return',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/skill-config-rule/index.vue'),
                        },
                        {
                            path: 'replenishmentReturn/create',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.replenishmentReturn.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.replenishmentReturn.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/replenishment-return/edit')),
                            },
                        },
                        {
                            path: 'replenishmentReturn/edit/:id',
                            name: 'ruleCenter.skillManagementRule.skillConfigRule.replenishmentReturn.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.skillConfigRule.replenishmentReturn.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/skill-config-rule/replenishment-return/edit')),
                            },
                        },
                    ],
                },
                {
                    path: 'operatorSkill',
                    name: 'ruleCenter.skillmanagementrule.operatorskill',
                    meta: {
                        title: 'Operator Skill',
                    },
                    redirect: '/rulecenter/skillManagementRule/operatorSkill/salesOutbound/picking',
                    component: () => import('../../views/layout/subPage.vue'),
                    children: [
                        {
                            path: 'salesOutbound',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound',
                            meta: {
                                title: 'Sales Outbound',
                                menuHide: true,
                            },
                            component: () => import('../../views/layout/subPage.vue'),
                            redirect: '/rulecenter/skillManagementRule/operatorSkill/salesOutbound/picking',
                        },
                        {
                            path: 'salesOutbound/picking',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound.picking',
                            meta: {
                                title: 'Picking',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'salesOutbound/picking/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound.picking.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/components/picking-skills/create.vue'),
                        },
                        {
                            path: 'pickingSkills/edit/:user_id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound.picking.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/components/picking-skills/create.vue'),
                        },
                        {
                            path: 'salesOutbound/sorting',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound.sorting',
                            meta: {
                                title: 'Sorting',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'salesOutbound/sorting/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound.sorting.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/sorting-rule/sorting-skill-management/create.vue'),
                        },
                        {
                            path: 'salesOutbound/sorting/edit/:id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.salesOutbound.sorting.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/sorting-rule/sorting-skill-management/create.vue'),
                        },
                        {
                            path: 'moveTransfer/picking',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.moveTransfer.picking',
                            meta: {
                                title: 'Picking',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'moveTransfer/picking/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.moveTransfer.picking.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/components/picking-skills/create-mt-outbound.vue'),
                        },
                        {
                            path: 'moveTransfer/picking/edit/:user_id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.moveTransfer.picking.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/components/picking-skills/create-mt-outbound.vue'),
                        },
                        {
                            path: 'cycleCount',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.cycleCount',
                            meta: {
                                title: 'Cycle Count',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'cycleCount/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.cycleCount.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.cycleCount.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/cycle-count/cycleCountEdit')),
                            },
                        },
                        {
                            path: 'cycleCount/edit/:id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.cycleCount.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.cycleCount',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/cycle-count/cycleCountEdit')),
                            },
                        },
                        {
                            path: 'rackTransfer',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.rackTransfer',
                            meta: {
                                title: 'Rack transfer',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'rackTransfer/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.rackTransfer.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.rackTransfer.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/rack-transfer/rackTransferEdit')),
                            },
                        },
                        {
                            path: 'rackTransfer/edit/:id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.rackTransfer.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.rackTransfer.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/rack-transfer/rackTransferEdit')),
                            },
                        },
                        {
                            path: 'inbound/putaway',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.inbound.putaway',
                            meta: {
                                title: 'Inbound',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'inbound/putaway/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.inbound.putaway.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.inbound.putaway.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/inbound/putaway/edit')),
                            },
                        },
                        {
                            path: 'inbound/putaway/edit/:id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.inbound.putaway.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.inbound.putaway.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/inbound/putaway/edit')),
                            },
                        },
                        {
                            path: 'replenishmentReturn',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.replenishmentReturn',
                            meta: {
                                title: 'Replenishment/Return',
                                menuHide: true,
                            },
                            component: () => import('@/views/rule-center/operator-skill/index.vue'),
                        },
                        {
                            path: 'replenishmentReturn/create',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.replenishmentReturn.create',
                            meta: {
                                title: 'Create',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.replenishmentReturn.create',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/replenishment-return/edit')),
                            },
                        },
                        {
                            path: 'replenishmentReturn/edit/:id',
                            name: 'ruleCenter.skillManagementRule.operatorSkill.replenishmentReturn.edit',
                            meta: {
                                title: 'Edit',
                                menuHide: true,
                            },
                            component: reactContainerFactory({
                                name: 'ruleCenter.skillManagementRule.operatorSkill.replenishmentReturn.edit',
                            }),
                            props: {
                                component: () => lazy(() => import('@/react-app/pages/operator-skill/replenishment-return/edit')),
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: 'rearrangementrule',
            name: 'ruleCenter.rearrangementrule',
            meta: {
                title: 'Rearragement Rule',
            },
            redirect: '/rulecenter/splitRTTask',
            component: () => import('../../views/layout/subPage.vue'),
        },
        {
            path: 'splitRTTask',
            name: 'PC.RuleCenter.RearrangementRule',
            meta: {
                title: 'Rearragement Rule',
                menuHide: true,
            },
            component: () => import('@/views/rule-center/rearragement-rule/index.vue'),
        },
        {
            path: 'fulfillment-chain-daily-sales-rule',
            name: 'ruleCenter.fulfillmentchaindailysalesrule',
            meta: {
                title: 'Fulfillment Chain Daily Sales Rule',
            },
            component: () => import('@/views/rule-center/fulfillment-chain-daily-sales-rule/index.vue'),
        },
        {
            path: 'fulfillment-chain-turnover-days-rule',
            name: 'ruleCenter.fulfillmentchainturnoverdaysrule',
            meta: {
                title: 'Fulfillment Chain Turnover Days Rule',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchainturnoverdaysrule',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fulfillment-chain-turnover-days-rule/index')),
            },
        },
        {
            path: 'fulfillmentChain',
            name: 'ruleCenter.fulfillmentchain',
            meta: {
                title: 'Fulfillment Chain Config',
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchain',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fullfillment-chain-config/fullfillment-chain-list')),
            },
        },
        {
            path: 'fulfillment-chain-turnover-days-rule/create',
            name: 'ruleCenter.fulfillmentchainturnoverdaysrule.create',
            meta: {
                title: 'Create Rule',
                menuHide: true,
                navList: fulfillmentChainTurnoverDaysRuleNavList,
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchainturnoverdaysrule.create',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fulfillment-chain-turnover-days-rule/create')),
            },
        },
        {
            path: 'fulfillmentChain/create',
            name: 'PC.RuleCenter.FulfillmentChain.Create',
            meta: {
                title: 'Create',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.fulfillmentchain',
                        title: 'Fulfillment Chain Config',
                    },
                ],
                menuHide: true,
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchain',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fullfillment-chain-config/fullfillment-chain-edit')),
            },
        },
        {
            path: 'fulfillment-chain-turnover-days-rule/edit',
            name: 'ruleCenter.fulfillmentchainturnoverdaysrule.edit',
            meta: {
                title: 'Edit Rule',
                menuHide: true,
                navList: fulfillmentChainTurnoverDaysRuleNavList,
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchainturnoverdaysrule.edit',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fulfillment-chain-turnover-days-rule/update')),
            },
        },
        {
            path: 'fulfillmentChain/edit/:id',
            name: 'PC.RuleCenter.FulfillmentChain.Edit',
            meta: {
                title: 'Edit',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.fulfillmentchain',
                        title: 'Fulfillment Chain Config',
                    },
                ],
                menuHide: true,
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchain',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fullfillment-chain-config/fullfillment-chain-edit')),
            },
        },
        {
            path: 'boxing-rule',
            name: 'ruleCenter.boxingrule',
            meta: {
                title: 'Boxing Rule',
            },
            redirect: '/rulecenter/boxing-rule/replenishment-reverse',
            children: [
                {
                    path: 'replenishment-reverse',
                    meta: {
                        title: 'Replenishment/Reverse',
                        menuHide: true,
                    },
                },
                {
                    path: 'inbound',
                    meta: {
                        title: 'Inbound',
                        menuHide: true,
                    },
                },
            ],
            component: reactContainerFactory({
                name: 'ruleCenter.boxingrule',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/boxing-rule/index')),
            },
        },
        {
            path: 'fulfillment-chain-turnover-days-rule/detail',
            name: 'ruleCenter.fulfillmentchainturnoverdaysrule.detail',
            meta: {
                title: 'Rule Detail',
                menuHide: true,
                navList: fulfillmentChainTurnoverDaysRuleNavList,
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchainturnoverdaysrule.detail',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fulfillment-chain-turnover-days-rule/detail')),
            },
        },
        {
            path: 'fulfillmentChain/view/:id',
            name: 'PC.RuleCenter.FulfillmentChain.View',
            meta: {
                title: 'Detail',
                navList: [
                    {
                        routeName: 'ruleCenter',
                        title: 'Rule Center',
                    },
                    {
                        routeName: 'ruleCenter.fulfillmentchain',
                        title: 'Fulfillment Chain Config',
                    },
                ],
                menuHide: true,
            },
            component: reactContainerFactory({
                name: 'ruleCenter.fulfillmentchain',
            }),
            props: {
                component: () => lazy(() => import('@/react-app/pages/rule-center/fullfillment-chain-config/fullfillment-chain-edit')),
            },
        },
        {
            path: 'replenishReverse',
            name: 'ruleCenter.replenishreverse',
            meta: {
                title: 'Replenish/Reverse',
            },
            redirect: '/rulecenter/replenishReverse/demandPoolConfig',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'demandPoolConfig',
                    name: 'ruleCenter.replenishReverse.demandPoolConfig',
                    meta: {
                        title: 'Demand Pool Config',
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'ruleCenter.replenishReverse.demandPoolConfig',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rule-center/demand-pool-config')),
                    },
                },
            ],
        },
        {
            path: 'picking-dispatch',
            name: 'ruleCenter.pickingdispatch',
            meta: {
                title: 'Picking Dispatch',
            },
            redirect: '/rulecenter/picking-dispatch/picking-dashboard',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'picking-dashboard',
                    name: 'ruleCenter.pickingdispatch.pickingdashboard',
                    component: reactContainerFactory({
                        name: 'ruleCenter.pickingdispatch.pickingdashboard',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/rule-center/picking-dispatch/picking-dashboard')),
                    },
                    meta: {
                        title: 'Picking Dashboard',
                    },
                },
            ],
        },
    ],
};
export default ruleCenter;
