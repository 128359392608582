export const DEFAULT_STATUS_TYPE = 'default';
export const default_status_label = ['pedding', 'created'];
export const info_status_label = [
    'adjusting',
    'assigned',
    'arrived',
    'approved',
    'bdconfirm',
    'bundling',
    'counting',
    'counted',
    'checking',
    'checked',
    'normal',
    'scanning',
    'received',
    'peceived',
    'putting',
    'ongoing',
    'locked',
    'allocated',
    'approving',
    'approved',
    'pending pick',
    'pending',
    'picking',
    'picked',
    'shipping',
    'packing',
    'packed',
    'sorting',
    'sorted',
    'pms approving',
    'using',
    'judging',
];
export const success_status_label = [
    'done',
    'picked',
    'outbound',
    'accepted',
    'takeover',
];
export const danger_status_label = [
    'reject',
    'cancel',
    'cancelled',
    'cancelled',
    'damaged',
    'incomplete',
    'pick fail',
    'rejected',
    'fail',
    'labelling',
    'priority',
];
export const warning_status_label = ['repackaging', 'unarrived'];
export const primary_status_label = [
    'invalid selling type',
    'carton',
    'pcs',
    'pack',
    'unknown',
    'weighing',
];
export const getDefaultStatusTypeByLabel = (label) => {
    const status_label_map = {
        default: default_status_label,
        info: info_status_label,
        success: success_status_label,
        danger: danger_status_label,
        warning: warning_status_label,
        primary: primary_status_label,
    };
    let result = DEFAULT_STATUS_TYPE;
    Object.keys(status_label_map).find((key) => {
        // 转换全小写
        const lowerLabel = label.toLocaleLowerCase();
        if ((status_label_map[key] || []).includes(lowerLabel)) {
            result = key;
            return true;
        }
    });
    return result;
};
