import { AbstractChain } from '@scfe-common/chain-tracker';
import chains from './config';
import { SLA_CHAINS_TYPE } from '../constants';
class SLAChains extends AbstractChain {
    constructor(chain) {
        super();
        Object.keys(chain).forEach((chainProp) => {
            this[`${chainProp}`] = chain[chainProp];
        });
        // this.name = chain.name;
        // this.status = chain.status;
        this.status.type = SLA_CHAINS_TYPE;
        this.pointList = chain.getPointList.call(this, this);
    }
}
const chainList = chains.map((chain) => {
    return new SLAChains(chain);
});
export default chainList;
