import { DataCollectorInstance } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';
export default [
    {
        name: 'ReportCenterNormalExportChain',
        status: {
            useResumeConfirm: 0,
        },
        collector: {},
        getPointList: function () {
            return [
                {
                    type: ActionType.CLICK,
                    rule: {
                        selectors: '#normal-export-sla-chain',
                    },
                    handler: () => {
                        this.status.useResumeConfirm = 1;
                        this.collector = DataCollectorInstance.createTransactionCollector();
                        this.collector.startTrack({
                            name: 'custom/ReportCenterNormalExport',
                        });
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/basic\/reportcenter\/create_export_task(\?.*)?$/,
                        state: RequestState.After,
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.useResumeConfirm) {
                            const results = this.collector.endTrackByName({
                                name: 'custom/ReportCenterNormalExport',
                                isSuccess: true,
                                endAll: true,
                            });
                            results.forEach((result) => {
                                sendDataFn({
                                    pointId: result.name,
                                    customData: { ...result, type: SLA_CHAINS_TYPE },
                                });
                            });
                            this.status.useResumeConfirm = 0;
                        }
                        DataCollectorInstance.removeTransactionCollector({
                            traceId: this.collector.traceId,
                        });
                    },
                },
            ];
        },
    },
    {
        name: 'ReportCenterBookmarkExportChain',
        status: {
            useResumeConfirm: 0,
        },
        collector: {},
        getPointList: function () {
            return [
                {
                    type: ActionType.CLICK,
                    rule: {
                        selectors: '#bookmark-export-sla-chain',
                    },
                    handler: () => {
                        this.status.useResumeConfirm = 1;
                        this.collector = DataCollectorInstance.createTransactionCollector();
                        this.collector.startTrack({
                            name: 'custom/ReportCenterBookmarkExport',
                        });
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/basic\/reportcenter\/create_export_task(\?.*)?$/,
                        state: RequestState.After,
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.useResumeConfirm) {
                            const results = this.collector.endTrackByName({
                                name: 'custom/ReportCenterBookmarkExport',
                                isSuccess: true,
                                endAll: true,
                            });
                            results.forEach((result) => {
                                sendDataFn({
                                    pointId: result.name,
                                    customData: { ...result, type: SLA_CHAINS_TYPE },
                                });
                            });
                            this.status.useResumeConfirm = 0;
                        }
                        DataCollectorInstance.removeTransactionCollector({
                            traceId: this.collector.traceId,
                        });
                    },
                },
            ];
        },
    },
];
