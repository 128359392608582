export function getCountry() {
    const country = localStorage.getItem('country');
    return country ? country.split('-')[0].trim() : '';
}
export function getPlatform() {
    if (/NQ1000/gi.test(window.navigator.userAgent)) {
        return 'wp';
    }
    else if (/mobile/i.test(window.navigator.userAgent)) {
        return 'm';
    }
    else {
        return 'pc';
    }
}
