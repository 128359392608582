const regionOverview = {
    path: '/regionOverview',
    name: 'dashboard',
    meta: {
        title: 'Region Overview',
        icon: 'Dashboard',
    },
    component: () => import('@/views/layout/index.vue'),
    redirect: '/regionOverview/overview',
    children: [
        {
            path: '/regionOverview/overview',
            name: 'dashboard.regionoverview',
            meta: {
                title: 'Overview',
            },
            component: () => import('../../views/dashboard/overview/region.vue'),
        },
    ],
};
export default regionOverview;
