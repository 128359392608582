import './moduleEntryBaseURL';
import Vue from 'vue';
import SSCUI from 'ssc-ui-vue';
import SUP from 'ssc-ui-vue-pro';
import 'ssc-ui-vue-pro/dist/ssc-ui-vue-pro.css';
import 'ssc-ui-vue/dist/ssc-ui-vue-new.css';
import 'react-pro-components/es/components/style.less';
import 'ssc-ui-react/es/components/style.less';
import 'element-ui/lib/theme-chalk/index.css';
import LiveTip from '@scfe-common/utils-live-tip';
import '@scfe-common/utils-live-tip/dist/index.cjs.css';
import { Tree } from 'element-ui';
import App from './App.vue';
// import { loginRoutesConfig } from './router/config';
import store from './store';
import router from './router';
import './permission';
import { hasPermission } from '@/utils/checkPermission';
import '@/directives/permission'; // 加载按钮权限指令
// import { makeServer } from './mock/server';
import { WmsTable, SFilter, WmsButton, CustomHooks, CreateForm, Action, } from '@/components/index';
import ZonetimeDatePicker from '@/components/zonetime-date-picker';
import WMSStatusTag from '@/components/wms-status-tag';
import request from '@/utils/request';
import i18nInstall, { sscComponentI18n } from '@/i18n/index';
import { validate, validateField } from '@/utils/tools';
import PortalVue from 'portal-vue';
import './icon';
import './utils/qms.ts';
import './utils/mdap.ts';
import logger from './utils/logger';
import { getCookie } from '@/utils/cookie';
import FullstorySdk from '@fullstory-sdk/core';
import * as filters from './filters'; // global filters
import { onlyScanInput } from '@/directives/onlyScanInput';
import { errorNotificationSound } from '@/utils/notification-sound';
import '@/assets/css/variable.css';
import { i18n as sscProI18n } from 'ssc-ui-vue-pro/es/locale';
import { i18n as sscI18n } from 'ssc-ui-vue/es/locale';
import initChainTracker from './utils/chain-tracker';
import { getEnv } from './utils/getEnv';
import { start as startCoverageReport } from '@shopee/marco-reporter';
import { gt } from './i18n';
// import {
//   getRemoteApps,
//   registerAllModules,
// } from './utils/register/register-apps';
window.APP_CONTEXT = {
    request,
    // 国际化翻译能力, 根据当前设置语言, 动态转换为对应语言文案
    gt,
    store,
    router,
};
Vue.use(i18nInstall);
SSCUI.configure({
    pagination: { controlled: true },
    table: {
        useLegacyColumnWidth: true,
    },
});
Vue.use(SSCUI, {
    i18n: sscComponentI18n,
});
Vue.use(SUP);
Vue.use(Tree);
Vue.use(PortalVue, {
    portalName: 'wms-portal',
    portalTargetName: 'wms-portal-target',
});
// register global utility filters
Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.prototype.$request = request;
Vue.prototype.$validate = validate;
Vue.prototype.$validateField = validateField;
Vue.prototype.$hasPermission = hasPermission;
Vue.prototype.$logger = logger;
//覆盖掉$message.error方法，添加播放error提示音
Vue.prototype.$message.error = function (message, showClose = false) {
    Vue.prototype.$message({
        showClose,
        message,
        type: 'error',
    });
    errorNotificationSound();
};
Vue.component('wms-filter', SFilter);
Vue.component('wms-button', WmsButton);
Vue.component('custom-hooks', CustomHooks);
Vue.component('test-wms-table', WmsTable);
Vue.component('create-form', CreateForm);
Vue.component('wms-table', WmsTable);
Vue.component('wms-table-action', Action);
Vue.component('zonetime-date-picker', ZonetimeDatePicker);
Vue.component('wms-status-tag', WMSStatusTag);
Vue.directive('onlyScan', onlyScanInput);
new FullstorySdk({
    projectId: '761707315868104857',
    env: 'live',
    uid: getCookie('wms_display_name') || '',
});
// 请求拦截，用于 mock 服务时候启用，不打开提交，会影响一些跨域请求
// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }
console.log('run env', process.env.NODE_ENV, process.env.VUE_APP_RUN_ENV);
sscProI18n(sscComponentI18n);
sscI18n(sscComponentI18n);
initChainTracker();
try {
    if (process.env.NODE_ENV === 'test') {
        startCoverageReport();
    }
}
catch (err) {
    console.error('Marco coverage reporter failed to run', err);
}
// const modules = getRemoteApps();
// 等待子模块加载/注册完毕
// await registerAllModules(modules);
const vueInstance = new Vue({
    el: '#app',
    router,
    store,
    render: (h) => h(App),
});
const isLive = getEnv() === 'live';
if (isLive) {
    new LiveTip();
}
export const getVueStuff = () => {
    return {
        router,
        store,
        vueInstance: vueInstance,
    };
};
export default vueInstance;
