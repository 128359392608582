import { DEFAULT_NULL_OR_EMPTY_STRING } from '@/common/constant';
import store from '@/store';
import { errorReport } from './tools';
import { DateTime } from 'luxon';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { isNumber } from 'lodash';
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
/**
 * @ignore
 */
const ts2date = (ts) => new Date(ts);
/**
 * @ignore
 */
const addLeadingZero = (t) => (t < 10 ? '0' : '') + t.toString();
/**
 * format date
 * @param date date value
 * @param unit
 * @return formatted string
 * @return formatted date string
 */
var Months;
(function (Months) {
    Months[Months["Jan"] = 0] = "Jan";
    Months[Months["Feb"] = 1] = "Feb";
    Months[Months["Mar"] = 2] = "Mar";
    Months[Months["Apr"] = 3] = "Apr";
    Months[Months["May"] = 4] = "May";
    Months[Months["Jun"] = 5] = "Jun";
    Months[Months["Jul"] = 6] = "Jul";
    Months[Months["Aug"] = 7] = "Aug";
    Months[Months["Sept"] = 8] = "Sept";
    Months[Months["Oct"] = 9] = "Oct";
    Months[Months["Nov"] = 10] = "Nov";
    Months[Months["Dec"] = 11] = "Dec";
})(Months || (Months = {}));
/* From BE: lijie.an */
// const whsTimeZoneMap: Record<string, any> = {
//   IDF: 7,
//   VNH: 7,
//   VNW: 7,
//   IDG: 7,
//   TWK: 8,
//   THA: 7,
//   THF: 7,
//   MYL: 8,
//   MYF: 8,
//   PHL: 8,
//   PHF: 8,
//   VNN: 7,
//   TWL: 8,
//   IDE: 7,
//   SGL: 8,
//   VNS: 7,
//   MYD: 8,
//   MYV: 8,
//   IDL: 7,
//   MYE: 8,
//   IDS: 7,
//   IDH: 7,
//   IDM: 7,
//   MYI: 8,
//   THO: 7,
//   IDN: 8,
//   IDK: 8,
//   IDA: 7,
//   SGD: 8,
//   PHC: 8,
//   IDC: 7,
//   IDR: 7,
//   IDP: 7,
//   PHN: 8,
// };
// const whsTimeRegionMap: Record<string, any> = {
//   ID: 'Asia/Jakarta',
//   VN: 'Asia/Bangkok',
//   TW: 'Asia/Shanghai',
//   TH: 'Asia/Bangkok',
//   MY: 'Asia/Singapore',
//   PH: 'Asia/Manila',
//   SG: 'Asia/Singapore',
//   CN: 'Asia/Shanghai',
//   MX: 'America/Mexico_City',
// };
// 暂时的前端兜底方案，后续等后端完善 get_login_info 的 whs_id_country 增加时区字段再切换
// export function getSettingTimeZone() {
//   const whsName = getCookie('setting_whs_v2');
//   const whsTimeZoneCookie = getCookie('setting_timezone');
//   // 优先取 cookie - setting_timezone
//   if (whsTimeZoneCookie) {
//     return +whsTimeZoneCookie;
//   }
//   // 兜底取 whsTimeZoneMap
//   const mapTimeZone = whsTimeZoneMap[whsName || ''];
//   return typeof mapTimeZone === 'number' ? mapTimeZone : 8; // 没命中，兜底为 8
// }
export function getSettingTimeZone(UTCSeconds) {
    if (UTCSeconds instanceof Date) {
        UTCSeconds = new Date(UTCSeconds).getTime();
    }
    let time_region = store.state.warehouseDetail.warehouse.time_region;
    if (!time_region) {
        errorReport(`time region is error: ${time_region}`);
        time_region = 'Asia/Shanghai'; //使用+8兜底
    }
    const dt = DateTime.fromMillis(UTCSeconds, {
        zone: time_region,
    });
    const gmtZone = dt.offset / 60; //返回是以分钟为单位，需要转化成+7这种小时单位
    return gmtZone;
}
export function getTimeZeroByWhs() {
    let time_region = store.state.warehouseDetail.warehouse.time_region;
    if (!time_region) {
        errorReport(`time region is error: ${time_region}`);
        time_region = 'Asia/Shanghai'; //使用+8兜底
    }
    const currentTimestamp = Date.now();
    const currentDateTime = DateTime.fromMillis(currentTimestamp);
    // 使用 setZone 方法将时间转换为 time_region 时区
    const singaporeDateTime = currentDateTime.setZone(time_region);
    // 获取零时刻的时间戳
    const zeroMomentTimestamp = singaporeDateTime.startOf('day').toMillis();
    return zeroMomentTimestamp / 1000;
}
/**
 * 返回基于仓库时区的时间戳
 * @param UTCSeconds UTCTimestamp
 * @return warehouse timestamp
 * 后端传给前端的展示数据需要用这个转一次
 */
export function getWhTimestampByUTCSeconds(UTCSeconds) {
    if (!UTCSeconds) {
        return 0;
    }
    if (UTCSeconds.toString().length === 10) {
        UTCSeconds *= 1000;
    }
    const localDate = new Date(UTCSeconds);
    const localDateTimestamp = localDate.getTime();
    const timezoneOffset = localDate.getTimezoneOffset() / 60; // getTimezoneOffset()返回是以分钟为单位，需要转化成h
    const timezone = getSettingTimeZone(UTCSeconds);
    return localDateTimestamp + (+timezone + timezoneOffset) * 3600000;
}
/**
 * 返回基于仓库时区的UTC秒
 * @param localDateTimestamp local time stamp
 * @returns UTC Seconds Based on Whs
 * 前端传给后端的时间需要用这个方法转一次
 */
export function getUTCSecondsBasedWhs(localDateTimestamp) {
    if (!localDateTimestamp)
        return;
    if (localDateTimestamp.toString().length === 10) {
        localDateTimestamp *= 1000;
    }
    // const localDate = (localDateTimestamp !== undefined) ? new Date(localDateTimestamp) : new Date()
    const localDate = new Date(localDateTimestamp);
    const localOffset = localDate.getTimezoneOffset() * 60; // getTimezoneOffset()返回是以分钟为单位，需要转化成s
    const UTCSeconds = Math.floor(localDateTimestamp / 1000) - localOffset;
    const timezone = getSettingTimeZone(localDateTimestamp);
    return Math.floor(UTCSeconds - 3600 * +timezone);
}
/**
 * 接收本地零时刻时间戳, 返回UTC零时刻时间戳
 * @param localDateTimestampAtZero local date time stamp at zero
 * @returns UTC Seconds At Zero
 */
export function getUTCSecondsAtZero(localDateTimestampAtZero) {
    // receiving页面为了给后端传utc时间0点
    if (!localDateTimestampAtZero) {
        return;
    }
    if (!(localDateTimestampAtZero % 86400000)) {
        // utc零时刻时间戳可以整除86400000，这里是为了避免重复换算
        return localDateTimestampAtZero / 1000;
    }
    // datepicker组件选择now时使用该工具函数，入参并不是当地零点的时间戳，而是当前时刻的时间戳，这里做一层兼容
    // 这段代码有问题，toLocaleDateString方法在不同语言的返回的字符串是不一样的，会导致在new Date一次，出现Invalid Date，从而转换成NAN
    // localDateTimestampAtZero = new Date(
    //   new Date(localDateTimestampAtZero).toLocaleDateString()
    // ).getTime();
    const localDate = new Date(localDateTimestampAtZero);
    const localOffset = localDate.getTimezoneOffset() * 60; // getTimezoneOffset()返回是以分钟为单位，需要转化成s
    const utc = Math.floor(localDateTimestampAtZero / 1000) - localOffset;
    return utc;
}
/**
 * 接收零时刻时间戳, 返回本地时区时刻时间戳
 * @param utcDateTimestampAtZero local date time stamp at zone time
 * @returns UTC Seconds At Zone time
 */
export function getUTCSecondsAtZoneTime(utcDateTimestampAtZero) {
    // receiving页面为了给后端传utc时间0点
    if (!utcDateTimestampAtZero) {
        return;
    }
    if (utcDateTimestampAtZero.toString().length === 10) {
        utcDateTimestampAtZero *= 1000;
    }
    const localDate = new Date(utcDateTimestampAtZero);
    const localOffset = localDate.getTimezoneOffset() * 60; // getTimezoneOffset()返回是以分钟为单位，需要转化成s
    const utc = utcDateTimestampAtZero / 1000 + localOffset;
    return utc;
}
export function format(date, unit = 'second', isUTCZero = false // 转换为utc 0 时区 0点
) {
    if (!date) {
        return '';
    }
    if (typeof date === 'string')
        return date;
    if (typeof date === 'number') {
        // 处理前后端标准时间戳相差 3 位的问题
        if (date.toString().length === 10) {
            date *= 1000;
        }
        if (isUTCZero) {
            date = getUTCSecondsAtZoneTime(date) * 1000 || 0;
        }
        else {
            date = getWhTimestampByUTCSeconds(date);
            // const time_region =
            //   store.state.warehouseDetail.warehouse.time_region;
            // const dt = DateTime.fromMillis(date, {
            //   zone: time_region,
            // });
            // // dt.toFormat('yyyy-MM-dd HH:mm:ss');
            // year = dt.toFormat('yyyy');
            // month = dt.toFormat('MM');
            // day = dt.toFormat('dd');
            // hour = dt.toFormat('HH');
            // minute = dt.toFormat('mm');
            // second = dt.toFormat('ss');
        }
        date = ts2date(date);
    }
    const year = addLeadingZero(date.getFullYear());
    const month = addLeadingZero(date.getMonth() + 1);
    const day = addLeadingZero(date.getDate());
    const hour = addLeadingZero(date.getHours());
    const minute = addLeadingZero(date.getMinutes());
    const second = addLeadingZero(date.getSeconds());
    if (unit === 'year')
        return `${year}`;
    if (unit === 'month')
        return `${year}-${month}`;
    if (unit === 'day')
        return `${year}-${month}-${day}`;
    if (unit === 'hour')
        return `${year}-${month}-${day} ${hour}`;
    if (unit === 'minute')
        return `${year}-${month}-${day} ${hour}:${minute}`;
    if (unit === 'second')
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    if (unit === 'hour2second')
        return `${hour}:${minute}:${second}`;
    if (unit === 'reverseDay')
        return `${day} ${Months[+month - 1]} ${year}`;
    if (unit === 'rfcMonth')
        return `${year}/${month}`;
    if (unit === 'rfcDay')
        return `${year}/${month}/${day}`;
}
export function second2Day(second) {
    const days = Math.floor(second / 86400);
    const hours = Math.floor((second % 86400) / 3600);
    const minutes = Math.floor(((second % 86400) % 3600) / 60);
    return `${addLeadingZero(days)}:${addLeadingZero(hours)}:${addLeadingZero(minutes)}`;
}
export function second2Hour(second) {
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const sec = Math.floor(second % 60);
    return `${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(sec)}`;
}
export const formatTime = (value, type = 'day') => {
    if (value === 0) {
        return DEFAULT_NULL_OR_EMPTY_STRING;
    }
    return format(value, type) || value || DEFAULT_NULL_OR_EMPTY_STRING;
};
export const formatTimeUTCZero = (value, type = 'day') => {
    if (value === 0) {
        return DEFAULT_NULL_OR_EMPTY_STRING;
    }
    const isUTCZero = true;
    return (format(value, type, isUTCZero) || value || DEFAULT_NULL_OR_EMPTY_STRING);
};
export const formatMin = (time) => {
    if (!time) {
        return;
    }
    const hour = Math.floor(time / 3600);
    const minite = Math.floor((time - hour * 3600) / 60);
    if (hour === 0 && minite === 0) {
        return '1m';
    }
    if (hour === 0) {
        return `${minite}m`;
    }
    if (minite === 0) {
        return `${hour}h`;
    }
    return `${hour}h ${minite}m`;
};
export const formatTimeToAmPm = (time) => {
    const SEC_OF_DAY = 24 * 60 * 60;
    time %= SEC_OF_DAY;
    const hours = Math.floor(time / 60 / 60);
    const minutes = (time - hours * 60 * 60) / 60;
    return ((hours % 12 < 10 ? '0' : '') +
        (hours % 12) +
        ':' +
        (minutes < 10 ? '0' : '') +
        minutes +
        (hours < 12 ? 'am' : 'pm'));
};
/**
 * 返回 0 时区当天的 UTC 00:00:00
 * @param localDateTimestamp local time stamp
 * @returns UTC Seconds Based on Whs
 * 前端传给后端的时间需要用这个方法转一次
 */
export function getCurrentUTCSecondsBasedZerozone() {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const monthStr = month >= 10 ? month : `0${month}`;
    return (+new Date(`${currentDate.getFullYear()}-${monthStr}-${currentDate.getDate()}`) / 1000);
}
export function getBpoDayStart(timestamp) {
    if (isNumber(timestamp)) {
        return dayjs(timestamp).format('YYYY-MM-DD');
    }
    return timestamp;
    // return dayjs(timestamp).startOf('day').utc().unix();
}
export function getBpoDayEnd(timestamp) {
    if (isNumber(timestamp)) {
        return dayjs(timestamp).format('YYYY-MM-DD');
    }
    return timestamp;
    // return dayjs(timestamp).utc().endOf('day').unix();
}
