import request from '../../utils/request';
const baseUrl = '/api/v2/apps/system/user';
export const getUserInfo = () => {
    return request.get(`${baseUrl}/get_login_info`);
};
export const logOut = () => {
    return request.get(`${baseUrl}/user_logout`);
};
export const setUserSetting = (name, value) => {
    return request.post(`${baseUrl}/set_user_setting`, {
        name,
        value,
    });
};
export const getOldUrlMap = () => {
    return request.get('/api/menu_switch_new/list');
};
