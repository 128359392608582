import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getOldUrlMap } from '../../api/basic/user';
let OldUrl = class OldUrl extends VuexModule {
    constructor() {
        super(...arguments);
        this.toOldMap = {};
    }
    setMap(data) {
        this.toOldMap = data;
    }
    async getOldSystemUrlMap() {
        const { data } = await getOldUrlMap();
        return data;
    }
};
__decorate([
    Mutation
], OldUrl.prototype, "setMap", null);
__decorate([
    Action({ commit: 'setMap' })
], OldUrl.prototype, "getOldSystemUrlMap", null);
OldUrl = __decorate([
    Module
], OldUrl);
export default OldUrl;
