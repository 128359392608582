/**
 * Creates a Ping instance.
 * @returns {Ping}
 * @constructor
 */
class Ping {
    constructor() {
        this.favicon = '/favicon.ico';
        this.timeout = 0;
        this.logError = false;
        this.timer = undefined;
        this.start = 0;
        this.promise = undefined;
        this.resolve = undefined;
        this.reject = undefined;
        this.wasSuccess = false;
        this.img = undefined;
    }
    // constructor(opt) {
    // }
    static getInstance() {
        if (!this.instance) {
            const instance = new Ping();
            this.instance = instance;
        }
        return this.instance;
    }
    ping(source, callback) {
        if (typeof Promise !== 'undefined') {
            this.promise = new Promise((_resolve, _reject) => {
                this.resolve = _resolve;
                this.reject = _reject;
            });
        }
        this.wasSuccess = false;
        this.img = new Image();
        this.img.onload = (e) => {
            this.wasSuccess = true;
            this.pingCheck(e, callback);
        };
        this.img.onerror = (e) => {
            this.wasSuccess = false;
            this.pingCheck(e, callback);
        };
        this.start = new Date();
        if (this.timeout) {
            this.timer = setTimeout(() => {
                this.pingCheck(undefined, callback);
            }, this.timeout);
        }
        this.img.src = source + this.favicon + '?' + +new Date();
        return this.promise;
    }
    pingCheck(event, callback) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        const pong = Number(new Date()) - this.start;
        if (!callback) {
            if (this.promise) {
                return this.wasSuccess ? this.resolve(pong) : this.reject(pong);
            }
            else {
                throw new Error('Promise is not supported by your browser. Use callback instead.');
            }
        }
        else if (typeof callback === 'function') {
            if (!this.wasSuccess) {
                if (this.logError) {
                    console.error('error loading resource');
                }
                if (this.promise) {
                    this.reject(pong);
                }
                return callback('error', pong);
            }
            if (this.promise) {
                this.resolve(pong);
            }
            return callback(null, pong);
        }
        else {
            throw new Error('Callback is not a function.');
        }
    }
}
const ping = Ping.getInstance();
// export const pingAll = async function () {
//   const urlList = ['https://google.com', 'https://spx.shopee.co.id/assets'];
//   const requestList = urlList.map((url) => {
//     return new Promise((resolve, reject) => {
//       ping.ping(url, (err: any, data: any) => {
//         if (err) {
//           data = data + ' ' + err;
//           reject(data);
//         }
//         resolve({ data, url });
//       });
//     });
//   });
//   return Promise.all(requestList);
// };
export const pingInOrder = async function (pingUrl, count = 3) {
    const resList = [];
    // const pingUrl = 'https://google.com';
    for (let i = 0; i < count; i++) {
        await ping.ping(pingUrl, (err, data) => {
            if (err) {
                data = data + ' ' + err;
            }
            resList.push({ data, url: pingUrl });
        });
    }
    return resList;
};
export default ping;
