// CycleCountTaskStage
export const CYCLE_COUNT_STAGE_1ST = 1;
// systemEnums.CycleCountTaskType
export const CYCLE_COUNT_TASK_TYPE_SKU = 1;
export const CYCLE_COUNT_TASK_TYPE_LOCATION = 2;
export const CYCLE_COUNT_TASK_TYPE_MOVEMENT = 3;
// systemEnums.CycleCountOrderStatus
export const CYCLE_COUNT_ORDER_STATUS_PEDDING = 1;
export const CYCLE_COUNT_ORDER_STATUS_CANCEL = 3;
export const CYCLE_COUNT_ORDER_STATUS_DONE = 4;
// systemEnums.CycleCountTaskStatus
export const CYCLE_COUNT_TASK_STATUS_PEDDING = 1;
export const CYCLE_COUNT_TASK_STATUS_ONGOING = 2;
export const CYCLE_COUNT_TASK_STATUS_CANCEL = 3;
export const CYCLE_COUNT_TASK_STATUS_DONE = 4;
export const CYCLE_COUNT_TASK_STATUS_ASSIGNED = 5;
// systemEnums.CycleCountTaskStage
export const CYCLE_COUNT_TASK_STAGE_1ST = 1;
// systemEnums.CycleCountMethod
export const CYCLE_COUNT_METHOD_EXPRESS_SECOND = 1;
export const CYCLE_COUNT_METHOD_FULL_SECOND = 2;
// systemEnums.CycleCountSkuCCOperateFunction
export const CYCLE_COUNT_SKU_CC_OPERATE_FUNC_NORMAL = 1;
// systemEnums.CycleCountDailyLocationCCOperateFunction
export const CYCLE_COUNT_DAILY_LOCATION_CCOPERATION_FUNC_NORMAL = 2;
// systemEnums.CycleCountRecommendationSkuType
export const CYCLE_COUNT_SKU_RECOMMENDATION_CUSTOMIZED_SKU = 0;
// systemEnums.CycleCountRecommendationLocationType
export const CYCLE_COUNT_RECOMMENDATION_LOCATION_ACTIVE = 0;
export const CYCLE_COUNT_RECOMMENDATION_LOCATION_HIGH = 1;
export const CYCLE_COUNT_RECOMMENDATION_LOCATION_CUSTOMIZED = 2;
// systemEnums.HighValueLocationFrequency
export const CYCLE_COUNT_HIGH_VALUE_LOCATION_EMPTY = 3;
// systemEnums.CycleCountLocationCCOperateFunction
export const CYCLE_COUNT_LOCATION_CC_OPERATE_FUNC_NORMAL = 2;
export const CYCLE_COUNT_LOCATION_CC_OPERATE_FUNC_EMPTY_LOCATION_COUNT = 3;
export const CYCLE_COUNT_LOCATION_CC_OPERATE_FUNC_LESS_QTY_COUNT = 4;
// systemEnums.CycleCountMovementCCOperateFunction
export const CYCLE_COUNT_MOVEMENT_CC_OPERATE_FUNC_PUTAWAY = 5;
export const CYCLE_COUNT_MOVEMENT_CC_OPERATE_FUNC_PICKING = 6;
export const CYCLE_COUNT_MOVEMENT_CC_OPERATE_FUNC_RACK_TRANSFER = 7;
// systemEnums.CycleCountIsCountedInCurrentQuarter
export const CYCLE_COUNT_IS_COUNTED_IN_CURRENT_QUARTER_ALL = 2;
// systemEnums.CycleCountSourceFrom
export const CYCLE_COUNT_SOURCE_FROM_MANUAL = 1;
// systemEnums.CycleCountOrderIsDiff
export const CYCLE_COUNT_ORDER_IS_DIFF_YES = 1;
// get_cc_order_detail_sku_location_list response: is_using_uid
export const IS_UNIT_MANAGE = 1;
// 上传错误后端返回需要弹窗的 retcode
export const MSG_ARR_RETCODE_LIST = [-285012, -285005];
const ccTaskTypes = [
    CYCLE_COUNT_TASK_TYPE_SKU,
    CYCLE_COUNT_TASK_TYPE_LOCATION,
    CYCLE_COUNT_TASK_TYPE_MOVEMENT,
];
export const OPERATE_FUNCTION_MAP = {
    [CYCLE_COUNT_TASK_TYPE_SKU]: 'CycleCountSkuCCOperateFunction',
    [CYCLE_COUNT_TASK_TYPE_LOCATION]: 'CycleCountLocationCCOperateFunction',
    [CYCLE_COUNT_TASK_TYPE_MOVEMENT]: 'CycleCountMovementCCOperateFunction',
};
// systemEnums.CycleCountTaskCreateFrom
export const Cycle_Count_Task_Create_From_Dashboard = 4;
export const Cycle_Count_Task_Create_From_Order = 1;
export var CycleCountSourceFrom;
(function (CycleCountSourceFrom) {
    CycleCountSourceFrom[CycleCountSourceFrom["DAILY_CYCLE_COUNT"] = 3] = "DAILY_CYCLE_COUNT";
    CycleCountSourceFrom[CycleCountSourceFrom["MANUAL_CREATION"] = 1] = "MANUAL_CREATION";
    CycleCountSourceFrom[CycleCountSourceFrom["OB_TROUBLE_SHOOT"] = 2] = "OB_TROUBLE_SHOOT";
})(CycleCountSourceFrom || (CycleCountSourceFrom = {}));
// systemEnums.CycleCountIsLocationMovement
export const CYCLE_COUNT_IS_LOCATION_MOVEMENT_NOT = 0;
export const CYCLE_COUNT_IS_LOCATION_MOVEMENT_YES = 1;
// daily cc order notice msg
export const NOTICE_MSG = 'Please go to Rule Center>Cycle Count Frequency to configure parameters.';
// daily_cycle_count_compliance_switch=1 msg
export const NOTICE_MSG_COMPLIANCE = 'Daily Cycle Count is in compliance mode, only Park Range and Location CC is allowed.';
export var CCTaskCompleteStatus;
(function (CCTaskCompleteStatus) {
    CCTaskCompleteStatus[CCTaskCompleteStatus["COMPLETING"] = 1] = "COMPLETING";
    CCTaskCompleteStatus[CCTaskCompleteStatus["FAIL"] = 3] = "FAIL";
    CCTaskCompleteStatus[CCTaskCompleteStatus["INITIAL"] = 0] = "INITIAL";
    CCTaskCompleteStatus[CCTaskCompleteStatus["SUCCESS"] = 2] = "SUCCESS";
})(CCTaskCompleteStatus || (CCTaskCompleteStatus = {}));
