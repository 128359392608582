export const handleResponseData = (trackData) => {
    let resData = {};
    try {
        const response = trackData?.params?.response?.response;
        if (response) {
            resData = typeof response === 'string' ? JSON.parse(response) : response;
        }
        // eslint-disable-next-line no-empty
    }
    catch (error) { }
    return resData;
};
