import { checkAndHandleCycleCountErrcode } from '../../../views/inventory-management/full-stock-count/utils';
import _request from '@/utils/request';
const requestProxy = {
    get: async (...params) => {
        try {
            const res = await _request.get(...params);
            return res;
        }
        catch (e) {
            checkAndHandleCycleCountErrcode(e.retcode);
            throw e;
        }
    },
    post: async (...params) => {
        try {
            const res = await _request.post(...params);
            return res;
        }
        catch (e) {
            checkAndHandleCycleCountErrcode(e.retcode);
            throw e;
        }
    },
};
export default requestProxy;
