import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import router from '@/router';
let Filter = class Filter extends VuexModule {
    constructor() {
        super(...arguments);
        this.filterOptions = {};
        this.pageOptions = {};
        this.sortOptions = {};
    }
    setFilterOptions(data) {
        this.filterOptions = { ...this.filterOptions, ...data };
    }
    async getFilterOptions(data) {
        const save = router.history.current.meta.filterSave;
        if (!save) {
            return;
        }
        return data;
    }
    setPageOptions(data) {
        this.pageOptions = { ...this.pageOptions, ...data };
    }
    async getPageOptions(data) {
        const save = router.history.current.meta.filterSave;
        if (!save) {
            return;
        }
        return data;
    }
    setSortOptions(data) {
        this.sortOptions = { ...this.sortOptions, ...data };
    }
    async getSortOptions(data) {
        return data;
    }
};
__decorate([
    Mutation
], Filter.prototype, "setFilterOptions", null);
__decorate([
    Action({ commit: 'setFilterOptions' })
], Filter.prototype, "getFilterOptions", null);
__decorate([
    Mutation
], Filter.prototype, "setPageOptions", null);
__decorate([
    Action({ commit: 'setPageOptions' })
], Filter.prototype, "getPageOptions", null);
__decorate([
    Mutation
], Filter.prototype, "setSortOptions", null);
__decorate([
    Action({ commit: 'setSortOptions' })
], Filter.prototype, "getSortOptions", null);
Filter = __decorate([
    Module
], Filter);
export default Filter;
