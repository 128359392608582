import request from '@/utils/request';
export const checkArrivalGroupOrNot = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/check_arrival_group_or_not', {
        ...params,
    });
    return res;
};
export const completeArrival = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/complete_arrival', {
        ...params,
    });
    return res;
};
export const createAcceptRejectArrival = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/create_accept_reject_arrival', {
        ...params,
    });
    return res;
};
export const createArrival = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/create_arrival', {
        ...params,
    });
    return res;
};
export const getArrivalConfig = async () => {
    const res = await request.get('/api/v2/apps/process/inbound/transferinbound/trarrival/get_arrival_config', {
        params: {
            random: window.crypto.getRandomValues(new Uint32Array(1))[0],
        },
    });
    return res;
};
export const getAsnPalletInfo = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/transferinbound/trarrival/get_asn_pallet_info', {
        params: {
            ...params,
        },
    });
    return res;
};
export const saveArrivalMtb = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/save_arrival_mtb', {
        ...params,
    });
    return res;
};
export const scanArrivalMtb = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/scan_arrival_mtb', {
        ...params,
    });
    return res;
};
export const scanArrivalSheetId = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/scan_arrival_sheet_id', {
        ...params,
    });
    return res;
};
export const searchCheckedArrivalMtb = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/transferinbound/trarrival/search_checked_arrival_mtb', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchExpectedArrivalMtb = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/transferinbound/trarrival/search_expected_arrival_mtb', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchMissingArrivalMtb = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/transferinbound/trarrival/search_missing_arrival_mtb', {
        params: {
            ...params,
        },
    });
    return res;
};
export const updateArrivalMtb = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/transferinbound/trarrival/update_arrival_mtb', {
        ...params,
    });
    return res;
};
