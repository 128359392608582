var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'fuzzy-search-wrap',
    _vm.isCollapse ? 'collapse-fuzzy-search-wrap' : '',
  ]},[_c('s-input',{staticClass:"fuzzy-search",attrs:{"show-search-btn":!_vm.searchText || !_vm.searchText.length,"clearable":_vm.searchText && !!_vm.searchText.length},on:{"input":_vm.filter,"focus":function($event){_vm.showSearchContent = true},"blur":_vm.handleBlur},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),(_vm.showSearchContent)?_c('div',{class:[
      'fuzzy-search-content',
      _vm.searchText && !_vm.searchText.length && _vm.historyList.length
        ? 'history-fuzzy-search-content'
        : '',
    ]},[_c('ul',_vm._l((_vm.showList),function(item){return _c('li',{key:item.title},[_c('p',{class:[
            'title',
            !_vm.searchText || !_vm.searchText.length ? 'history-title' : '',
          ],domProps:{"innerHTML":_vm._s(_vm.getHighlight(item.title))},on:{"click":function($event){_vm.showSearchContent = true}}}),_c('ul',_vm._l((item.list),function(subItem){return _c('li',{key:subItem.title,staticClass:"item",on:{"click":function($event){return _vm.goLink(item.title, subItem)}}},[(!_vm.searchText || !_vm.searchText.length)?_c('svg-icon',{staticClass:"clock-icon",attrs:{"icon-class":"clock"}}):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(_vm.getHighlight(subItem.title))}})],1)}),0)])}),0),(_vm.searchText && _vm.searchText.length > 1 && !_vm.showMenuList.length)?_c('div',{staticClass:"no-result"},[_vm._v(" "+_vm._s(_vm.$i18n('No Result'))+" ")]):_vm._e(),_c('div',{staticClass:"clear-btn"},[((!_vm.searchText || !_vm.searchText.length) && _vm.historyList.length)?_c('s-button',{attrs:{"type":"text"},on:{"click":_vm.clearHistory}},[_vm._v(" Clear ")]):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }