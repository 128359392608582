const inventoryAdjustment = {
    path: 'surplus',
    name: 'inventoryManagement.surplusRegistration',
    meta: {
        title: 'Surplus Registration',
        filterSave: true,
    },
    // redirect: '/inventorymanage/adjust/list',
    component: () => import('@/views/inventory-management/surplus-registration'),
    children: [
        {
            path: 'create',
            name: 'inventoryManagement.surplusRegistration.create',
            meta: {
                title: 'Create',
                menuHide: true,
            },
            exact: true,
            component: () => import('@/views/inventory-management/surplus-registration/create'),
        },
        {
            path: 'edit',
            name: 'inventoryManagement.surplusRegistration.edit',
            meta: {
                title: 'Edit',
                menuHide: true,
            },
            exact: true,
            component: () => import('@/views/inventory-management/surplus-registration/edit'),
        },
        {
            path: 'detail',
            name: 'inventoryManagement.surplusRegistration.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
            },
            exact: true,
            component: () => import('@/views/inventory-management/surplus-registration/detail'),
        },
    ],
};
export default inventoryAdjustment;
