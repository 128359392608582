import { DataCollectorInstance } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
export default [
    {
        name: 'SLASaleInboundQC',
        status: {
            IBTErrorFlag: 0,
            errorCount: 0,
        },
        collector: {},
        ALLName: 'custom_InboundQC',
        ScanDevice: 'custom_InboundQC_scan_device',
        SubmitQcResult: 'custom_InboundQC_submit_qc_result',
        ErrorCount: 'custom_InboundQC_error_count',
        getPointList: function () {
            return [
                {
                    type: ActionType.CLICK,
                    rule: {
                        selectors: '#qc-device-input',
                    },
                    handler: () => {
                        this.collector = DataCollectorInstance.createTransactionCollector();
                        this.collector.startTrack({
                            name: this.ALLName,
                        });
                        this.collector.startTrack({
                            name: this.ScanDevice,
                        });
                        this.status.IBTErrorFlag = 0;
                        this.status.errorCount = 0;
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poqc\/scan_sheet_id(\?.*)?$/,
                        state: RequestState.After,
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            const [result] = this.collector.endTrackByName({
                                name: this.ScanDevice,
                                isSuccess: true,
                            });
                            sendDataFn({
                                pointId: result.name,
                                customData: { ...result, type: 'experienceTime' },
                            });
                        }
                    },
                },
                // 监听所有的请求报错
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poqc\//,
                        state: RequestState.After,
                    },
                    handler: (trackData, _) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode !== 0) {
                            this.status.errorCount += 1;
                        }
                    },
                },
                {
                    type: ActionType.CLICK,
                    rule: {
                        selectors: '#qc-ibt-error-submit',
                    },
                    handler: () => {
                        this.status.IBTErrorFlag = 1;
                    },
                },
                {
                    type: ActionType.CLICK,
                    rule: {
                        selectors: '#qc-sku-input',
                    },
                    handler: () => {
                        this.collector.startTrack({
                            name: this.SubmitQcResult,
                        });
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poqc\/confirm_qc(\?.*)?$/,
                        state: RequestState.After,
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            const results = this.collector.endTrackByName({
                                name: this.SubmitQcResult,
                                isSuccess: true,
                            });
                            results.forEach((result) => {
                                sendDataFn({
                                    pointId: result.name,
                                    customData: { ...result, type: 'experienceTime' },
                                });
                            });
                            // 全流程耗时和错误数量上报
                            if (response?.data.can_next_batch === false) {
                                const [allResult] = this.collector.endTrackByName({
                                    name: this.ALLName,
                                    isSuccess: true,
                                    endAll: true,
                                });
                                sendDataFn({
                                    pointId: allResult.name,
                                    customData: { ...allResult, type: 'experienceTime' },
                                });
                                sendDataFn({
                                    pointId: this.ErrorCount,
                                    customData: {
                                        errorCount_n: this.status.errorCount,
                                        IBTErrorFlag_n: this.status.IBTErrorFlag,
                                        type: 'experienceCount',
                                    },
                                });
                                DataCollectorInstance.removeTransactionCollector({
                                    traceId: this.collector.traceId,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
];
