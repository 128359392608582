import { MdapSdk } from '@mdap/javascript-sdk';
import APIPlugin from '@mdap/sdk-plugin-api';
import ResourcePlugin from '@mdap/sdk-plugin-resource';
import PagePerfPlugin from '@mdap/sdk-plugin-performance';
import ExceptionPlugin from '@mdap/sdk-plugin-exception';
// import { getEnv } from '@/utils/getEnv';
// import { getCookie } from '@/utils/cookie';
import { getEnv } from './getEnv';
import { getCookie } from './cookie';
export const getCountry = function () {
    // const country = localStorage.getItem('country');
    // return country ? country.split('-')[0].trim() : '';
    const country = getCookie('setting_whs_v2') || '';
    return country.slice(0, 2);
};
const secretKeys = {
    test: '0ea38cdbb453939860071262f1ccc9a0ca460a978c4843194e1690024fbfe8c4',
    live: '9298c2530884081edc134133d59b5c78172d3a8bbe8c08aa533162ac52cd8d06', // 需要修改
};
const env = getEnv();
const secretKey = env == 'live' ? secretKeys.live : secretKeys.test;
const sdk = new MdapSdk({
    // mdap 应用id application id
    app_name: 'wms-fev2',
    secret_key: secretKey,
    // reporting env: test | staging | uat | live
    // if environment is ‘live’ ，data will be reported to Live env for user: https://mdap.shopee.io; else, data will be reported to Test env for user:  https://mdap.exp.shopee.io
    // 上报环境: test | staging | uat | live
    // environment值为 ‘live’ 时，数据会上报到正服环境 https://mdap.shopee.io， 为其他值时数据会上报到测服  https://mdap.exp.shopee.io
    environment: env,
    // 上报地区/Reporting Region | sg | tw | ph | th | id | my | br | mx | ...
    region: getCountry(),
    // 是否开启调试日志/Toggle of debug logger | default - false
    logger: false,
    // 业务应用版本 business application version
    app_version: process.env.VUE_APP_VERSION,
    // sampling rate 采样率 - default 0.1 | 采样率控制sdk是否采样开启，如果采样命中则全引用的插件生效，否则全部不生效，采样结果会在下一次启动时(刷新或新开tab)生效 Sampling result will be effective at next startup (refresh page OR open a new tab)
    sample: 1,
});
// API 监控
const apiPlugin = new APIPlugin({
    path: ['/api', '/v2/langs'],
});
// 静态资源加载监控插件 Plugin of resource loading
const resourcePlugin = new ResourcePlugin({
    path: ['/v2/js', '/v2/css'],
});
const pagePerfPlugin = new PagePerfPlugin({
    path: ['/v2'],
});
const exceptionPlugin = new ExceptionPlugin();
sdk.use(apiPlugin);
sdk.use(resourcePlugin);
sdk.use(pagePerfPlugin);
sdk.use(exceptionPlugin);
