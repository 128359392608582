import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getNotificationSoundListV2 } from '../../api/wms-config/notification-sound';
let Notification = class Notification extends VuexModule {
    constructor() {
        super(...arguments);
        this.notificationSoundConfig = {};
    }
    setNotificationSoundConfig(data = {}) {
        this.notificationSoundConfig = data;
    }
    async getNotificationSoundConfigList() {
        const resp = await getNotificationSoundListV2();
        return resp.data.list;
    }
};
__decorate([
    Mutation
], Notification.prototype, "setNotificationSoundConfig", null);
__decorate([
    Action({ commit: 'setNotificationSoundConfig' })
], Notification.prototype, "getNotificationSoundConfigList", null);
Notification = __decorate([
    Module
], Notification);
export default Notification;
