import { numericTracker, reporter } from '@/utils/qms';
// 上报
export const reportTransaction = (data) => {
    return reporter.report({
        s: location.pathname + location.hash,
        dt: 'transaction',
        d: data,
        r: 1, // 不采样
    });
};
export const reportErrorCount = function (data = {}) {
    if (numericTracker) {
        numericTracker.collect({
            d: {
                type: 'exposure',
                ...data,
            },
            dt: 'count',
            s: location.href,
        });
    }
};
export default reporter;
