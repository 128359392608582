import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getFavorite } from '@/api/dashboard/outbound';
import { safeGet } from '@/utils/safeGet';
import isString from 'lodash/isString';
let OutboundFavoutite = class OutboundFavoutite extends VuexModule {
    constructor() {
        super(...arguments);
        // 购物车个数
        this.shoppingCartNum = 0;
        // 购物车内容
        this.shoppingCart = [];
        // 购物车标题
        this.favouriteFileName = '';
    }
    setShoppingCart(cart) {
        this.shoppingCartNum = cart?.favorites?.length || 0;
        this.shoppingCart = cart?.favorites || [];
        this.favouriteFileName = cart?.fileName || '';
    }
    async getShoppingCart() {
        const data = safeGet(await getFavorite({}), 'data');
        let favorites = data?.favorites;
        if (!favorites) {
            favorites = [];
        }
        else {
            favorites = JSON.parse(favorites).map((el) => {
                if (isString(el)) {
                    return JSON.parse(el);
                }
                return el;
            });
        }
        return ({
            favorites: (favorites || []).filter((f) => !f?.is_V2),
            fileName: data?.title,
        } || []);
    }
};
__decorate([
    Mutation
], OutboundFavoutite.prototype, "setShoppingCart", null);
__decorate([
    Action({ commit: 'setShoppingCart' })
], OutboundFavoutite.prototype, "getShoppingCart", null);
OutboundFavoutite = __decorate([
    Module
], OutboundFavoutite);
export default OutboundFavoutite;
