import { safeGet } from '@/utils/safeGet';
import store from '@/store';
import { tabNames } from '@/common/tab-names';
export const childrenToParent = (routers) => {
    const m = {}; // {<tab router path>: <menu router name>, ...}
    const revertM = {}; // {<tab router name>: <menu router name>, ...}
    for (const index in routers) {
        if (routers[index].path == '/')
            continue;
        if (routers[index]?.children) {
            const parentPath = routers[index].path;
            // construct {<tab router path>: <tab router name>, ...}
            for (const ii in routers[index].children) {
                if (!routers[index].children[ii].meta.menuHide &&
                    routers[index].children[ii].redirect) {
                    let redirect = routers[index].children[ii].redirect;
                    // in case it is the relative path, change it to be the absolute path
                    if (routers[index].children[ii].redirect &&
                        !routers[index].children[ii].redirect.startsWith('/')) {
                        redirect = parentPath + redirect;
                    }
                    m[redirect] = routers[index].children[ii].name;
                }
            }
            // construct {<tab router name>: <menu router name>, ...}
            for (const ii in routers[index].children) {
                if (routers[index]?.children[ii].meta?.menuHide) {
                    let path = routers[index].children[ii].path;
                    if (routers[index].children[ii].path &&
                        !routers[index].children[ii].path.startsWith('/')) {
                        path = parentPath + '/' + path;
                    }
                    if (path in m) {
                        revertM[routers[index].children[ii].name] = m[path];
                    }
                }
            }
        }
    }
    return revertM;
};
// find the first tab router name
const findFirstTabRouterName = (routerName) => {
    let firstTabRouterName = '';
    for (const index in tabNames) {
        const i = tabNames[index].findIndex((item) => item == routerName);
        if (i != -1) {
            firstTabRouterName = tabNames[index][0];
            break;
        }
    }
    return firstTabRouterName;
};
// find the menu router name
// 临时修复导航栏红点和打开的问题
export const searchMenuRouterName = (routerName) => {
    const m = safeGet(store.state.tabMenu, 'tabMenuMap');
    const firstTabRouterName = findFirstTabRouterName(routerName);
    if (firstTabRouterName && firstTabRouterName in m) {
        return m[firstTabRouterName];
    }
    return '';
};
