import { getArrivalConfig } from '@/api/move/arriving-preqc';
export const dynamicMap = {
    'moveInbound.arriving': {
        title: 'Arriving & Pre-QC',
        handler: async () => {
            const res = await getArrivalConfig();
            return !!res.data.is_scan_mtb;
        },
    },
};
