export default [
    {
        path: 'actList',
        name: 'laborManagement.actList',
        meta: {
            title: 'Activity List',
        },
        component: () => import('@/views/labor-management/activity-list/index.vue'),
    },
];
