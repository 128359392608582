// components 操作相关的方法
import { config } from './config';
const componentsCollection = [];
const update = () => {
    let len = componentsCollection.length;
    while (len--) {
        const vm = componentsCollection[len];
        if (vm) {
            vm[config.componentUpdateMethodName]();
            const computedWatchers = vm._computedWatchers;
            if (computedWatchers) {
                Object.keys(computedWatchers).forEach((computed) => {
                    computedWatchers[computed].update();
                });
            }
        }
    }
};
function remove(arr, item) {
    if (arr.length) {
        const index = arr.indexOf(item);
        if (index > -1) {
            return arr.splice(index, 1);
        }
    }
}
const addComponentInstance = (component) => componentsCollection.push(component);
const rmComponentInstance = (component) => remove(componentsCollection, component);
export { componentsCollection, update, addComponentInstance, rmComponentInstance, };
