import { addComponentInstance, rmComponentInstance } from './components';
export default {
    beforeCreate() {
        addComponentInstance(this);
        this._subscribing = true;
    },
    beforeDestroy() {
        if (this._subscribing) {
            rmComponentInstance(this);
            delete this._subscribing;
        }
    },
};
