const mtOutbound = {
    path: '/mtoutbound',
    name: 'mtOutbound',
    meta: {
        title: 'MT Outbound',
        icon: 'MT Outbound',
    },
    redirect: '/mtoutbound/order',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'order',
            name: 'mtOutbound.order',
            meta: {
                title: 'Order',
                filterSave: true,
            },
            component: () => import('../../views/mt-outbound/order/index.vue'),
        },
        {
            path: 'order/create',
            name: 'mtOutbound.order.create',
            meta: {
                title: 'Create',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'mtOutbound.order',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/order/create.vue'),
        },
        {
            path: 'order/edit/:orderNo',
            name: 'mtOutbound.order.edit',
            meta: {
                title: 'Edit',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'mtOutbound.order',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/order/create.vue'),
        },
        {
            path: 'order/allocate/:orderNo',
            name: 'mtOutbound.order.allocate',
            meta: {
                title: 'Allocate',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'mtOutbound.order',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/order/allocate-approve.vue'),
        },
        {
            path: 'order/approve/:orderNo',
            name: 'mtOutbound.order.approve',
            meta: {
                title: 'Approve',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'mtOutbound.order',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/order/allocate-approve.vue'),
        },
        {
            path: 'order/detail/',
            name: 'mtOutbound.order.detail',
            meta: {
                title: 'Order Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'mtOutbound.order',
                        title: 'Order',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/order/detail.vue'),
        },
        {
            path: 'task',
            name: 'mtOutbound.task',
            meta: {
                title: 'Task',
            },
            redirect: '/mtoutbound/task/picking',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'picking',
                    name: 'PC.MTOutbound.Task.Picking.Task.View',
                    meta: {
                        title: 'Picking Task',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'mtOutbound',
                                title: 'MT Outbound',
                            },
                        ],
                        filterSave: true,
                    },
                    component: () => import('../../views/mt-outbound/task/index.vue'),
                },
                {
                    path: 'checking',
                    name: 'PC.MTOutbound.Task.CheckingTask.View',
                    meta: {
                        title: 'Checking Task',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'mtOutbound',
                                title: 'MT Outbound',
                            },
                        ],
                    },
                    component: () => import('../../views/mt-outbound/task/index.vue'),
                },
                {
                    path: 'packing',
                    name: 'PC.MTOutbound.Task.Packing.Task.View',
                    meta: {
                        title: 'Packing Task',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'mtOutbound',
                                title: 'MT Outbound',
                            },
                        ],
                        filterSave: true,
                    },
                    component: () => import('../../views/mt-outbound/task/index.vue'),
                },
                {
                    path: 'shipping',
                    name: 'PC.MTOutbound.Shipping.Task.View',
                    meta: {
                        title: 'Shipping Task',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'mtOutbound',
                                title: 'MT Outbound',
                            },
                        ],
                        filterSave: true,
                    },
                    component: () => import('../../views/mt-outbound/task/index.vue'),
                },
            ],
        },
        {
            path: 'pickingTaskDetail',
            name: 'mtOutbound.task.pickingTaskDetail',
            meta: {
                title: 'Picking Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'mtOutbound.task',
                        title: 'Picking Task',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/task/picking-task-detail.vue'),
        },
        {
            path: 'checkingTaskDetail',
            name: 'mtOutbound.task.checkingTaskDetail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'PC.MTOutbound.Task.CheckingTask.View',
                        title: 'Checking Task',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/task/components/checking-task/checking-task-detail.vue'),
        },
        {
            path: 'packing/detail/:packingId',
            name: 'mtOutbound.packing.detail',
            meta: {
                title: 'Packing Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'PC.MTOutbound.Task.Packing.Task.View',
                        title: 'Packing',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/task/components/packing-task-detail.vue'),
        },
        {
            path: 'picking/:pickup_id?',
            name: 'mtOutbound.picking',
            meta: {
                title: 'Picking',
            },
            component: () => import('../../views/mt-outbound/picking/index.vue'),
        },
        {
            path: 'checking/:picking_id?',
            name: 'mtOutbound.checking',
            meta: {
                title: 'Checking',
            },
            component: () => import('../../views/mt-outbound/checking/index.vue'),
        },
        {
            path: 'packing/edit/:packingId?/:palletId?',
            name: 'mtOutbound.packing',
            meta: {
                title: 'Packing',
            },
            component: () => import('../../views/mt-outbound/packing/packing.vue'),
        },
        {
            path: 'shipping/:shippingId?',
            name: 'mtOutbound.shipping',
            meta: {
                title: 'Shipping',
            },
            component: () => import('../../views/mt-outbound/shipping/shipping.vue'),
        },
        {
            path: 'shipping/detail/:shippingId',
            name: 'mtOutbound.task.shippingTaskDetail',
            meta: {
                title: 'Shipping Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'mtOutbound',
                        title: 'MT Outbound',
                    },
                    {
                        routeName: 'PC.MTOutbound.Shipping.Task.View',
                        title: 'Shipping Task',
                    },
                ],
            },
            component: () => import('../../views/mt-outbound/task/components/shipping-task-detail.vue'),
        },
    ],
};
export default mtOutbound;
