export default [
    {
        path: 'recording',
        name: 'laborManagement.recording',
        meta: {
            title: 'Recording',
        },
        redirect: '/labormanagement/recording/attendance',
        component: () => import('@/views/labor-management/recording/index.vue'),
        children: [
            {
                path: 'attendance',
                name: 'laborManagement.recording.attendance',
                meta: {
                    title: 'Record Attendance',
                    menuHide: true,
                },
                component: () => import('@/views/labor-management/recording/attendance.vue'),
            },
            {
                path: 'activity',
                name: 'laborManagement.recording.activity',
                meta: {
                    title: 'Record Activity',
                    menuHide: true,
                },
                component: () => import('@/views/labor-management/recording/activity.vue'),
            },
        ],
    },
];
