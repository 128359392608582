export const loginRoutesConfig = [
    {
        path: '/login',
        name: 'login',
        meta: {
            title: 'Login',
        },
        component: () => import('../views/login/login.vue'),
    },
    {
        path: '/forget',
        name: 'forget',
        meta: {
            title: 'Forget',
        },
        component: () => import('../views/login/forget.vue'),
    },
    {
        path: '/reset',
        name: 'reset',
        meta: {
            title: 'Reset',
        },
        component: () => import('../views/login/reset.vue'),
    },
    {
        path: '/resetSuccess',
        name: 'resetSuccess',
        meta: {
            title: 'Reset Success',
        },
        component: () => import('../views/login/resetSuccess.vue'),
    },
    {
        path: '/google/login',
        name: 'googleLogin',
        meta: {
            title: 'googleLogin',
        },
        component: () => import('../views/login/googleLogin.vue'),
    },
    {
        path: '/tob/login',
        name: 'tobLogin',
        meta: {
            title: 'Tob Login',
        },
        component: () => import('../views/login/tobLogin.vue'),
    },
    {
        path: '/tob/callback',
        name: 'tobLoginCallback',
        meta: {
            title: 'Tob Login',
        },
        component: () => import('../views/login/tobLoginCallback.vue'),
    },
    {
        path: '/403',
        name: '403',
        meta: {
            title: '403',
        },
        component: () => import('../views/login/403.vue'),
    },
    {
        path: '*',
        meta: {
            menuHide: true,
        },
        component: () => import('../views/login/404.vue'),
    },
];
