import { __decorate } from "tslib";
import capitalize from '@/utils/capitalize';
import { enumsName } from '@/utils/safeGet';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Tag } from 'ssc-ui-vue';
import { getDefaultStatusTypeByLabel, DEFAULT_STATUS_TYPE } from './utils';
let StatusTag = class StatusTag extends Tag {
    constructor() {
        super(...arguments);
        this.statusText = ''; // Tag innerHtml text
        this.statusType = DEFAULT_STATUS_TYPE; // Tag type
    }
    onStatusChange() {
        this.setTypeMapValue();
    }
    setTypeMapValue() {
        const { statusValue, statusMap, statusEnumsKey, isAutoMap } = this;
        // 根据 当前 statusValue 返回的 label name 查找 statusType
        if (isAutoMap) {
            this.statusText = enumsName(statusEnumsKey, statusValue) || '';
            this.statusType = getDefaultStatusTypeByLabel(this.statusText);
            return;
        }
        Object.entries(statusMap).find(([typeKey = '', typeValues = []]) => {
            const result = typeValues.find((item) => {
                if (typeof item !== 'object') {
                    if (item === statusValue) {
                        // 根据 value 查找 枚举里的 keyName
                        this.statusText = enumsName(statusEnumsKey, statusValue) || '';
                        this.statusType = typeKey;
                    }
                }
                else {
                    // 自定义 label 和 value
                    if (item['value'] === statusValue) {
                        this.statusText = capitalize(item['label'] || '');
                        this.statusType = typeKey;
                        return true;
                    }
                }
            });
            return !!result;
        });
    }
    render(h) {
        const { statusType, statusText } = this;
        // return <s-tag type={statusType} {...this.$props} {...this.$attrs} {...this.$listeners}>{this.$scopedSlots || statusText}</s-tag>;
        return h('s-tag', {
            props: {
                ...this.$props,
                type: statusType,
            },
            attrs: this.$attrs,
            on: this.$listeners,
            scopedSlots: this.$scopedSlots,
        }, this.prefixLabel ? this.prefixLabel + statusText : statusText);
    }
};
__decorate([
    Prop({
        default: () => ({
            default: [],
            primary: [],
            success: [],
            warning: [],
            danger: [],
            info: [],
        }),
    })
], StatusTag.prototype, "statusMap", void 0);
__decorate([
    Prop()
], StatusTag.prototype, "statusValue", void 0);
__decorate([
    Prop()
], StatusTag.prototype, "statusEnumsKey", void 0);
__decorate([
    Prop({ default: true })
], StatusTag.prototype, "isAutoMap", void 0);
__decorate([
    Prop()
], StatusTag.prototype, "prefixLabel", void 0);
__decorate([
    Watch('statusValue', { immediate: true })
], StatusTag.prototype, "onStatusChange", null);
StatusTag = __decorate([
    Component
], StatusTag);
export default StatusTag;
