import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getWarehouseSwitchTime } from '../../api/system-config/get-warehouse-switch-time';
let SwtichTime = class SwtichTime extends VuexModule {
    constructor() {
        super(...arguments);
        this.warehouseSwitchTime = {};
    }
    setWarehouseSwitchTime(switchTime) {
        this.warehouseSwitchTime = switchTime;
    }
    async loadWarehouseSwitchTime() {
        const resp = await getWarehouseSwitchTime();
        const data = resp.data || {};
        return data;
    }
};
__decorate([
    Mutation
], SwtichTime.prototype, "setWarehouseSwitchTime", null);
__decorate([
    Action({ commit: 'setWarehouseSwitchTime' })
], SwtichTime.prototype, "loadWarehouseSwitchTime", null);
SwtichTime = __decorate([
    Module
], SwtichTime);
export default SwtichTime;
