import { SoundStatus } from '@/views/system-config/notification-center/constant';
import store from '@/store/index';
import { safeGet } from '@/utils/safeGet';
import SuccessSoundPath from '@/assets/audio/success-notification-sound.mp3';
import ScanErrorAlertPath from '@/assets/audio/scan_error_alert.mp3';
/**
 * 操作声音提示配置处理函数
 * @param operationNodeName 节点名称
 */
export function notificationSoundProcess(operationNodeName) {
    return new Promise((resolve) => {
        const config = safeGet(store.state.notification, 'notificationSoundConfig') || [];
        if (config
            .filter((item) => item.sound_type === SoundStatus.Open)
            .map((item) => item.node_name)
            .includes(operationNodeName)) {
            const audio = new Audio(SuccessSoundPath);
            audio.play();
        }
        resolve(0);
    });
}
export function errorNotificationSound() {
    return new Promise((resolve) => {
        const audio = new Audio(ScanErrorAlertPath);
        audio.play();
        resolve(0);
    });
}
