import { __decorate } from "tslib";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
let WMSButton = class WMSButton extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.requiredItemDic = {};
        this.disabledButton = false;
    }
    async onAfterChanged() {
        try {
            await this.checkRequiredItem();
            this.disabledButton = false;
        }
        catch (e) {
            console.log('error', e);
            this.disabledButton = true;
        }
    }
    async loadingClick() {
        try {
            this.loading = true;
            const formatMode = {};
            if (Object.keys(this.mode).length) {
                Object.keys(this.mode).forEach((item) => {
                    // 特别用于判断 time picker 收集的 [startTimeStamp, endTimeStamp]
                    if (item.indexOf('+') >= 0) {
                        this.setTimerRangeDateMode(item, this.mode, formatMode);
                    }
                    else {
                        formatMode[item] = this.mode[item];
                    }
                });
                await this.click(formatMode);
            }
            else {
                await this.click();
            }
        }
        catch (e) {
            console.log('error', e);
            this.loading = false;
        }
        finally {
            this.loading = false;
        }
    }
    async showButton() {
        try {
            await this.checkRequiredItem();
            this.disabledButton = false;
        }
        catch (e) {
            console.log('error', e);
            this.disabledButton = true;
        }
    }
    // 特别用于设置formatmode 的  time picker 收集的 [startTimeStamp, endTimeStamp]
    setTimerRangeDateMode(key, mode, formatMode) {
        const [startKey, endKey] = key.split('+');
        if (mode[key]) {
            const startDate = mode[key][0];
            const endDate = mode[key][1];
            formatMode[startKey] =
                typeof startDate !== 'undefined'
                    ? +new Date(startDate) / 1000
                    : startDate;
            formatMode[endKey] =
                typeof endDate !== 'undefined' ? +new Date(endDate) / 1000 : endDate;
        }
    }
    checkRequiredItem() {
        return new Promise((resolve, reject) => {
            const temp = this.$parent.ruleForm;
            const validate = Object.keys(this.requiredItemDic).every((item) => {
                return temp[item] || temp[item] === 0;
            });
            if (validate) {
                resolve(true);
            }
            else {
                reject(true);
            }
        });
    }
    formatRequiredItemDic() {
        Object.keys(this.$parent.rules).forEach((item) => {
            this.$parent.rules[item].forEach((subItem) => {
                if (subItem.required) {
                    this.requiredItemDic[item] = true;
                }
            });
        });
    }
    mounted() {
        if (this.fillAll === '' || this.fillAll) {
            this.formatRequiredItemDic();
            window.addEventListener('change', this.showButton);
            this.disabledButton = true;
        }
    }
    destroyed() {
        if (this.fillAll) {
            window.removeEventListener('change', this.showButton);
        }
    }
    render() {
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        return (<s-button title={this.title} type={this.type} disabled={this.disabled || this.disabledButton} size={this.size} shape={this.shape} plain={this.plain} loading={this.loading} onClick={this.loadingClick}>
        {this.$slots.default}
      </s-button>);
    }
};
__decorate([
    Prop()
], WMSButton.prototype, "title", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "type", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "disabled", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "size", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "shape", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "plain", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "click", void 0);
__decorate([
    Prop({
        default: () => {
            return {};
        },
    })
], WMSButton.prototype, "mode", void 0);
__decorate([
    Prop()
], WMSButton.prototype, "fillAll", void 0);
__decorate([
    Watch('$parent.ruleForm')
], WMSButton.prototype, "onAfterChanged", null);
WMSButton = __decorate([
    Component
], WMSButton);
export default WMSButton;
