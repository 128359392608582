import { typeDic, noNeedTooltip, inputGroupType } from './config';
import { isArray } from '@/utils/vue-types/utils';
import skuInputMappingFunction from '@/utils/skuInputMappingFunction';
import { skuMappingParams } from './skuMappingConst';
import InputRange from './input-range.vue';
import InputNumberRange from './input-number-range.vue';
import TextCard from './input-card.vue';
import SelectCard from './select-card.vue';
import { i18n } from '@/i18n/index';
let addMarginFlag = 4;
function isLastItem(type) {
    const formItemDic = typeDic[type];
    if (typeof formItemDic === 'undefined') {
        throw 'must registered the filtertype type dic';
    }
    addMarginFlag -= typeDic[type];
    if (!addMarginFlag) {
        addMarginFlag = 4;
        return true;
    }
    return !addMarginFlag;
}
function getParents(element, className) {
    let returnParentElem = null;
    function getParentNode(element, className) {
        if (element) {
            if (element &&
                element.classList.contains(className) &&
                element.tagName.toLowerCase() != 'body') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                returnParentElem = element;
                return;
            }
            else {
                getParentNode(element.parentElement, className);
            }
        }
        return;
    }
    getParentNode(element, className);
    return returnParentElem;
}
function generateLabel(h, renderLabel) {
    if (!renderLabel)
        return;
    return h('span', {
        props: {
            slot: 'label',
        },
        class: {
            'ssc-form-item-label': true,
        },
    }, renderLabel);
}
function generateTooltipLabel(h, item, renderLabel) {
    return h('s-tooltip', {
        props: {
            placement: 'top-start',
            content: item.label ? item.label : item.placeholder,
        },
        class: {
            tooltip: true,
        },
    }, [generateLabel(h, renderLabel)]);
}
function judgeLabelFormat(h, item) {
    let renderLabel = '';
    let maxWidth = ['big-input', 'datetimerange'].includes(item.filterType)
        ? 24
        : 12;
    const isTooltipLabel = item.label &&
        item.label.length > maxWidth &&
        !noNeedTooltip.includes(item.label);
    // 临时处理大写字符导致样式溢出问题, 超过 3个 大写字符， 强制 maxWidth = 11
    if (maxWidth === 11 && isTooltipLabel) {
        let countUpperChar = 0;
        for (let i = 0; i < item.label.length; i++) {
            const char = item.label[i].charCodeAt();
            // 判断是否为大写字符
            if (char >= 65 && char <= 90) {
                countUpperChar++;
            }
        }
        maxWidth = countUpperChar >= 4 ? 11 : maxWidth;
    }
    if (isTooltipLabel) {
        renderLabel = i18n(item.label).slice(0, maxWidth).concat('...');
    }
    else {
        renderLabel = i18n(item.label);
    }
    if (isTooltipLabel) {
        return generateTooltipLabel(h, item, renderLabel);
    }
    else {
        return generateLabel(h, renderLabel);
    }
}
export default function (self, h, input) {
    addMarginFlag = 4;
    let filterPart = self.showCollapse
        ? self.filterList
        : self.collapseList;
    if (self.showFilterSelect && self.selectFilterList.length) {
        filterPart = filterPart.filter((item) => self.selectFilterList.includes(item.tLabel));
    }
    const filterPartList = filterPart.map((filterItem) => {
        // 排除 defaultValue 值透传到控件组件中
        const { defaultValue, ...item } = filterItem;
        let sFormItem = [];
        let optionList;
        let selectOptionList;
        if (item.tLabel && !item?.hideLabel) {
            item.label = item.tLabel;
        }
        if (item.label === 'undefined') {
            item.label = '';
        }
        /* eslint-disable */
        switch ((item.filterType || '').toLocaleLowerCase()) {
            case 'big-input':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-input', {
                        props: {
                            value: self.filterData[item.prop],
                            placeholder: i18n('Please Input'),
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                input(value.trim(), item.prop);
                                const prevValue = self.filterData[item.prop];
                                if (value.trim()) {
                                    self.change(prevValue, value, item.prop);
                                }
                                else {
                                    self.filterData[item.prop] = undefined;
                                }
                            },
                            keydown: async ($event) => {
                                if ($event.keyCode === 13 && $event.target.value) {
                                    const regExp = /^\$|^#/i;
                                    if (!regExp.test($event.target.value)) {
                                        self.filterData[item.prop] = $event.target.value;
                                        if (self.enterSearch) {
                                            let searchBtn = null;
                                            const parent = getParents($event.target, 'ssc-form');
                                            if (parent) {
                                                // @ts-ignore
                                                searchBtn = parent.getElementsByClassName('filter-search ssc-button')[0];
                                            }
                                            searchBtn && searchBtn.click();
                                        }
                                    }
                                    else if (skuMappingParams.indexOf(item.prop) !== -1) {
                                        self.filterData[item.prop] = await skuInputMappingFunction($event.target.value);
                                    }
                                }
                            },
                            clear: () => {
                                self.filterData[item.prop] = undefined;
                            },
                        },
                    }),
                ];
                break;
            case 'big-input-number-group':
                if (item.selections && Object.keys(item.selections).length > 0) {
                    const _arr = [];
                    for (const [key, value] of Object.entries(item.selections)) {
                        // @ts-ignore
                        _arr.push({
                            key,
                            value,
                        });
                    }
                    selectOptionList = _arr.map((item) => {
                        return h('s-option', {
                            props: {
                                key: item.key,
                                value: item.value,
                                label: self.$i18n(item.key),
                            },
                            domProps: {
                                innerHTML: self.$i18n(item.key),
                            },
                        });
                    });
                }
                const selectValue = self.filterData[item.selectProp];
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-input-group', {
                        props: {
                            compact: true,
                            ...item,
                        },
                    }, [
                        h('s-select', {
                            // style: {
                            //   backgroundColor: '#f9fafd',
                            //   color: self.filterData[item.selectProp] ? '#3a414b' : '#d2d6db',
                            // },
                            props: {
                                value: selectValue,
                                placeholder: ' ',
                                ...item.selectPropsSetting,
                            },
                            on: {
                                change: (value) => {
                                    const combinedSelect = 'select';
                                    input(value, item.selectProp, combinedSelect);
                                    const prevValue = self.filterData[item.prop];
                                    self.change(prevValue, value, item.selectProp);
                                },
                            },
                        }, [selectOptionList]),
                        h('s-input-number', {
                            props: {
                                value: self.filterData[item.prop],
                                placeholder: i18n('Please Input'),
                                ...item,
                            },
                            on: {
                                input: (value) => {
                                    const prevValue = self.filterData[item.prop];
                                    input(value, item.prop);
                                    if (typeof value === 'number') {
                                        self.change(prevValue, value, item.prop);
                                    }
                                    else {
                                        self.filterData[item.prop] = undefined;
                                    }
                                },
                            },
                        }),
                    ]),
                ];
                break;
            case 'input':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-input', {
                        props: {
                            value: self.filterData[item.prop],
                            placeholder: i18n('Please Input'),
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                const prevValue = self.filterData[item.prop];
                                input(value.trim(), item.prop);
                                if (value.trim()) {
                                    self.change(prevValue, value, item.prop);
                                }
                                else {
                                    self.filterData[item.prop] = undefined;
                                }
                            },
                            keydown: async ($event) => {
                                if (item.scanOnly)
                                    return;
                                if ($event.keyCode === 13 && $event.target.value) {
                                    const regExp = /^\$|^#/i;
                                    if (!regExp.test($event.target.value)) {
                                        self.filterData[item.prop] = $event.target.value;
                                        if (self.enterSearch) {
                                            let searchBtn = null;
                                            const parent = getParents($event.target, 'ssc-form');
                                            if (parent) {
                                                // @ts-ignore
                                                searchBtn = parent.getElementsByClassName('filter-search ssc-button')[0];
                                            }
                                            searchBtn && searchBtn.click();
                                        }
                                    }
                                    else if (skuMappingParams.indexOf(item.prop) !== -1) {
                                        self.filterData[item.prop] = await skuInputMappingFunction($event.target.value);
                                    }
                                }
                            },
                            clear: () => {
                                if (self.onChange) {
                                    self.filterData[item.prop] = '';
                                }
                                else {
                                    self.filterData[item.prop] = undefined;
                                }
                            },
                        },
                        directives: item.scanOnly && [
                            {
                                name: 'only-scan',
                                value: {
                                    interval: item.scanOnly,
                                    expression: `filterData.${item.prop}`,
                                },
                            },
                        ],
                    }),
                ];
                break;
            case 'input-number':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-input-number', {
                        props: {
                            value: self.filterData[item.prop],
                            placeholder: i18n('Please Input Number'),
                            clearable: true,
                            ...item,
                        },
                        on: {
                            input: (value) => {
                                const prevValue = self.filterData[item.prop];
                                input(value, item.prop);
                                if (typeof value === 'number') {
                                    self.change(prevValue, value, item.prop);
                                }
                                else {
                                    self.filterData[item.prop] = undefined;
                                }
                            },
                            keydown: async ($event) => {
                                if (item.scanOnly)
                                    return;
                                // if (skuMappingParams.indexOf(item.prop) === -1) return;
                                if ($event.keyCode === 13 && $event.target.value) {
                                    const regExp = /^\$|^#/i;
                                    if (!regExp.test($event.target.value)) {
                                        self.filterData[item.prop] = +$event.target.value;
                                        if (self.enterSearch) {
                                            let searchBtn = null;
                                            const parent = getParents($event.target, 'ssc-form');
                                            if (parent) {
                                                // @ts-ignore
                                                searchBtn = parent.getElementsByClassName('filter-search ssc-button')[0];
                                            }
                                            searchBtn && searchBtn.click();
                                        }
                                    }
                                    else if (skuMappingParams.indexOf(item.prop) !== -1) {
                                        self.filterData[item.prop] = +(await skuInputMappingFunction($event.target.value));
                                    }
                                }
                            },
                            clear: () => {
                                self.filterData[item.prop] = undefined;
                            },
                        },
                    }),
                ];
                break;
            case 'input-number-clearable':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-input', {
                        props: {
                            value: self.filterData[item.prop],
                            placeholder: i18n('Please Input Number'),
                            clearable: true,
                            ...item,
                        },
                        on: {
                            input: (value) => {
                                if (!value)
                                    return;
                                const reg = /[^0-9]/g;
                                value = value.replace(reg, '');
                                const prevValue = self.filterData[item.prop];
                                input(value, item.prop);
                                if (value) {
                                    self.change(prevValue, value, item.prop);
                                }
                                else {
                                    self.filterData[item.prop] = undefined;
                                }
                            },
                            keydown: async ($event) => {
                                if (item.scanOnly)
                                    return;
                                if ($event.keyCode === 13 && $event.target.value) {
                                    const regExp = /^\$|^#/i;
                                    if (!regExp.test($event.target.value)) {
                                        const searchBtn = document.getElementsByClassName('filter-search ssc-button')[0];
                                        self.filterData[item.prop] = +$event.target.value;
                                        if (searchBtn && self.enterSearch) {
                                            searchBtn.click();
                                        }
                                    }
                                    else if (skuMappingParams.indexOf(item.prop) !== -1) {
                                        self.filterData[item.prop] = +(await skuInputMappingFunction($event.target.value));
                                    }
                                }
                            },
                            clear: () => {
                                self.filterData[item.prop] = undefined;
                            },
                        },
                    }),
                ];
                break;
            case 'datetime':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-date-picker', {
                        props: {
                            value: self.filterData[item.prop],
                            type: 'datetime',
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                input(value, item.prop);
                            },
                        },
                    }),
                ];
                break;
            case 'daterange':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-date-picker', {
                        props: {
                            value: self.filterData[item.prop.join('+')],
                            type: 'daterange',
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                const prevValue = self.filterData[item.prop.join('+')];
                                // 转为number方便store-service读写
                                const timeValue = self.getTimeValue(item, value);
                                input(timeValue, item.prop.join('+'));
                                self.change(prevValue, timeValue, item.prop.join('+'));
                            },
                        },
                    }),
                ];
                break;
            case 'datetimerange':
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-date-picker', {
                        props: {
                            'value': self.filterData[item.prop.join('+')],
                            'type': 'datetimerange',
                            'clearable': true,
                            'picker-options': item.pickerOptions,
                            'default-time': ['00:00:00', '23:59:59'],
                            ...item,
                        },
                        on: {
                            change: async (value) => {
                                const prevValue = self.filterData[item.prop.join('+')];
                                // 转为number方便store-service读写
                                const timeValue = self.getTimeValue(item, value);
                                input(timeValue, item.prop.join('+'));
                                self.change(prevValue, timeValue, item.prop.join('+'));
                                if (item.change) {
                                    // format change paramter from the filterConfig
                                    const tempFilterConfig = await item.change(timeValue, self.filterConfig, self.changeFilterData);
                                    if (tempFilterConfig && tempFilterConfig.length > 0) {
                                        self.addItem(tempFilterConfig);
                                    }
                                }
                            },
                        },
                    }),
                ];
                break;
            // 3pl-select的情况下拉框的label和value都统一改为label值
            case '3pl-select':
            case '3pl-big-select':
            case 'big-select':
            case 'select':
                const filterType = (item.filterType || '').toLocaleLowerCase();
                const threePlFilterType = Boolean(filterType === '3pl-select' || filterType === '3pl-big-select');
                if (Array.isArray(item.selections)) {
                    let selections = item.selections;
                    // 3pl-select的情况对selection数组根据label去重
                    if (threePlFilterType) {
                        selections = selections.filter(function (option, index, arr) {
                            return (arr.findIndex((element) => element.label === option.label)) === index;
                        });
                    }
                    optionList = selections.map((item) => {
                        return h('s-option', {
                            props: {
                                key: item.value,
                                value: threePlFilterType && item.label.toLocaleLowerCase() !== 'all' ? item.label : item.value,
                                label: self.$i18n(item.label),
                            },
                            domProps: {
                                innerHTML: self.$i18n(item.label),
                            },
                            key: item.value,
                        });
                    });
                }
                else {
                    if (item.selections && Object.keys(item.selections).length > 0) {
                        const _arr = [];
                        for (const [key, value] of Object.entries(item.selections)) {
                            // @ts-ignore
                            _arr.push({
                                key,
                                value,
                            });
                        }
                        optionList = _arr.map((item) => {
                            return h('s-option', {
                                props: {
                                    key: item.value,
                                    value: threePlFilterType && item.key.toLocaleLowerCase() !== 'all' ? item.key : item.value,
                                    label: self.$i18n(item.key),
                                },
                                domProps: {
                                    innerHTML: self.$i18n(item.key),
                                },
                            });
                        });
                    }
                }
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-select', {
                        attrs: {
                            tabIndex: '0',
                        },
                        props: {
                            value: self.filterData[item.prop],
                            placeholder: i18n('Please Select'),
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: async (value) => {
                                const prevValue = self.filterData[item.prop];
                                input(value, item.prop);
                                self.change(prevValue, value, item.prop);
                                if (item.change) {
                                    // format change paramter from the filterConfig
                                    const tempFilterConfig = await item.change(value, self.filterConfig, self.changeFilterData);
                                    if (tempFilterConfig && tempFilterConfig.length > 0) {
                                        self.addItem(tempFilterConfig);
                                    }
                                }
                            },
                            clear: async () => {
                                self.filterData[item.prop] = undefined;
                                if (Object.keys(self.filterData).length === 1) {
                                    const searchData = self.getFilterData();
                                    self.$parent.search &&
                                        self.$parent.search(searchData);
                                    self.onSearch && self.onSearch(searchData);
                                }
                                // 支持配置传入 clear callback
                                if (item.clear) {
                                    const tempFilterConfig = await item.clear(self.filterData[item.prop], self.filterConfig, self.changeFilterData);
                                    if (tempFilterConfig && tempFilterConfig.length > 0) {
                                        self.addItem(tempFilterConfig);
                                    }
                                }
                            },
                            ...(item.events || {})
                        },
                    }, [optionList]),
                ];
                break;
            case 'inputrange': {
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h(InputRange, {
                        props: {
                            range_value: [...(self.filterData[item.prop.join('+')] || [])],
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                const prevValue = self.filterData[item.prop.join('+')];
                                input(value, item.prop.join('+'));
                                self.change(prevValue, value, item.prop);
                            },
                        },
                    }),
                ];
                break;
            }
            case 'big-text-card':
            case 'text-card': {
                sFormItem = [
                    h(TextCard, {
                        props: {
                            inputValue: [...(self.filterData[item.prop.join('+')] || [])],
                            title: item.label,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                const prevValue = self.filterData[item.prop.join('+')];
                                input(value, item.prop.join('+'));
                                self.change(prevValue, value, item.prop);
                            },
                        },
                    }),
                ];
                break;
            }
            case 'big-select-card':
            case 'select-card': {
                sFormItem = [
                    h(SelectCard, {
                        props: {
                            inputValue: [...(self.filterData[item.prop.join('+')] || [])],
                            title: item.label,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                const prevValue = self.filterData[item.prop.join('+')];
                                input(value, item.prop.join('+'));
                                self.change(prevValue, value, item.prop);
                            },
                        },
                    }),
                ];
                break;
            }
            case 'small-input-number-range':
            case 'inputnumberrange': {
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h(InputNumberRange, {
                        props: {
                            range_value: [...(self.filterData[item.prop.join('+')] || [])],
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: (value) => {
                                const prevValue = self.filterData[item.prop.join('+')];
                                input(value, item.prop.join('+'));
                                self.change(prevValue, value, item.prop);
                            },
                        },
                    }),
                ];
                break;
            }
            case 'big-input-cascader':
            case 'input-cascader':
                if (Array.isArray(item.selections)) {
                    optionList = item.selections.map((item) => {
                        return h('s-option', {
                            props: {
                                key: item.value,
                                value: item.value,
                                label: self.$i18n(item.label),
                            },
                            domProps: {
                                innerHTML: self.$i18n(item.label),
                            },
                        });
                    });
                }
                else {
                    if (item.selections && Object.keys(item.selections).length > 0) {
                        const _arr = [];
                        for (const [key, value] of Object.entries(item.selections)) {
                            // @ts-ignore
                            _arr.push({
                                key,
                                value,
                            });
                        }
                        optionList = _arr.map((item) => {
                            return h('s-option', {
                                props: {
                                    key: item.value,
                                    value: item.value,
                                    label: self.$i18n(item.key),
                                },
                                domProps: {
                                    innerHTML: self.$i18n(item.key),
                                },
                            });
                        });
                    }
                }
                sFormItem = [
                    judgeLabelFormat(h, item),
                    h('s-input-cascader', {
                        attrs: {
                            tabIndex: '0',
                        },
                        props: {
                            value: self.filterData[item.prop],
                            placeholder: i18n('Please Select'),
                            clearable: true,
                            ...item,
                        },
                        on: {
                            change: async (value) => {
                                const prevValue = self.filterData[item.prop];
                                input(value, item.prop);
                                self.change(prevValue, value, item.prop);
                                if (item.change) {
                                    // format change paramter from the filterConfig
                                    const tempFilterConfig = await item.change(value, self.filterConfig, self.changeFilterData);
                                    if (tempFilterConfig && tempFilterConfig.length > 0) {
                                        self.addItem(tempFilterConfig);
                                    }
                                }
                            },
                            clear: async (e) => {
                                self.filterData[item.prop] = undefined;
                                if (Object.keys(self.filterData).length === 1) {
                                    const searchData = self.getFilterData();
                                    self.$parent.search &&
                                        self.$parent.search(searchData);
                                    self.onSearch && self.onSearch(searchData);
                                }
                                // 支持配置传入 clear callback
                                if (item.clear) {
                                    const tempFilterConfig = await item.clear(self.filterData[item.prop], self.filterConfig, self.changeFilterData);
                                    if (tempFilterConfig && tempFilterConfig.length > 0) {
                                        self.addItem(tempFilterConfig);
                                    }
                                }
                            },
                            ...(item.events || {})
                        },
                    }, [optionList]),
                ];
                break;
        }
        /* eslint-enable */
        const formItem = h('s-form-item', {
            class: {
                'big-input': [
                    'big-input',
                    'big-select',
                    '3pl-big-select',
                    'inputrange',
                    'inputnumberrange',
                    'big-text-card',
                    'big-select-card',
                    'big-input-cascader',
                ].includes(item.filterType),
                'date': item.filterType === 'daterange' ||
                    item.filterType === 'datetimerange',
                'last-item': isLastItem(item.filterType),
                // judge if contains label
                'only-input': !sFormItem[0],
                'big-input-group': inputGroupType.includes(item.filterType),
            },
            props: {
                inline: true,
                prop: item.prop instanceof Array ? item.prop.join('+') : item.prop,
            },
            key: isArray(item.prop) ? item.prop[0] : item.prop,
        }, [sFormItem]);
        return formItem;
    });
    return filterPartList;
}
