import * as core from '@scfe-common/core';
import { getEnv } from './getEnv';
import { getCountry } from './getCountry';
// const addLeadingZero = (t: number) => (t < 10 ? '0' : '') + t.toString();
// function formatTime(date: Date) {
//   const year: string = addLeadingZero(date.getFullYear());
//   const month: string = addLeadingZero(date.getMonth() + 1);
//   const day: string = addLeadingZero(date.getDate());
//   const hour: string = addLeadingZero(date.getHours());
//   const minute: string = addLeadingZero(date.getMinutes());
//   const second: string = addLeadingZero(date.getSeconds());
//   const milliSeconds: number = date.getMilliseconds();
//   return `${year}-${month}-${day} ${hour}:${minute}:${second}.${milliSeconds}`;
// }
const reportURL = getEnv() === 'live'
    ? 'https://autobahn.ssc.shopeemobile.com/data/'
    : 'https://autobahn.ssc.test.shopeemobile.com/data/';
// QMS\DAS
const { storage: { cookie }, request, } = core;
const basicData = {
    bt: 'SBS',
    sbt: 'WMSV2',
    ct: Math.floor(Date.now() / 1000),
    env: getEnv(),
    // d: {
    //   log: 'default log',
    // },
    dt: 'code_log',
    uid: cookie.get('wms_display_name') || '',
    ut: process.env.VUE_APP_VERSION + '',
    r: 1,
    s: location.href,
    sid: core.user.session.get() || '',
    a: getCountry() || '',
    pf: /mobile/i.test(navigator.userAgent) ? 'm' : 'pc',
};
// 日志类型集合
const types = [
    'verbose',
    'debug',
    'info',
    'warn',
    'error',
    'liveDebug',
    'assert',
];
// 日志等级，只上报高于该等级的日志
const level = 'error';
const all = (type, message) => {
    if (getEnv() === 'dev') {
        return;
    }
    if (types.indexOf(type) < types.indexOf(level))
        return;
    const logMessage = `[${type.toUpperCase()}] - ${message}`;
    const data = { ...basicData, d: { log: logMessage, type } };
    return request
        .post(reportURL, data, { _is_report: true })
        .catch((err) => {
        console.log('logger error', err);
    });
};
const verbose = (message) => all('verbose', message);
const debug = (message) => all('debug', message);
const info = (message) => all('info', message);
const warn = (message) => all('warn', message);
const error = (message) => all('error', message);
const liveDebug = (message) => all('liveDebug', message);
const assert = (message) => all('assert', message);
export default {
    verbose,
    debug,
    info,
    warn,
    error,
    liveDebug,
    assert,
};
