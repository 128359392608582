import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getCookie } from '@/utils/cookie';
import wmsBasicApi from '@/api/wms-basic/warehouse';
let WarehouseDetail = class WarehouseDetail extends VuexModule {
    constructor() {
        super(...arguments);
        this.warehouse = {};
    }
    setWarehouseDetail(warehouseDetail) {
        this.warehouse = warehouseDetail;
    }
    async loadWarehouseDetail() {
        try {
            const curWhsId = getCookie('setting_whs_v2');
            const { data } = await wmsBasicApi.detailWarehouse(curWhsId);
            /** 对于 VN 的仓库，开放 rms 入口 */
            if (curWhsId && curWhsId.toLowerCase().startsWith('vn')) {
                data.is_rms = true;
            }
            /** 对于 VN 的仓库，开放 rms 入口 */
            return data;
        }
        catch (err) {
            return {};
        }
    }
};
__decorate([
    Mutation
], WarehouseDetail.prototype, "setWarehouseDetail", null);
__decorate([
    Action({ commit: 'setWarehouseDetail' })
], WarehouseDetail.prototype, "loadWarehouseDetail", null);
WarehouseDetail = __decorate([
    Module
], WarehouseDetail);
export default WarehouseDetail;
