export default function isEmpty(val) {
    if (val === '' || val === null || val === undefined) {
        return true;
    }
    if (typeof val === 'object') {
        return !Object.keys(val).length;
    }
    return false;
}
export function emptyListFormat(list) {
    list.forEach((row) => {
        Object.keys(row).forEach((key) => {
            if (isEmpty(row[key])) {
                row[key] = '-';
            }
        });
    });
    return list;
}
