import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import routesConfig from '../../router/config';
import { childrenToParent } from '../../utils/menu';
import { getWhsStoreyNameSetup } from '@/api/wms-basic/storey';
let TabMenu = class TabMenu extends VuexModule {
    constructor() {
        super(...arguments);
        this.tabMenuMap = {};
        this.storeyShow = 0;
    }
    setTabMenu(data) {
        this.tabMenuMap = data;
    }
    // set storey tab
    setStoreyTab(data) {
        this.storeyShow = data.showStorey;
    }
    async loadTabMenu() {
        try {
            const m = childrenToParent(routesConfig);
            return m;
        }
        catch (err) {
            return {};
        }
    }
    // storage area setting tab permission - storey
    async loadShowStoreyTab() {
        try {
            const { data } = await getWhsStoreyNameSetup();
            return {
                showStorey: data.storey_name_setup || 0,
            };
        }
        catch (err) {
            return false;
        }
    }
};
__decorate([
    Mutation
], TabMenu.prototype, "setTabMenu", null);
__decorate([
    Mutation
], TabMenu.prototype, "setStoreyTab", null);
__decorate([
    Action({ commit: 'setTabMenu' })
], TabMenu.prototype, "loadTabMenu", null);
__decorate([
    Action({ commit: 'setStoreyTab' })
], TabMenu.prototype, "loadShowStoreyTab", null);
TabMenu = __decorate([
    Module
], TabMenu);
export default TabMenu;
