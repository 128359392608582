const warehouseMap = {
    path: '/warehouseMap',
    name: 'warehouseMap',
    meta: {
        title: 'Warehouse Map',
        icon: 'Labor Management',
    },
    component: () => import('@/views/layout/index.vue'),
    redirect: '/warehouseMap/mapList',
    children: [
        {
            path: 'mapList',
            name: 'warehouseMap.file',
            meta: {
                title: 'Map Editor',
            },
            component: () => import('@/views/warehouse-map/map-edit/index.vue'),
            children: [
                {
                    path: 'config',
                    // TODO: 确认路由修改
                    name: 'warehouseMap.file.mapConfig',
                    meta: {
                        title: 'Map Config',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/warehouse-map/map-edit/map-config/index.vue'),
                },
            ],
        },
        {
            path: 'recycleBin',
            name: 'warehouseMap.file.recycleBin',
            meta: {
                title: 'BinWarehouse Map Recycle ',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-edit/recycle-bin.vue'),
        },
        {
            path: 'shelfList',
            name: 'warehouseMap.elementConfig',
            meta: {
                title: 'Element Setting',
            },
            component: () => import('@/views/warehouse-map/map-element-config/shelf-list.vue'),
        },
        {
            path: 'shelfCreate',
            name: 'warehouseMap.elementConfig.shelfCreate',
            meta: {
                title: 'Shelf / Rack detail',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/shelf-view.vue'),
        },
        {
            path: 'shelfView',
            name: 'warehouseMap.elementConfig.shelfView',
            meta: {
                title: 'Shelf / Rack detail',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/shelf-view.vue'),
        },
        {
            path: 'shelfEdit',
            name: 'warehouseMap.elementConfig.shelfEdit',
            meta: {
                title: 'Shelf / Rack detail',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/shelf-view.vue'),
        },
        {
            path: 'pathwayList',
            name: 'warehouseMap.elementConfig.pathwayList',
            meta: {
                title: 'Pathway Setting',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/pathway-list.vue'),
        },
        {
            path: 'pathwayEdit',
            name: 'warehouseMap.elementConfig.pathwayEdit',
            meta: {
                title: 'Pathway Detail',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/pathway-view.vue'),
        },
        {
            path: 'pathwayCreate',
            name: 'warehouseMap.elementConfig.pathwayCreate',
            meta: {
                title: 'Pathway Detail',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/pathway-view.vue'),
        },
        {
            path: 'pathwayView',
            name: 'warehouseMap.elementConfig.pathwayView',
            meta: {
                title: 'Pathway Detail',
                menuHide: true,
            },
            component: () => import('@/views/warehouse-map/map-element-config/pathway-view.vue'),
        },
        {
            path: 'heatMap',
            name: 'warehouseMap.heatMap',
            meta: {
                title: 'Heat Map',
            },
            component: () => import('@/views/warehouse-map/heat-map/map.vue'),
        },
    ],
};
export default warehouseMap;
