import { __decorate } from "tslib";
import '../style/index.scss';
import { Component, Vue, Prop } from 'vue-property-decorator';
import spreadCustomHooks from './getCustomHooks';
let SCustomHooks = class SCustomHooks extends Vue {
    render(h) {
        const customHooks = spreadCustomHooks(this, h);
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        return <div class="s-custom-hooks">{customHooks}</div>;
    }
};
__decorate([
    Prop()
], SCustomHooks.prototype, "customHooks", void 0);
SCustomHooks = __decorate([
    Component
], SCustomHooks);
export default SCustomHooks;
