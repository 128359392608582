// 根据权限过滤不需要处理的路由
export const filterByPermission = (config, permissionMenu) => {
    const keys = Object.keys(config).filter((key) => permissionMenu.includes(key));
    return keys.reduce((acc, key) => {
        acc[key] = { ...config[key] };
        return acc;
    }, {});
};
// 并发拉取业务接口，没有使用 Promise.all，所以即使单个接口出错也不影响
export const asyncConfigQueue = async (config) => {
    const result = {};
    try {
        await Object.keys(config)
            .map((key) => [config[key].handler(), key])
            .reduce((task, [handler, key]) => {
            return task
                .then(() => handler)
                .then((res) => {
                if (res) {
                    result[key] = config[key].title;
                }
            })
                .catch();
        }, Promise.resolve());
        return result;
    }
    catch (err) {
        return {};
    }
};
export const changeMenuList = (menuList, resultMap) => {
    //目前 menuList 只有两层，暂时不使用递归
    const newMenuList = menuList.map((item) => {
        if (resultMap[item.name]) {
            console.log(resultMap[item.name]);
            return {
                ...item,
                title: resultMap[item.name],
            };
        }
        if (Array.isArray(item.children)) {
            item.children = item.children.map((child) => {
                if (resultMap[child.name]) {
                    console.log(resultMap[child.name]);
                    return {
                        ...child,
                        meta: {
                            ...child.meta,
                            title: resultMap[child.name],
                        },
                    };
                }
                else {
                    return { ...child };
                }
            });
        }
        return { ...item };
    });
    return newMenuList;
};
export const patchNavbarItemTitle = (item, config) => {
    if (config[item.name]) {
        return config[item.name].title;
    }
    else {
        return item.name;
    }
};
export const changeRouteConfig = (routerConfig, resultMap) => {
    const newRouterConfig = routerConfig.map((item) => {
        if (resultMap[item.name]) {
            return {
                ...item,
                title: resultMap[item.name],
            };
        }
        if (Array.isArray(item.children)) {
            item.children = item.children.map((child) => {
                const newChild = { ...child };
                if (resultMap[child.name]) {
                    newChild.meta = {
                        ...child.meta,
                        title: resultMap[child.name],
                    };
                }
                return newChild;
            });
        }
        return { ...item };
    });
    return newRouterConfig;
};
