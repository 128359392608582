import {
  createContext,
  useMemo,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from 'react';
import { i18n, EventBus } from '@/i18n';

export const I18nContext = createContext({
  i18n: (param: string) => {
    //
    return param;
  },
});

type Ti18n = (key: string, params?: string[]) => string;

export const useTransition = (): Ti18n => {
  const { i18n } = useContext(I18nContext);
  return i18n;
};
export default function I18nProvider({ children }: { children: ReactNode }) {
  const [langChangeToggle, setLangChange] = useState(false);
  useEffect(() => {
    EventBus.$on('langChange', () => {
      setLangChange(!langChangeToggle);
    });
  }, [langChangeToggle]);
  const value = useMemo(() => {
    if (langChangeToggle) {
      // 纯绕开规则用
    }
    return { i18n };
  }, [langChangeToggle]);
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
}
