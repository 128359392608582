var Enums_1;
import { __decorate } from "tslib";
import { safeGet } from '@/utils/safeGet';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import enums from '../../api/basic/enum';
import { errorReport } from '@/utils/tools';
let Enums = Enums_1 = class Enums extends VuexModule {
    constructor() {
        super(...arguments);
        this.systemEnums = {};
    }
    setSystemEnums(systemEnums) {
        this.systemEnums = systemEnums;
    }
    async loadSystemEnums() {
        //转换配置，这个很重要，后端没法将老的key改成数组格式，因为pda那边老的key也用上了，只能加了个对应的新的key，返回排序好的数组
        //这里后期需要优化下
        const changeConfig = {
            CheckingTaskStatus: 'CheckingTaskStatusList',
            CheckingTaskMassOrderFlag: 'CheckingTaskMassOrderFlagList',
            CheckingTaskSbsFlag: 'CheckingTaskSbsFlagList',
            CheckinTaskPrintTag: 'CheckinTaskPrintTagList',
            MoveTransferOutboundStatus: 'MoveTransferOutboundStatusList',
            PickStatus: 'PickStatusList',
            OutboundTaskStatus: 'OutboundTaskStatusList',
            PaperlessFlag: 'PaperlessFlagList',
            PickingTaskMassOrderFlag: 'PickingTaskMassOrderFlagList',
            RTSOrderStatus: 'RTSOrderStatusList',
            RTSPrintTag: 'RTSPrintTagList',
            WaveRuleType: 'WaveRuleTypeList',
            SalesOrderStatus: 'SalesOrderStatusList',
            SalesOrderMassOrderFlag: 'SalesOrderMassOrderFlagList',
            SalesOrderUrgentFlag: 'SalesOrderUrgentFlagList',
            SalesOrderSbsFlag: 'SalesOrderSbsFlagList',
            SalesOrderPendingOTFlag: 'SalesOrderPendingOTFlagList',
            ShipTaskStatus: 'ShipTaskStatusList',
            SortingTaskStatus: 'SortingTaskStatusList',
            WaveStatus: 'WaveStatusList',
            CommonEnum: 'CommonEnumYAndN',
            PackTaskStatus: 'PackTaskStatusList',
            ReverseTaskStatus: 'ReverseTaskStatusList',
            MTOPackingTaskStatus: 'MTOPackingTaskStatusList',
            MTOShippingTaskStatus: 'MTOShippingTaskStatusList',
        };
        const resp = await enums.systemEnums();
        let data = resp.data || {};
        //这里添加rms的枚举值获取
        // const systemEnums = enums.systemEnums();
        // const rmsEnums = enums.rmsEnums();
        // const res = await Promise.all([systemEnums, rmsEnums]);
        // const data: any = { ...res[0].data, ...res[1].data };
        const rmsEnums = await Enums_1.getRmsEnums();
        data = { ...data, ...rmsEnums };
        // const rmsEnums = await enums.rmsEnums();
        // data = { ...data, ...rmsEnums };
        //枚举值，假如不需要排序的字段，返回object,假如需要排序的字段，返回array，统一处理成object
        const newData = {};
        newData.CommonEnumYesAndNo = safeGet(data, 'CommonEnum') || {};
        Object.keys(data).forEach((key) => {
            //假如存在转换的key，使用新key进行转换
            if (changeConfig[key] && data[changeConfig[key]]) {
                data[key] = data[changeConfig[key]];
            }
            //假如是数组，转换成对象，跟其他数据一致
            if (Array.isArray(data[key])) {
                const _obj = {};
                data[key].forEach((item) => {
                    _obj[item.key] = item.value;
                });
                newData[key] = _obj;
            }
            else {
                newData[key] = data[key];
            }
        });
        return newData;
    }
    static async getRmsEnums() {
        let data = {};
        try {
            const res = await enums.rmsEnums();
            data = res.data ? res.data : {};
        }
        catch (e) {
            errorReport(e);
        }
        return data;
    }
};
__decorate([
    Mutation
], Enums.prototype, "setSystemEnums", null);
__decorate([
    Action({ commit: 'setSystemEnums' })
], Enums.prototype, "loadSystemEnums", null);
Enums = Enums_1 = __decorate([
    Module
], Enums);
export default Enums;
