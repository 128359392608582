import Vue from 'vue';
import VueRouter from 'vue-router';
import routesConfig from './config';
import { loginRoutesConfig } from './login-route.config';
import request from '@/utils/request';
import Store from '@/store';
import { subscribeCCRouterCheck } from './router-check';
// import { getCookie } from '@/utils/cookie';
import { ReportDwellTime, reportPV } from '@/utils/qms';
import regionOverview from './region-overview-config';
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: '/v2/',
    routes: [...routesConfig, ...regionOverview, ...loginRoutesConfig],
});
let dwellTimeReport;
// 切换路由的时候清空之前所有缓存的请求
router.beforeEach((to, from, next) => {
    request.clearRequestList();
    Store.dispatch('getRouteFromAndTo', { to, from });
    next();
});
// 新增上报停留时间
router.afterEach((to) => {
    reportPV(window.location.href);
    dwellTimeReport?.end();
    const { dwellKey } = to.meta;
    dwellTimeReport = dwellKey ? new ReportDwellTime(dwellKey) : undefined;
});
subscribeCCRouterCheck(router);
// router.beforeEach((to, from, next) => {
//   // 有些Url需要跳到老系统
//   /* const pathMap: any = Store.state.urlMapJumpToOld.toOldMap;
//   const item = pathMap['/v2' + to.path];
//   const whs = getCookie('setting_whs_v2');
//   if (item && !(item.switch_whs_list || '').split(',').includes(whs)) {
//     //不在switch_whs_list中, 跳转到老系统
//     location.href = location.origin + item.target_path;
//   } else {
//     next();
//   } */
// });
export default router;
