import request from '@/utils/request';
export const getCycleCountSwitch = async () => {
    const res = await request.get('/api/v2/apps/process/inventory/fullstockcount/get_cycle_count_switch');
    return res;
};
export const operateFullStockCount = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/fullstockcount/operate_full_stock_count', {
        ...params,
    });
    return res;
};
