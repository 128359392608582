import { handleResponseData } from '../utils';
import { ActionType } from '@scfe-common/chain-tracker';
export default [
    {
        name: 'InboundBoxingChain',
        status: {
            hasScanAsn: false,
            scanAsnTime: 0,
            scanSkuPieceByPieceTime: 0,
            scanSkuNormalTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/scan_sheet_id(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanAsnTime = Date.now();
                        this.status.hasScanAsn = true;
                    },
                },
                // Piece By Piece 模式
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/scan_and_save_sku(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanSkuPieceByPieceTime = Date.now();
                    },
                },
                // 普通输入数量模式，1.扫SKU 2.输入数量
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/get_sku_list_by_scan_code(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanSkuNormalTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/scan_and_save_sku(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            const { hasScanAsn, scanAsnTime, scanSkuPieceByPieceTime } = this.status;
                            if (hasScanAsn && scanAsnTime) {
                                this.status.hasScanAsn = false;
                                sendDataFn({
                                    pointId: 'inbound_boxing_piece_by_piece',
                                    customData: { ...this.status },
                                    duration: Date.now() - scanAsnTime,
                                });
                            }
                            if (scanSkuPieceByPieceTime) {
                                sendDataFn({
                                    pointId: 'boxing_piece_by_piece',
                                    customData: { ...this.status },
                                    duration: Date.now() - scanSkuPieceByPieceTime,
                                });
                            }
                        }
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/save_box_sku(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0) {
                            const { hasScanAsn, scanAsnTime, scanSkuNormalTime } = this.status;
                            if (hasScanAsn && scanAsnTime) {
                                this.status.hasScanAsn = false;
                                sendDataFn({
                                    pointId: 'inbound_boxing',
                                    customData: { ...this.status },
                                    duration: Date.now() - scanAsnTime,
                                });
                            }
                            if (scanSkuNormalTime) {
                                sendDataFn({
                                    pointId: 'inbound_boxing_scan_sku',
                                    customData: { ...this.status },
                                    duration: Date.now() - scanSkuNormalTime,
                                });
                            }
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'InboundQCChain',
        status: {
            operationTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poqc\/scan_sheet_id(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.operationTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/poqc\/confirm_qc(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.operationTime) {
                            sendDataFn({
                                pointId: 'inbound_qc',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.operationTime,
                            });
                        }
                    },
                },
            ];
        },
    },
    {
        name: 'InboundCountingChain',
        status: {
            scanSkuTime: 0,
        },
        getPointList: function () {
            return [
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/counting\/search_sku_scanning(\?.*)?$/,
                        state: 'before',
                    },
                    handler: () => {
                        this.status.scanSkuTime = Date.now();
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/counting\/check_surplus(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.scanSkuTime) {
                            sendDataFn({
                                pointId: 'inbound_counting',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.scanSkuTime,
                            });
                        }
                    },
                },
                {
                    type: ActionType.REQUEST,
                    rule: {
                        url: /^\/api\/v2\/apps\/process\/inbound\/counting\/create_wrong_sku_record(\?.*)?$/,
                        state: 'after',
                    },
                    handler: (trackData, sendDataFn) => {
                        const response = handleResponseData(trackData);
                        if (response?.retcode === 0 && this.status.scanSkuTime) {
                            sendDataFn({
                                pointId: 'inbound_counting_wrong_sku',
                                customData: { ...this.status },
                                duration: Date.now() - this.status.scanSkuTime,
                            });
                        }
                    },
                },
            ];
        },
    },
];
