const wmsConfig = {
    path: '/wmsconfig',
    name: 'wmsConfig',
    meta: {
        title: 'System Config',
        icon: 'System Config',
    },
    redirect: '/wmsconfig/reportAccess',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'reportaccess',
            name: 'wmsConfig.reportAccess',
            meta: {
                title: 'Report Access',
                icon: 'SIconBasicData',
            },
            component: () => import('@/views/rule-center/report-access'),
        },
        {
            path: 'emailcenter',
            name: 'wmsConfig.emailCenter',
            meta: {
                title: 'Email Center',
                icon: 'SIconBasicData',
            },
            component: () => import('@/views/rule-center/email-center/index.vue'),
        },
        {
            path: 'printtool',
            name: 'wmsConfig.printTool',
            meta: {
                title: 'Print Tool',
                // icon: 'SIconBasicData',
            },
            redirect: '/wmsconfig/printtool/print',
            component: () => import('@/views/system-config/print-tool'),
            children: [
                {
                    path: 'print',
                    name: 'wmsConfig.printTool.print',
                    meta: {
                        title: 'Print',
                        menuHide: true,
                    },
                    component: () => import('@/views/system-config/print-tool/print'),
                },
                {
                    path: 'newuid',
                    name: 'wmsConfig.printTool.newUID',
                    meta: {
                        title: 'New UID',
                        menuHide: true,
                    },
                    component: () => import('@/views/system-config/print-tool/new-uid'),
                },
                {
                    path: 'newtrackingid',
                    name: 'wmsConfig.printTool.newTrackingID',
                    meta: {
                        title: 'New Tracking ID',
                        menuHide: true,
                    },
                    component: () => import('@/views/system-config/print-tool/new-tracking-id'),
                },
                {
                    path: 'reprint',
                    name: 'wmsConfig.printTool.reprint',
                    meta: {
                        title: 'Reprint',
                        menuHide: true,
                    },
                    component: () => import('@/views/system-config/print-tool/reprint'),
                },
            ],
        },
        {
            path: 'printsettings',
            name: 'wmsConfig.printSettings',
            meta: {
                title: 'Print Settings',
                // icon: 'SIconBasicData',
            },
            component: () => import('@/views/system-config/print-tool/print-settings/index.vue'),
        },
        {
            path: 'notificationCenter',
            name: 'wmsConfig.notificationCenter',
            meta: {
                title: 'Notification Center',
            },
            redirect: '/wmsconfig/notificationCenter/sound',
            component: () => import('@/views/system-config/notification-center/index.vue'),
            children: [
                {
                    path: 'sound',
                    name: 'wmsConfig.notificationCenter.sound',
                    meta: {
                        title: 'Sound',
                        menuHide: true,
                    },
                    component: () => import('@/views/system-config/notification-center/sound/index.vue'),
                },
            ],
        },
        {
            path: 'userlist',
            name: 'wmsConfig.userList',
            meta: {
                title: 'User List',
            },
            component: () => import('@/views/system-config/user-list/user-list.vue'),
        },
        {
            path: 'taskoutlook',
            name: 'wmsConfig.taskOutlook',
            meta: {
                title: 'Task Outlook',
            },
            component: () => import('@/views/system-config/task-outlook/index.vue'),
        },
        {
            path: 'userrole',
            name: 'wmsConfig.userrole',
            meta: {
                title: 'User Role',
                filterSave: true,
            },
            component: () => import('@/views/system-config/user-role/user-role.vue'),
        },
        {
            path: 'rolelist',
            name: 'wmsConfig.userrole.rolelist',
            meta: {
                title: 'User List',
                menuHide: true,
                navList: [
                    {
                        routeName: 'wmsConfig',
                        title: 'System Config',
                    },
                    {
                        routeName: 'wmsConfig.userrole',
                        title: 'User Role',
                    },
                ],
            },
            component: () => import('@/views/system-config/user-role/userRoleList.vue'),
        },
        {
            path: 'roledetail',
            name: 'wmsConfig.userrole.detail',
            meta: {
                title: 'Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'wmsConfig',
                        title: 'System Config',
                    },
                    {
                        routeName: 'wmsConfig.userrole',
                        title: 'User Role',
                    },
                ],
            },
            component: () => import('@/views/system-config/user-role/components/permissionTree.vue'),
        },
        // {
        //   path: 'rulecenter',
        //   name: 'wmsConfig.rulecenter',
        //   meta: {
        //     title: 'Rule Center',
        //   },
        //   redirect: '/wmsconfig/rulecenter/putawayrule',
        //   component: () => import('../../views/layout/subPage.vue'),
        //   children: [
        //     {
        //       path: 'putawayrule',
        //       name: 'wmsConfig.rulecenter.putawayRule',
        //       meta: {
        //         title: 'Putaway Rule',
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/putaway-rule/putawayRule.vue'
        //         ),
        //     },
        //     {
        //       path: 'putawayrule/detail',
        //       name: 'wmsConfig.rulecenter.putawayRule.detail',
        //       meta: {
        //         title: 'Detail',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter',
        //             title: 'Rule Center',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.putawayRule',
        //             title: 'Putaway Rule',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/putaway-rule/putaway-detail/putawayDetail.vue'
        //         ),
        //     },
        //     {
        //       path: 'putawayrule/edit',
        //       name: 'wmsConfig.rulecenter.putawayRule.edit',
        //       meta: {
        //         title: 'Edit',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter',
        //             title: 'Rule Center',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.putawayRule',
        //             title: 'Putaway Rule',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/putaway-rule/putaway-detail/putawayDetail.vue'
        //         ),
        //     },
        //     {
        //       path: 'batchrule',
        //       name: 'wmsConfig.rulecenter.batchRule',
        //       meta: {
        //         title: 'Rule Group',
        //         menuHide: true,
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/batch-rule.vue'
        //         ),
        //     },
        //     {
        //       path: 'batchrule/add',
        //       name: 'wmsConfig.rulecenter.batchRule.add',
        //       meta: {
        //         title: 'Batch Rule Add',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.batchRule',
        //             title: 'Rule Group',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/batch-rule-add.vue'
        //         ),
        //     },
        //     {
        //       path: 'batchrule/detail',
        //       name: 'wmsConfig.rulecenter.batchRule.detail',
        //       meta: {
        //         title: 'Batch Rule Detail',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.batchRule',
        //             title: 'Rule Group',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/batch-rule-add.vue'
        //         ),
        //     },
        //     {
        //       path: 'batchrule/edit',
        //       name: 'wmsConfig.rulecenter.batchRule.edit',
        //       meta: {
        //         title: 'Batch Rule Edit',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.batchRule',
        //             title: 'Rule Group',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/batch-rule-add.vue'
        //         ),
        //     },
        //     {
        //       path: 'uidrule',
        //       name: 'wmsConfig.rulecenter.uidRule',
        //       meta: {
        //         title: 'Rule Group',
        //         menuHide: true,
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/uid-rule.vue'
        //         ),
        //     },
        //     {
        //       path: 'rulegroup',
        //       name: 'wmsConfig.rulecenter.ruleGroup',
        //       meta: {
        //         title: 'Rule Group',
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/rule-group.vue'
        //         ),
        //     },
        //     {
        //       path: 'rulegroup/add',
        //       name: 'wmsConfig.rulecenter.ruleGroupAdd',
        //       meta: {
        //         title: 'Rule Group Create',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.ruleGroup',
        //             title: 'Rule Group',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/rule-group-detail.vue'
        //         ),
        //     },
        //     {
        //       path: 'rulegroup/edit',
        //       name: 'wmsConfig.rulecenter.ruleGroupEdit',
        //       meta: {
        //         title: 'Rule Group Edit',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.ruleGroup',
        //             title: 'Rule Group',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/rule-group-detail.vue'
        //         ),
        //     },
        //     {
        //       path: 'rulegroup/detail',
        //       name: 'wmsConfig.rulecenter.ruleGroupDetail',
        //       meta: {
        //         title: 'Rule Group Detail',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.ruleGroup',
        //             title: 'Rule Group',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/rule-group/rule-group-detail.vue'
        //         ),
        //     },
        //     {
        //       path: 'qcruleconfig',
        //       name: 'wmsConfig.rulecenter.qcruleconfig',
        //       meta: {
        //         title: 'Qc Rule',
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/qc-rule/qc-rule-config.vue'
        //         ),
        //     },
        //     {
        //       path: 'qcruleconfigcreate',
        //       name: 'wmsConfig.rulecenter.qcruleconfigcreate',
        //       meta: {
        //         title: 'QC Rule Config Create',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.qcruleconfig',
        //             title: 'QC Rule',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/qc-rule/qc-config-create.vue'
        //         ),
        //     },
        //     {
        //       path: 'qcruleconfigedit',
        //       name: 'wmsConfig.rulecenter.qcruleconfigedit',
        //       meta: {
        //         title: 'QC Rule Config Edit',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.qcruleconfig',
        //             title: 'QC Rule',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/qc-rule/qc-config-create.vue'
        //         ),
        //     },
        //     {
        //       path: 'qcruleconfigview',
        //       name: 'wmsConfig.rulecenter.qcruleconfigview',
        //       meta: {
        //         title: 'QC Rule Config Detail',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.qcruleconfig',
        //             title: 'QC Rule',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/qc-rule/qc-config-create.vue'
        //         ),
        //     },
        //     {
        //       path: 'qcsuppliersetting',
        //       name: 'wmsConfig.rulecenter.qcsuppliersetting',
        //       meta: {
        //         title: 'Supplier Setting',
        //         menuHide: true,
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/qc-rule/supplier-setting.vue'
        //         ),
        //     },
        //     {
        //       path: 'qcsuppliersettingedit',
        //       name: 'wmsConfig.rulecenter.qcsuppliersettingedit',
        //       meta: {
        //         title: 'Supplier Setting Edit',
        //         menuHide: true,
        //         navList: [
        //           {
        //             routeName: 'wmsConfig',
        //             title: 'WMS Config',
        //           },
        //           {
        //             routeName: 'wmsConfig.rulecenter.qcsuppliersetting',
        //             title: 'QC Rule',
        //           },
        //         ],
        //       },
        //       component: () =>
        //         import(
        //           '../../views/wms-config/rule-center/qc-rule/supplier-setting-edit.vue'
        //         ),
        //     },
        //   ],
        // },
        // {
        //   path: 'quotaconfig',
        //   name: 'wmsConfig.quotaConfig',
        //   meta: {
        //     title: 'Quota Config',
        //   },
        //   component: () =>
        //     import('../../views/wms-config/quota-config/asn-quota-list.vue'),
        // },
        // {
        //   path: 'quotaconfig/massedit',
        //   name: 'wmsConfig.quotaConfig.massEdit',
        //   meta: {
        //     title: 'Quota Config Mass Edit',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('../../views/wms-config/quota-config/asn-quota-list.vue'),
        // },
        // {
        //   path: 'quotaconfig/edit',
        //   name: 'wmsConfig.quotaConfig.edit',
        //   meta: {
        //     title: 'Quota Config Edit',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('../../views/wms-config/quota-config/asn-quota-list.vue'),
        // },
        // {
        //   path: 'asnquotaconfiguration',
        //   name: 'wmsConfig.asnQuotaConfig',
        //   meta: {
        //     title: 'ASN Quota Configuration',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('../../views/wms-config/quota-config/asn-quota-config.vue'),
        // },
        // {
        //   path: 'asnquotaconfiguration/edit',
        //   name: 'wmsConfig.asnQuotaConfig.edit',
        //   meta: {
        //     title: 'ASN Quota Configuration Edit',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('../../views/wms-config/quota-config/asn-quota-config.vue'),
        // },
        // {
        //   path: 'shiftconfig',
        //   name: 'wmsCongfig.shiftConfig',
        //   meta: {
        //     title: 'Shift Config',
        //   },
        //   component: () =>
        //     import('../../views/wms-config/shift-config/shift-config.vue'),
        // },
        // {
        //   path: 'shiftconfig/add',
        //   name: 'wmsCongfig.shiftConfig.add',
        //   meta: {
        //     title: 'Add',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsCongfig.shiftConfig',
        //         title: 'Shift Config',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import('../../views/wms-config/shift-config/shift-config.vue'),
        // },
        // {
        //   path: 'shiftconfig/edit',
        //   name: 'wmsCongfig.shiftConfig.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsCongfig.shiftConfig',
        //         title: 'Shift Config',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import('../../views/wms-config/shift-config/shift-config.vue'),
        // },
        // {
        //   path: 'lifecyclerules',
        //   name: 'wmsConfig.lifeCycleRules',
        //   meta: {
        //     title: 'LifeCycle Rule',
        //   },
        //   component: () =>
        //     import('../../views/wms-config/lifecycle-rule/lifecycle.vue'),
        // },
        // {
        //   path: 'lifecyclerules/detail',
        //   name: 'wmsConfig.lifeCycleRules.detail',
        //   meta: {
        //     title: 'Detail',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.lifeCycleRules',
        //         title: 'LifeCycle Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import('../../views/wms-config/lifecycle-rule/lifecycle-detail.vue'),
        // },
        // {
        //   path: 'allocateRule/sales',
        //   name: 'wmsConfig.allocateRule.sales',
        //   meta: {
        //     title: 'Allocate Rule',
        //     // menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/allocate-rule/index.vue'),
        // },
        // {
        //   path: 'allocateRule/rts',
        //   name: 'wmsConfig.allocateRule.rts',
        //   meta: {
        //     title: 'Allocate Rule',
        //     menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/allocate-rule/index.vue'),
        // },
        // {
        //   path: 'allocateRule/replenishment',
        //   name: 'wmsConfig.allocateRule.replenishment',
        //   meta: {
        //     title: 'Allocate Rule',
        //     menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/allocate-rule/index.vue'),
        // },
        // {
        //   path: 'pickingrule',
        //   name: 'wmsConfig.pickingRule',
        //   meta: {
        //     title: 'Picking Rule',
        //     // menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/picking-rule/index.vue'),
        // },
        // {
        //   path: 'pickingRule/create',
        //   name: 'wmsConfig.pickingRule.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.pickingRule',
        //         title: 'Picking Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/picking-rule/components/picking-rule/create.vue'
        //     ),
        // },
        // {
        //   path: 'pickingRule/edit/:rule_id',
        //   name: 'wmsConfig.pickingRule.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.pickingRule',
        //         title: 'Picking Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/picking-rule/components/picking-rule/create.vue'
        //     ),
        // },
        // {
        //   path: 'pickingskills',
        //   name: 'wmsConfig.pickingSkills',
        //   meta: {
        //     title: 'Picking Skill Management',
        //     menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/picking-rule/index.vue'),
        // },
        // {
        //   path: 'pickingSkills/create',
        //   name: 'wmsConfig.pickingSkills.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.pickingSkills',
        //         title: 'Picking Skills Management',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/picking-rule/components/picking-skills/create.vue'
        //     ),
        // },
        // {
        //   path: 'pickingSkills/edit/:user_id',
        //   name: 'wmsConfig.pickingSkills.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.pickingSkills',
        //         title: 'Picking Skills Management',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/picking-rule/components/picking-skills/create.vue'
        //     ),
        // },
        // {
        //   path: 'putawaytaskassignmentrules',
        //   name: 'wmsCongfig.putawayTaskAssignmentRules',
        //   meta: {
        //     title: 'Putaway Task Assignment Rule',
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/putaway-task-assignment-rule/assignment-rule.vue'
        //     ),
        // },
        // {
        //   path: 'putawaytaskhighprioritytaskrule',
        //   name: 'wmsConfig.highPriorityTaskRules',
        //   meta: {
        //     menuHide: true,
        //     title: 'High Priority Task Rule',
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/putaway-task-assignment-rule/high-priority-task-rule.vue'
        //     ),
        // },
        // {
        //   path: 'waverule',
        //   name: 'wmsConfig.waveRule',
        //   meta: {
        //     title: 'Wave Rule',
        //   },
        //   component: () => import('../../views/wms-config/wave-rule/index.vue'),
        // },
        // {
        //   path: 'waverule/create',
        //   name: 'wmsConfig.waveRule.create',
        //   meta: {
        //     title: 'Create Wave Rule',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.waveRule',
        //         title: 'Wave Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/wave-rule/components/wave-rule/wave-rule-create.vue'
        //     ),
        // },
        // {
        //   path: 'waverule/edit/:id',
        //   name: 'wmsConfig.waveRule.edit',
        //   meta: {
        //     title: 'Edit Wave Rule',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.waveRule',
        //         title: 'Wave Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/wave-rule/components/wave-rule/wave-rule-create.vue'
        //     ),
        // },
        // {
        //   path: 'waveRuleGroup',
        //   name: 'wmsConfig.waveRuleGroup',
        //   meta: {
        //     title: 'Wave Rule Group',
        //     menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/wave-rule/index.vue'),
        // },
        // {
        //   path: 'waveRuleGroup/create',
        //   name: 'wmsConfig.waveRuleGroup.create',
        //   meta: {
        //     title: 'Add New Group',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.waveRuleGroup',
        //         title: 'Wave Rule Group',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/wave-rule/components/wave-group/wave-group-create.vue'
        //     ),
        // },
        // {
        //   path: 'waveRuleGroup/edit/:id',
        //   name: 'wmsConfig.waveRuleGroup.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.waveRuleGroup',
        //         title: 'Wave Rule Group',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/wave-rule/components/wave-group/wave-group-create.vue'
        //     ),
        // },
        // {
        //   path: 'customWaveRule',
        //   name: 'wmsConfig.customWaveRule',
        //   meta: {
        //     title: 'Custom Wave Rule',
        //     menuHide: true,
        //   },
        //   component: () => import('../../views/wms-config/wave-rule/index.vue'),
        // },
        // {
        //   path: 'customWaveRule/detail/:id',
        //   name: 'wmsConfig.customWaveRule.detail',
        //   meta: {
        //     title: 'View',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.customWaveRule',
        //         title: 'Custom Wave Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/wave-rule/components/custom-wave/custom-wave-detail.vue'
        //     ),
        // },
        // {
        //   path: 'customWaveRule/create',
        //   name: 'wmsConfig.customWaveRule.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.customWaveRule',
        //         title: 'Custom Wave Rule',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/wave-rule/components/custom-wave/custom-wave-create.vue'
        //     ),
        // },
        // {
        //   path: 'highValue',
        //   name: 'wmsConfig.highValue',
        //   meta: {
        //     title: 'High Value',
        //   },
        //   component: () => import('../../views/wms-config/high-value/index.vue'),
        // },
        // {
        //   path: 'highValue/create',
        //   name: 'wmsConfig.highValue.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.highValue',
        //         title: 'High Value',
        //       },
        //     ],
        //   },
        //   component: () => import('../../views/wms-config/high-value/edit.vue'),
        // },
        // {
        //   path: 'highValue/edit/:id',
        //   name: 'wmsConfig.highValue.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.highValue',
        //         title: 'High Value',
        //       },
        //     ],
        //   },
        //   component: () => import('../../views/wms-config/high-value/edit.vue'),
        // },
        // {
        //   path: 'dialySalesConfig',
        //   name: 'wmsConfig.dialySalesConfig',
        //   meta: {
        //     title: 'Replenishment Rules',
        //   },
        //   component: () =>
        //     import('../../views/wms-config/replenishment-rules/index.vue'),
        // },
        // {
        //   path: 'dialySalesConfig/edit/:id',
        //   name: 'wmsConfig.dialySalesConfig.edit',
        //   meta: {
        //     title: 'Edit Daily Sales Config',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.dialySalesConfig',
        //         title: 'Replenishment Rules',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/replenishment-rules/components/dialy-sales-config/edit.vue'
        //     ),
        // },
        // {
        //   path: 'dialySalesConfig/view/:id',
        //   name: 'wmsConfig.dialySalesConfig.view',
        //   meta: {
        //     title: 'View Daily Sales Config',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.dialySalesConfig',
        //         title: 'Replenishment Rules',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/replenishment-rules/components/dialy-sales-config/edit.vue'
        //     ),
        // },
        // {
        //   path: 'skuConfig',
        //   name: 'wmsConfig.skuConfig',
        //   meta: {
        //     title: 'Replenishment Rules',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('../../views/wms-config/replenishment-rules/index.vue'),
        // },
        // {
        //   path: 'skuConfig/create',
        //   name: 'wmsConfig.skuConfig.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //     navList: [
        //       {
        //         routeName: 'wmsConfig',
        //         title: 'WMS Config',
        //       },
        //       {
        //         routeName: 'wmsConfig.skuConfig',
        //         title: 'Replenishment Rules',
        //       },
        //     ],
        //   },
        //   component: () =>
        //     import(
        //       '../../views/wms-config/replenishment-rules/components/sku-config/sku-create.vue'
        //     ),
        // },
        /* {
          path: 'inventorystrategy/forecastsales',
          name: 'wmsConfig.inventoryStrategy.forecastSales',
          meta: {
            title: 'Forecast Sales',
          },
          component: () => import('@/views/wms-config/inventory-strategy'),
        },
        {
          path: 'inventorystrategy/safetyandtarget',
          name: 'wmsConfig.inventoryStrategy.safeTarget',
          meta: {
            title: 'Safety and Target Stock',
          },
          component: () => import('@/views/wms-config/inventory-strategy'),
        },
        {
          path: 'inventorystrategy/dailysalesreport',
          name: 'wmsConfig.inventoryStrategy.dailySalesReport',
          meta: {
            title: 'Daily Sales Report',
          },
          component: () => import('@/views/wms-config/inventory-strategy'),
        }, */
        // {
        //   path: 'sizetype',
        //   name: 'wmsConfig.sizetype',
        //   meta: {
        //     title: 'Size Type',
        //     icon: 'SIconBasicData',
        //   },
        //   component: () => import('@/views/wms-config/size-type/cell-size.vue'),
        // },
        // {
        //   path: 'cellsize',
        //   name: 'wmsconfig.cellsize',
        //   meta: {
        //     title: 'Size Type',
        //     menuHide: true,
        //   },
        //   component: () => import('@/views/wms-config/size-type/cell-size.vue'),
        // },
        // {
        //   path: 'cellsize/create',
        //   name: 'wmsconfig.cellsize.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('@/views/wms-config/size-type/cellSizeCreate.vue'),
        // },
        // {
        //   path: 'cellsize/view',
        //   name: 'wmsconfig.cellsize.view',
        //   meta: {
        //     title: 'Detail',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('@/views/wms-config/size-type/cellSizeCreate.vue'),
        // },
        // {
        //   path: 'cellsize/edit',
        //   name: 'wmsconfig.cellsize.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('@/views/wms-config/size-type/cellSizeCreate.vue'),
        // },
        // {
        //   path: 'skusize',
        //   name: 'wmsconfig.skusize',
        //   meta: {
        //     title: 'SKU Size Type',
        //     menuHide: true,
        //   },
        //   component: () => import('@/views/wms-config/size-type/sku-size.vue'),
        // },
        // {
        //   path: 'skusize/create',
        //   name: 'wmsconfig.skusize.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //   },
        //   component: () => import('@/views/wms-config/size-type/skuSizeCreate.vue'),
        // },
        // {
        //   path: 'skusize/view',
        //   name: 'wmsconfig.skusize.view',
        //   meta: {
        //     title: 'Detail',
        //     menuHide: true,
        //   },
        //   component: () => import('@/views/wms-config/size-type/skuSizeCreate.vue'),
        // },
        // {
        //   path: 'skusize/edit',
        //   name: 'wmsconfig.skusize.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //   },
        //   component: () => import('@/views/wms-config/size-type/skuSizeCreate.vue'),
        // },
        // {
        //   path: 'skuweight',
        //   name: 'wmsconfig.skuweight',
        //   meta: {
        //     title: 'SKU Weight Type',
        //     menuHide: true,
        //   },
        //   component: () => import('@/views/wms-config/size-type/sku-weight.vue'),
        // },
        // {
        //   path: 'skuweight/create',
        //   name: 'wmsconfig.skuweight.create',
        //   meta: {
        //     title: 'Create',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('@/views/wms-config/size-type/skuWeightCreate.vue'),
        // },
        // {
        //   path: 'skuweight/view',
        //   name: 'wmsconfig.skuweight.view',
        //   meta: {
        //     title: 'Detail',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('@/views/wms-config/size-type/skuWeightCreate.vue'),
        // },
        // {
        //   path: 'skuweight/edit',
        //   name: 'wmsconfig.skuweight.edit',
        //   meta: {
        //     title: 'Edit',
        //     menuHide: true,
        //   },
        //   component: () =>
        //     import('@/views/wms-config/size-type/skuWeightCreate.vue'),
        // },
        {
            path: 'approveconfig',
            name: 'wmsConfig.approveConfiguration',
            meta: {
                title: 'Approve Configuration',
            },
            component: () => import('@/views/system-config/approve-configuration'),
            children: [
                {
                    path: 'edit',
                    name: 'wmsConfig.approveConfiguration.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/system-config/approve-configuration/edit'),
                },
                {
                    path: 'view',
                    name: 'wmsConfig.approveConfiguration.view',
                    meta: {
                        title: 'View',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/system-config/approve-configuration/view'),
                },
            ],
        },
        {
            path: 'pdapackage',
            name: 'wmsConfig.pdaPackage',
            meta: {
                title: 'PDA Package',
            },
            component: () => import('@/views/system-config/pda-package'),
        },
    ],
};
export default wmsConfig;
