import request from '@/utils/request';
export const createStorey = async (params) => {
    const res = await request.post('/api/v2/apps/basic/storey/create_storey', {
        ...params,
    });
    return res;
};
export const deleteStorey = async (params) => {
    const res = await request.post('/api/v2/apps/basic/storey/delete_storey', {
        ...params,
    });
    return res;
};
export const getWhsStoreyNameSetup = async () => {
    const res = await request.get('/api/v2/apps/basic/storey/get_whs_storey_name_setup');
    return res;
};
export const searchStorey = async (params) => {
    const res = await request.get('/api/v2/apps/basic/storey/search_storey', {
        params: {
            ...params,
        },
    });
    return res;
};
export const updateStorey = async (params) => {
    const res = await request.post('/api/v2/apps/basic/storey/update_storey', {
        ...params,
    });
    return res;
};
