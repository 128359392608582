import ChainTracker from '@scfe-common/chain-tracker';
import { numericTracker } from '../qms';
import dwellTimeChainList from './dwellTime';
import operateChainList from './operate-chains/index';
import slaChainList from './sla-chains';
import { reportTransaction, reportErrorCount, } from './sla-chains/report';
import { SLA_CHAINS_TYPE } from './constants';
const initChainTracker = () => {
    const chainTracker = new ChainTracker({
        sendDataFn: (data) => {
            const { type } = data.customData;
            if (type === 'dwellTime' && data.duration) {
                // 停留时长监控
                numericTracker.report({
                    key: data.customData.url.replaceAll('/', '_').slice(1),
                    value: data.duration,
                });
            }
            if (type === 'operateTime') {
                numericTracker.report({
                    id: 'wms_process_operate_time',
                    key: data.pointId,
                    value: data.duration,
                });
            }
            if (type === 'experienceTime') {
                numericTracker.report({
                    id: 'wms_process_operate_time',
                    key: data.pointId,
                    value: data.customData?.duration,
                });
            }
            if (type === 'experienceCount') {
                reportErrorCount({
                    key: data.pointId,
                    ...data.customData,
                });
            }
            if (type === SLA_CHAINS_TYPE) {
                reportTransaction(data?.customData);
            }
        },
        logger: false,
        chainList: [...dwellTimeChainList, ...operateChainList, ...slaChainList],
    });
    return chainTracker;
};
export default initChainTracker;
