import { translatedIsNotEN } from '@/i18n';
import { reportToastTransition, reportTransition } from './qms';
class TranslateReport {
    constructor() {
        this.pathWhiteList = [
            'inbound.qc',
            'inbound.boxing',
            'salesOutbound.checking',
            'salesOutbound.picking',
            'salesOutbound.sorting',
        ]; // 翻译上报的路由白名单
        this.data = []; // 翻译率数据
        this.pathWhiteList.forEach((item) => {
            this.data.push({
                route: item,
                keyList: [],
                success_translate_n: 0,
                total_translate_n: 0,
            });
        });
    }
    static getInstance() {
        // 如果存在instance实例直接返回，如果没有，实例化后返回
        this._instance || (this._instance = new TranslateReport());
        return this._instance;
    }
    addSuccessTranslateCount(route) {
        if (!this.pathWhiteList.includes(route))
            return;
        const params = this.data.find((item) => item.route === route);
        if (params) {
            params.total_translate_n += 1;
            params.success_translate_n += 1;
        }
    }
    addFailTranslateKey(route, key) {
        if (!this.pathWhiteList.includes(route))
            return;
        const params = this.data.find((item) => item.route === route);
        if (params) {
            params.total_translate_n += 1;
            // 如果key是非英语
            if (translatedIsNotEN(key)) {
                params.success_translate_n += 1;
                return;
            }
            const keyList = params?.keyList ?? [];
            if (!keyList.includes(key))
                params.keyList.push(key);
        }
    }
    report(route) {
        if (!this.pathWhiteList.includes(route))
            return;
        const params = this.data.find((item) => item.route === route);
        if (params && params.total_translate_n) {
            reportTransition({
                key_list: params.keyList,
                module_s: route,
                translated_n: params.success_translate_n,
                total_n: params.total_translate_n,
            });
            params.keyList = [];
            params.success_translate_n = 0;
            params.total_translate_n = 0;
        }
    }
    resetData() {
        this.data = [];
        this.pathWhiteList.forEach((item) => {
            this.data.push({
                route: item,
                keyList: [],
                success_translate_n: 0,
                total_translate_n: 0,
            });
        });
    }
    toastUnTranslateReport(message) {
        const is_translated_n = Number(translatedIsNotEN(message));
        reportToastTransition({
            message_s: message,
            is_translated_n,
        });
    }
}
export default TranslateReport.getInstance();
