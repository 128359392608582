// import { default as vueRouter } from '@/router';
// import store from '@/store';
// import vueInstance from '@/main';
import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import ComponentLayout from '@/views/layout/index.vue';
import ComponnetSubPage from '@/views/layout/subPage.vue';
// export const getVueStuff = (): VueStuff => {
//   return {
//     router: vueRouter,
//     store,
//     // vueInstance: vueInstance as Vue,
//   };
// };
export const convertReactRoutesToVue = (reactRoutes, level = 1) => {
    const vueRoutes = reactRoutes.map((item) => {
        const { component, props = {}, children, ...rest } = item;
        let finalComponent;
        const hasComponent = !!component; // 如果是Menu的父节点，则没有component，值为false
        if (hasComponent) {
            finalComponent = reactContainerFactory({ name: item.name });
        }
        else if (level === 1) {
            finalComponent = ComponentLayout;
        }
        else {
            finalComponent = ComponnetSubPage;
        }
        level++;
        return {
            ...rest,
            component: finalComponent,
            children: children ? convertReactRoutesToVue(children, level) : undefined,
            props: {
                ...props,
                component: hasComponent ? component : undefined,
            },
        };
    });
    return vueRoutes;
};
