import { i18n } from '@/i18n/index';
export default function (self, h) {
    const customHooks = self.customHooks;
    return customHooks.map((item) => {
        return h('s-button', {
            class: 'custom-hooks-button',
            props: {
                type: 'primary',
            },
            on: {
                click: () => {
                    item.handler.call(self);
                },
            },
        }, [i18n(item.label)]);
    });
}
