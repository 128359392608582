import request from '@/utils/request';
export const checkGroupCompleteRecord = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/check_group_complete_record', {
        params: {
            ...params,
        },
    });
    return res;
};
export const checkSurplus = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/check_surplus', {
        params: {
            ...params,
        },
    });
    return res;
};
export const createGroupCompleteRecord = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/counting/create_group_complete_record', {
        ...params,
    });
    return res;
};
export const createTrackingId = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/counting/create_tracking_id', {
        ...params,
    });
    return res;
};
export const createWrongSkuRecord = async (params, requestSetting) => {
    const res = await request.post('/api/v2/apps/process/inbound/counting/create_wrong_sku_record', {
        ...params,
    }, requestSetting || {});
    return res;
};
export const deleteTrackingId = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/counting/delete_tracking_id', {
        ...params,
    });
    return res;
};
export const saveCountingRecord = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/counting/save_counting_record', {
        ...params,
    });
    return res;
};
export const searchGroupDetail = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/search_group_detail', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchGroupList = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/search_group_list', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchGroupWorkingList = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/search_group_working_list', {
        params: {
            ...params,
        },
    });
    return res;
};
export const searchSkuScanning = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/search_sku_scanning', {
        params: {
            ...params,
        },
    });
    return res;
};
export const updateTrackingId = async (params) => {
    const res = await request.post('/api/v2/apps/process/inbound/counting/update_tracking_id', {
        ...params,
    });
    return res;
};
export const checkExceptionTrackingId = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/check_exception_tracking_id', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getDevConf = async (params) => {
    const res = await request.get('/api/v2/configuration/get_dev_conf', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getDevConfList = async (params) => {
    const res = await request.post('/api/v2/configuration/get_conf_list', {
        ...params,
    });
    return res;
};
export const checkTrackingId = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/check_tracking_id', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getExpectedQcQty = async (params) => {
    const res = await request.get('/api/v2/apps/process/inbound/counting/get_expected_qc_qty', {
        params: {
            ...params,
        },
    });
    return res;
};
