export const skuMappingParams = [
    'sku_id_upc',
    'sku_id',
    'sku_upc_code',
    'sku_search_data',
    'item_code',
    'sku_or_upc_id',
    'sku_id_or_item_id',
    'sku_code',
    'skuIdOrUpc',
];
