import { getCookie } from '@/utils/cookie';
import { getEnv } from '@/utils/getEnv';
import * as storeService from '@shopee/ssc-fe-store-service-utils';
function getUserEmail() {
    return getCookie('wms_display_name') || '';
}
function getStation() {
    return getCookie('setting_whs_v2') || '';
}
export async function createTemplate(data, keyName, templateName) {
    const userEmail = getUserEmail();
    if (!userEmail)
        return;
    const params = {
        user_email: userEmail,
        type: 1,
        data,
        url_path: window.location.host + window.location.pathname + window.location.hash,
        key_name: keyName,
        template_name: templateName,
        station: getStation(),
    };
    await storeService.createTemplate(params);
}
export async function getTemplateList(keyName) {
    storeService.init({ isLive: getEnv() === 'live', bt: 'SBS', sbt: 'WMSV2' });
    const userEmail = getUserEmail();
    if (!userEmail)
        return [];
    const params = {
        user_email: userEmail,
        type: 1,
        url_path: window.location.host + window.location.pathname + window.location.hash,
        key_name: keyName,
        station: getStation(),
    };
    const res = await storeService.getTemplates(params);
    return res?.data?.data?.list || [];
}
export async function updateTemplate(templateID, data) {
    const params = {
        data,
        template_id: templateID,
    };
    await storeService.updateTemplate(params);
}
export async function recentlyUsed(templateID) {
    await storeService.recentlyUsed(templateID);
}
