import { __decorate } from "tslib";
import { Component, Vue, Model, Watch } from 'vue-property-decorator';
import { getUTCSecondsBasedWhs, getWhTimestampByUTCSeconds, } from '@/utils/date';
import { getIsDate } from '@/utils/tools';
let ZonetimeDataPicker = class ZonetimeDataPicker extends Vue {
    constructor() {
        super(...arguments);
        this.date = null;
    }
    onWhsDateChange() {
        if (this.whsDate) {
            if (Array.isArray(this.whsDate)) {
                this.date = this.whsDate.map((item) => getIsDate(item)
                    ? new Date(getWhTimestampByUTCSeconds(+item))
                    : getWhTimestampByUTCSeconds(+item));
            }
            else {
                this.date = getIsDate(this.whsDate)
                    ? +new Date(getWhTimestampByUTCSeconds(+this.whsDate))
                    : getWhTimestampByUTCSeconds(+this.whsDate);
            }
        }
        else {
            this.date = this.whsDate;
        }
    }
    handleChange(value) {
        if (value) {
            // 转换回需要回传给后端的时间戳
            if (Array.isArray(value)) {
                const formatWhsDate = value.map((item) => getIsDate(item)
                    ? new Date(getUTCSecondsBasedWhs(+item) * 1000)
                    : getUTCSecondsBasedWhs(+item) * 1000);
                this.$emit('update', formatWhsDate);
            }
            else {
                const formatWhsDate = getIsDate(value)
                    ? +new Date(getUTCSecondsBasedWhs(+value) * 1000)
                    : getUTCSecondsBasedWhs(+value) * 1000;
                this.$emit('update', formatWhsDate);
            }
        }
        else {
            this.$emit('update', value);
        }
        // 处理托管 change 事件后的逻辑
        const { change } = this.$listeners;
        if (!change) {
            return;
        }
        if (typeof change === 'function') {
            change && change(value);
        }
        else {
            change.forEach((item) => item(value));
        }
    }
    handleInput(value) {
        if (value) {
            // 转换回需要回传给后端的时间戳
            if (Array.isArray(value)) {
                const formatWhsDate = value.map((item) => getIsDate(item)
                    ? new Date(getUTCSecondsBasedWhs(+item) * 1000)
                    : getUTCSecondsBasedWhs(+item) * 1000);
                this.$emit('update', formatWhsDate);
            }
            else {
                const formatWhsDate = getIsDate(value)
                    ? +new Date(getUTCSecondsBasedWhs(+value) * 1000)
                    : getUTCSecondsBasedWhs(+value) * 1000;
                this.$emit('update', formatWhsDate);
            }
        }
        else {
            this.$emit('update', value);
        }
        // 处理托管 input 事件后的逻辑
        const { input } = this.$listeners;
        if (!input) {
            return;
        }
        if (typeof input === 'function') {
            input && input(value);
        }
        else {
            input.forEach((item) => item(value));
        }
    }
    render(h) {
        // return <s-date-picker />
        const listeners = {
            ...this.$listeners,
            change: this.handleChange,
            input: this.handleInput,
        };
        return h('s-date-picker', {
            props: {
                ...this.$props,
                value: this.date,
            },
            attrs: this.$attrs,
            on: listeners,
            scopedSlots: this.$scopedSlots,
        });
    }
};
__decorate([
    Model('update')
], ZonetimeDataPicker.prototype, "whsDate", void 0);
__decorate([
    Watch('whsDate', { immediate: true })
], ZonetimeDataPicker.prototype, "onWhsDateChange", null);
ZonetimeDataPicker = __decorate([
    Component
], ZonetimeDataPicker);
export default ZonetimeDataPicker;
