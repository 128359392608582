// import Layout from '../views/layout/index.vue';
import home from './modules/home';
import inbound from './modules/inbound';
import salesOutbound from './modules/sales-outbound';
import mtOutbound from './modules/mt-outbound';
import rtsOutbound from './modules/rts-outbound';
// import taskCenter from './modules/task-center';
import wmsBasic from './modules/wms-basic';
import transfer from './modules/transfer';
import consumablesManagement from './modules/consumables-management';
import systemConfig from './modules/system-config';
import reportCenter from './modules/report-center';
import dashboard from './modules/dashboard';
import dashboardTVNew from './modules/dashboard-tv-new';
import inventoryMap from './modules/inventory-map';
import ruleCenter from './modules/rule-center';
import user from './modules/user';
import returnInbound from './modules/return-inbound';
import move from './modules/move';
import inventoryManagement from './modules/inventory-management';
import exceptionCenter from './modules/exception-center';
import laborManagement from './modules/labor-management';
import controlManagement from './modules/control-management';
import warehouseMap from './modules/warehouse-map';
import queryCenter from './modules/query-center';
import deviceManagement from './modules/device-management';
import cctv from './modules/cctv';
import { convertReactRoutesToVue } from '@/utils/bridge';
import { reactAppRoutes } from '@/react-app';
import wes from './modules/wes';
const convertedReactRoutes = convertReactRoutesToVue(reactAppRoutes);
let routesConfig = [];
routesConfig = [
    ...convertedReactRoutes,
    home,
    inbound,
    move,
    returnInbound,
    salesOutbound,
    rtsOutbound,
    mtOutbound,
    inventoryManagement,
    inventoryMap,
    ruleCenter,
    systemConfig,
    wmsBasic,
    transfer,
    consumablesManagement,
    exceptionCenter,
    reportCenter,
    laborManagement,
    controlManagement,
    dashboard,
    // dashboardTV,
    dashboardTVNew,
    user,
    // test,
    warehouseMap,
    queryCenter,
    deviceManagement,
    wes,
    cctv,
];
export default routesConfig;
