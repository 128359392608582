import request from '../../utils/request';
export default {
    async systemEnums() {
        return request.get('/api/v2/apps/basic/system/domain/enums');
    },
    //rms 新增枚举值
    async rmsEnums() {
        return request.get('/api/apps/process/returninbound/enums');
    },
};
