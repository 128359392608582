import { Loader } from '@spx-portal/plugin-loader';
import router from './src/router';
import { getDevConf } from './src/api/basic/displaySetting';
import { getCookie } from './src/utils/cookie';
export const runLoader = async () => {
    const whs = getCookie('setting_whs_v2') || '';
    const { data } = await getDevConf({
        conf_key: 'wms-cctv-version',
        whs_id: whs,
    });
    const isDev = process.env.NODE_ENV === 'development';
    const loader = new Loader({
        isDev: process.env.NODE_ENV === 'development',
        router,
        preLoadModules: [
            { name: 'inwork', type: 'react', load: true, modules: ['module'] },
        ],
        moduleGlobalMap: { react: { inwork: 'SPXInworkReactCCTVModule' } },
        moduleEntryFileNameMap: {
            SPXInworkReactCCTVModule: `cctv${data.conf_value}remoteEntry.react.js`,
        },
        digging: false,
        allRouteModules: [],
    });
    loader.run();
};
