import request from './utils/cycle-count-proxy-request';
import { MSG_ARR_RETCODE_LIST } from '@/views/inventory-management/cycle-count/constant';
export const cancelCcOrder = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/cancel_cc_order', {
        ...params,
    });
    return res;
};
export const ccGetAllPathwayInfo = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/cc_get_all_pathway_info', {
        params: {
            ...params,
        },
    });
    return res;
};
/* PermissionKey: ALL */
export const getParkZoneTree = async (params) => {
    const res = await request.get('/api/v2/apps/basic/location/get_park_zone_tree', {
        params: {
            park_data_type: 1,
            ...params,
        },
    });
    return res;
};
export const createCcOrder = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/create_cc_order', {
        ...params,
    }, {
        noErrorCode: MSG_ARR_RETCODE_LIST,
    });
    return res;
};
export const getCcOrderDetail = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getCcOrderDetailCcTaskList = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_cc_task_list', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getCcOrderDetailLog = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_log', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getCcOrderDetailSkuLocationBatch = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_sku_location_batch', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getCcOrderDetailSkuLocationList = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_sku_location_list', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getCcOrderDetailSkuLocationUid = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_sku_location_uid', {
        params: {
            ...params,
        },
    });
    return res;
};
export const skuCcSearchSku = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_search_sku', {
        ...params,
    });
    return res;
};
export const locationCcSearchConfirm = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/location_cc_search_confirm', {
        ...params,
    }, {
        noErrorCode: MSG_ARR_RETCODE_LIST,
    });
    return res;
};
export const locationCcSearchLocation = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/location_cc_search_location', {
        ...params,
    });
    return res;
};
export const movementCcSearchConfirm = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/movement_cc_search_confirm', {
        ...params,
    }, {
        noErrorCode: MSG_ARR_RETCODE_LIST,
    });
    return res;
};
export const movementCcSearchSku = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/movement_cc_search_sku', {
        ...params,
    });
    return res;
};
export const searchCcOrder = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/search_cc_order', {
        params: {
            ...params,
        },
    });
    return res;
};
// interface InterfaceSkuCcGetUploadTemplate {}
export const skuCcGetUploadTemplate = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_get_upload_template', {
        params: {
            ...params,
        },
    });
    return res;
};
export const skuCcSearchConfirm = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_search_confirm', {
        ...params,
    }, {
        noErrorCode: MSG_ARR_RETCODE_LIST,
    });
    return res;
};
export const skuCcSearchSupplierFuzzy = async (params) => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_search_supplier_fuzzy', {
        params: {
            ...params,
        },
    });
    return res;
};
export const skuCcUploadSku = async (params) => {
    const res = await request.post('/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_upload_sku', {
        ...params,
    });
    return res;
};
export const getCycleCountConfig = async () => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/get_cycle_count_config', {
        params: {},
    });
    return res;
};
export const getWhsIsSkuMapping = async () => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/ccorder/get_whs_is_sku_mapping', {
        params: {},
    });
    return res;
};
export const getCCSkuLimit = async () => {
    const res = await request.get('/api/v2/apps/process/inventory/cyclecount/get_cycle_count_sku_location_limit_config', {
        params: {},
    });
    return res;
};
export const getSkuCCUploadSkuHistoryList = async (params) => {
    return await request.post(
    /* PermissionKey: ALL */
    '/api/v2/apps/basic/importdata/search_import_data_task', params);
};
