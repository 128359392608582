import { DEFAULT_NULL_OR_EMPTY_STRING } from '@/common/constant';
import { format as _format } from '@/utils/date';
import store from '../store';
import { getRenderValue, getRenderValueWithoutCapitalize, getRenderValueWithoutUnderline, } from '@/utils/safeGet';
import { i18n } from '@/i18n/index';
const renderValueMap = {
    0: getRenderValue,
    1: getRenderValueWithoutCapitalize,
    2: getRenderValueWithoutUnderline,
};
export function emptyFormat(val) {
    if (val === '' || val === null || val === undefined) {
        return DEFAULT_NULL_OR_EMPTY_STRING;
    }
    return val;
}
export const format = _format; // 用法: {{ data | format }} 或者 {{ data | format(arg2, arg3) }}
export const formatEnum = (val, enumName, renderType = 0) => {
    const enums = { ...store.state.enums };
    return (renderValueMap[renderType](enums, `systemEnums.${enumName}`, val) || '-');
};
export const formatNumbers = (val) => {
    if (!val) {
        return 0;
    }
    else if (Number.isNaN(val) || val < 0 || ('' + val).includes('.')) {
        return val;
    }
    let res = '';
    const stack = (Number(val) + '').split('');
    let count = 0;
    while (stack.length) {
        const target = stack.pop();
        res = target + res;
        count++;
        if (count >= 3 && !!stack.length) {
            res = ',' + res;
            count = 0;
        }
    }
    return res;
};
export const $i18nFilter = i18n;
