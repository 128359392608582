import request from '../../utils/request';
export async function getPagingHeader() {
    return request.get('/api/v2/apps/config/paging_header/search_paging_header_list');
}
export async function setPagingHeader(data) {
    return request.post(`/api/v2/apps/config/paging_header/set_paging_header?time=${Date.now()}`, {
        ...data,
    });
}
export async function getAutoPrintConfig() {
    return request.get(
    /* PermissionKey: PC.RTSOutbound.Shipping */
    '/api/v2/apps/process/outbound/rtsorder/rtsshipment/get_manifest_auto_print_config');
}
export async function updateAutoPrintConfig(config_value) {
    return request.post(
    /* PermissionKey: PC.RTSOutbound.Shipping */
    '/api/v2/apps/process/outbound/rtsorder/rtsshipment/update_manifest_auto_print_config', {
        config_value,
    });
}
export const INNER_PACKAGING_DISPLAY_CONFIG = 'inner_packaging_display';
export const getDevConf = async (params) => {
    const res = await request.get('/api/v2/configuration/get_dev_conf', {
        params: {
            ...params,
        },
    });
    return res;
};
export const getDevConfList = async (params) => {
    /* PermissionKey: ALL */
    const res = await request.post('/api/v2/configuration/get_conf_list', {
        ...params,
    });
    return res;
};
export const getTranslateData = async (params) => {
    const res = await request.post(
    /* PermissionKey: ALL */
    '/api/v2/apps/basic/system/translate', {
        ...params,
    });
    return res;
};
