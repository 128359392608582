export var ABCClassification;
(function (ABCClassification) {
    ABCClassification[ABCClassification["A"] = 1] = "A";
    ABCClassification[ABCClassification["B"] = 2] = "B";
    ABCClassification[ABCClassification["C"] = 3] = "C";
    ABCClassification[ABCClassification["EXCLUDED"] = 5] = "EXCLUDED";
    ABCClassification[ABCClassification["SA"] = 4] = "SA";
    ABCClassification[ABCClassification["UNDEFINED"] = 0] = "UNDEFINED";
})(ABCClassification || (ABCClassification = {}));
export var AWBPrintType;
(function (AWBPrintType) {
    AWBPrintType[AWBPrintType["AWB_PRINT_TYPE_HTML"] = 2] = "AWB_PRINT_TYPE_HTML";
    AWBPrintType[AWBPrintType["AWB_PRINT_TYPE_PDF"] = 1] = "AWB_PRINT_TYPE_PDF";
})(AWBPrintType || (AWBPrintType = {}));
export var AbcActivityType;
(function (AbcActivityType) {
    AbcActivityType["HIGH_VALUE"] = "high_value";
    AbcActivityType["TEMPERATURE_CONTROL"] = "temperature_control";
})(AbcActivityType || (AbcActivityType = {}));
export var AbcCriteria;
(function (AbcCriteria) {
    AbcCriteria[AbcCriteria["ITEMS"] = 0] = "ITEMS";
    AbcCriteria[AbcCriteria["ORDERS"] = 1] = "ORDERS";
    AbcCriteria[AbcCriteria["PICKS"] = 2] = "PICKS";
})(AbcCriteria || (AbcCriteria = {}));
export var AbcDailySalesIntervalToDays;
(function (AbcDailySalesIntervalToDays) {
    AbcDailySalesIntervalToDays[AbcDailySalesIntervalToDays["15_DAYS"] = 1] = "15_DAYS";
    AbcDailySalesIntervalToDays[AbcDailySalesIntervalToDays["30_DAYS"] = 2] = "30_DAYS";
    AbcDailySalesIntervalToDays[AbcDailySalesIntervalToDays["60_DAYS"] = 4] = "60_DAYS";
    AbcDailySalesIntervalToDays[AbcDailySalesIntervalToDays["7_DAYS"] = 0] = "7_DAYS";
    AbcDailySalesIntervalToDays[AbcDailySalesIntervalToDays["90_DAYS"] = 3] = "90_DAYS";
})(AbcDailySalesIntervalToDays || (AbcDailySalesIntervalToDays = {}));
export var ActOp;
(function (ActOp) {
    ActOp[ActOp["INBOUND"] = 2] = "INBOUND";
    ActOp[ActOp["INVENTORY"] = 3] = "INVENTORY";
    ActOp[ActOp["MOVE__TRANSFER"] = 6] = "MOVE__TRANSFER";
    ActOp[ActOp["OTHERS"] = 7] = "OTHERS";
    ActOp[ActOp["OUTBOUND"] = 1] = "OUTBOUND";
    ActOp[ActOp["RETURN__INBOUND"] = 5] = "RETURN__INBOUND";
    ActOp[ActOp["RTS"] = 4] = "RTS";
})(ActOp || (ActOp = {}));
export var ActType;
(function (ActType) {
    ActType[ActType["DIRECT"] = 1] = "DIRECT";
    ActType[ActType["INDIRECT"] = 2] = "INDIRECT";
    ActType[ActType["UNPRODUCTIVE"] = 3] = "UNPRODUCTIVE";
})(ActType || (ActType = {}));
export var AdjustAdditionReason;
(function (AdjustAdditionReason) {
    AdjustAdditionReason[AdjustAdditionReason["INBOUND_EXCESS_QTY"] = 7] = "INBOUND_EXCESS_QTY";
    AdjustAdditionReason[AdjustAdditionReason["OUTBOUND_WRONG_ITEM"] = 6] = "OUTBOUND_WRONG_ITEM";
    AdjustAdditionReason[AdjustAdditionReason["RECOVER_WRONGLY_ADJUSTED_SKU_QTY"] = 8] = "RECOVER_WRONGLY_ADJUSTED_SKU_QTY";
    AdjustAdditionReason[AdjustAdditionReason["UNIDENTIFIED_REASON"] = 9] = "UNIDENTIFIED_REASON";
})(AdjustAdditionReason || (AdjustAdditionReason = {}));
export var AdjustAvAgeFlag;
(function (AdjustAvAgeFlag) {
    AdjustAvAgeFlag[AdjustAvAgeFlag["<"] = 2] = "<";
    AdjustAvAgeFlag[AdjustAvAgeFlag["="] = 3] = "=";
    AdjustAvAgeFlag[AdjustAvAgeFlag[">"] = 1] = ">";
})(AdjustAvAgeFlag || (AdjustAvAgeFlag = {}));
export var AdjustDeductionReason;
(function (AdjustDeductionReason) {
    AdjustDeductionReason[AdjustDeductionReason["BUYER_REPLACEMENT"] = 5] = "BUYER_REPLACEMENT";
    AdjustDeductionReason[AdjustDeductionReason["DAMAGE_ITEM"] = 1] = "DAMAGE_ITEM";
    AdjustDeductionReason[AdjustDeductionReason["EXPIRING_EXPIRY"] = 2] = "EXPIRING_EXPIRY";
    AdjustDeductionReason[AdjustDeductionReason["MANUAL_ORDER_FULFILLMENT"] = 4] = "MANUAL_ORDER_FULFILLMENT";
    AdjustDeductionReason[AdjustDeductionReason["MISSING_ITEM"] = 3] = "MISSING_ITEM";
})(AdjustDeductionReason || (AdjustDeductionReason = {}));
export var AdjustSourceFrom;
(function (AdjustSourceFrom) {
    AdjustSourceFrom[AdjustSourceFrom["MANUAL_CREATION"] = 1] = "MANUAL_CREATION";
    AdjustSourceFrom[AdjustSourceFrom["RT_TROUBLESHOOT_TASK"] = 3] = "RT_TROUBLESHOOT_TASK";
})(AdjustSourceFrom || (AdjustSourceFrom = {}));
export var AdjustSyncPMSStatus;
(function (AdjustSyncPMSStatus) {
    AdjustSyncPMSStatus[AdjustSyncPMSStatus["ADJUST__SENT"] = 5] = "ADJUST__SENT";
    AdjustSyncPMSStatus[AdjustSyncPMSStatus["ADJUST__UNSENT"] = 4] = "ADJUST__UNSENT";
    AdjustSyncPMSStatus[AdjustSyncPMSStatus["APPLY__SENT"] = 2] = "APPLY__SENT";
    AdjustSyncPMSStatus[AdjustSyncPMSStatus["APPLY__UNSENT"] = 1] = "APPLY__UNSENT";
    AdjustSyncPMSStatus[AdjustSyncPMSStatus["PMS__REPLY"] = 3] = "PMS__REPLY";
})(AdjustSyncPMSStatus || (AdjustSyncPMSStatus = {}));
export var AdjustTaskSkuLocationStatus;
(function (AdjustTaskSkuLocationStatus) {
    AdjustTaskSkuLocationStatus[AdjustTaskSkuLocationStatus["CANCEL"] = 9] = "CANCEL";
    AdjustTaskSkuLocationStatus[AdjustTaskSkuLocationStatus["DONE"] = 8] = "DONE";
    AdjustTaskSkuLocationStatus[AdjustTaskSkuLocationStatus["ON_GOING"] = 10] = "ON_GOING";
    AdjustTaskSkuLocationStatus[AdjustTaskSkuLocationStatus["PENDING"] = 1] = "PENDING";
})(AdjustTaskSkuLocationStatus || (AdjustTaskSkuLocationStatus = {}));
export var AdjustTaskStatus;
(function (AdjustTaskStatus) {
    AdjustTaskStatus[AdjustTaskStatus["Created"] = 1] = "Created";
    AdjustTaskStatus[AdjustTaskStatus["Pending"] = 2] = "Pending";
    AdjustTaskStatus[AdjustTaskStatus["Locked"] = 21] = "Locked";
    AdjustTaskStatus[AdjustTaskStatus["Rejected"] = 5] = "Rejected";
    AdjustTaskStatus[AdjustTaskStatus["Approved"] = 4] = "Approved";
    AdjustTaskStatus[AdjustTaskStatus["PMS_Approving"] = 3] = "PMS_Approving";
    AdjustTaskStatus[AdjustTaskStatus["Adjusting"] = 6] = "Adjusting";
    AdjustTaskStatus[AdjustTaskStatus["Done"] = 8] = "Done";
    AdjustTaskStatus[AdjustTaskStatus["Cancel"] = 9] = "Cancel";
    AdjustTaskStatus[AdjustTaskStatus["Fail"] = 10] = "Fail";
})(AdjustTaskStatus || (AdjustTaskStatus = {}));
export var AdjustType;
(function (AdjustType) {
    AdjustType[AdjustType["ADDITION_"] = 2] = "ADDITION_";
    AdjustType[AdjustType["DEDUCTION"] = 1] = "DEDUCTION";
})(AdjustType || (AdjustType = {}));
export var AllActiveLocationFrequency;
(function (AllActiveLocationFrequency) {
    AllActiveLocationFrequency[AllActiveLocationFrequency["DAYS_7"] = 0] = "DAYS_7";
    AllActiveLocationFrequency[AllActiveLocationFrequency["DAYS_14"] = 1] = "DAYS_14";
    AllActiveLocationFrequency[AllActiveLocationFrequency["DAYS_30"] = 2] = "DAYS_30";
    AllActiveLocationFrequency[AllActiveLocationFrequency["DAYS_90"] = 3] = "DAYS_90";
})(AllActiveLocationFrequency || (AllActiveLocationFrequency = {}));
export var AllocateRuleRuleType;
(function (AllocateRuleRuleType) {
    AllocateRuleRuleType[AllocateRuleRuleType["RTS__OUTBOUND"] = 1] = "RTS__OUTBOUND";
    AllocateRuleRuleType[AllocateRuleRuleType["SALES__OUTBOUND"] = 0] = "SALES__OUTBOUND";
})(AllocateRuleRuleType || (AllocateRuleRuleType = {}));
export var ApproveConfigurationApprove;
(function (ApproveConfigurationApprove) {
    ApproveConfigurationApprove[ApproveConfigurationApprove["N"] = 0] = "N";
    ApproveConfigurationApprove[ApproveConfigurationApprove["Y"] = 1] = "Y";
})(ApproveConfigurationApprove || (ApproveConfigurationApprove = {}));
export var ApproveConfigurationTaskType;
(function (ApproveConfigurationTaskType) {
    ApproveConfigurationTaskType[ApproveConfigurationTaskType["INVENTORY_MANAGEMENT"] = 0] = "INVENTORY_MANAGEMENT";
})(ApproveConfigurationTaskType || (ApproveConfigurationTaskType = {}));
export var ArrivingTaskOrder;
(function (ArrivingTaskOrder) {
    ArrivingTaskOrder[ArrivingTaskOrder["ASC"] = 0] = "ASC";
    ArrivingTaskOrder[ArrivingTaskOrder["DESC"] = 1] = "DESC";
})(ArrivingTaskOrder || (ArrivingTaskOrder = {}));
export var ArrivingTaskOrderKey;
(function (ArrivingTaskOrderKey) {
    ArrivingTaskOrderKey[ArrivingTaskOrderKey["CREATE_TIME"] = 2] = "CREATE_TIME";
    ArrivingTaskOrderKey[ArrivingTaskOrderKey["TIME_SINCE_CREATION"] = 1] = "TIME_SINCE_CREATION";
    ArrivingTaskOrderKey[ArrivingTaskOrderKey["TIME_SINCE_LAST_MODIFIED"] = 3] = "TIME_SINCE_LAST_MODIFIED";
})(ArrivingTaskOrderKey || (ArrivingTaskOrderKey = {}));
export var ArrivingTaskStatus;
(function (ArrivingTaskStatus) {
    ArrivingTaskStatus[ArrivingTaskStatus["PENDING"] = 10] = "PENDING";
    ArrivingTaskStatus[ArrivingTaskStatus["ONGOING"] = 20] = "ONGOING";
    ArrivingTaskStatus[ArrivingTaskStatus["DONE"] = 30] = "DONE";
    ArrivingTaskStatus[ArrivingTaskStatus["CANCELLED"] = 40] = "CANCELLED";
})(ArrivingTaskStatus || (ArrivingTaskStatus = {}));
export var AsnGroupStatus;
(function (AsnGroupStatus) {
    AsnGroupStatus[AsnGroupStatus["CANCELLED"] = 1] = "CANCELLED";
    AsnGroupStatus[AsnGroupStatus["DONE"] = 6] = "DONE";
    AsnGroupStatus[AsnGroupStatus["PENDING"] = 2] = "PENDING";
    AsnGroupStatus[AsnGroupStatus["PUTTING"] = 5] = "PUTTING";
    AsnGroupStatus[AsnGroupStatus["RECEIVED"] = 4] = "RECEIVED";
    AsnGroupStatus[AsnGroupStatus["SCANNING"] = 3] = "SCANNING";
})(AsnGroupStatus || (AsnGroupStatus = {}));
export var AsnQuotaStatus;
(function (AsnQuotaStatus) {
    AsnQuotaStatus[AsnQuotaStatus["OFF"] = 1] = "OFF";
    AsnQuotaStatus[AsnQuotaStatus["ON"] = 2] = "ON";
})(AsnQuotaStatus || (AsnQuotaStatus = {}));
export var BatchTransferRelatedSheetType;
(function (BatchTransferRelatedSheetType) {
    BatchTransferRelatedSheetType[BatchTransferRelatedSheetType["BATCH_TRANSFER"] = 0] = "BATCH_TRANSFER";
    BatchTransferRelatedSheetType[BatchTransferRelatedSheetType["MOVE_TRANSFER_INBOUND"] = 1] = "MOVE_TRANSFER_INBOUND";
})(BatchTransferRelatedSheetType || (BatchTransferRelatedSheetType = {}));
export var BatchTransferStatus;
(function (BatchTransferStatus) {
    BatchTransferStatus[BatchTransferStatus["CANCEL"] = 9] = "CANCEL";
    BatchTransferStatus[BatchTransferStatus["DONE"] = 10] = "DONE";
    BatchTransferStatus[BatchTransferStatus["ONGOING"] = 5] = "ONGOING";
    BatchTransferStatus[BatchTransferStatus["PENDING"] = 1] = "PENDING";
})(BatchTransferStatus || (BatchTransferStatus = {}));
export var BatchTransferTaskStatus;
(function (BatchTransferTaskStatus) {
    BatchTransferTaskStatus[BatchTransferTaskStatus["CANCEL"] = 9] = "CANCEL";
    BatchTransferTaskStatus[BatchTransferTaskStatus["DONE"] = 10] = "DONE";
    BatchTransferTaskStatus[BatchTransferTaskStatus["ONGOING"] = 5] = "ONGOING";
    BatchTransferTaskStatus[BatchTransferTaskStatus["PENDING"] = 1] = "PENDING";
})(BatchTransferTaskStatus || (BatchTransferTaskStatus = {}));
export var BatchTransferTaskType;
(function (BatchTransferTaskType) {
    BatchTransferTaskType[BatchTransferTaskType["NORMAL"] = 1] = "NORMAL";
    BatchTransferTaskType[BatchTransferTaskType["QUALITY_TRANSFER"] = 2] = "QUALITY_TRANSFER";
})(BatchTransferTaskType || (BatchTransferTaskType = {}));
export var BlockReasonType;
(function (BlockReasonType) {
    BlockReasonType["BATCH_TRANSFER"] = "Batch transfer";
    BlockReasonType["EXPIRING_TO_EXPIRED"] = "Expiring to expired";
    BlockReasonType["INVENTORY_ADJUSTMENT"] = "Inventory adjustment";
    BlockReasonType["OPERATOR_UNBLOCK"] = "Operator Unblock";
    BlockReasonType["RACK_TRANSFER"] = "Rack transfer";
    BlockReasonType["RETURN_INBOUND"] = "Return Inbound";
    BlockReasonType["RETURN_ORDER"] = "Return order";
    BlockReasonType["SYSTEM"] = "System";
    BlockReasonType["UNKNOWN"] = "Unknown";
})(BlockReasonType || (BlockReasonType = {}));
export var BlockStatusType;
(function (BlockStatusType) {
    BlockStatusType[BlockStatusType["BLOCK"] = 2] = "BLOCK";
    BlockStatusType[BlockStatusType["FAIL"] = 3] = "FAIL";
    BlockStatusType[BlockStatusType["PENDING"] = 1] = "PENDING";
    BlockStatusType[BlockStatusType["UNBLOCK"] = 0] = "UNBLOCK";
})(BlockStatusType || (BlockStatusType = {}));
export var BlockTypeDisplay;
(function (BlockTypeDisplay) {
    BlockTypeDisplay[BlockTypeDisplay["ALL"] = 3] = "ALL";
    BlockTypeDisplay[BlockTypeDisplay["EXPIRED"] = 2] = "EXPIRED";
    BlockTypeDisplay[BlockTypeDisplay["EXPIRING"] = 1] = "EXPIRING";
    BlockTypeDisplay[BlockTypeDisplay["NORMAL"] = 0] = "NORMAL";
})(BlockTypeDisplay || (BlockTypeDisplay = {}));
export var CBOption;
(function (CBOption) {
    CBOption[CBOption["CB"] = 1] = "CB";
    CBOption[CBOption["LOCAL"] = 0] = "LOCAL";
})(CBOption || (CBOption = {}));
export var CategoryLevel;
(function (CategoryLevel) {
    CategoryLevel[CategoryLevel["CATEGORY_LEVEL_L1"] = 1] = "CATEGORY_LEVEL_L1";
    CategoryLevel[CategoryLevel["CATEGORY_LEVEL_L2"] = 2] = "CATEGORY_LEVEL_L2";
    CategoryLevel[CategoryLevel["CATEGORY_LEVEL_L3"] = 3] = "CATEGORY_LEVEL_L3";
    CategoryLevel[CategoryLevel["CATEGORY_LEVEL_L4"] = 4] = "CATEGORY_LEVEL_L4";
    CategoryLevel[CategoryLevel["CATEGORY_LEVEL_L5"] = 5] = "CATEGORY_LEVEL_L5";
})(CategoryLevel || (CategoryLevel = {}));
export var CategoryOperationType;
(function (CategoryOperationType) {
    CategoryOperationType[CategoryOperationType["CATEGORY_OPERATION_TYPE_HIGH_VALUE_FLAG_CHANGE"] = 5] = "CATEGORY_OPERATION_TYPE_HIGH_VALUE_FLAG_CHANGE";
    CategoryOperationType[CategoryOperationType["CATEGORY_OPERATION_TYPE_INIT"] = 0] = "CATEGORY_OPERATION_TYPE_INIT";
    CategoryOperationType[CategoryOperationType["CATEGORY_OPERATION_TYPE_LIFE_CYCLE_RULE_CHANGE"] = 2] = "CATEGORY_OPERATION_TYPE_LIFE_CYCLE_RULE_CHANGE";
    CategoryOperationType[CategoryOperationType["CATEGORY_OPERATION_TYPE_SCBS_CHANGE"] = 3] = "CATEGORY_OPERATION_TYPE_SCBS_CHANGE";
    CategoryOperationType[CategoryOperationType["CATEGORY_OPERATION_TYPE_SHELF_LIFE_FLAG_CHANGE"] = 1] = "CATEGORY_OPERATION_TYPE_SHELF_LIFE_FLAG_CHANGE";
    CategoryOperationType[CategoryOperationType["CATEGORY_OPERATION_TYPE_TEMP_ZONE_CHANGE"] = 4] = "CATEGORY_OPERATION_TYPE_TEMP_ZONE_CHANGE";
})(CategoryOperationType || (CategoryOperationType = {}));
export var CategoryTemperatureControl;
(function (CategoryTemperatureControl) {
    CategoryTemperatureControl[CategoryTemperatureControl["NORMAL"] = 0] = "NORMAL";
    CategoryTemperatureControl[CategoryTemperatureControl["AIR_CONDITIONED"] = 1] = "AIR_CONDITIONED";
    CategoryTemperatureControl[CategoryTemperatureControl["CHILLED"] = 2] = "CHILLED";
    CategoryTemperatureControl[CategoryTemperatureControl["FROZEN"] = 3] = "FROZEN";
    CategoryTemperatureControl[CategoryTemperatureControl["DEEP_FROZEN"] = 4] = "DEEP_FROZEN";
})(CategoryTemperatureControl || (CategoryTemperatureControl = {}));
export var CdnFolder;
(function (CdnFolder) {
    CdnFolder["APP_DOWNLOAD_URL_PARENT_PATH"] = "/appdownloads";
    CdnFolder["AWB_LABEL"] = "/awb_label";
    CdnFolder["BLOCK_FILE_DOWNLOAD"] = "/inventory_map/block";
    CdnFolder["CELL_DOWNLOAD"] = "/cell";
    CdnFolder["CYCLE_COUNT_DOWNLOAD"] = "/cycle_count/excel";
    CdnFolder["DAILY_SALES"] = "/daily_sales_map";
    CdnFolder["EXPORT_ACTIVITY"] = "/export_activity";
    CdnFolder["EXPORT_ACTIVITY_LIST"] = "/export_activity_list";
    CdnFolder["EXPORT_CELL"] = "/export_cell";
    CdnFolder["EXPORT_CONSUMABLES"] = "/mconsumables";
    CdnFolder["EXPORT_CYCLE_COUNT_ORDER"] = "/export_cycle_count_order";
    CdnFolder["EXPORT_DAILY_SALES"] = "/export_daily_sales";
    CdnFolder["EXPORT_DEVICE"] = "/export_device";
    CdnFolder["EXPORT_EXCEPTION_CENTER"] = "/export_exception_center";
    CdnFolder["EXPORT_INBOUND"] = "/export_inbound";
    CdnFolder["EXPORT_MERGING_TASK"] = "/merging_task";
    CdnFolder["EXPORT_OUTBOUND_SALE_CHECKING"] = "/outbound_sale_checking";
    CdnFolder["EXPORT_OUTBOUND_SALE_ORDER"] = "/outbound_sale_order";
    CdnFolder["EXPORT_OUTBOUND_SALE_PACKING_USER"] = "/outbound_sale_packing_user";
    CdnFolder["EXPORT_OUTBOUND_SALE_PICKING"] = "/outbound_sale_picking";
    CdnFolder["EXPORT_OUTBOUND_SALE_SALE_WEIGHT_VALIDATE"] = "/outbound_sale_weight_walidate";
    CdnFolder["EXPORT_OUTBOUND_SALE_SHIPPING"] = "/outbound_sale_shipping";
    CdnFolder["EXPORT_PDA_LOGIN_LOG"] = "/export_pda_login_log";
    CdnFolder["EXPORT_PICKER_SKILL"] = "/export_picker_skill";
    CdnFolder["EXPORT_QC_RULE"] = "/export_qc_rule";
    CdnFolder["EXPORT_RETURN_INBOUND"] = "/export_return_inbound";
    CdnFolder["EXPORT_SHOP"] = "/export_shop";
    CdnFolder["EXPORT_SKU"] = "/export_sku";
    CdnFolder["EXPORT_STAFF"] = "/export_staff";
    CdnFolder["EXPORT_STAFF_ACTIVITY"] = "/export_staff_activity";
    CdnFolder["EXPORT_STAFF_ATTENDANCE"] = "/export_staff_attendance";
    CdnFolder["EXPORT_STAFF_LIST"] = "/export_staff_list";
    CdnFolder["EXPORT_STOCK"] = "/export_stock";
    CdnFolder["EXPORT_SUB_PICKING_TASK"] = "/sub_picking";
    CdnFolder["EXPORT_SUPPLIER"] = "/export_supplier";
    CdnFolder["EXPORT_TRANSITING_TASK"] = "/transiting_task";
    CdnFolder["GIFT_IMAGE"] = "/gift_image";
    CdnFolder["GIFT_TASK_DOWNLOAD"] = "/gift";
    CdnFolder["INBOUND_ASN_TRACKING_ID"] = "/inbound_tracking";
    CdnFolder["INBOUND_EXCEPTION_IMAGE"] = "/inbound_exception_image";
    CdnFolder["INBOUND_MAP_DOWNLOAD"] = "/inbound_map";
    CdnFolder["INBOUND_SKU_MAP_DOWNLOAD"] = "/inbound_sku_map";
    CdnFolder["INTRANSIT_INVENTORY_DOWNLOAD"] = "/intransit_inventory";
    CdnFolder["INVENTORY_ADJUSTMENT"] = "/Inventory_adjustment";
    CdnFolder["INVENTORY_ADJUST_DOWNLOAD"] = "/inventory_adjust";
    CdnFolder["INVENTORY_MAP_DOWNLOAD"] = "/inventory_map";
    CdnFolder["INV_MAP_AVAILABLE_DOWNLOAD"] = "/inventory_map/available";
    CdnFolder["INV_MAP_EXPIRY_DOWNLOAD"] = "/inventory_map/expiry";
    CdnFolder["INV_MAP_ONHAND_DOWNLOAD"] = "/inventory_map/onhand";
    CdnFolder["LOCATION_OCCUPATION_DOWNLOAD"] = "/location_occupation_map";
    CdnFolder["MOVE_TRANSFER_ARRIVAL_ORDER_DOWNLOAD"] = "/move_transfer_inbound/arrival_orders";
    CdnFolder["MOVE_TRANSFER_INBOUND_DOWNLOAD"] = "/move_transfer_inbound";
    CdnFolder["MOVE_TRANSFER_OUTBOUND_PACKING_DOWNLOAD"] = "/mto_outbound/packing_task";
    CdnFolder["MOVE_TRANSFER_OUTBOUND_SHIP_DOWNLOAD"] = "/mto_outbound/ship_order";
    CdnFolder["ORDERS_DOWNLOAD"] = "/orders";
    CdnFolder["OUTBOUND_SHIPPED_TASK_DOWNLOAD"] = "/outbound_shipped_orders";
    CdnFolder["PACKERS_PRODUCTIVITY_DOWNLOAD"] = "/packers_productivity";
    CdnFolder["PACKING_TASK_DOWNLOAD"] = "/packing_task";
    CdnFolder["PDA_ARRIVAL"] = "/pda_arrival";
    CdnFolder["PDA_PACKAGE"] = "/pda_package";
    CdnFolder["PICKING_TASK_DOWNLOAD"] = "/picking_task";
    CdnFolder["RACK_TRANSFER_DOWNLOAD"] = "/rack_transfer/excel";
    CdnFolder["REPLENISHMENT"] = "/replenishment_map";
    CdnFolder["REPORT_ACCESS"] = "/report_access";
    CdnFolder["RETURN_INBOUND_QC"] = "/return_inbound_qc_images";
    CdnFolder["RETURN_ORDER_DOWNLOAD"] = "/rts_order";
    CdnFolder["REVERSE_TASK_DOWNLOAD"] = "/reverse_task";
    CdnFolder["RTS_AVAILABLE_QUANTITY"] = "/rts_available_quantity";
    CdnFolder["RTS_RECONCILIATION"] = "/rts_reconciliation";
    CdnFolder["SHIP_ORDER_DOWNLOAD"] = "/ship_orders";
    CdnFolder["SKU_ITEM_DOWNLOAD"] = "/sku_item";
    CdnFolder["SKU_LOG_DOWNLOAD"] = "/sku_log/excel";
    CdnFolder["TRAN_MAP_DOWNLOAD"] = "/transaction_map";
    CdnFolder["TREE"] = "/Folder_tree";
    CdnFolder["UN_BLOCK_FILE_DOWNLOAD"] = "/inventory_map/unblock";
    CdnFolder["UPLOADED_FILES_PATH"] = "/uploaded_files";
    CdnFolder["USER_DOWNLOAD"] = "/user/excel";
    CdnFolder["WEIGHT_VALIDATION_DOWNLOAD"] = "/weight_validation";
    CdnFolder["WRONG_SKU_IMAGE"] = "/wrong_sku_image";
})(CdnFolder || (CdnFolder = {}));
export var CellStatus;
(function (CellStatus) {
    CellStatus[CellStatus["BLOCKED"] = 2] = "BLOCKED";
    CellStatus[CellStatus["DELETED"] = 4] = "DELETED";
    CellStatus[CellStatus["FROZEN"] = 3] = "FROZEN";
    CellStatus[CellStatus["NORMAL"] = 1] = "NORMAL";
    CellStatus[CellStatus["UNDEFINED"] = 0] = "UNDEFINED";
})(CellStatus || (CellStatus = {}));
export var CellStructLevel;
(function (CellStructLevel) {
    CellStructLevel[CellStructLevel["CELL"] = 3] = "CELL";
    CellStructLevel[CellStructLevel["PATHWAY"] = 2] = "PATHWAY";
    CellStructLevel[CellStructLevel["UNDEFINED"] = 0] = "UNDEFINED";
    CellStructLevel[CellStructLevel["ZONE"] = 1] = "ZONE";
})(CellStructLevel || (CellStructLevel = {}));
export var CheckinTaskCheckMethod;
(function (CheckinTaskCheckMethod) {
    CheckinTaskCheckMethod[CheckinTaskCheckMethod["Checking"] = 0] = "Checking";
    CheckinTaskCheckMethod[CheckinTaskCheckMethod["Mass_Checking"] = 2] = "Mass_Checking";
    CheckinTaskCheckMethod[CheckinTaskCheckMethod["Sort_While_Checking"] = 1] = "Sort_While_Checking";
})(CheckinTaskCheckMethod || (CheckinTaskCheckMethod = {}));
export var CheckinTaskPrintTag;
(function (CheckinTaskPrintTag) {
    CheckinTaskPrintTag[CheckinTaskPrintTag["Y"] = 1] = "Y";
    CheckinTaskPrintTag[CheckinTaskPrintTag["N"] = 0] = "N";
})(CheckinTaskPrintTag || (CheckinTaskPrintTag = {}));
export var CheckingTaskMassOrderFlag;
(function (CheckingTaskMassOrderFlag) {
    CheckingTaskMassOrderFlag[CheckingTaskMassOrderFlag["Y"] = 1] = "Y";
    CheckingTaskMassOrderFlag[CheckingTaskMassOrderFlag["N"] = 0] = "N";
})(CheckingTaskMassOrderFlag || (CheckingTaskMassOrderFlag = {}));
export var CheckingTaskSbsFlag;
(function (CheckingTaskSbsFlag) {
    CheckingTaskSbsFlag[CheckingTaskSbsFlag["Y"] = 0] = "Y";
    CheckingTaskSbsFlag[CheckingTaskSbsFlag["N"] = 1] = "N";
})(CheckingTaskSbsFlag || (CheckingTaskSbsFlag = {}));
export var CheckingTaskSourceType;
(function (CheckingTaskSourceType) {
    CheckingTaskSourceType[CheckingTaskSourceType["PICKING__ID"] = 0] = "PICKING__ID";
})(CheckingTaskSourceType || (CheckingTaskSourceType = {}));
export var CheckingTaskStatus;
(function (CheckingTaskStatus) {
    CheckingTaskStatus[CheckingTaskStatus["Pending"] = 0] = "Pending";
    CheckingTaskStatus[CheckingTaskStatus["Ongoing"] = 1] = "Ongoing";
    CheckingTaskStatus[CheckingTaskStatus["Done"] = 2] = "Done";
    CheckingTaskStatus[CheckingTaskStatus["Cancel"] = 9] = "Cancel";
})(CheckingTaskStatus || (CheckingTaskStatus = {}));
export var CollectUnitType;
(function (CollectUnitType) {
    CollectUnitType[CollectUnitType["PUTAWAY"] = 1] = "PUTAWAY";
    CollectUnitType[CollectUnitType["RECEIVING"] = 2] = "RECEIVING";
    CollectUnitType[CollectUnitType["UNDEFINED"] = 0] = "UNDEFINED";
})(CollectUnitType || (CollectUnitType = {}));
export var CommonEnum;
(function (CommonEnum) {
    CommonEnum[CommonEnum["NO"] = 0] = "NO";
    CommonEnum[CommonEnum["YES"] = 1] = "YES";
})(CommonEnum || (CommonEnum = {}));
export var CommonEnumYAndN;
(function (CommonEnumYAndN) {
    CommonEnumYAndN[CommonEnumYAndN["Y"] = 1] = "Y";
    CommonEnumYAndN[CommonEnumYAndN["N"] = 0] = "N";
})(CommonEnumYAndN || (CommonEnumYAndN = {}));
export var ConfAvailableType;
(function (ConfAvailableType) {
    ConfAvailableType[ConfAvailableType["Disable"] = 0] = "Disable";
    ConfAvailableType[ConfAvailableType["Enabled"] = 1] = "Enabled";
})(ConfAvailableType || (ConfAvailableType = {}));
export var ConfLevelType;
(function (ConfLevelType) {
    ConfLevelType[ConfLevelType["Region"] = 1] = "Region";
    ConfLevelType[ConfLevelType["Warehouse"] = 2] = "Warehouse";
})(ConfLevelType || (ConfLevelType = {}));
export var ConfNameOrder;
(function (ConfNameOrder) {
    ConfNameOrder[ConfNameOrder["ASC"] = 0] = "ASC";
    ConfNameOrder[ConfNameOrder["DESC"] = 1] = "DESC";
})(ConfNameOrder || (ConfNameOrder = {}));
export var ConfOrderKey;
(function (ConfOrderKey) {
    ConfOrderKey[ConfOrderKey["ConfName"] = 2] = "ConfName";
    ConfOrderKey[ConfOrderKey["Mtime"] = 1] = "Mtime";
})(ConfOrderKey || (ConfOrderKey = {}));
export var ConsumableManualRemovalTaskStatus;
(function (ConsumableManualRemovalTaskStatus) {
    ConsumableManualRemovalTaskStatus[ConsumableManualRemovalTaskStatus["DONE"] = 2] = "DONE";
    ConsumableManualRemovalTaskStatus[ConsumableManualRemovalTaskStatus["ONGOING"] = 1] = "ONGOING";
})(ConsumableManualRemovalTaskStatus || (ConsumableManualRemovalTaskStatus = {}));
export var ConsumableStockTakeCreateType;
(function (ConsumableStockTakeCreateType) {
    ConsumableStockTakeCreateType[ConsumableStockTakeCreateType["BY__ID"] = 2] = "BY__ID";
    ConsumableStockTakeCreateType[ConsumableStockTakeCreateType["BY__TYPE"] = 1] = "BY__TYPE";
})(ConsumableStockTakeCreateType || (ConsumableStockTakeCreateType = {}));
export var ConsumableStockTakeTaskDetailStatus;
(function (ConsumableStockTakeTaskDetailStatus) {
    ConsumableStockTakeTaskDetailStatus[ConsumableStockTakeTaskDetailStatus["DONE"] = 2] = "DONE";
    ConsumableStockTakeTaskDetailStatus[ConsumableStockTakeTaskDetailStatus["ONGOING"] = 1] = "ONGOING";
    ConsumableStockTakeTaskDetailStatus[ConsumableStockTakeTaskDetailStatus["PENDING"] = 0] = "PENDING";
})(ConsumableStockTakeTaskDetailStatus || (ConsumableStockTakeTaskDetailStatus = {}));
export var ConsumableStockTaskStatus;
(function (ConsumableStockTaskStatus) {
    ConsumableStockTaskStatus[ConsumableStockTaskStatus["CANCEL"] = 7] = "CANCEL";
    ConsumableStockTaskStatus[ConsumableStockTaskStatus["DONE"] = 2] = "DONE";
    ConsumableStockTaskStatus[ConsumableStockTaskStatus["ONGOING"] = 1] = "ONGOING";
    ConsumableStockTaskStatus[ConsumableStockTaskStatus["PENDING"] = 0] = "PENDING";
})(ConsumableStockTaskStatus || (ConsumableStockTaskStatus = {}));
export var ConsumableType;
(function (ConsumableType) {
    ConsumableType[ConsumableType["FILLERS"] = 4] = "FILLERS";
    ConsumableType[ConsumableType["OTHERS"] = 6] = "OTHERS";
    ConsumableType[ConsumableType["PACKING_BOX"] = 1] = "PACKING_BOX";
    ConsumableType[ConsumableType["POUCH"] = 2] = "POUCH";
    ConsumableType[ConsumableType["STATIONERIES"] = 3] = "STATIONERIES";
    ConsumableType[ConsumableType["STRETCH_FILMS"] = 5] = "STRETCH_FILMS";
})(ConsumableType || (ConsumableType = {}));
export var ConsumableWithMultiType;
(function (ConsumableWithMultiType) {
    ConsumableWithMultiType[ConsumableWithMultiType["ALL"] = 8] = "ALL";
    ConsumableWithMultiType[ConsumableWithMultiType["FILLERS"] = 4] = "FILLERS";
    ConsumableWithMultiType[ConsumableWithMultiType["MIX"] = 7] = "MIX";
    ConsumableWithMultiType[ConsumableWithMultiType["OTHERS"] = 6] = "OTHERS";
    ConsumableWithMultiType[ConsumableWithMultiType["PACKING_BOX"] = 1] = "PACKING_BOX";
    ConsumableWithMultiType[ConsumableWithMultiType["POUCH"] = 2] = "POUCH";
    ConsumableWithMultiType[ConsumableWithMultiType["STATIONERIES"] = 3] = "STATIONERIES";
    ConsumableWithMultiType[ConsumableWithMultiType["STRETCH_FILMS"] = 5] = "STRETCH_FILMS";
})(ConsumableWithMultiType || (ConsumableWithMultiType = {}));
export var ConsumablesStatus;
(function (ConsumablesStatus) {
    ConsumablesStatus[ConsumablesStatus["OFF"] = 0] = "OFF";
    ConsumablesStatus[ConsumablesStatus["ON"] = 1] = "ON";
    ConsumablesStatus[ConsumablesStatus["UNDEFINE"] = 2] = "UNDEFINE";
})(ConsumablesStatus || (ConsumablesStatus = {}));
export var ContractType;
(function (ContractType) {
    ContractType[ContractType["CONTRACT"] = 3] = "CONTRACT";
    ContractType[ContractType["PERMANENT"] = 1] = "PERMANENT";
    ContractType[ContractType["TEMPORARY"] = 2] = "TEMPORARY";
})(ContractType || (ContractType = {}));
export var CountryRegionType;
(function (CountryRegionType) {
    CountryRegionType["CN"] = "CN";
    CountryRegionType["ID"] = "ID";
    CountryRegionType["MY"] = "MY";
    CountryRegionType["PH"] = "PH";
    CountryRegionType["SG"] = "SG";
    CountryRegionType["SZ"] = "SZ";
    CountryRegionType["TH"] = "TH";
    CountryRegionType["TW"] = "TW";
    CountryRegionType["VN"] = "VN";
})(CountryRegionType || (CountryRegionType = {}));
export var CrateReplWaveOrderFilter;
(function (CrateReplWaveOrderFilter) {
    CrateReplWaveOrderFilter[CrateReplWaveOrderFilter["CUT_OFF_TIME"] = 1] = "CUT_OFF_TIME";
    CrateReplWaveOrderFilter[CrateReplWaveOrderFilter["ONLY_URGENT_ORDER"] = 3] = "ONLY_URGENT_ORDER";
    CrateReplWaveOrderFilter[CrateReplWaveOrderFilter["URGENT_CREATE_TIME"] = 2] = "URGENT_CREATE_TIME";
})(CrateReplWaveOrderFilter || (CrateReplWaveOrderFilter = {}));
export var CreateReplWaveCreateBySku;
(function (CreateReplWaveCreateBySku) {
    CreateReplWaveCreateBySku[CreateReplWaveCreateBySku["N"] = 0] = "N";
    CreateReplWaveCreateBySku[CreateReplWaveCreateBySku["Y"] = 1] = "Y";
})(CreateReplWaveCreateBySku || (CreateReplWaveCreateBySku = {}));
export var CreateReplWavePaperless;
(function (CreateReplWavePaperless) {
    CreateReplWavePaperless[CreateReplWavePaperless["N"] = 0] = "N";
    CreateReplWavePaperless[CreateReplWavePaperless["Y"] = 1] = "Y";
})(CreateReplWavePaperless || (CreateReplWavePaperless = {}));
export var CreateReplWaveReplType;
(function (CreateReplWaveReplType) {
    CreateReplWaveReplType[CreateReplWaveReplType["ALL"] = 5] = "ALL";
    CreateReplWaveReplType[CreateReplWaveReplType["FORWARD_ONLY"] = 6] = "FORWARD_ONLY";
    CreateReplWaveReplType[CreateReplWaveReplType["REVERSE_ONLY"] = 7] = "REVERSE_ONLY";
})(CreateReplWaveReplType || (CreateReplWaveReplType = {}));
export var CreateRplWaveCrossZoneCluster;
(function (CreateRplWaveCrossZoneCluster) {
    CreateRplWaveCrossZoneCluster[CreateRplWaveCrossZoneCluster["N"] = 0] = "N";
    CreateRplWaveCrossZoneCluster[CreateRplWaveCrossZoneCluster["Y"] = 1] = "Y";
})(CreateRplWaveCrossZoneCluster || (CreateRplWaveCrossZoneCluster = {}));
export var ReplRunWaveViewRackTransferType;
(function (ReplRunWaveViewRackTransferType) {
    ReplRunWaveViewRackTransferType[ReplRunWaveViewRackTransferType["AUTO"] = 0] = "AUTO";
    ReplRunWaveViewRackTransferType[ReplRunWaveViewRackTransferType["ONE_STEP"] = 1] = "ONE_STEP";
    ReplRunWaveViewRackTransferType[ReplRunWaveViewRackTransferType["TWO_STEP"] = 2] = "TWO_STEP";
    ReplRunWaveViewRackTransferType[ReplRunWaveViewRackTransferType["THREE_STEP"] = 3] = "THREE_STEP";
})(ReplRunWaveViewRackTransferType || (ReplRunWaveViewRackTransferType = {}));
export var CreateRplWaveSkuSalesType;
(function (CreateRplWaveSkuSalesType) {
    CreateRplWaveSkuSalesType[CreateRplWaveSkuSalesType["ALL"] = 4] = "ALL";
    CreateRplWaveSkuSalesType[CreateRplWaveSkuSalesType["CAMPAIGN_SALES"] = 2] = "CAMPAIGN_SALES";
    CreateRplWaveSkuSalesType[CreateRplWaveSkuSalesType["FLASH_SALES"] = 1] = "FLASH_SALES";
    CreateRplWaveSkuSalesType[CreateRplWaveSkuSalesType["ORGANIC_SALES"] = 3] = "ORGANIC_SALES";
})(CreateRplWaveSkuSalesType || (CreateRplWaveSkuSalesType = {}));
export var CycleCountDashboardCurrentQuarter;
(function (CycleCountDashboardCurrentQuarter) {
    CycleCountDashboardCurrentQuarter[CycleCountDashboardCurrentQuarter["APR_JUN"] = 1] = "APR_JUN";
    CycleCountDashboardCurrentQuarter[CycleCountDashboardCurrentQuarter["JAN_MAR"] = 0] = "JAN_MAR";
    CycleCountDashboardCurrentQuarter[CycleCountDashboardCurrentQuarter["JUL_SEP"] = 2] = "JUL_SEP";
    CycleCountDashboardCurrentQuarter[CycleCountDashboardCurrentQuarter["OCT_DEC"] = 3] = "OCT_DEC";
})(CycleCountDashboardCurrentQuarter || (CycleCountDashboardCurrentQuarter = {}));
export var CycleCountDiffOrderItemNeedHandle;
(function (CycleCountDiffOrderItemNeedHandle) {
    CycleCountDiffOrderItemNeedHandle[CycleCountDiffOrderItemNeedHandle["-"] = 2] = "-";
    CycleCountDiffOrderItemNeedHandle[CycleCountDiffOrderItemNeedHandle["N"] = 0] = "N";
    CycleCountDiffOrderItemNeedHandle[CycleCountDiffOrderItemNeedHandle["Y"] = 1] = "Y";
})(CycleCountDiffOrderItemNeedHandle || (CycleCountDiffOrderItemNeedHandle = {}));
export var CycleCountDiffOrderItemStatus;
(function (CycleCountDiffOrderItemStatus) {
    CycleCountDiffOrderItemStatus[CycleCountDiffOrderItemStatus["APPROVED"] = 2] = "APPROVED";
    CycleCountDiffOrderItemStatus[CycleCountDiffOrderItemStatus["PENDING"] = 1] = "PENDING";
    CycleCountDiffOrderItemStatus[CycleCountDiffOrderItemStatus["REJECTED"] = 3] = "REJECTED";
})(CycleCountDiffOrderItemStatus || (CycleCountDiffOrderItemStatus = {}));
export var CycleCountDiffOrderStatus;
(function (CycleCountDiffOrderStatus) {
    CycleCountDiffOrderStatus[CycleCountDiffOrderStatus["DONE"] = 3] = "DONE";
    CycleCountDiffOrderStatus[CycleCountDiffOrderStatus["ONGOING"] = 2] = "ONGOING";
    CycleCountDiffOrderStatus[CycleCountDiffOrderStatus["PENDING"] = 1] = "PENDING";
})(CycleCountDiffOrderStatus || (CycleCountDiffOrderStatus = {}));
export var CycleCountIsCountedInCurrentQuarter;
(function (CycleCountIsCountedInCurrentQuarter) {
    CycleCountIsCountedInCurrentQuarter[CycleCountIsCountedInCurrentQuarter["ALL"] = 2] = "ALL";
    CycleCountIsCountedInCurrentQuarter[CycleCountIsCountedInCurrentQuarter["N"] = 0] = "N";
    CycleCountIsCountedInCurrentQuarter[CycleCountIsCountedInCurrentQuarter["Y"] = 1] = "Y";
})(CycleCountIsCountedInCurrentQuarter || (CycleCountIsCountedInCurrentQuarter = {}));
export var CycleCountIsLocationMovement;
(function (CycleCountIsLocationMovement) {
    CycleCountIsLocationMovement[CycleCountIsLocationMovement["N"] = 0] = "N";
    CycleCountIsLocationMovement[CycleCountIsLocationMovement["Y"] = 1] = "Y";
})(CycleCountIsLocationMovement || (CycleCountIsLocationMovement = {}));
export var CycleCountDailyLocationCCOperateFunction;
(function (CycleCountDailyLocationCCOperateFunction) {
    CycleCountDailyLocationCCOperateFunction[CycleCountDailyLocationCCOperateFunction["NORMAL"] = 2] = "NORMAL";
})(CycleCountDailyLocationCCOperateFunction || (CycleCountDailyLocationCCOperateFunction = {}));
export var CycleCountLocationCCOperateFunction;
(function (CycleCountLocationCCOperateFunction) {
    CycleCountLocationCCOperateFunction[CycleCountLocationCCOperateFunction["EMPTY_LOCATION_COUNT"] = 3] = "EMPTY_LOCATION_COUNT";
    CycleCountLocationCCOperateFunction[CycleCountLocationCCOperateFunction["LESS_QTY_COUNT"] = 4] = "LESS_QTY_COUNT";
    CycleCountLocationCCOperateFunction[CycleCountLocationCCOperateFunction["NORMAL"] = 2] = "NORMAL";
})(CycleCountLocationCCOperateFunction || (CycleCountLocationCCOperateFunction = {}));
export var CycleCountMethod;
(function (CycleCountMethod) {
    CycleCountMethod[CycleCountMethod["EXPRESS_CYCLE_COUNT"] = 1] = "EXPRESS_CYCLE_COUNT";
    CycleCountMethod[CycleCountMethod["FULL_CYCLE_COUNT"] = 2] = "FULL_CYCLE_COUNT";
})(CycleCountMethod || (CycleCountMethod = {}));
export var CycleCountMovementCCOperateFunction;
(function (CycleCountMovementCCOperateFunction) {
    CycleCountMovementCCOperateFunction[CycleCountMovementCCOperateFunction["PICKING_COUNT"] = 6] = "PICKING_COUNT";
    CycleCountMovementCCOperateFunction[CycleCountMovementCCOperateFunction["PUTAWAY_COUNT"] = 5] = "PUTAWAY_COUNT";
    CycleCountMovementCCOperateFunction[CycleCountMovementCCOperateFunction["RACK_TRANSFER_COUNT"] = 7] = "RACK_TRANSFER_COUNT";
})(CycleCountMovementCCOperateFunction || (CycleCountMovementCCOperateFunction = {}));
export var CycleCountOrderIsDiff;
(function (CycleCountOrderIsDiff) {
    CycleCountOrderIsDiff[CycleCountOrderIsDiff["-"] = 2] = "-";
    CycleCountOrderIsDiff[CycleCountOrderIsDiff["N"] = 0] = "N";
    CycleCountOrderIsDiff[CycleCountOrderIsDiff["Y"] = 1] = "Y";
})(CycleCountOrderIsDiff || (CycleCountOrderIsDiff = {}));
export var CycleCountOrderStatus;
(function (CycleCountOrderStatus) {
    CycleCountOrderStatus[CycleCountOrderStatus["PENDING"] = 1] = "PENDING";
    CycleCountOrderStatus[CycleCountOrderStatus["ONGOING"] = 2] = "ONGOING";
    CycleCountOrderStatus[CycleCountOrderStatus["DONE"] = 4] = "DONE";
    CycleCountOrderStatus[CycleCountOrderStatus["CANCEL"] = 3] = "CANCEL";
})(CycleCountOrderStatus || (CycleCountOrderStatus = {}));
export var CycleCountSkuCCOperateFunction;
(function (CycleCountSkuCCOperateFunction) {
    CycleCountSkuCCOperateFunction[CycleCountSkuCCOperateFunction["NORMAL"] = 1] = "NORMAL";
})(CycleCountSkuCCOperateFunction || (CycleCountSkuCCOperateFunction = {}));
export var CycleCountSourceFrom;
(function (CycleCountSourceFrom) {
    CycleCountSourceFrom[CycleCountSourceFrom["DAILY_CYCLE_COUNT"] = 3] = "DAILY_CYCLE_COUNT";
    CycleCountSourceFrom[CycleCountSourceFrom["MANUAL_CREATION"] = 1] = "MANUAL_CREATION";
    CycleCountSourceFrom[CycleCountSourceFrom["OB_TROUBLE_SHOOT"] = 2] = "OB_TROUBLE_SHOOT";
    CycleCountSourceFrom[CycleCountSourceFrom["FULL_STOCK_COUNT"] = 4] = "FULL_STOCK_COUNT";
})(CycleCountSourceFrom || (CycleCountSourceFrom = {}));
export var CycleCountStatus;
(function (CycleCountStatus) {
    CycleCountStatus[CycleCountStatus["CHECKING"] = 2] = "CHECKING";
    CycleCountStatus[CycleCountStatus["NORMAL"] = 1] = "NORMAL";
    CycleCountStatus[CycleCountStatus["STATIC_LOCK"] = 3] = "STATIC_LOCK";
    CycleCountStatus[CycleCountStatus["UNDEFINED"] = 0] = "UNDEFINED";
})(CycleCountStatus || (CycleCountStatus = {}));
export var CycleCountTaskAssignEvent;
(function (CycleCountTaskAssignEvent) {
    CycleCountTaskAssignEvent[CycleCountTaskAssignEvent["ASSIGN"] = 1] = "ASSIGN";
    CycleCountTaskAssignEvent[CycleCountTaskAssignEvent["RELEASE"] = 0] = "RELEASE";
})(CycleCountTaskAssignEvent || (CycleCountTaskAssignEvent = {}));
export var CycleCountTaskAssignStatus;
(function (CycleCountTaskAssignStatus) {
    CycleCountTaskAssignStatus[CycleCountTaskAssignStatus["ALL"] = 4] = "ALL";
    CycleCountTaskAssignStatus[CycleCountTaskAssignStatus["ASSIGNED_TO_ME"] = 1] = "ASSIGNED_TO_ME";
    CycleCountTaskAssignStatus[CycleCountTaskAssignStatus["ASSIGNED_TO_OTHERS"] = 2] = "ASSIGNED_TO_OTHERS";
    CycleCountTaskAssignStatus[CycleCountTaskAssignStatus["UNASSIGNED"] = 3] = "UNASSIGNED";
})(CycleCountTaskAssignStatus || (CycleCountTaskAssignStatus = {}));
export var CycleCountTaskCreateFrom;
(function (CycleCountTaskCreateFrom) {
    CycleCountTaskCreateFrom[CycleCountTaskCreateFrom["ONE"] = 1] = "ONE";
    CycleCountTaskCreateFrom[CycleCountTaskCreateFrom["TWO"] = 2] = "TWO";
    CycleCountTaskCreateFrom[CycleCountTaskCreateFrom["THREE"] = 3] = "THREE";
})(CycleCountTaskCreateFrom || (CycleCountTaskCreateFrom = {}));
export var CycleCountTaskIsDiff;
(function (CycleCountTaskIsDiff) {
    CycleCountTaskIsDiff[CycleCountTaskIsDiff["UNDEFINE"] = 2] = "UNDEFINE";
    CycleCountTaskIsDiff[CycleCountTaskIsDiff["N"] = 0] = "N";
    CycleCountTaskIsDiff[CycleCountTaskIsDiff["Y"] = 1] = "Y";
})(CycleCountTaskIsDiff || (CycleCountTaskIsDiff = {}));
export var CycleCountTaskIsOnHold;
(function (CycleCountTaskIsOnHold) {
    CycleCountTaskIsOnHold[CycleCountTaskIsOnHold["ALL"] = 2] = "ALL";
    CycleCountTaskIsOnHold[CycleCountTaskIsOnHold["N"] = 0] = "N";
    CycleCountTaskIsOnHold[CycleCountTaskIsOnHold["Y"] = 1] = "Y";
})(CycleCountTaskIsOnHold || (CycleCountTaskIsOnHold = {}));
export var CycleCountTaskIsUrgent;
(function (CycleCountTaskIsUrgent) {
    CycleCountTaskIsUrgent[CycleCountTaskIsUrgent["ALL"] = 2] = "ALL";
    CycleCountTaskIsUrgent[CycleCountTaskIsUrgent["N"] = 0] = "N";
    CycleCountTaskIsUrgent[CycleCountTaskIsUrgent["Y"] = 1] = "Y";
})(CycleCountTaskIsUrgent || (CycleCountTaskIsUrgent = {}));
export var CycleCountTaskOrder;
(function (CycleCountTaskOrder) {
    CycleCountTaskOrder[CycleCountTaskOrder["ASC"] = 0] = "ASC";
    CycleCountTaskOrder[CycleCountTaskOrder["DESC"] = 1] = "DESC";
})(CycleCountTaskOrder || (CycleCountTaskOrder = {}));
export var CycleCountTaskOrderKey;
(function (CycleCountTaskOrderKey) {
    CycleCountTaskOrderKey[CycleCountTaskOrderKey["Ctime"] = 2] = "Ctime";
    CycleCountTaskOrderKey[CycleCountTaskOrderKey["Urgent"] = 1] = "Urgent";
})(CycleCountTaskOrderKey || (CycleCountTaskOrderKey = {}));
export var CycleCountTaskSkuItemIsDiff;
(function (CycleCountTaskSkuItemIsDiff) {
    CycleCountTaskSkuItemIsDiff[CycleCountTaskSkuItemIsDiff["UNDEFINE"] = 2] = "UNDEFINE";
    CycleCountTaskSkuItemIsDiff[CycleCountTaskSkuItemIsDiff["N"] = 0] = "N";
    CycleCountTaskSkuItemIsDiff[CycleCountTaskSkuItemIsDiff["Y"] = 1] = "Y";
})(CycleCountTaskSkuItemIsDiff || (CycleCountTaskSkuItemIsDiff = {}));
export var CycleCountTaskSkuItemStatus;
(function (CycleCountTaskSkuItemStatus) {
    CycleCountTaskSkuItemStatus[CycleCountTaskSkuItemStatus["CANCEL"] = 4] = "CANCEL";
    CycleCountTaskSkuItemStatus[CycleCountTaskSkuItemStatus["DONE"] = 3] = "DONE";
    CycleCountTaskSkuItemStatus[CycleCountTaskSkuItemStatus["ONGOING"] = 2] = "ONGOING";
    CycleCountTaskSkuItemStatus[CycleCountTaskSkuItemStatus["PENDING"] = 1] = "PENDING";
})(CycleCountTaskSkuItemStatus || (CycleCountTaskSkuItemStatus = {}));
export var CycleCountTaskStage;
(function (CycleCountTaskStage) {
    CycleCountTaskStage[CycleCountTaskStage["01ST_COUNT"] = 1] = "01ST_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["02ND_COUNT"] = 2] = "02ND_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["03RD_COUNT"] = 3] = "03RD_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["04TH_COUNT"] = 4] = "04TH_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["05TH_COUNT"] = 5] = "05TH_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["06TH_COUNT"] = 6] = "06TH_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["07TH_COUNT"] = 7] = "07TH_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["08TH_COUNT"] = 8] = "08TH_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["09TH_COUNT"] = 9] = "09TH_COUNT";
    CycleCountTaskStage[CycleCountTaskStage["10TH_COUNT"] = 10] = "10TH_COUNT";
})(CycleCountTaskStage || (CycleCountTaskStage = {}));
export var CycleCountTaskStatus;
(function (CycleCountTaskStatus) {
    CycleCountTaskStatus[CycleCountTaskStatus["ASSIGNED"] = 5] = "ASSIGNED";
    CycleCountTaskStatus[CycleCountTaskStatus["CANCEL"] = 3] = "CANCEL";
    CycleCountTaskStatus[CycleCountTaskStatus["DONE"] = 4] = "DONE";
    CycleCountTaskStatus[CycleCountTaskStatus["ONGOING"] = 2] = "ONGOING";
    CycleCountTaskStatus[CycleCountTaskStatus["PENDING"] = 1] = "PENDING";
})(CycleCountTaskStatus || (CycleCountTaskStatus = {}));
export var CycleCountRecommendationLocationType;
(function (CycleCountRecommendationLocationType) {
    CycleCountRecommendationLocationType[CycleCountRecommendationLocationType["ALL_ACTIVE_LOCATIONS"] = 0] = "ALL_ACTIVE_LOCATIONS";
    CycleCountRecommendationLocationType[CycleCountRecommendationLocationType["CUSTOMIZED_LOCATIONS"] = 2] = "CUSTOMIZED_LOCATIONS";
    CycleCountRecommendationLocationType[CycleCountRecommendationLocationType["ONLY_HIGH_VALUE"] = 1] = "ONLY_HIGH_VALUE";
})(CycleCountRecommendationLocationType || (CycleCountRecommendationLocationType = {}));
export var CycleCountRecommendationSkuType;
(function (CycleCountRecommendationSkuType) {
    CycleCountRecommendationSkuType[CycleCountRecommendationSkuType["CUSTOMIZED_SKU"] = 0] = "CUSTOMIZED_SKU";
})(CycleCountRecommendationSkuType || (CycleCountRecommendationSkuType = {}));
export var CycleCountType;
(function (CycleCountType) {
    CycleCountType[CycleCountType["LOCATION_CC"] = 2] = "LOCATION_CC";
    CycleCountType[CycleCountType["MOVEMENT_CC"] = 3] = "MOVEMENT_CC";
    CycleCountType[CycleCountType["SKU_CC"] = 1] = "SKU_CC";
})(CycleCountType || (CycleCountType = {}));
export var CycleCountUnitCountStatus;
(function (CycleCountUnitCountStatus) {
    CycleCountUnitCountStatus[CycleCountUnitCountStatus["MISSING"] = 2] = "MISSING";
    CycleCountUnitCountStatus[CycleCountUnitCountStatus["NORMAL"] = 0] = "NORMAL";
    CycleCountUnitCountStatus[CycleCountUnitCountStatus["SURPLUS"] = 1] = "SURPLUS";
})(CycleCountUnitCountStatus || (CycleCountUnitCountStatus = {}));
export var DFBizType;
(function (DFBizType) {
    DFBizType[DFBizType["OUTBOUND"] = 3] = "OUTBOUND";
    DFBizType[DFBizType["INVENTORY"] = 2] = "INVENTORY";
    DFBizType[DFBizType["INBOUND"] = 1] = "INBOUND";
})(DFBizType || (DFBizType = {}));
export var DFRegion;
(function (DFRegion) {
    DFRegion["SG"] = "SG";
    DFRegion["TW"] = "TW";
})(DFRegion || (DFRegion = {}));
export var DFStatus;
(function (DFStatus) {
    DFStatus[DFStatus["PENDING"] = 0] = "PENDING";
    DFStatus[DFStatus["RUNNING"] = 1] = "RUNNING";
    DFStatus[DFStatus["KILLED"] = 2] = "KILLED";
    DFStatus[DFStatus["SUCCESS"] = 3] = "SUCCESS";
    DFStatus[DFStatus["FAIL"] = -1] = "FAIL";
})(DFStatus || (DFStatus = {}));
export var DailySalesIntervalType;
(function (DailySalesIntervalType) {
    DailySalesIntervalType[DailySalesIntervalType["SEVEN"] = 0] = "SEVEN";
    DailySalesIntervalType[DailySalesIntervalType["FIFTEEN"] = 1] = "FIFTEEN";
    DailySalesIntervalType[DailySalesIntervalType["THIRTY"] = 2] = "THIRTY";
    DailySalesIntervalType[DailySalesIntervalType["SIXTY"] = 4] = "SIXTY";
    DailySalesIntervalType[DailySalesIntervalType["NINETY"] = 3] = "NINETY";
})(DailySalesIntervalType || (DailySalesIntervalType = {}));
export var DashboardDataType;
(function (DashboardDataType) {
    DashboardDataType[DashboardDataType["ITEM"] = 1] = "ITEM";
    DashboardDataType[DashboardDataType["ORDER"] = 0] = "ORDER";
    DashboardDataType[DashboardDataType["TRACKING_ID"] = 2] = "TRACKING_ID";
})(DashboardDataType || (DashboardDataType = {}));
export var DashboardProductivityStatus;
(function (DashboardProductivityStatus) {
    DashboardProductivityStatus[DashboardProductivityStatus["CHECKING"] = 41] = "CHECKING";
    DashboardProductivityStatus[DashboardProductivityStatus["PACKING"] = 42] = "PACKING";
    DashboardProductivityStatus[DashboardProductivityStatus["PICKING"] = 40] = "PICKING";
    DashboardProductivityStatus[DashboardProductivityStatus["PUTTING"] = 1] = "PUTTING";
})(DashboardProductivityStatus || (DashboardProductivityStatus = {}));
export var DashboardProductivityTaskStatus;
(function (DashboardProductivityTaskStatus) {
    DashboardProductivityTaskStatus[DashboardProductivityTaskStatus["PICKING"] = 40] = "PICKING";
    DashboardProductivityTaskStatus[DashboardProductivityTaskStatus["CHECKING"] = 41] = "CHECKING";
    DashboardProductivityTaskStatus[DashboardProductivityTaskStatus["PACKING"] = 42] = "PACKING";
})(DashboardProductivityTaskStatus || (DashboardProductivityTaskStatus = {}));
export var DashboardStaffAllocationType;
(function (DashboardStaffAllocationType) {
    DashboardStaffAllocationType[DashboardStaffAllocationType["ACTIVE"] = 2] = "ACTIVE";
    DashboardStaffAllocationType[DashboardStaffAllocationType["BREAK"] = 3] = "BREAK";
    DashboardStaffAllocationType[DashboardStaffAllocationType["INBOUND"] = 4] = "INBOUND";
    DashboardStaffAllocationType[DashboardStaffAllocationType["INVENTORY"] = 6] = "INVENTORY";
    DashboardStaffAllocationType[DashboardStaffAllocationType["OTHERS"] = 8] = "OTHERS";
    DashboardStaffAllocationType[DashboardStaffAllocationType["OUTBOUND"] = 5] = "OUTBOUND";
    DashboardStaffAllocationType[DashboardStaffAllocationType["RETURN_INBOUND"] = 7] = "RETURN_INBOUND";
    DashboardStaffAllocationType[DashboardStaffAllocationType["TOTAL"] = 1] = "TOTAL";
})(DashboardStaffAllocationType || (DashboardStaffAllocationType = {}));
export var DashboardTimeType;
(function (DashboardTimeType) {
    DashboardTimeType[DashboardTimeType["LAST_12_HOURS"] = 1] = "LAST_12_HOURS";
    DashboardTimeType[DashboardTimeType["LAST_7_DAYS"] = 2] = "LAST_7_DAYS";
})(DashboardTimeType || (DashboardTimeType = {}));
export var DashboardTrackingTimeType;
(function (DashboardTrackingTimeType) {
    DashboardTrackingTimeType[DashboardTrackingTimeType["LAST_24_HOURS"] = 2] = "LAST_24_HOURS";
    DashboardTrackingTimeType[DashboardTrackingTimeType["LAST_7_DAYS"] = 3] = "LAST_7_DAYS";
    DashboardTrackingTimeType[DashboardTrackingTimeType["TODAY"] = 1] = "TODAY";
})(DashboardTrackingTimeType || (DashboardTrackingTimeType = {}));
export var DateFormatType;
(function (DateFormatType) {
    DateFormatType["DD_MM_YYYY"] = "DD/MM/YYYY";
    DateFormatType["MM_DD_YYYY"] = "MM/DD/YYYY";
    DateFormatType["YYYY_MM_DD"] = "YYYY/MM/DD";
})(DateFormatType || (DateFormatType = {}));
export var DeviceLogLocationStatusType;
(function (DeviceLogLocationStatusType) {
    DeviceLogLocationStatusType[DeviceLogLocationStatusType["EMPTY"] = 2] = "EMPTY";
    DeviceLogLocationStatusType[DeviceLogLocationStatusType["FROM"] = 0] = "FROM";
    DeviceLogLocationStatusType[DeviceLogLocationStatusType["FROM_TO"] = 3] = "FROM_TO";
    DeviceLogLocationStatusType[DeviceLogLocationStatusType["TO"] = 1] = "TO";
})(DeviceLogLocationStatusType || (DeviceLogLocationStatusType = {}));
export var DeviceLogLocationType;
(function (DeviceLogLocationType) {
    DeviceLogLocationType[DeviceLogLocationType["FROM"] = 0] = "FROM";
    DeviceLogLocationType[DeviceLogLocationType["TO"] = 1] = "TO";
})(DeviceLogLocationType || (DeviceLogLocationType = {}));
export var DeviceLogProgressType;
(function (DeviceLogProgressType) {
    DeviceLogProgressType[DeviceLogProgressType["INBOUND"] = 0] = "INBOUND";
    DeviceLogProgressType[DeviceLogProgressType["INVENTORY"] = 2] = "INVENTORY";
    DeviceLogProgressType[DeviceLogProgressType["MOVE_TRANSFER"] = 3] = "MOVE_TRANSFER";
    DeviceLogProgressType[DeviceLogProgressType["OUTBOUND"] = 1] = "OUTBOUND";
    DeviceLogProgressType[DeviceLogProgressType["RETURN_INBOUND"] = 4] = "RETURN_INBOUND";
})(DeviceLogProgressType || (DeviceLogProgressType = {}));
export var DeviceLogTaskType;
(function (DeviceLogTaskType) {
    DeviceLogTaskType[DeviceLogTaskType["CHECKING"] = 3] = "CHECKING";
    DeviceLogTaskType[DeviceLogTaskType["MERGING"] = 12] = "MERGING";
    DeviceLogTaskType[DeviceLogTaskType["PACKING"] = 5] = "PACKING";
    DeviceLogTaskType[DeviceLogTaskType["PICKING"] = 2] = "PICKING";
    DeviceLogTaskType[DeviceLogTaskType["PUTAWAY"] = 1] = "PUTAWAY";
    DeviceLogTaskType[DeviceLogTaskType["QC"] = 6] = "QC";
    DeviceLogTaskType[DeviceLogTaskType["RE_RECEIVING"] = 10] = "RE_RECEIVING";
    DeviceLogTaskType[DeviceLogTaskType["RECEIVING"] = 0] = "RECEIVING";
    DeviceLogTaskType[DeviceLogTaskType["SHIPPING"] = 4] = "SHIPPING";
    DeviceLogTaskType[DeviceLogTaskType["SORTING"] = 14] = "SORTING";
    DeviceLogTaskType[DeviceLogTaskType["TRANSITING"] = 13] = "TRANSITING";
    DeviceLogTaskType[DeviceLogTaskType["TWO_STEP_TRANSFER_PICKING"] = 8] = "TWO_STEP_TRANSFER_PICKING";
    DeviceLogTaskType[DeviceLogTaskType["TWO_STEP_TRANSFER_PUTAWAY"] = 9] = "TWO_STEP_TRANSFER_PUTAWAY";
    DeviceLogTaskType[DeviceLogTaskType["WHOLE_LOCATION_TRANSFER"] = 7] = "WHOLE_LOCATION_TRANSFER";
})(DeviceLogTaskType || (DeviceLogTaskType = {}));
export var DeviceProcess;
(function (DeviceProcess) {
    DeviceProcess[DeviceProcess["ALL"] = 0] = "ALL";
    DeviceProcess[DeviceProcess["INBOUND"] = 1] = "INBOUND";
    DeviceProcess[DeviceProcess["INVENTORY"] = 2] = "INVENTORY";
    DeviceProcess[DeviceProcess["MOVE_TRANSFER"] = 10] = "MOVE_TRANSFER";
    DeviceProcess[DeviceProcess["PICKING"] = 3] = "PICKING";
    DeviceProcess[DeviceProcess["RETURN_INBOUND"] = 6] = "RETURN_INBOUND";
    DeviceProcess[DeviceProcess["RTS"] = 7] = "RTS";
    DeviceProcess[DeviceProcess["SHIPPING"] = 5] = "SHIPPING";
    DeviceProcess[DeviceProcess["SORTING"] = 4] = "SORTING";
    DeviceProcess[DeviceProcess["TROUBLESHOOTING"] = 8] = "TROUBLESHOOTING";
})(DeviceProcess || (DeviceProcess = {}));
export var DeviceProgressInHouseGeneral;
(function (DeviceProgressInHouseGeneral) {
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["ALL"] = 0] = "ALL";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["INBOUND"] = 1] = "INBOUND";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["INVENTORY"] = 2] = "INVENTORY";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["PICKING"] = 3] = "PICKING";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["SHIPPING"] = 4] = "SHIPPING";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["RETURN_INBOUND"] = 5] = "RETURN_INBOUND";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["RTS"] = 6] = "RTS";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["SORTING"] = 7] = "SORTING";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["TROUBLE_SHOOTING"] = 8] = "TROUBLE_SHOOTING";
    DeviceProgressInHouseGeneral[DeviceProgressInHouseGeneral["MERGING"] = 9] = "MERGING";
})(DeviceProgressInHouseGeneral || (DeviceProgressInHouseGeneral = {}));
export var DeviceSheetType;
(function (DeviceSheetType) {
    DeviceSheetType[DeviceSheetType["INBOUND"] = 1] = "INBOUND";
    DeviceSheetType[DeviceSheetType["MOVE_TRANSFER_INBOUND"] = 10] = "MOVE_TRANSFER_INBOUND";
    DeviceSheetType[DeviceSheetType["MOVE_TRANSFER_OUTBOUND"] = 11] = "MOVE_TRANSFER_OUTBOUND";
    DeviceSheetType[DeviceSheetType["MTO_CHECKING_TASK"] = 15] = "MTO_CHECKING_TASK";
    DeviceSheetType[DeviceSheetType["MTO_PICKING_TASK"] = 12] = "MTO_PICKING_TASK";
    DeviceSheetType[DeviceSheetType["MTO_SHIP_ORDER"] = 13] = "MTO_SHIP_ORDER";
    DeviceSheetType[DeviceSheetType["OUTBOUND_CHECKING"] = 7] = "OUTBOUND_CHECKING";
    DeviceSheetType[DeviceSheetType["OUTBOUND_PICKING"] = 4] = "OUTBOUND_PICKING";
    DeviceSheetType[DeviceSheetType["OUTBOUND_SHIPPING"] = 5] = "OUTBOUND_SHIPPING";
    DeviceSheetType[DeviceSheetType["OUTBOUND_SORTING"] = 8] = "OUTBOUND_SORTING";
    DeviceSheetType[DeviceSheetType["RACK_TRANSFER"] = 3] = "RACK_TRANSFER";
    DeviceSheetType[DeviceSheetType["RETURN_INBOUND"] = 2] = "RETURN_INBOUND";
    DeviceSheetType[DeviceSheetType["RETURN_TO_SUPPLIER"] = 6] = "RETURN_TO_SUPPLIER";
    DeviceSheetType[DeviceSheetType["UNDEFINED"] = 0] = "UNDEFINED";
})(DeviceSheetType || (DeviceSheetType = {}));
export var DeviceStatus;
(function (DeviceStatus) {
    DeviceStatus[DeviceStatus["DAMAGED"] = 9] = "DAMAGED";
    DeviceStatus[DeviceStatus["NORMAL"] = 0] = "NORMAL";
    DeviceStatus[DeviceStatus["USED"] = 2] = "USED";
    DeviceStatus[DeviceStatus["USING"] = 1] = "USING";
})(DeviceStatus || (DeviceStatus = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["BASKET"] = 4] = "BASKET";
    DeviceType[DeviceType["BOX"] = 0] = "BOX";
    DeviceType[DeviceType["PALLET"] = 1] = "PALLET";
    DeviceType[DeviceType["PICK_CART"] = 2] = "PICK_CART";
    DeviceType[DeviceType["PUT_WALL"] = 3] = "PUT_WALL";
})(DeviceType || (DeviceType = {}));
export var DeviceUsageType;
(function (DeviceUsageType) {
    DeviceUsageType[DeviceUsageType["INHOUSE_GENERAL"] = 0] = "INHOUSE_GENERAL";
    DeviceUsageType[DeviceUsageType["MOVE_TRANSFER"] = 1] = "MOVE_TRANSFER";
})(DeviceUsageType || (DeviceUsageType = {}));
export var DistributedIDType;
(function (DistributedIDType) {
    DistributedIDType[DistributedIDType["ARRIVE_GROUP_ID"] = 3005] = "ARRIVE_GROUP_ID";
    DistributedIDType[DistributedIDType["BATCH_NO"] = 102] = "BATCH_NO";
    DistributedIDType[DistributedIDType["BATCH_TRANSFER_ID"] = 2001] = "BATCH_TRANSFER_ID";
    DistributedIDType[DistributedIDType["CELL_SIZE_TYPE_ID"] = 4002] = "CELL_SIZE_TYPE_ID";
    DistributedIDType[DistributedIDType["CHECKING_TASK_NUMBER"] = 4015] = "CHECKING_TASK_NUMBER";
    DistributedIDType[DistributedIDType["CUSTOM_WAVE_RULE_ID"] = 4132] = "CUSTOM_WAVE_RULE_ID";
    DistributedIDType[DistributedIDType["CYCLE_COUNT_DIFF_ORDER_ID"] = 2005] = "CYCLE_COUNT_DIFF_ORDER_ID";
    DistributedIDType[DistributedIDType["CYCLE_COUNT_ORDER_ID"] = 2002] = "CYCLE_COUNT_ORDER_ID";
    DistributedIDType[DistributedIDType["CYCLE_COUNT_TASK_ID"] = 2004] = "CYCLE_COUNT_TASK_ID";
    DistributedIDType[DistributedIDType["EXPORT_TASK_ID"] = 1022] = "EXPORT_TASK_ID";
    DistributedIDType[DistributedIDType["INBOUND_ID"] = 3001] = "INBOUND_ID";
    DistributedIDType[DistributedIDType["INBOUND_QUEUE_ID"] = 3006] = "INBOUND_QUEUE_ID";
    DistributedIDType[DistributedIDType["INBOUND_UAT_ID"] = 3007] = "INBOUND_UAT_ID";
    DistributedIDType[DistributedIDType["INVENTORY_ADJUST_NO"] = 103] = "INVENTORY_ADJUST_NO";
    DistributedIDType[DistributedIDType["MOVE_ORDER_ID"] = 2003] = "MOVE_ORDER_ID";
    DistributedIDType[DistributedIDType["MOVE_TRANSFER_ARRIVAL_ORDER_ID"] = 3003] = "MOVE_TRANSFER_ARRIVAL_ORDER_ID";
    DistributedIDType[DistributedIDType["MOVE_TRANSFER_INBOUND_ID"] = 3002] = "MOVE_TRANSFER_INBOUND_ID";
    DistributedIDType[DistributedIDType["MOVE_TRANSFER_OUTBOUND_ID"] = 4001] = "MOVE_TRANSFER_OUTBOUND_ID";
    DistributedIDType[DistributedIDType["MOVE_TRANSFER_PACKING_TASK_ID"] = 4003] = "MOVE_TRANSFER_PACKING_TASK_ID";
    DistributedIDType[DistributedIDType["MOVE_TRANSFER_SHIP_TASK_ID"] = 4004] = "MOVE_TRANSFER_SHIP_TASK_ID";
    DistributedIDType[DistributedIDType["PACKING_TASK_ID"] = 4006] = "PACKING_TASK_ID";
    DistributedIDType[DistributedIDType["PAPERLESS_PICKING_RULE_ID"] = 4120] = "PAPERLESS_PICKING_RULE_ID";
    DistributedIDType[DistributedIDType["PO_UAT_ID"] = 3008] = "PO_UAT_ID";
    DistributedIDType[DistributedIDType["PUTAWAY_ID"] = 2010] = "PUTAWAY_ID";
    DistributedIDType[DistributedIDType["QC_RULE_ID"] = 4140] = "QC_RULE_ID";
    DistributedIDType[DistributedIDType["QC_TASK_ID"] = 3009] = "QC_TASK_ID";
    DistributedIDType[DistributedIDType["RACK_TRANSFER_TASK_ID"] = 2000] = "RACK_TRANSFER_TASK_ID";
    DistributedIDType[DistributedIDType["RACK_TRANSFER_TWO_STEP_SHEET_STATUS_CHANGE"] = 2020] = "RACK_TRANSFER_TWO_STEP_SHEET_STATUS_CHANGE";
    DistributedIDType[DistributedIDType["REPLENISHMENT_TASK_ID"] = 4100] = "REPLENISHMENT_TASK_ID";
    DistributedIDType[DistributedIDType["REPLENISHMENT_WAVE_ID"] = 2030] = "REPLENISHMENT_WAVE_ID";
    DistributedIDType[DistributedIDType["RETURN_BUYER_ORDER_ID"] = 4110] = "RETURN_BUYER_ORDER_ID";
    DistributedIDType[DistributedIDType["RETURN_INBOUND_ID"] = 3012] = "RETURN_INBOUND_ID";
    DistributedIDType[DistributedIDType["RETURN_ORDER_NUMBER"] = 1003] = "RETURN_ORDER_NUMBER";
    DistributedIDType[DistributedIDType["RETURN_PICKING_TASK_NUMBER"] = 1005] = "RETURN_PICKING_TASK_NUMBER";
    DistributedIDType[DistributedIDType["RETURN_SHIPPED_ORDER_NUMBER"] = 1004] = "RETURN_SHIPPED_ORDER_NUMBER";
    DistributedIDType[DistributedIDType["REVERSE_TASK_NUMBER"] = 4020] = "REVERSE_TASK_NUMBER";
    DistributedIDType[DistributedIDType["SALES_ORDER_NUMBER"] = 4010] = "SALES_ORDER_NUMBER";
    DistributedIDType[DistributedIDType["SALES_ORDER_PARCEL_ID"] = 4022] = "SALES_ORDER_PARCEL_ID";
    DistributedIDType[DistributedIDType["SHEET_STATUS_SYNC_REQUEST_ID"] = 103] = "SHEET_STATUS_SYNC_REQUEST_ID";
    DistributedIDType[DistributedIDType["SHIPPED_ORDER_TASK_ID"] = 1002] = "SHIPPED_ORDER_TASK_ID";
    DistributedIDType[DistributedIDType["SHIPPING_TASK_TASK_NUMBER"] = 4016] = "SHIPPING_TASK_TASK_NUMBER";
    DistributedIDType[DistributedIDType["SORTING_TASK_TASK_NUMBER"] = 4007] = "SORTING_TASK_TASK_NUMBER";
    DistributedIDType[DistributedIDType["STOCK_SYNC_LOG_TAB"] = 1001] = "STOCK_SYNC_LOG_TAB";
    DistributedIDType[DistributedIDType["TRACKING_ID"] = 3004] = "TRACKING_ID";
    DistributedIDType[DistributedIDType["TRANSACTION_ID"] = 101] = "TRANSACTION_ID";
    DistributedIDType[DistributedIDType["TRANSACTION_UNIT_TAB"] = 1000] = "TRANSACTION_UNIT_TAB";
    DistributedIDType[DistributedIDType["TROUBLESHOOTING_TASK_NUMBER"] = 4030] = "TROUBLESHOOTING_TASK_NUMBER";
    DistributedIDType[DistributedIDType["USER_BEHAVIOR_ID"] = 4200] = "USER_BEHAVIOR_ID";
    DistributedIDType[DistributedIDType["WAVE_RULE_GROUP_ID"] = 4131] = "WAVE_RULE_GROUP_ID";
    DistributedIDType[DistributedIDType["WAVE_RULE_ID"] = 4130] = "WAVE_RULE_ID";
    DistributedIDType[DistributedIDType["WAVE_RULE_SN"] = 4017] = "WAVE_RULE_SN";
})(DistributedIDType || (DistributedIDType = {}));
export var DockStatus;
(function (DockStatus) {
    DockStatus[DockStatus["ASSIGNED"] = 2] = "ASSIGNED";
    DockStatus[DockStatus["NORMAL"] = 1] = "NORMAL";
    DockStatus[DockStatus["OCCUPIED"] = 3] = "OCCUPIED";
})(DockStatus || (DockStatus = {}));
export var EmailReminderIntervalTime;
(function (EmailReminderIntervalTime) {
    EmailReminderIntervalTime[EmailReminderIntervalTime["EVERY_1_HOUR"] = 60] = "EVERY_1_HOUR";
    EmailReminderIntervalTime[EmailReminderIntervalTime["EVERY_10_MINS"] = 10] = "EVERY_10_MINS";
    EmailReminderIntervalTime[EmailReminderIntervalTime["EVERY_12_HOUR"] = 720] = "EVERY_12_HOUR";
    EmailReminderIntervalTime[EmailReminderIntervalTime["EVERY_2_HOUR"] = 120] = "EVERY_2_HOUR";
    EmailReminderIntervalTime[EmailReminderIntervalTime["EVERY_24_HOUR"] = 1440] = "EVERY_24_HOUR";
    EmailReminderIntervalTime[EmailReminderIntervalTime["EVERY_30_MINS"] = 30] = "EVERY_30_MINS";
    EmailReminderIntervalTime[EmailReminderIntervalTime["NEVER"] = -1] = "NEVER";
})(EmailReminderIntervalTime || (EmailReminderIntervalTime = {}));
export var ExceptionFrom;
(function (ExceptionFrom) {
    ExceptionFrom[ExceptionFrom["RACK_TRANSFER_PUTAWAY"] = 1] = "RACK_TRANSFER_PUTAWAY";
})(ExceptionFrom || (ExceptionFrom = {}));
export var ExceptionStatus;
(function (ExceptionStatus) {
    ExceptionStatus[ExceptionStatus["CANCEL"] = 4] = "CANCEL";
    ExceptionStatus[ExceptionStatus["DONE"] = 3] = "DONE";
    ExceptionStatus[ExceptionStatus["ONGOING"] = 2] = "ONGOING";
    ExceptionStatus[ExceptionStatus["PENDING"] = 1] = "PENDING";
})(ExceptionStatus || (ExceptionStatus = {}));
export var ExceptionTaskType;
(function (ExceptionTaskType) {
    ExceptionTaskType[ExceptionTaskType["INVENTORY"] = 2] = "INVENTORY";
})(ExceptionTaskType || (ExceptionTaskType = {}));
export var ExceptionType;
(function (ExceptionType) {
    ExceptionType[ExceptionType["QTY_ABNORMAL"] = 1] = "QTY_ABNORMAL";
})(ExceptionType || (ExceptionType = {}));
export var ExportCycleCountOrderIsDiff;
(function (ExportCycleCountOrderIsDiff) {
    ExportCycleCountOrderIsDiff[ExportCycleCountOrderIsDiff["N"] = 0] = "N";
    ExportCycleCountOrderIsDiff[ExportCycleCountOrderIsDiff["Y"] = 1] = "Y";
})(ExportCycleCountOrderIsDiff || (ExportCycleCountOrderIsDiff = {}));
export var ExportModule;
(function (ExportModule) {
    ExportModule[ExportModule["CONSUMABLE__MANAGEMENT"] = 14] = "CONSUMABLE__MANAGEMENT";
    ExportModule[ExportModule["DASHBOARD"] = 5] = "DASHBOARD";
    ExportModule[ExportModule["EXCEPTION__CENTER"] = 12] = "EXCEPTION__CENTER";
    ExportModule[ExportModule["INBOUND"] = 1] = "INBOUND";
    ExportModule[ExportModule["INBOUND__OPERATOR__PRODUCTIVITY"] = 13] = "INBOUND__OPERATOR__PRODUCTIVITY";
    ExportModule[ExportModule["INVENTORY__MANAGEMENT"] = 4] = "INVENTORY__MANAGEMENT";
    ExportModule[ExportModule["INVENTORY__MAP"] = 3] = "INVENTORY__MAP";
    ExportModule[ExportModule["LABOR__MANAGEMENT"] = 10] = "LABOR__MANAGEMENT";
    ExportModule[ExportModule["MOVE"] = 6] = "MOVE";
    ExportModule[ExportModule["OUTBOUND"] = 2] = "OUTBOUND";
    ExportModule[ExportModule["RETURN__INBOUND"] = 11] = "RETURN__INBOUND";
    ExportModule[ExportModule["RETURN__TO__SUPPLIER"] = 9] = "RETURN__TO__SUPPLIER";
    ExportModule[ExportModule["WMS__BASIC"] = 8] = "WMS__BASIC";
    ExportModule[ExportModule["WMS__CONFIG"] = 7] = "WMS__CONFIG";
})(ExportModule || (ExportModule = {}));
export var ExportTaskStatus;
(function (ExportTaskStatus) {
    ExportTaskStatus[ExportTaskStatus["PENDING"] = 0] = "PENDING";
    ExportTaskStatus[ExportTaskStatus["ONGOING"] = 1] = "ONGOING";
    ExportTaskStatus[ExportTaskStatus["DONE"] = 2] = "DONE";
    ExportTaskStatus[ExportTaskStatus["FAIL"] = 3] = "FAIL";
})(ExportTaskStatus || (ExportTaskStatus = {}));
export var ExportTaskStatusType;
(function (ExportTaskStatusType) {
    ExportTaskStatusType[ExportTaskStatusType["CANCEL"] = 9] = "CANCEL";
    ExportTaskStatusType[ExportTaskStatusType["DONE"] = 2] = "DONE";
    ExportTaskStatusType[ExportTaskStatusType["FAILED"] = 3] = "FAILED";
    ExportTaskStatusType[ExportTaskStatusType["ONGOING"] = 1] = "ONGOING";
    ExportTaskStatusType[ExportTaskStatusType["PENDING"] = 0] = "PENDING";
})(ExportTaskStatusType || (ExportTaskStatusType = {}));
export var ExportTaskType;
(function (ExportTaskType) {
    ExportTaskType[ExportTaskType["ACTIVITY__LIST"] = 1004] = "ACTIVITY__LIST";
    ExportTaskType[ExportTaskType["ASN__LIST"] = 102] = "ASN__LIST";
    ExportTaskType[ExportTaskType["ASN__REPORT"] = 101] = "ASN__REPORT";
    ExportTaskType[ExportTaskType["ASN__SKU__REPORT"] = 103] = "ASN__SKU__REPORT";
    ExportTaskType[ExportTaskType["ASN__TRACKING__ID__REPORT"] = 104] = "ASN__TRACKING__ID__REPORT";
    ExportTaskType[ExportTaskType["AVAILABLE__INVENTORY__MAP"] = 92] = "AVAILABLE__INVENTORY__MAP";
    ExportTaskType[ExportTaskType["BLOCK__INVENTORY__MAP"] = 94] = "BLOCK__INVENTORY__MAP";
    ExportTaskType[ExportTaskType["CELL__LIST"] = 29] = "CELL__LIST";
    ExportTaskType[ExportTaskType["CHECKING__TASK"] = 704] = "CHECKING__TASK";
    ExportTaskType[ExportTaskType["CHECKING__WEIGHT__VALIDATION"] = 706] = "CHECKING__WEIGHT__VALIDATION";
    ExportTaskType[ExportTaskType["CONSUMABLE__MANAGEMENT__INFO"] = 901] = "CONSUMABLE__MANAGEMENT__INFO";
    ExportTaskType[ExportTaskType["CONSUMABLE__MANAGEMENT__LOG"] = 902] = "CONSUMABLE__MANAGEMENT__LOG";
    ExportTaskType[ExportTaskType["CYCLE__COUNT__ORDER"] = 405] = "CYCLE__COUNT__ORDER";
    ExportTaskType[ExportTaskType["DAILY__SALES"] = 402] = "DAILY__SALES";
    ExportTaskType[ExportTaskType["DEVICE__MANAGEMENT"] = 89] = "DEVICE__MANAGEMENT";
    ExportTaskType[ExportTaskType["EXPIRY_AGING_INVENTORY_MAP"] = 93] = "EXPIRY_AGING_INVENTORY_MAP";
    ExportTaskType[ExportTaskType["FULL__STOCK__COUNT"] = 408] = "FULL__STOCK__COUNT";
    ExportTaskType[ExportTaskType["GIFT__MANAGEMENT__REPORT"] = 30] = "GIFT__MANAGEMENT__REPORT";
    ExportTaskType[ExportTaskType["HISTORICAL__INVENTORY__MAP"] = 64] = "HISTORICAL__INVENTORY__MAP";
    ExportTaskType[ExportTaskType["INBOUND__LABOUR__PLANNING__REPORT"] = 107] = "INBOUND__LABOUR__PLANNING__REPORT";
    ExportTaskType[ExportTaskType["INBOUND__OPERATOR__PRODUCTIVITY"] = 108] = "INBOUND__OPERATOR__PRODUCTIVITY";
    ExportTaskType[ExportTaskType["INVENTORY__ADJUSTMENT"] = 404] = "INVENTORY__ADJUSTMENT";
    ExportTaskType[ExportTaskType["LOCATION__OCCUPATION__PERFORMANCE"] = 91] = "LOCATION__OCCUPATION__PERFORMANCE";
    ExportTaskType[ExportTaskType["MAPPING__SKU__AVAILABLE__INVENTORY__QTY__MAP"] = 96] = "MAPPING__SKU__AVAILABLE__INVENTORY__QTY__MAP";
    ExportTaskType[ExportTaskType["MAPPING__SKU__BLOCK__INVENTORY__QTY__MAP"] = 99] = "MAPPING__SKU__BLOCK__INVENTORY__QTY__MAP";
    ExportTaskType[ExportTaskType["MAPPING__SKU__EXPIRY__INVENTORY__QTY__MAP"] = 98] = "MAPPING__SKU__EXPIRY__INVENTORY__QTY__MAP";
    ExportTaskType[ExportTaskType["MAPPING__SKU__ON_HAND__INVENTORY__QTY__MAP"] = 97] = "MAPPING__SKU__ON_HAND__INVENTORY__QTY__MAP";
    ExportTaskType[ExportTaskType["MAPPING__SKU__REPORT"] = 83] = "MAPPING__SKU__REPORT";
    ExportTaskType[ExportTaskType["MERGING__TASK"] = 1051] = "MERGING__TASK";
    ExportTaskType[ExportTaskType["MERGING__WHILE__SORTING__TASK"] = 1053] = "MERGING__WHILE__SORTING__TASK";
    ExportTaskType[ExportTaskType["MTO__PACKING__TASK"] = 1101] = "MTO__PACKING__TASK";
    ExportTaskType[ExportTaskType["MTO__SHIP__ORDER"] = 1102] = "MTO__SHIP__ORDER";
    ExportTaskType[ExportTaskType["MT__INBOUND__REPORT"] = 1201] = "MT__INBOUND__REPORT";
    ExportTaskType[ExportTaskType["OB__TOUBLESHOOT__REPORT"] = 510] = "OB__TOUBLESHOOT__REPORT";
    ExportTaskType[ExportTaskType["ON_HAND__INVENTORY__MAP"] = 86] = "ON_HAND__INVENTORY__MAP";
    ExportTaskType[ExportTaskType["OUTBOUND__SKU__REPORT"] = 701] = "OUTBOUND__SKU__REPORT";
    ExportTaskType[ExportTaskType["PACKER__PRODUCTIVITY__LOG"] = 708] = "PACKER__PRODUCTIVITY__LOG";
    ExportTaskType[ExportTaskType["PDA__LOGIN__LOG__EXPORT"] = 27] = "PDA__LOGIN__LOG__EXPORT";
    ExportTaskType[ExportTaskType["PICKING__TASK"] = 702] = "PICKING__TASK";
    ExportTaskType[ExportTaskType["PUTAWAY__LOCATION__REPORT"] = 106] = "PUTAWAY__LOCATION__REPORT";
    ExportTaskType[ExportTaskType["RACK__TRANSFER__ORDER"] = 406] = "RACK__TRANSFER__ORDER";
    ExportTaskType[ExportTaskType["REPLENISHMENT__TASK"] = 26] = "REPLENISHMENT__TASK";
    ExportTaskType[ExportTaskType["REPORT__ACCESS"] = 63] = "REPORT__ACCESS";
    ExportTaskType[ExportTaskType["REVERSE__TASK"] = 709] = "REVERSE__TASK";
    ExportTaskType[ExportTaskType["RI__ORDER__REPORT"] = 201] = "RI__ORDER__REPORT";
    ExportTaskType[ExportTaskType["RTB__ORDER__REPORT"] = 202] = "RTB__ORDER__REPORT";
    ExportTaskType[ExportTaskType["RI__RTS__ORDER__REPORT"] = 203] = "RI__RTS__ORDER__REPORT";
    ExportTaskType[ExportTaskType["RI__3PL__CLAIM__REPORT"] = 204] = "RI__3PL__CLAIM__REPORT";
    ExportTaskType[ExportTaskType["RTS__ORDER__REPORT"] = 801] = "RTS__ORDER__REPORT";
    ExportTaskType[ExportTaskType["RT__TROUBLESHOOT"] = 403] = "RT__TROUBLESHOOT";
    ExportTaskType[ExportTaskType["SAFETY__AND__TARGET__INVENTORY"] = 401] = "SAFETY__AND__TARGET__INVENTORY";
    ExportTaskType[ExportTaskType["SHIPPED__ORDER"] = 707] = "SHIPPED__ORDER";
    ExportTaskType[ExportTaskType["SHOP__LIST"] = 88] = "SHOP__LIST";
    ExportTaskType[ExportTaskType["SKU__ITEM"] = 82] = "SKU__ITEM";
    ExportTaskType[ExportTaskType["SKU__MODIFICATION__LOG"] = 90] = "SKU__MODIFICATION__LOG";
    ExportTaskType[ExportTaskType["STAFF__ACTIVITY"] = 1003] = "STAFF__ACTIVITY";
    ExportTaskType[ExportTaskType["STAFF__ATTENDANCE"] = 1002] = "STAFF__ATTENDANCE";
    ExportTaskType[ExportTaskType["STAFF__LIST"] = 1001] = "STAFF__LIST";
    ExportTaskType[ExportTaskType["STATION__ID__LIST"] = 300] = "STATION__ID__LIST";
    ExportTaskType[ExportTaskType["SUB_PICKING__TASK"] = 1050] = "SUB_PICKING__TASK";
    ExportTaskType[ExportTaskType["SUGGESTED__PUTAWAY__LOCATION__REPORT"] = 105] = "SUGGESTED__PUTAWAY__LOCATION__REPORT";
    ExportTaskType[ExportTaskType["SUPPLIER__LIST"] = 87] = "SUPPLIER__LIST";
    ExportTaskType[ExportTaskType["SUPPLIER__QC__SETTINGS"] = 81] = "SUPPLIER__QC__SETTINGS";
    ExportTaskType[ExportTaskType["SURPLUS__REGISTRATION"] = 407] = "SURPLUS__REGISTRATION";
    ExportTaskType[ExportTaskType["TEST__BIG__DATA__EXPORT"] = 9999] = "TEST__BIG__DATA__EXPORT";
    ExportTaskType[ExportTaskType["TOUBLESHOOT__REPORT"] = 501] = "TOUBLESHOOT__REPORT";
    ExportTaskType[ExportTaskType["TRANSACTION__INVENTORY__MAP"] = 85] = "TRANSACTION__INVENTORY__MAP";
    ExportTaskType[ExportTaskType["TRANSITING__TASK"] = 1052] = "TRANSITING__TASK";
    ExportTaskType[ExportTaskType["UNBLOCK__LOG"] = 95] = "UNBLOCK__LOG";
    ExportTaskType[ExportTaskType["USER__LIST__REPORT"] = 330] = "USER__LIST__REPORT";
    ExportTaskType[ExportTaskType["INSPECT_TASK"] = 425] = "INSPECT_TASK";
    ExportTaskType[ExportTaskType["FULFILL_CHAIN_REQUIREMENT"] = 413] = "FULFILL_CHAIN_REQUIREMENT";
    ExportTaskType[ExportTaskType["LOGIC_AREA_REQUIREMENT"] = 414] = "LOGIC_AREA_REQUIREMENT";
    ExportTaskType[ExportTaskType["WHS_REQUIREMENT"] = 415] = "WHS_REQUIREMENT";
})(ExportTaskType || (ExportTaskType = {}));
export var GiftCategoryType;
(function (GiftCategoryType) {
    GiftCategoryType[GiftCategoryType["Commodity"] = 1] = "Commodity";
    GiftCategoryType[GiftCategoryType["Makeup"] = 2] = "Makeup";
    GiftCategoryType[GiftCategoryType["Food"] = 3] = "Food";
    GiftCategoryType[GiftCategoryType["Stationery"] = 4] = "Stationery";
    GiftCategoryType[GiftCategoryType["Others"] = 5] = "Others";
})(GiftCategoryType || (GiftCategoryType = {}));
export var GiftCategoryTypeList;
(function (GiftCategoryTypeList) {
    GiftCategoryTypeList[GiftCategoryTypeList["Commodity"] = 1] = "Commodity";
    GiftCategoryTypeList[GiftCategoryTypeList["Makeup"] = 2] = "Makeup";
    GiftCategoryTypeList[GiftCategoryTypeList["Stationery"] = 4] = "Stationery";
    GiftCategoryTypeList[GiftCategoryTypeList["Food"] = 3] = "Food";
    GiftCategoryTypeList[GiftCategoryTypeList["Others"] = 5] = "Others";
})(GiftCategoryTypeList || (GiftCategoryTypeList = {}));
export var GiftOperateStationType;
(function (GiftOperateStationType) {
    GiftOperateStationType[GiftOperateStationType["SORTING_WHILE_CHECKING"] = 2] = "SORTING_WHILE_CHECKING";
    GiftOperateStationType[GiftOperateStationType["CHECKING"] = 1] = "CHECKING";
    GiftOperateStationType[GiftOperateStationType["ALL"] = 4] = "ALL";
})(GiftOperateStationType || (GiftOperateStationType = {}));
export var GiftStatus;
(function (GiftStatus) {
    GiftStatus[GiftStatus["AVAILABLE"] = 0] = "AVAILABLE";
    GiftStatus[GiftStatus["UNAVAILABLE"] = 1] = "UNAVAILABLE";
})(GiftStatus || (GiftStatus = {}));
export var GiftType;
(function (GiftType) {
    GiftType[GiftType["SPECIAL_ORDER"] = 1] = "SPECIAL_ORDER";
    GiftType[GiftType["SPECIAL_SHOP"] = 2] = "SPECIAL_SHOP";
    GiftType[GiftType["SPECIAL_SKU_BY_SHOP"] = 3] = "SPECIAL_SKU_BY_SHOP";
})(GiftType || (GiftType = {}));
export var GiftValidityType;
(function (GiftValidityType) {
    GiftValidityType[GiftValidityType["Less_than_a_month"] = 1] = "Less_than_a_month";
    GiftValidityType[GiftValidityType["Less_than_half_a_year"] = 2] = "Less_than_half_a_year";
    GiftValidityType[GiftValidityType["Over_half_a_year"] = 3] = "Over_half_a_year";
    GiftValidityType[GiftValidityType["Over_a_year"] = 4] = "Over_a_year";
})(GiftValidityType || (GiftValidityType = {}));
export var GroupActivityTaskType;
(function (GroupActivityTaskType) {
    GroupActivityTaskType[GroupActivityTaskType["Counting"] = 1] = "Counting";
    GroupActivityTaskType[GroupActivityTaskType["Qc"] = 2] = "Qc";
    GroupActivityTaskType[GroupActivityTaskType["Receiving"] = 3] = "Receiving";
    GroupActivityTaskType[GroupActivityTaskType["Putaway"] = 4] = "Putaway";
    GroupActivityTaskType[GroupActivityTaskType["Labelling"] = 5] = "Labelling";
    GroupActivityTaskType[GroupActivityTaskType["Bundling"] = 6] = "Bundling";
    GroupActivityTaskType[GroupActivityTaskType["Repackaging"] = 7] = "Repackaging";
})(GroupActivityTaskType || (GroupActivityTaskType = {}));
export var GroupActivityType;
(function (GroupActivityType) {
    GroupActivityType[GroupActivityType["FIX"] = 2] = "FIX";
    GroupActivityType[GroupActivityType["TEMP"] = 1] = "TEMP";
})(GroupActivityType || (GroupActivityType = {}));
export var HighValueCategoryTreeDisabled;
(function (HighValueCategoryTreeDisabled) {
    HighValueCategoryTreeDisabled[HighValueCategoryTreeDisabled["DISABLED"] = 1] = "DISABLED";
    HighValueCategoryTreeDisabled[HighValueCategoryTreeDisabled["NOT_DISABLED"] = 0] = "NOT_DISABLED";
})(HighValueCategoryTreeDisabled || (HighValueCategoryTreeDisabled = {}));
export var HighValueLocationFrequency;
(function (HighValueLocationFrequency) {
    HighValueLocationFrequency[HighValueLocationFrequency["DAYS_7"] = 0] = "DAYS_7";
    HighValueLocationFrequency[HighValueLocationFrequency["DAYS_14"] = 1] = "DAYS_14";
    HighValueLocationFrequency[HighValueLocationFrequency["DAYS_30"] = 2] = "DAYS_30";
    HighValueLocationFrequency[HighValueLocationFrequency["EMPTY"] = 3] = "EMPTY";
})(HighValueLocationFrequency || (HighValueLocationFrequency = {}));
export var HireStatus;
(function (HireStatus) {
    HireStatus[HireStatus["ACTIVE"] = 1] = "ACTIVE";
    HireStatus[HireStatus["LEAVE__OF__ABSENCE"] = 3] = "LEAVE__OF__ABSENCE";
    HireStatus[HireStatus["TERMINATED"] = 2] = "TERMINATED";
})(HireStatus || (HireStatus = {}));
export var HistoryMaintainType;
(function (HistoryMaintainType) {
    HistoryMaintainType[HistoryMaintainType["N"] = 0] = "N";
    HistoryMaintainType[HistoryMaintainType["Y"] = 1] = "Y";
})(HistoryMaintainType || (HistoryMaintainType = {}));
export var ISCPurchase;
(function (ISCPurchase) {
    ISCPurchase["CONSIGNMENT"] = "Consignment";
    ISCPurchase["FULFILLMENT"] = "Fulfillment";
    ISCPurchase["OUTRIGHT"] = "Outright";
})(ISCPurchase || (ISCPurchase = {}));
export var InboundAdditionalServiceType;
(function (InboundAdditionalServiceType) {
    InboundAdditionalServiceType[InboundAdditionalServiceType["LABELLING"] = 1] = "LABELLING";
    InboundAdditionalServiceType[InboundAdditionalServiceType["BUNDLING"] = 2] = "BUNDLING";
    InboundAdditionalServiceType[InboundAdditionalServiceType["REPACKAGING"] = 3] = "REPACKAGING";
})(InboundAdditionalServiceType || (InboundAdditionalServiceType = {}));
export var InboundAdditionalStatus;
(function (InboundAdditionalStatus) {
    InboundAdditionalStatus[InboundAdditionalStatus["PENDING"] = 10] = "PENDING";
    InboundAdditionalStatus[InboundAdditionalStatus["ONGOING"] = 20] = "ONGOING";
    InboundAdditionalStatus[InboundAdditionalStatus["DONE"] = 30] = "DONE";
    InboundAdditionalStatus[InboundAdditionalStatus["CANCELLED"] = 40] = "CANCELLED";
})(InboundAdditionalStatus || (InboundAdditionalStatus = {}));
export var InboundArrivalGroupOrNotType;
(function (InboundArrivalGroupOrNotType) {
    InboundArrivalGroupOrNotType[InboundArrivalGroupOrNotType["DEFAULT"] = 0] = "DEFAULT";
    InboundArrivalGroupOrNotType[InboundArrivalGroupOrNotType["GROUP"] = 2] = "GROUP";
    InboundArrivalGroupOrNotType[InboundArrivalGroupOrNotType["NOT_GROUP"] = 1] = "NOT_GROUP";
})(InboundArrivalGroupOrNotType || (InboundArrivalGroupOrNotType = {}));
export var InboundArrivalRejectConfig;
(function (InboundArrivalRejectConfig) {
    InboundArrivalRejectConfig[InboundArrivalRejectConfig["CAN_NOT_REJECT"] = 0] = "CAN_NOT_REJECT";
    InboundArrivalRejectConfig[InboundArrivalRejectConfig["CAN_REJECT_ALL"] = 2] = "CAN_REJECT_ALL";
    InboundArrivalRejectConfig[InboundArrivalRejectConfig["REJECT_NOT_ON_TIME"] = 1] = "REJECT_NOT_ON_TIME";
})(InboundArrivalRejectConfig || (InboundArrivalRejectConfig = {}));
export var InboundArrivalTag;
(function (InboundArrivalTag) {
    InboundArrivalTag[InboundArrivalTag["EARLY"] = 2] = "EARLY";
    InboundArrivalTag[InboundArrivalTag["LATE"] = 3] = "LATE";
    InboundArrivalTag[InboundArrivalTag["ON_TIME"] = 1] = "ON_TIME";
    InboundArrivalTag[InboundArrivalTag["UN_ARRIVED"] = 0] = "UN_ARRIVED";
})(InboundArrivalTag || (InboundArrivalTag = {}));
export var InboundAsnBizType;
(function (InboundAsnBizType) {
    InboundAsnBizType[InboundAsnBizType["PURCHASE_INBOUND"] = 1] = "PURCHASE_INBOUND";
    InboundAsnBizType[InboundAsnBizType["RETURN_INBOUND"] = 2] = "RETURN_INBOUND";
    InboundAsnBizType[InboundAsnBizType["TRANSFER_INBOUND"] = 3] = "TRANSFER_INBOUND";
})(InboundAsnBizType || (InboundAsnBizType = {}));
export var InboundAsnDateReferenceType;
(function (InboundAsnDateReferenceType) {
    InboundAsnDateReferenceType[InboundAsnDateReferenceType["ACTUAL_ARRIVAL_TIME"] = 3] = "ACTUAL_ARRIVAL_TIME";
    InboundAsnDateReferenceType[InboundAsnDateReferenceType["ASN_CREATION_TIME"] = 2] = "ASN_CREATION_TIME";
    InboundAsnDateReferenceType[InboundAsnDateReferenceType["EXPECTED_DELIVERY_TIME"] = 1] = "EXPECTED_DELIVERY_TIME";
    InboundAsnDateReferenceType[InboundAsnDateReferenceType["TRACKING_ID_CREATE_TIME"] = 4] = "TRACKING_ID_CREATE_TIME";
})(InboundAsnDateReferenceType || (InboundAsnDateReferenceType = {}));
export var InboundAsnSourceType;
(function (InboundAsnSourceType) {
    InboundAsnSourceType[InboundAsnSourceType["CONSIGNMENT"] = 1] = "CONSIGNMENT";
    InboundAsnSourceType[InboundAsnSourceType["FBS"] = 2] = "FBS";
    InboundAsnSourceType[InboundAsnSourceType["OUTRIGHT"] = 0] = "OUTRIGHT";
    InboundAsnSourceType[InboundAsnSourceType["RETURN_INBOUND"] = 3] = "RETURN_INBOUND";
    InboundAsnSourceType[InboundAsnSourceType["TRANSFER_INBOUND"] = 4] = "TRANSFER_INBOUND";
})(InboundAsnSourceType || (InboundAsnSourceType = {}));
export var InboundDeviceDisplayStatus;
(function (InboundDeviceDisplayStatus) {
    InboundDeviceDisplayStatus[InboundDeviceDisplayStatus["DONE"] = 0] = "DONE";
    InboundDeviceDisplayStatus[InboundDeviceDisplayStatus["PUTTING"] = 2] = "PUTTING";
    InboundDeviceDisplayStatus[InboundDeviceDisplayStatus["RECEIVING"] = 1] = "RECEIVING";
})(InboundDeviceDisplayStatus || (InboundDeviceDisplayStatus = {}));
export var InboundExceptionTaskBdDecision;
(function (InboundExceptionTaskBdDecision) {
    InboundExceptionTaskBdDecision["Raise_new_ASN"] = "Raise new ASN";
    InboundExceptionTaskBdDecision["Receive"] = "Receive";
    InboundExceptionTaskBdDecision["Return_to_Supplier"] = "Return to Supplier";
    InboundExceptionTaskBdDecision["SKU_Info_Updated"] = "SKU Info Updated";
    InboundExceptionTaskBdDecision["UPC_Updated"] = "UPC Updated";
})(InboundExceptionTaskBdDecision || (InboundExceptionTaskBdDecision = {}));
export var InboundExceptionTaskPmsChangeType;
(function (InboundExceptionTaskPmsChangeType) {
    InboundExceptionTaskPmsChangeType[InboundExceptionTaskPmsChangeType["BD_Decision"] = 1] = "BD_Decision";
    InboundExceptionTaskPmsChangeType[InboundExceptionTaskPmsChangeType["BD_Remark"] = 2] = "BD_Remark";
})(InboundExceptionTaskPmsChangeType || (InboundExceptionTaskPmsChangeType = {}));
export var InboundExceptionTaskReason;
(function (InboundExceptionTaskReason) {
    InboundExceptionTaskReason["Damaged"] = "Damaged";
    InboundExceptionTaskReason["Expired"] = "Expired";
    InboundExceptionTaskReason["Expiring"] = "Expiring";
    InboundExceptionTaskReason["Other_issues"] = "Other issues";
    InboundExceptionTaskReason["SKU_does_not_belong_to_ASN_Group"] = "SKU does not belong to ASN Group";
    InboundExceptionTaskReason["Surplus"] = "Surplus";
    InboundExceptionTaskReason["Unknown_item"] = "Unknown item";
    InboundExceptionTaskReason["Wrong_SKU_Information"] = "Wrong SKU Information";
    InboundExceptionTaskReason["Wrong_UPC"] = "Wrong UPC";
})(InboundExceptionTaskReason || (InboundExceptionTaskReason = {}));
export var InboundExceptionTaskSceneType;
(function (InboundExceptionTaskSceneType) {
    InboundExceptionTaskSceneType[InboundExceptionTaskSceneType["COUNTING"] = 1] = "COUNTING";
    InboundExceptionTaskSceneType[InboundExceptionTaskSceneType["QC"] = 2] = "QC";
})(InboundExceptionTaskSceneType || (InboundExceptionTaskSceneType = {}));
export var InboundExceptionTaskSearchOrder;
(function (InboundExceptionTaskSearchOrder) {
    InboundExceptionTaskSearchOrder[InboundExceptionTaskSearchOrder["ASC"] = 1] = "ASC";
    InboundExceptionTaskSearchOrder[InboundExceptionTaskSearchOrder["DESC"] = 2] = "DESC";
})(InboundExceptionTaskSearchOrder || (InboundExceptionTaskSearchOrder = {}));
export var InboundExceptionTaskSearchOrderKey;
(function (InboundExceptionTaskSearchOrderKey) {
    InboundExceptionTaskSearchOrderKey[InboundExceptionTaskSearchOrderKey["CTIME"] = 1] = "CTIME";
    InboundExceptionTaskSearchOrderKey[InboundExceptionTaskSearchOrderKey["PENDING_BD_TIME"] = 2] = "PENDING_BD_TIME";
})(InboundExceptionTaskSearchOrderKey || (InboundExceptionTaskSearchOrderKey = {}));
export var InboundExceptionTaskStatus;
(function (InboundExceptionTaskStatus) {
    InboundExceptionTaskStatus[InboundExceptionTaskStatus["PENDING"] = 10] = "PENDING";
    InboundExceptionTaskStatus[InboundExceptionTaskStatus["CHECKED"] = 20] = "CHECKED";
    InboundExceptionTaskStatus[InboundExceptionTaskStatus["BD_CONFIRMED"] = 40] = "BD_CONFIRMED";
    InboundExceptionTaskStatus[InboundExceptionTaskStatus["DONE"] = 50] = "DONE";
    InboundExceptionTaskStatus[InboundExceptionTaskStatus["CANCELLED"] = 60] = "CANCELLED";
})(InboundExceptionTaskStatus || (InboundExceptionTaskStatus = {}));
export var InboundExceptionTaskTreatment;
(function (InboundExceptionTaskTreatment) {
    InboundExceptionTaskTreatment[InboundExceptionTaskTreatment["Inbound_Current_Asn"] = 2] = "Inbound_Current_Asn";
    InboundExceptionTaskTreatment[InboundExceptionTaskTreatment["Inbound_New_Asn"] = 3] = "Inbound_New_Asn";
    InboundExceptionTaskTreatment[InboundExceptionTaskTreatment["Return"] = 1] = "Return";
})(InboundExceptionTaskTreatment || (InboundExceptionTaskTreatment = {}));
export var InboundExceptionType;
(function (InboundExceptionType) {
    InboundExceptionType["AbnormalItem"] = "Abnormal Item";
    InboundExceptionType["RejectedItem"] = "Rejected Item";
    InboundExceptionType["Shortage"] = "Shortage";
    InboundExceptionType["Surplus"] = "Surplus";
    InboundExceptionType["WrongSKU"] = "Wrong SKU";
})(InboundExceptionType || (InboundExceptionType = {}));
export var InboundExportRIOrderTimeType;
(function (InboundExportRIOrderTimeType) {
    InboundExportRIOrderTimeType[InboundExportRIOrderTimeType["ACTUAL_ARRIVAL_TIME"] = 2] = "ACTUAL_ARRIVAL_TIME";
    InboundExportRIOrderTimeType[InboundExportRIOrderTimeType["CREATE_TIME"] = 1] = "CREATE_TIME";
})(InboundExportRIOrderTimeType || (InboundExportRIOrderTimeType = {}));
export var InboundExportTroubleShootTimeType;
(function (InboundExportTroubleShootTimeType) {
    InboundExportTroubleShootTimeType[InboundExportTroubleShootTimeType["CREATE_TIME"] = 1] = "CREATE_TIME";
    InboundExportTroubleShootTimeType[InboundExportTroubleShootTimeType["RESOLVED_TIME"] = 2] = "RESOLVED_TIME";
})(InboundExportTroubleShootTimeType || (InboundExportTroubleShootTimeType = {}));
export var InboundPrintStatus;
(function (InboundPrintStatus) {
    InboundPrintStatus[InboundPrintStatus["PRINTED"] = 2] = "PRINTED";
    InboundPrintStatus[InboundPrintStatus["PRINT_DISABLED"] = 0] = "PRINT_DISABLED";
    InboundPrintStatus[InboundPrintStatus["UN_PRINTED"] = 1] = "UN_PRINTED";
})(InboundPrintStatus || (InboundPrintStatus = {}));
export var InboundReason;
(function (InboundReason) {
    InboundReason["B2B_Virtual"] = "B2B-Virtual";
    InboundReason["Deal"] = "Deal";
    InboundReason["New_Product"] = "New Product";
    InboundReason["Regular_Replenishment"] = "Regular Replenishment";
})(InboundReason || (InboundReason = {}));
export var InboundRejectReason;
(function (InboundRejectReason) {
    InboundRejectReason[InboundRejectReason["CANCEL_CLOSE_PO"] = 3] = "CANCEL_CLOSE_PO";
    InboundRejectReason[InboundRejectReason["OTHER"] = 0] = "OTHER";
    InboundRejectReason[InboundRejectReason["SUPPLIER_REQUESTED"] = 1] = "SUPPLIER_REQUESTED";
    InboundRejectReason[InboundRejectReason["WRONG_ASN_CREATION"] = 2] = "WRONG_ASN_CREATION";
})(InboundRejectReason || (InboundRejectReason = {}));
export var InboundReprocessTaskStatus;
(function (InboundReprocessTaskStatus) {
    InboundReprocessTaskStatus[InboundReprocessTaskStatus["PENDING"] = 10] = "PENDING";
    InboundReprocessTaskStatus[InboundReprocessTaskStatus["ONGOING"] = 20] = "ONGOING";
    InboundReprocessTaskStatus[InboundReprocessTaskStatus["DONE"] = 30] = "DONE";
})(InboundReprocessTaskStatus || (InboundReprocessTaskStatus = {}));
export var InboundSource;
(function (InboundSource) {
    InboundSource[InboundSource["OMS"] = 3] = "OMS";
    InboundSource[InboundSource["PMS"] = 1] = "PMS";
    InboundSource[InboundSource["SELLER_CENTER"] = 5] = "SELLER_CENTER";
    InboundSource[InboundSource["SRM"] = 4] = "SRM";
    InboundSource[InboundSource["WMS"] = 2] = "WMS";
})(InboundSource || (InboundSource = {}));
export var InboundStatus;
(function (InboundStatus) {
    InboundStatus[InboundStatus["PENDING"] = 0] = "PENDING";
    InboundStatus[InboundStatus["IN_TRANSIT"] = 13] = "IN_TRANSIT";
    InboundStatus[InboundStatus["ARRIVING"] = 12] = "ARRIVING";
    InboundStatus[InboundStatus["ARRIVED"] = 6] = "ARRIVED";
    InboundStatus[InboundStatus["COUNTING"] = 7] = "COUNTING";
    InboundStatus[InboundStatus["COUNTED"] = 8] = "COUNTED";
    InboundStatus[InboundStatus["CHECKING"] = 9] = "CHECKING";
    InboundStatus[InboundStatus["CHECKED"] = 10] = "CHECKED";
    InboundStatus[InboundStatus["SCANNING"] = 1] = "SCANNING";
    InboundStatus[InboundStatus["RECEIVED"] = 2] = "RECEIVED";
    InboundStatus[InboundStatus["PUTTING"] = 3] = "PUTTING";
    InboundStatus[InboundStatus["DONE"] = 4] = "DONE";
    InboundStatus[InboundStatus["CANCELLED"] = 5] = "CANCELLED";
    InboundStatus[InboundStatus["REJECTED"] = 11] = "REJECTED";
    InboundStatus[InboundStatus["FAILED"] = 15] = "FAILED";
})(InboundStatus || (InboundStatus = {}));
export var InboundType;
(function (InboundType) {
    InboundType[InboundType["BUYER_RETURN"] = 2] = "BUYER_RETURN";
    InboundType[InboundType["FAILED_DELIVERY"] = 3] = "FAILED_DELIVERY";
    InboundType[InboundType["MOVE_INBOUND"] = 4] = "MOVE_INBOUND";
    InboundType[InboundType["PURCHASE_INBOUND"] = 0] = "PURCHASE_INBOUND";
    InboundType[InboundType["REPLACE_INBOUND"] = 1] = "REPLACE_INBOUND";
})(InboundType || (InboundType = {}));
export var IncludeBatchType;
(function (IncludeBatchType) {
    IncludeBatchType["N"] = "N";
    IncludeBatchType["Y"] = "Y";
})(IncludeBatchType || (IncludeBatchType = {}));
export var IncludeSkuList;
(function (IncludeSkuList) {
    IncludeSkuList[IncludeSkuList["Yes"] = 1] = "Yes";
    IncludeSkuList[IncludeSkuList["No"] = 0] = "No";
})(IncludeSkuList || (IncludeSkuList = {}));
export var InventoryExportAngleType;
(function (InventoryExportAngleType) {
    InventoryExportAngleType[InventoryExportAngleType["SKU"] = 1] = "SKU";
    InventoryExportAngleType[InventoryExportAngleType["UID"] = 2] = "UID";
})(InventoryExportAngleType || (InventoryExportAngleType = {}));
export var InventoryFoundAt;
(function (InventoryFoundAt) {
    InventoryFoundAt[InventoryFoundAt["ON_PATHWAY"] = 2] = "ON_PATHWAY";
    InventoryFoundAt[InventoryFoundAt["ON_RACK"] = 1] = "ON_RACK";
    InventoryFoundAt[InventoryFoundAt["OTHERS"] = 3] = "OTHERS";
})(InventoryFoundAt || (InventoryFoundAt = {}));
export var IsFirstTimeIn;
(function (IsFirstTimeIn) {
    IsFirstTimeIn[IsFirstTimeIn["NO"] = 0] = "NO";
    IsFirstTimeIn[IsFirstTimeIn["YES"] = 1] = "YES";
})(IsFirstTimeIn || (IsFirstTimeIn = {}));
export var IsMedicalType;
(function (IsMedicalType) {
    IsMedicalType[IsMedicalType["NO"] = 0] = "NO";
    IsMedicalType[IsMedicalType["YES"] = 1] = "YES";
})(IsMedicalType || (IsMedicalType = {}));
export var LaborActivityOperation;
(function (LaborActivityOperation) {
    LaborActivityOperation[LaborActivityOperation["INBOUND"] = 2] = "INBOUND";
    LaborActivityOperation[LaborActivityOperation["INVENTORY"] = 3] = "INVENTORY";
    LaborActivityOperation[LaborActivityOperation["MOVE__TRANSFER"] = 6] = "MOVE__TRANSFER";
    LaborActivityOperation[LaborActivityOperation["OTHERS"] = 7] = "OTHERS";
    LaborActivityOperation[LaborActivityOperation["OUTBOUND"] = 1] = "OUTBOUND";
    LaborActivityOperation[LaborActivityOperation["RETURN__INBOUND"] = 5] = "RETURN__INBOUND";
    LaborActivityOperation[LaborActivityOperation["RTS"] = 4] = "RTS";
})(LaborActivityOperation || (LaborActivityOperation = {}));
export var LaborActivityStatus;
(function (LaborActivityStatus) {
    LaborActivityStatus[LaborActivityStatus["DISABLE"] = 2] = "DISABLE";
    LaborActivityStatus[LaborActivityStatus["ENABLED"] = 1] = "ENABLED";
})(LaborActivityStatus || (LaborActivityStatus = {}));
export var LaborActivityType;
(function (LaborActivityType) {
    LaborActivityType[LaborActivityType["DIRECT"] = 1] = "DIRECT";
    LaborActivityType[LaborActivityType["INDIRECT"] = 2] = "INDIRECT";
    LaborActivityType[LaborActivityType["UNPRODUCTIVE"] = 3] = "UNPRODUCTIVE";
})(LaborActivityType || (LaborActivityType = {}));
export var LabourPlanningProcessNode;
(function (LabourPlanningProcessNode) {
    LabourPlanningProcessNode[LabourPlanningProcessNode["Counting"] = 1] = "Counting";
    LabourPlanningProcessNode[LabourPlanningProcessNode["Labeling"] = 5] = "Labeling";
    LabourPlanningProcessNode[LabourPlanningProcessNode["Putaway"] = 3] = "Putaway";
    LabourPlanningProcessNode[LabourPlanningProcessNode["QC"] = 2] = "QC";
    LabourPlanningProcessNode[LabourPlanningProcessNode["Receiving"] = 4] = "Receiving";
    LabourPlanningProcessNode[LabourPlanningProcessNode["Sorting_Unloading"] = 6] = "Sorting_Unloading";
})(LabourPlanningProcessNode || (LabourPlanningProcessNode = {}));
export var LabourPlanningSkuTag;
(function (LabourPlanningSkuTag) {
    LabourPlanningSkuTag[LabourPlanningSkuTag["Bulky"] = 4] = "Bulky";
    LabourPlanningSkuTag[LabourPlanningSkuTag["CB"] = 8] = "CB";
    LabourPlanningSkuTag[LabourPlanningSkuTag["Heavy"] = 5] = "Heavy";
    LabourPlanningSkuTag[LabourPlanningSkuTag["High_Value"] = 6] = "High_Value";
    LabourPlanningSkuTag[LabourPlanningSkuTag["New_SKU"] = 3] = "New_SKU";
    LabourPlanningSkuTag[LabourPlanningSkuTag["No_Tag"] = 0] = "No_Tag";
    LabourPlanningSkuTag[LabourPlanningSkuTag["Require_Labelling"] = 1] = "Require_Labelling";
    LabourPlanningSkuTag[LabourPlanningSkuTag["Require_Shelflife"] = 2] = "Require_Shelflife";
    LabourPlanningSkuTag[LabourPlanningSkuTag["Temperature_Control"] = 7] = "Temperature_Control";
})(LabourPlanningSkuTag || (LabourPlanningSkuTag = {}));
export var LabourPlanningUnit;
(function (LabourPlanningUnit) {
    LabourPlanningUnit[LabourPlanningUnit["Man"] = 1] = "Man";
    LabourPlanningUnit[LabourPlanningUnit["Manhr"] = 2] = "Manhr";
})(LabourPlanningUnit || (LabourPlanningUnit = {}));
export var LifeCycleRuleItemType;
(function (LifeCycleRuleItemType) {
    LifeCycleRuleItemType[LifeCycleRuleItemType["BLOCK"] = 4] = "BLOCK";
    LifeCycleRuleItemType[LifeCycleRuleItemType["LOCK__UP"] = 3] = "LOCK__UP";
    LifeCycleRuleItemType[LifeCycleRuleItemType["NORMAL__REJECT"] = 1] = "NORMAL__REJECT";
    LifeCycleRuleItemType[LifeCycleRuleItemType["PROMO__REJECT"] = 2] = "PROMO__REJECT";
    LifeCycleRuleItemType[LifeCycleRuleItemType["REBLOCK"] = 5] = "REBLOCK";
})(LifeCycleRuleItemType || (LifeCycleRuleItemType = {}));
export var LocationPutAwayDeviceType;
(function (LocationPutAwayDeviceType) {
    LocationPutAwayDeviceType[LocationPutAwayDeviceType["ALL"] = 0] = "ALL";
    LocationPutAwayDeviceType[LocationPutAwayDeviceType["BOX"] = 1] = "BOX";
    LocationPutAwayDeviceType[LocationPutAwayDeviceType["PALLET"] = 2] = "PALLET";
})(LocationPutAwayDeviceType || (LocationPutAwayDeviceType = {}));
export var LocationDuringBlackOutPeriod;
(function (LocationDuringBlackOutPeriod) {
    LocationDuringBlackOutPeriod[LocationDuringBlackOutPeriod["ACCUMULATED"] = 0] = "ACCUMULATED";
    LocationDuringBlackOutPeriod[LocationDuringBlackOutPeriod["EXEMPTED"] = 1] = "EXEMPTED";
})(LocationDuringBlackOutPeriod || (LocationDuringBlackOutPeriod = {}));
export var LocationSKUType;
(function (LocationSKUType) {
    LocationSKUType[LocationSKUType["LOCATION_SKU_TYPE_AVAILABLE"] = 3] = "LOCATION_SKU_TYPE_AVAILABLE";
    LocationSKUType[LocationSKUType["LOCATION_SKU_TYPE_LOCKED"] = 1] = "LOCATION_SKU_TYPE_LOCKED";
    LocationSKUType[LocationSKUType["LOCATION_SKU_TYPE_ONHAND"] = 0] = "LOCATION_SKU_TYPE_ONHAND";
    LocationSKUType[LocationSKUType["LOCATION_SKU_TYPE_PRE_PICK"] = 2] = "LOCATION_SKU_TYPE_PRE_PICK";
})(LocationSKUType || (LocationSKUType = {}));
export var MassUnblockTaskStatusEnum;
(function (MassUnblockTaskStatusEnum) {
    MassUnblockTaskStatusEnum[MassUnblockTaskStatusEnum["Created"] = 0] = "Created";
    MassUnblockTaskStatusEnum[MassUnblockTaskStatusEnum["Running"] = 1] = "Running";
    MassUnblockTaskStatusEnum[MassUnblockTaskStatusEnum["PartialSuccess"] = 2] = "PartialSuccess";
    MassUnblockTaskStatusEnum[MassUnblockTaskStatusEnum["Success"] = 3] = "Success";
})(MassUnblockTaskStatusEnum || (MassUnblockTaskStatusEnum = {}));
export var MTOPackingTaskStatus;
(function (MTOPackingTaskStatus) {
    MTOPackingTaskStatus[MTOPackingTaskStatus["Cancel"] = 40] = "Cancel";
    MTOPackingTaskStatus[MTOPackingTaskStatus["Done"] = 30] = "Done";
    MTOPackingTaskStatus[MTOPackingTaskStatus["Ongoing"] = 20] = "Ongoing";
    MTOPackingTaskStatus[MTOPackingTaskStatus["Pending"] = 10] = "Pending";
})(MTOPackingTaskStatus || (MTOPackingTaskStatus = {}));
export var MTOPackingTaskStatusList;
(function (MTOPackingTaskStatusList) {
    MTOPackingTaskStatusList[MTOPackingTaskStatusList["Pending"] = 10] = "Pending";
    MTOPackingTaskStatusList[MTOPackingTaskStatusList["Ongoing"] = 20] = "Ongoing";
    MTOPackingTaskStatusList[MTOPackingTaskStatusList["Done"] = 30] = "Done";
    MTOPackingTaskStatusList[MTOPackingTaskStatusList["Cancel"] = 40] = "Cancel";
})(MTOPackingTaskStatusList || (MTOPackingTaskStatusList = {}));
export var MTOShippingTaskStatus;
(function (MTOShippingTaskStatus) {
    MTOShippingTaskStatus[MTOShippingTaskStatus["Cancel"] = 30] = "Cancel";
    MTOShippingTaskStatus[MTOShippingTaskStatus["Done"] = 20] = "Done";
    MTOShippingTaskStatus[MTOShippingTaskStatus["Ongoing"] = 10] = "Ongoing";
})(MTOShippingTaskStatus || (MTOShippingTaskStatus = {}));
export var MTOShippingTaskStatusList;
(function (MTOShippingTaskStatusList) {
    MTOShippingTaskStatusList[MTOShippingTaskStatusList["Ongoing"] = 10] = "Ongoing";
    MTOShippingTaskStatusList[MTOShippingTaskStatusList["Done"] = 20] = "Done";
    MTOShippingTaskStatusList[MTOShippingTaskStatusList["Cancel"] = 30] = "Cancel";
})(MTOShippingTaskStatusList || (MTOShippingTaskStatusList = {}));
export var MerchantStatus;
(function (MerchantStatus) {
    MerchantStatus[MerchantStatus["DELETED"] = 2] = "DELETED";
    MerchantStatus[MerchantStatus["NORMAL"] = 1] = "NORMAL";
})(MerchantStatus || (MerchantStatus = {}));
export var MergeAt;
(function (MergeAt) {
    MergeAt[MergeAt["Merge_Lane"] = 1] = "Merge_Lane";
    MergeAt[MergeAt["Merge_While_Sorting"] = 2] = "Merge_While_Sorting";
})(MergeAt || (MergeAt = {}));
export var MergeSortingNextProcess;
(function (MergeSortingNextProcess) {
    MergeSortingNextProcess[MergeSortingNextProcess["ABNORMAL_SUMMARY"] = 2] = "ABNORMAL_SUMMARY";
    MergeSortingNextProcess[MergeSortingNextProcess["FINISHED"] = 3] = "FINISHED";
    MergeSortingNextProcess[MergeSortingNextProcess["SCAN_SKU"] = 1] = "SCAN_SKU";
    MergeSortingNextProcess[MergeSortingNextProcess["UNDEFINED"] = 0] = "UNDEFINED";
})(MergeSortingNextProcess || (MergeSortingNextProcess = {}));
export var MergeTaskStatus;
(function (MergeTaskStatus) {
    MergeTaskStatus[MergeTaskStatus["Pending"] = 0] = "Pending";
    MergeTaskStatus[MergeTaskStatus["Ongoing"] = 1] = "Ongoing";
    MergeTaskStatus[MergeTaskStatus["Done"] = 2] = "Done";
    MergeTaskStatus[MergeTaskStatus["Cancel"] = 9] = "Cancel";
})(MergeTaskStatus || (MergeTaskStatus = {}));
export var MergingTaskStatus;
(function (MergingTaskStatus) {
    MergingTaskStatus[MergingTaskStatus["Pending"] = 0] = "Pending";
    MergingTaskStatus[MergingTaskStatus["Ongoing"] = 1] = "Ongoing";
    MergingTaskStatus[MergingTaskStatus["Done"] = 2] = "Done";
    MergingTaskStatus[MergingTaskStatus["Cancel"] = 9] = "Cancel";
})(MergingTaskStatus || (MergingTaskStatus = {}));
export var MixOfSku;
(function (MixOfSku) {
    MixOfSku[MixOfSku["CANNOT_MIX"] = 1] = "CANNOT_MIX";
    MixOfSku[MixOfSku["MIX_ATTRS_OF_ONE_SKU"] = 2] = "MIX_ATTRS_OF_ONE_SKU";
    MixOfSku[MixOfSku["MIX_SKU"] = 3] = "MIX_SKU";
    MixOfSku[MixOfSku["UNDEFINED"] = 0] = "UNDEFINED";
})(MixOfSku || (MixOfSku = {}));
export var MixOfSupplier;
(function (MixOfSupplier) {
    MixOfSupplier[MixOfSupplier["CANNOT_MIX"] = 2] = "CANNOT_MIX";
    MixOfSupplier[MixOfSupplier["MIX_SUPPLIER"] = 1] = "MIX_SUPPLIER";
    MixOfSupplier[MixOfSupplier["UNDEFINED"] = 0] = "UNDEFINED";
})(MixOfSupplier || (MixOfSupplier = {}));
export var MoveTransferOutboundOrderCancelReason;
(function (MoveTransferOutboundOrderCancelReason) {
    MoveTransferOutboundOrderCancelReason[MoveTransferOutboundOrderCancelReason["Wrongly_Created"] = 1] = "Wrongly_Created";
    MoveTransferOutboundOrderCancelReason[MoveTransferOutboundOrderCancelReason["Missing_Physical_Stock"] = 2] = "Missing_Physical_Stock";
    MoveTransferOutboundOrderCancelReason[MoveTransferOutboundOrderCancelReason["Plan_Changes"] = 3] = "Plan_Changes";
    MoveTransferOutboundOrderCancelReason[MoveTransferOutboundOrderCancelReason["Other"] = 4] = "Other";
})(MoveTransferOutboundOrderCancelReason || (MoveTransferOutboundOrderCancelReason = {}));
export var MoveTransferOutboundOrderCreateChannel;
(function (MoveTransferOutboundOrderCreateChannel) {
    MoveTransferOutboundOrderCreateChannel[MoveTransferOutboundOrderCreateChannel["WMS"] = 0] = "WMS";
    MoveTransferOutboundOrderCreateChannel[MoveTransferOutboundOrderCreateChannel["PMS"] = 1] = "PMS";
})(MoveTransferOutboundOrderCreateChannel || (MoveTransferOutboundOrderCreateChannel = {}));
export var MoveTransferOutboundStatus;
(function (MoveTransferOutboundStatus) {
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Pending"] = 10] = "Pending";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Allocated"] = 40] = "Allocated";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Approved"] = 20] = "Approved";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Rejected"] = 30] = "Rejected";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Outbounding"] = 130] = "Outbounding";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Shipping"] = 100] = "Shipping";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["In_Transit"] = 140] = "In_Transit";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Cancel"] = 120] = "Cancel";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Inbounding"] = 150] = "Inbounding";
    MoveTransferOutboundStatus[MoveTransferOutboundStatus["Done"] = 160] = "Done";
})(MoveTransferOutboundStatus || (MoveTransferOutboundStatus = {}));
export var MtOrderSource;
(function (MtOrderSource) {
    MtOrderSource[MtOrderSource["WMS"] = 0] = "WMS";
    MtOrderSource[MtOrderSource["PMS"] = 1] = "PMS";
})(MtOrderSource || (MtOrderSource = {}));
export var MtbQualityType;
(function (MtbQualityType) {
    MtbQualityType[MtbQualityType["GOOD"] = 1] = "GOOD";
    MtbQualityType[MtbQualityType["DAMAGE"] = 2] = "DAMAGE";
    MtbQualityType[MtbQualityType["MISSING"] = 3] = "MISSING";
})(MtbQualityType || (MtbQualityType = {}));
export var MtbStatus;
(function (MtbStatus) {
    MtbStatus[MtbStatus["CLOSED"] = 0] = "CLOSED";
    MtbStatus[MtbStatus["USING"] = 1] = "USING";
    MtbStatus[MtbStatus["USED"] = 2] = "USED";
})(MtbStatus || (MtbStatus = {}));
export var MtoCheckingTaskStatus;
(function (MtoCheckingTaskStatus) {
    MtoCheckingTaskStatus[MtoCheckingTaskStatus["Pending"] = 0] = "Pending";
    MtoCheckingTaskStatus[MtoCheckingTaskStatus["Ongoing"] = 1] = "Ongoing";
    MtoCheckingTaskStatus[MtoCheckingTaskStatus["Done"] = 2] = "Done";
})(MtoCheckingTaskStatus || (MtoCheckingTaskStatus = {}));
export var MtoPickTaskStatus;
(function (MtoPickTaskStatus) {
    MtoPickTaskStatus[MtoPickTaskStatus["Assigned"] = 10] = "Assigned";
    MtoPickTaskStatus[MtoPickTaskStatus["Cancel"] = 9] = "Cancel";
    MtoPickTaskStatus[MtoPickTaskStatus["Done"] = 2] = "Done";
    MtoPickTaskStatus[MtoPickTaskStatus["Ongoing"] = 1] = "Ongoing";
    MtoPickTaskStatus[MtoPickTaskStatus["Pending"] = 0] = "Pending";
})(MtoPickTaskStatus || (MtoPickTaskStatus = {}));
export var MultiWhsType;
(function (MultiWhsType) {
    MultiWhsType[MultiWhsType["N"] = 0] = "N";
    MultiWhsType[MultiWhsType["Y"] = 1] = "Y";
})(MultiWhsType || (MultiWhsType = {}));
export var NormalRuleType;
(function (NormalRuleType) {
    NormalRuleType[NormalRuleType["EMPTY_LOCATION_RECOMMEND"] = 1] = "EMPTY_LOCATION_RECOMMEND";
    NormalRuleType[NormalRuleType["SAME_SKU_KEY_BATCH_ATTR_NOT_MIX"] = 4] = "SAME_SKU_KEY_BATCH_ATTR_NOT_MIX";
    NormalRuleType[NormalRuleType["SAME_SKU_SAME_BATCH_INFO_RECOMMEND"] = 2] = "SAME_SKU_SAME_BATCH_INFO_RECOMMEND";
    NormalRuleType[NormalRuleType["SAME_SKU_SAME_KEY_BATCH_ATTR_RECOMMEND"] = 3] = "SAME_SKU_SAME_KEY_BATCH_ATTR_RECOMMEND";
    NormalRuleType[NormalRuleType["SIMILARITY_SKU_NOT_MIX"] = 5] = "SIMILARITY_SKU_NOT_MIX";
})(NormalRuleType || (NormalRuleType = {}));
export var NotificationSoundType;
(function (NotificationSoundType) {
    NotificationSoundType[NotificationSoundType["MUTE"] = 2] = "MUTE";
    NotificationSoundType[NotificationSoundType["SOUND"] = 1] = "SOUND";
})(NotificationSoundType || (NotificationSoundType = {}));
export var NotificationActivity;
(function (NotificationActivity) {
    NotificationActivity[NotificationActivity["ADJUSTMENT_PUTAWAY"] = 16] = "ADJUSTMENT_PUTAWAY";
    NotificationActivity[NotificationActivity["ARRIVING"] = 1] = "ARRIVING";
    NotificationActivity[NotificationActivity["ASN_CREATION"] = 17] = "ASN_CREATION";
    NotificationActivity[NotificationActivity["BATCH_PICKING"] = 19] = "BATCH_PICKING";
    NotificationActivity[NotificationActivity["CHECKING"] = 12] = "CHECKING";
    NotificationActivity[NotificationActivity["COUNTING"] = 2] = "COUNTING";
    NotificationActivity[NotificationActivity["MASS_PUTWAY"] = 6] = "MASS_PUTWAY";
    NotificationActivity[NotificationActivity["ONE_STEP_TRANSFER"] = 26] = "ONE_STEP_TRANSFER";
    NotificationActivity[NotificationActivity["PACKING"] = 13] = "PACKING";
    NotificationActivity[NotificationActivity["PAPERLESS_PICKING"] = 21] = "PAPERLESS_PICKING";
    NotificationActivity[NotificationActivity["PICKING"] = 9] = "PICKING";
    NotificationActivity[NotificationActivity["QC"] = 3] = "QC";
    NotificationActivity[NotificationActivity["QC_AND_RECIEVING"] = 7] = "QC_AND_RECIEVING";
    NotificationActivity[NotificationActivity["RACK_TRANSFER_PICKING"] = 15] = "RACK_TRANSFER_PICKING";
    NotificationActivity[NotificationActivity["RECIEVING"] = 4] = "RECIEVING";
    NotificationActivity[NotificationActivity["RETURN_TO_BUYER"] = 18] = "RETURN_TO_BUYER";
    NotificationActivity[NotificationActivity["RTS_PICKING"] = 24] = "RTS_PICKING";
    NotificationActivity[NotificationActivity["SHIPPING"] = 14] = "SHIPPING";
    NotificationActivity[NotificationActivity["SORTING"] = 10] = "SORTING";
    NotificationActivity[NotificationActivity["SORTING_BY_ITEM"] = 23] = "SORTING_BY_ITEM";
    NotificationActivity[NotificationActivity["SORTING_BY_ORDER"] = 22] = "SORTING_BY_ORDER";
    NotificationActivity[NotificationActivity["SORTING_WHILE_CHECKING"] = 11] = "SORTING_WHILE_CHECKING";
    NotificationActivity[NotificationActivity["SORTING_WHILE_PICKING"] = 20] = "SORTING_WHILE_PICKING";
    NotificationActivity[NotificationActivity["STANDARD_PUTAWAY"] = 5] = "STANDARD_PUTAWAY";
    NotificationActivity[NotificationActivity["TWO_STEP_TRANSFER_PICKING"] = 27] = "TWO_STEP_TRANSFER_PICKING";
    NotificationActivity[NotificationActivity["TWO_STEP_TRANSFER_PUTAWAY_MASS_PUTAWAY"] = 29] = "TWO_STEP_TRANSFER_PUTAWAY_MASS_PUTAWAY";
    NotificationActivity[NotificationActivity["TWO_STEP_TRANSFER_PUTAWAY_STANDARD_PUTAWAY"] = 28] = "TWO_STEP_TRANSFER_PUTAWAY_STANDARD_PUTAWAY";
    NotificationActivity[NotificationActivity["WAVE_RUN"] = 8] = "WAVE_RUN";
    NotificationActivity[NotificationActivity["WHOLE_LOCATION_TRANSFER"] = 25] = "WHOLE_LOCATION_TRANSFER";
})(NotificationActivity || (NotificationActivity = {}));
export var NotificationDeviceType;
(function (NotificationDeviceType) {
    NotificationDeviceType[NotificationDeviceType["PC"] = 1] = "PC";
    NotificationDeviceType[NotificationDeviceType["PDA"] = 2] = "PDA";
})(NotificationDeviceType || (NotificationDeviceType = {}));
export var NotificationSoundClip;
(function (NotificationSoundClip) {
    NotificationSoundClip[NotificationSoundClip["SOUND_CLIP_A"] = 1] = "SOUND_CLIP_A";
    NotificationSoundClip[NotificationSoundClip["SOUND_CLIP_B"] = 2] = "SOUND_CLIP_B";
    NotificationSoundClip[NotificationSoundClip["SOUND_CLIP_C"] = 3] = "SOUND_CLIP_C";
})(NotificationSoundClip || (NotificationSoundClip = {}));
export var NotificationWarehouseModule;
(function (NotificationWarehouseModule) {
    NotificationWarehouseModule[NotificationWarehouseModule["INBOUND"] = 1] = "INBOUND";
    NotificationWarehouseModule[NotificationWarehouseModule["INVENTORY"] = 3] = "INVENTORY";
    NotificationWarehouseModule[NotificationWarehouseModule["OUTBOUND"] = 2] = "OUTBOUND";
})(NotificationWarehouseModule || (NotificationWarehouseModule = {}));
export var NotificationWorkProcess;
(function (NotificationWorkProcess) {
    NotificationWorkProcess[NotificationWorkProcess["INBOUND"] = 1] = "INBOUND";
    NotificationWorkProcess[NotificationWorkProcess["INVENTORY_ADJUSTMENT"] = 8] = "INVENTORY_ADJUSTMENT";
    NotificationWorkProcess[NotificationWorkProcess["MT_INBOUND"] = 2] = "MT_INBOUND";
    NotificationWorkProcess[NotificationWorkProcess["MT_OUTBOUND"] = 6] = "MT_OUTBOUND";
    NotificationWorkProcess[NotificationWorkProcess["RACK_TRANSFER"] = 7] = "RACK_TRANSFER";
    NotificationWorkProcess[NotificationWorkProcess["RETURN_INBOUND"] = 3] = "RETURN_INBOUND";
    NotificationWorkProcess[NotificationWorkProcess["RTS_OUTBOUND"] = 5] = "RTS_OUTBOUND";
    NotificationWorkProcess[NotificationWorkProcess["SALES_OUTBOUND"] = 4] = "SALES_OUTBOUND";
})(NotificationWorkProcess || (NotificationWorkProcess = {}));
export var OccupiedFlag;
(function (OccupiedFlag) {
    OccupiedFlag[OccupiedFlag["/"] = 1] = "/";
    OccupiedFlag[OccupiedFlag["N"] = 3] = "N";
    OccupiedFlag[OccupiedFlag["UNDEFINED"] = 0] = "UNDEFINED";
    OccupiedFlag[OccupiedFlag["Y"] = 2] = "Y";
})(OccupiedFlag || (OccupiedFlag = {}));
export var OrderTypeList;
(function (OrderTypeList) {
    OrderTypeList[OrderTypeList["All"] = 0] = "All";
    OrderTypeList[OrderTypeList["Partial_Cancel"] = 1] = "Partial_Cancel";
})(OrderTypeList || (OrderTypeList = {}));
export var OriginalReturnQcReturnReason;
(function (OriginalReturnQcReturnReason) {
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Did_not_receive_the_order"] = 1] = "Did_not_receive_the_order";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_an_incomplete_product_missing_quantity_or_accessories"] = 103] = "Received_an_incomplete_product_missing_quantity_or_accessories";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_the_wrong_product_wrong_size"] = 104] = "Received_the_wrong_product_wrong_size";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["_wrong_colour"] = 105] = "_wrong_colour";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["_different_product"] = 2] = "_different_product";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_a_product_with_physical_damage_dented"] = 3] = "Received_a_product_with_physical_damage_dented";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["_scratched"] = 4] = "_scratched";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["_shattered"] = 106] = "_shattered";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_a_faulty_product_malfunction"] = 107] = "Received_a_faulty_product_malfunction";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["_does_not_work_as_intended"] = 107] = "_does_not_work_as_intended";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_a_counterfeit_product"] = 105] = "Received_a_counterfeit_product";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Change_of_mind"] = 102] = "Change_of_mind";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Product_is_significantly_different_from_description"] = 4] = "Product_is_significantly_different_from_description";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Product_is_used_refurbished_rebuilt"] = 7] = "Product_is_used_refurbished_rebuilt";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Product_does_not_meet_expectations"] = 104] = "Product_does_not_meet_expectations";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_damaged_wrong_product"] = 101] = "Received_damaged_wrong_product";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Received_damaged_faulty_product"] = 3] = "Received_damaged_faulty_product";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Mutual_agreement_with_the_seller"] = 5] = "Mutual_agreement_with_the_seller";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["RI_order_has_never_been_created_but_buyer_has_requested_R"] = 81] = "RI_order_has_never_been_created_but_buyer_has_requested_R";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Buyer_never_request_R_but_WH_still_decide_to_process_offline"] = 82] = "Buyer_never_request_R_but_WH_still_decide_to_process_offline";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["RI_order_has_never_been_created_failed_delivery"] = 83] = "RI_order_has_never_been_created_failed_delivery";
    OriginalReturnQcReturnReason[OriginalReturnQcReturnReason["Others"] = 6] = "Others";
})(OriginalReturnQcReturnReason || (OriginalReturnQcReturnReason = {}));
export var OutboundTaskStatus;
(function (OutboundTaskStatus) {
    OutboundTaskStatus[OutboundTaskStatus["Pending"] = 0] = "Pending";
    OutboundTaskStatus[OutboundTaskStatus["Assigned"] = 10] = "Assigned";
    OutboundTaskStatus[OutboundTaskStatus["Ongoing"] = 1] = "Ongoing";
    OutboundTaskStatus[OutboundTaskStatus["Done"] = 2] = "Done";
    OutboundTaskStatus[OutboundTaskStatus["Failed"] = 3] = "Failed";
    OutboundTaskStatus[OutboundTaskStatus["Cancel"] = 9] = "Cancel";
})(OutboundTaskStatus || (OutboundTaskStatus = {}));
export var OutboundTaskType;
(function (OutboundTaskType) {
    OutboundTaskType[OutboundTaskType["Checking"] = 2] = "Checking";
    OutboundTaskType[OutboundTaskType["Merging"] = 7] = "Merging";
    OutboundTaskType[OutboundTaskType["Picking"] = 1] = "Picking";
    OutboundTaskType[OutboundTaskType["Reverse"] = 3] = "Reverse";
    OutboundTaskType[OutboundTaskType["RtsPicking"] = 11] = "RtsPicking";
    OutboundTaskType[OutboundTaskType["Sorting"] = 4] = "Sorting";
    OutboundTaskType[OutboundTaskType["SubPicking"] = 5] = "SubPicking";
    OutboundTaskType[OutboundTaskType["Transiting"] = 6] = "Transiting";
    OutboundTaskType[OutboundTaskType["TroubleShooting"] = 10] = "TroubleShooting";
})(OutboundTaskType || (OutboundTaskType = {}));
export var OutboundTrackingOrderStatus;
(function (OutboundTrackingOrderStatus) {
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["CREATED"] = 0] = "CREATED";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["PENDING_PICK"] = 9] = "PENDING_PICK";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["PICKING"] = 2] = "PICKING";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["PICKED"] = 3] = "PICKED";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["CHECKING"] = 5] = "CHECKING";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["CHECKED"] = 6] = "CHECKED";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["SHIPPING"] = 7] = "SHIPPING";
    OutboundTrackingOrderStatus[OutboundTrackingOrderStatus["OUTBOUND"] = 8] = "OUTBOUND";
})(OutboundTrackingOrderStatus || (OutboundTrackingOrderStatus = {}));
export var OwnerTransferSkuDisplayStatus;
(function (OwnerTransferSkuDisplayStatus) {
    OwnerTransferSkuDisplayStatus[OwnerTransferSkuDisplayStatus["DONE"] = 3] = "DONE";
    OwnerTransferSkuDisplayStatus[OwnerTransferSkuDisplayStatus["NOT_EXIST"] = 4] = "NOT_EXIST";
    OwnerTransferSkuDisplayStatus[OwnerTransferSkuDisplayStatus["ONGOING"] = 2] = "ONGOING";
    OwnerTransferSkuDisplayStatus[OwnerTransferSkuDisplayStatus["PENDING"] = 1] = "PENDING";
})(OwnerTransferSkuDisplayStatus || (OwnerTransferSkuDisplayStatus = {}));
export var OwnerTransfertTaskStatus;
(function (OwnerTransfertTaskStatus) {
    OwnerTransfertTaskStatus[OwnerTransfertTaskStatus["DONE"] = 3] = "DONE";
    OwnerTransfertTaskStatus[OwnerTransfertTaskStatus["ONGOING"] = 2] = "ONGOING";
    OwnerTransfertTaskStatus[OwnerTransfertTaskStatus["PENDING"] = 1] = "PENDING";
})(OwnerTransfertTaskStatus || (OwnerTransfertTaskStatus = {}));
export var PackTaskStatus;
(function (PackTaskStatus) {
    PackTaskStatus[PackTaskStatus["Pending"] = 0] = "Pending";
    PackTaskStatus[PackTaskStatus["Ongoing"] = 1] = "Ongoing";
    PackTaskStatus[PackTaskStatus["Done"] = 2] = "Done";
    PackTaskStatus[PackTaskStatus["Cancel"] = 9] = "Cancel";
})(PackTaskStatus || (PackTaskStatus = {}));
export var PackagingType;
(function (PackagingType) {
    PackagingType[PackagingType["PACKAGING_TYPE_BOX"] = 1] = "PACKAGING_TYPE_BOX";
    PackagingType[PackagingType["PACKAGING_TYPE_ORIGINAL_BOX"] = 0] = "PACKAGING_TYPE_ORIGINAL_BOX";
    PackagingType[PackagingType["PACKAGING_TYPE_POUCH"] = 2] = "PACKAGING_TYPE_POUCH";
})(PackagingType || (PackagingType = {}));
export var PaperlessFlag;
(function (PaperlessFlag) {
    PaperlessFlag[PaperlessFlag["Y"] = 1] = "Y";
    PaperlessFlag[PaperlessFlag["N"] = 0] = "N";
})(PaperlessFlag || (PaperlessFlag = {}));
export var ParcelSplitProcess;
(function (ParcelSplitProcess) {
    ParcelSplitProcess[ParcelSplitProcess["NOT_PARCEL_SPLIT"] = 0] = "NOT_PARCEL_SPLIT";
    ParcelSplitProcess[ParcelSplitProcess["PARCEL_SPLIT_FINISHED"] = 3] = "PARCEL_SPLIT_FINISHED";
    ParcelSplitProcess[ParcelSplitProcess["REQUEST_SUCCESS"] = 2] = "REQUEST_SUCCESS";
    ParcelSplitProcess[ParcelSplitProcess["SPLIT_INFO_SAVED"] = 1] = "SPLIT_INFO_SAVED";
})(ParcelSplitProcess || (ParcelSplitProcess = {}));
export var PdaPackageNeedUpgradeType;
(function (PdaPackageNeedUpgradeType) {
    PdaPackageNeedUpgradeType[PdaPackageNeedUpgradeType["No"] = 0] = "No";
    PdaPackageNeedUpgradeType[PdaPackageNeedUpgradeType["Yes"] = 1] = "Yes";
})(PdaPackageNeedUpgradeType || (PdaPackageNeedUpgradeType = {}));
export var PdaPackageStatusType;
(function (PdaPackageStatusType) {
    PdaPackageStatusType[PdaPackageStatusType["Active"] = 1] = "Active";
    PdaPackageStatusType[PdaPackageStatusType["Expiry"] = 2] = "Expiry";
    PdaPackageStatusType[PdaPackageStatusType["Pending"] = 0] = "Pending";
})(PdaPackageStatusType || (PdaPackageStatusType = {}));
export var PdaPackageUpdateModeType;
(function (PdaPackageUpdateModeType) {
    PdaPackageUpdateModeType[PdaPackageUpdateModeType["Mandatory"] = 1] = "Mandatory";
    PdaPackageUpdateModeType[PdaPackageUpdateModeType["Optional"] = 0] = "Optional";
})(PdaPackageUpdateModeType || (PdaPackageUpdateModeType = {}));
export var PdaTaskCenterType;
(function (PdaTaskCenterType) {
    PdaTaskCenterType[PdaTaskCenterType["Cycle_Counting_Task"] = 7] = "Cycle_Counting_Task";
    PdaTaskCenterType[PdaTaskCenterType["Merging_Task"] = 9] = "Merging_Task";
    PdaTaskCenterType[PdaTaskCenterType["Picking_Task"] = 4] = "Picking_Task";
    PdaTaskCenterType[PdaTaskCenterType["Putaway_Task"] = 3] = "Putaway_Task";
    PdaTaskCenterType[PdaTaskCenterType["QC_Task"] = 1] = "QC_Task";
    PdaTaskCenterType[PdaTaskCenterType["Rack_Transfer_Task"] = 8] = "Rack_Transfer_Task";
    PdaTaskCenterType[PdaTaskCenterType["Receiving_Task"] = 2] = "Receiving_Task";
    PdaTaskCenterType[PdaTaskCenterType["Shipping_Task"] = 6] = "Shipping_Task";
    PdaTaskCenterType[PdaTaskCenterType["Sorting_Task"] = 5] = "Sorting_Task";
    PdaTaskCenterType[PdaTaskCenterType["Transiting_Task"] = 10] = "Transiting_Task";
})(PdaTaskCenterType || (PdaTaskCenterType = {}));
export var PickOrderType;
(function (PickOrderType) {
    PickOrderType[PickOrderType["Mass_Order"] = 1] = "Mass_Order";
    PickOrderType[PickOrderType["SBS"] = 0] = "SBS";
})(PickOrderType || (PickOrderType = {}));
export var PickPlanRelationType;
(function (PickPlanRelationType) {
    PickPlanRelationType[PickPlanRelationType["Pick"] = 0] = "Pick";
    PickPlanRelationType[PickPlanRelationType["RTS"] = 1] = "RTS";
})(PickPlanRelationType || (PickPlanRelationType = {}));
export var PickStatus;
(function (PickStatus) {
    PickStatus[PickStatus["Pending"] = 0] = "Pending";
    PickStatus[PickStatus["Assigned"] = 10] = "Assigned";
    PickStatus[PickStatus["Ongoing"] = 1] = "Ongoing";
    PickStatus[PickStatus["Done"] = 2] = "Done";
    PickStatus[PickStatus["Failed"] = 3] = "Failed";
    PickStatus[PickStatus["Cancel"] = 9] = "Cancel";
})(PickStatus || (PickStatus = {}));
export var PickingAssignmentMode;
(function (PickingAssignmentMode) {
    PickingAssignmentMode[PickingAssignmentMode["Manual"] = 2] = "Manual";
    PickingAssignmentMode[PickingAssignmentMode["System"] = 1] = "System";
})(PickingAssignmentMode || (PickingAssignmentMode = {}));
export var PickingPriorityFlag;
(function (PickingPriorityFlag) {
    PickingPriorityFlag[PickingPriorityFlag["Normal"] = 2] = "Normal";
    PickingPriorityFlag[PickingPriorityFlag["On_hold"] = 1] = "On_hold";
    PickingPriorityFlag[PickingPriorityFlag["Priority"] = 3] = "Priority";
})(PickingPriorityFlag || (PickingPriorityFlag = {}));
export var PickingTaskBusinessType;
(function (PickingTaskBusinessType) {
    PickingTaskBusinessType[PickingTaskBusinessType["RTS_Outbound"] = 1] = "RTS_Outbound";
    PickingTaskBusinessType[PickingTaskBusinessType["Sales_Outbound"] = 0] = "Sales_Outbound";
})(PickingTaskBusinessType || (PickingTaskBusinessType = {}));
export var PickingTaskMassOrderFlag;
(function (PickingTaskMassOrderFlag) {
    PickingTaskMassOrderFlag[PickingTaskMassOrderFlag["Y"] = 1] = "Y";
    PickingTaskMassOrderFlag[PickingTaskMassOrderFlag["N"] = 0] = "N";
})(PickingTaskMassOrderFlag || (PickingTaskMassOrderFlag = {}));
export var PickupMethod;
(function (PickupMethod) {
    PickupMethod[PickupMethod["BLOCKED"] = 3] = "BLOCKED";
    PickupMethod[PickupMethod["PICKING_LIST"] = 1] = "PICKING_LIST";
    PickupMethod[PickupMethod["TASK"] = 2] = "TASK";
    PickupMethod[PickupMethod["UNDEFINED"] = 0] = "UNDEFINED";
})(PickupMethod || (PickupMethod = {}));
export var PickupType;
(function (PickupType) {
    PickupType[PickupType["MIX_AREA"] = 3] = "MIX_AREA";
    PickupType[PickupType["PICKING_AREA"] = 1] = "PICKING_AREA";
    PickupType[PickupType["STORAGE_AREA"] = 2] = "STORAGE_AREA";
    PickupType[PickupType["UNDEFINED"] = 0] = "UNDEFINED";
})(PickupType || (PickupType = {}));
export var PrintMethod;
(function (PrintMethod) {
    PrintMethod[PrintMethod["Batch_Print_In_Order"] = 1] = "Batch_Print_In_Order";
    PrintMethod[PrintMethod["Manual_Input_Printing"] = 2] = "Manual_Input_Printing";
})(PrintMethod || (PrintMethod = {}));
export var PrintPaperType;
(function (PrintPaperType) {
    PrintPaperType["Barcode_Label"] = "Barcode Label";
    PrintPaperType["Invoice"] = "Invoice";
    PrintPaperType["List"] = "List";
    PrintPaperType["Waybill_Label"] = "Waybill Label";
})(PrintPaperType || (PrintPaperType = {}));
export var PrintSettingOrder;
(function (PrintSettingOrder) {
    PrintSettingOrder[PrintSettingOrder["ASC"] = 0] = "ASC";
    PrintSettingOrder[PrintSettingOrder["DESC"] = 1] = "DESC";
})(PrintSettingOrder || (PrintSettingOrder = {}));
export var PrintSettingOrderKey;
(function (PrintSettingOrderKey) {
    PrintSettingOrderKey[PrintSettingOrderKey["Mtime"] = 1] = "Mtime";
})(PrintSettingOrderKey || (PrintSettingOrderKey = {}));
export var PrintSettingsAvailable;
(function (PrintSettingsAvailable) {
    PrintSettingsAvailable[PrintSettingsAvailable["Disabled"] = 0] = "Disabled";
    PrintSettingsAvailable[PrintSettingsAvailable["Enabled"] = 1] = "Enabled";
})(PrintSettingsAvailable || (PrintSettingsAvailable = {}));
export var PrintSettingsLevelType;
(function (PrintSettingsLevelType) {
    PrintSettingsLevelType[PrintSettingsLevelType["All"] = 0] = "All";
    PrintSettingsLevelType[PrintSettingsLevelType["Region"] = 1] = "Region";
    PrintSettingsLevelType[PrintSettingsLevelType["Warehouse"] = 2] = "Warehouse";
})(PrintSettingsLevelType || (PrintSettingsLevelType = {}));
export var PrintSettingsPaperType;
(function (PrintSettingsPaperType) {
    PrintSettingsPaperType[PrintSettingsPaperType["Barcode"] = 0] = "Barcode";
    PrintSettingsPaperType[PrintSettingsPaperType["Label"] = 1] = "Label";
    PrintSettingsPaperType[PrintSettingsPaperType["List"] = 2] = "List";
    PrintSettingsPaperType[PrintSettingsPaperType["Waybill"] = 3] = "Waybill";
})(PrintSettingsPaperType || (PrintSettingsPaperType = {}));
export var PushSpxStatus;
(function (PushSpxStatus) {
    PushSpxStatus[PushSpxStatus["Done"] = 2] = "Done";
    PushSpxStatus[PushSpxStatus["Ongoing"] = 1] = "Ongoing";
    PushSpxStatus[PushSpxStatus["Pending"] = 0] = "Pending";
})(PushSpxStatus || (PushSpxStatus = {}));
export var PutawayOnhold;
(function (PutawayOnhold) {
    PutawayOnhold[PutawayOnhold["NO"] = 0] = "NO";
    PutawayOnhold[PutawayOnhold["YES"] = 1] = "YES";
})(PutawayOnhold || (PutawayOnhold = {}));
export var PutawayRuleSheetType;
(function (PutawayRuleSheetType) {
    PutawayRuleSheetType[PutawayRuleSheetType["INBOUND"] = 0] = "INBOUND";
    PutawayRuleSheetType[PutawayRuleSheetType["RACK_TRANSFER"] = 2] = "RACK_TRANSFER";
    PutawayRuleSheetType[PutawayRuleSheetType["RETURN_INBOUND"] = 1] = "RETURN_INBOUND";
})(PutawayRuleSheetType || (PutawayRuleSheetType = {}));
export var PutawayTaskAssignMode;
(function (PutawayTaskAssignMode) {
    PutawayTaskAssignMode[PutawayTaskAssignMode["AUTO_ASSIGN"] = 1] = "AUTO_ASSIGN";
    PutawayTaskAssignMode[PutawayTaskAssignMode["MANUAL_ASSIGN"] = 2] = "MANUAL_ASSIGN";
    PutawayTaskAssignMode[PutawayTaskAssignMode["SCAN_DEVICE"] = 3] = "SCAN_DEVICE";
})(PutawayTaskAssignMode || (PutawayTaskAssignMode = {}));
export var PutawayTaskAssignRuleCondition;
(function (PutawayTaskAssignRuleCondition) {
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["IS_BULKY_SKU"] = 3] = "IS_BULKY_SKU";
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["IS_HEAVY_SKU"] = 5] = "IS_HEAVY_SKU";
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["IS_HIGH_VALUE_SKU"] = 2] = "IS_HIGH_VALUE_SKU";
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["IS_OOS_IN_WHS_SKU"] = 4] = "IS_OOS_IN_WHS_SKU";
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["IS_TEMPERATURE_CONTROLLED_SKU"] = 1] = "IS_TEMPERATURE_CONTROLLED_SKU";
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["SHOP_LIST"] = 7] = "SHOP_LIST";
    PutawayTaskAssignRuleCondition[PutawayTaskAssignRuleCondition["SUPPLIER_LIST"] = 6] = "SUPPLIER_LIST";
})(PutawayTaskAssignRuleCondition || (PutawayTaskAssignRuleCondition = {}));
export var PutawayTaskEvent;
(function (PutawayTaskEvent) {
    PutawayTaskEvent[PutawayTaskEvent["ASSIGNED_BY"] = 6] = "ASSIGNED_BY";
    PutawayTaskEvent[PutawayTaskEvent["ASSIGNMENT_MODE"] = 5] = "ASSIGNMENT_MODE";
    PutawayTaskEvent[PutawayTaskEvent["DEVICE_TRANSFER"] = 4] = "DEVICE_TRANSFER";
    PutawayTaskEvent[PutawayTaskEvent["IS_ON_HOLD"] = 3] = "IS_ON_HOLD";
    PutawayTaskEvent[PutawayTaskEvent["IS_URGENT"] = 2] = "IS_URGENT";
    PutawayTaskEvent[PutawayTaskEvent["PUTAWAY_BY"] = 1] = "PUTAWAY_BY";
    PutawayTaskEvent[PutawayTaskEvent["STATUS"] = 7] = "STATUS";
})(PutawayTaskEvent || (PutawayTaskEvent = {}));
export var PutawayTaskOrder;
(function (PutawayTaskOrder) {
    PutawayTaskOrder[PutawayTaskOrder["ASC"] = 0] = "ASC";
    PutawayTaskOrder[PutawayTaskOrder["DESC"] = 1] = "DESC";
})(PutawayTaskOrder || (PutawayTaskOrder = {}));
export var PutawayTaskOrderKey;
(function (PutawayTaskOrderKey) {
    PutawayTaskOrderKey[PutawayTaskOrderKey["CREATE_TIME"] = 2] = "CREATE_TIME";
    PutawayTaskOrderKey[PutawayTaskOrderKey["TIME_SINCE_CREATION"] = 1] = "TIME_SINCE_CREATION";
    PutawayTaskOrderKey[PutawayTaskOrderKey["TIME_SINCE_LAST_MODIFIED"] = 3] = "TIME_SINCE_LAST_MODIFIED";
})(PutawayTaskOrderKey || (PutawayTaskOrderKey = {}));
export var PutawayTaskPriority;
(function (PutawayTaskPriority) {
    PutawayTaskPriority[PutawayTaskPriority["HIGH"] = 2] = "HIGH";
    PutawayTaskPriority[PutawayTaskPriority["MEDIUM"] = 1] = "MEDIUM";
})(PutawayTaskPriority || (PutawayTaskPriority = {}));
export var PutawayTaskSheetType;
(function (PutawayTaskSheetType) {
    PutawayTaskSheetType[PutawayTaskSheetType["INBOUND_ORDER"] = 1] = "INBOUND_ORDER";
    PutawayTaskSheetType[PutawayTaskSheetType["MOVE_TRANSFER_INBOUND_RECEIVE_TASK"] = 4] = "MOVE_TRANSFER_INBOUND_RECEIVE_TASK";
    PutawayTaskSheetType[PutawayTaskSheetType["ONE_STEP_RACK_TRANSFER_PICKING_TASK"] = 5] = "ONE_STEP_RACK_TRANSFER_PICKING_TASK";
    PutawayTaskSheetType[PutawayTaskSheetType["PO_INBOUND_RECEIVE_TASK"] = 2] = "PO_INBOUND_RECEIVE_TASK";
    PutawayTaskSheetType[PutawayTaskSheetType["RETURN_INBOUND_RECEIVE_TASK"] = 3] = "RETURN_INBOUND_RECEIVE_TASK";
    PutawayTaskSheetType[PutawayTaskSheetType["TWO_SETP_RACK_TRANSFER_PICKING_TASK"] = 6] = "TWO_SETP_RACK_TRANSFER_PICKING_TASK";
})(PutawayTaskSheetType || (PutawayTaskSheetType = {}));
export var PutawayTaskSkuStatus;
(function (PutawayTaskSkuStatus) {
    PutawayTaskSkuStatus[PutawayTaskSkuStatus["CANCELLED"] = 90] = "CANCELLED";
    PutawayTaskSkuStatus[PutawayTaskSkuStatus["DONE"] = 80] = "DONE";
    PutawayTaskSkuStatus[PutawayTaskSkuStatus["PENDING"] = 10] = "PENDING";
})(PutawayTaskSkuStatus || (PutawayTaskSkuStatus = {}));
export var PutawayTaskSource;
(function (PutawayTaskSource) {
    PutawayTaskSource[PutawayTaskSource["MOVE_TRANSFER_INBOUND_RECEIVED"] = 3] = "MOVE_TRANSFER_INBOUND_RECEIVED";
    PutawayTaskSource[PutawayTaskSource["ONE_STEP_RACK_TRANSFER_PICKING"] = 4] = "ONE_STEP_RACK_TRANSFER_PICKING";
    PutawayTaskSource[PutawayTaskSource["PO_INBOUND_RECEIVED"] = 1] = "PO_INBOUND_RECEIVED";
    PutawayTaskSource[PutawayTaskSource["RETURN_INBOUND_RECEIVED"] = 2] = "RETURN_INBOUND_RECEIVED";
    PutawayTaskSource[PutawayTaskSource["TWO_SETP_RACK_TRANSFER_PICKING"] = 5] = "TWO_SETP_RACK_TRANSFER_PICKING";
})(PutawayTaskSource || (PutawayTaskSource = {}));
export var PutawayTaskStatus;
(function (PutawayTaskStatus) {
    PutawayTaskStatus[PutawayTaskStatus["PENDING"] = 10] = "PENDING";
    PutawayTaskStatus[PutawayTaskStatus["ASSIGNED"] = 100] = "ASSIGNED";
    PutawayTaskStatus[PutawayTaskStatus["ONGOING"] = 20] = "ONGOING";
    PutawayTaskStatus[PutawayTaskStatus["DONE"] = 80] = "DONE";
})(PutawayTaskStatus || (PutawayTaskStatus = {}));
export var PutawayTaskType;
(function (PutawayTaskType) {
    PutawayTaskType[PutawayTaskType["MOVE_TRANSFER_INBOUND"] = 3] = "MOVE_TRANSFER_INBOUND";
    PutawayTaskType[PutawayTaskType["PO_INBOUND"] = 1] = "PO_INBOUND";
    PutawayTaskType[PutawayTaskType["RETURN_INBOUND"] = 2] = "RETURN_INBOUND";
    PutawayTaskType[PutawayTaskType["THREE_SETP_RACK_TRANSFER_PUTAWAY"] = 7] = "THREE_SETP_RACK_TRANSFER_PUTAWAY";
    PutawayTaskType[PutawayTaskType["THREE_SETP_RACK_TRANSFER_PUTBACK"] = 8] = "THREE_SETP_RACK_TRANSFER_PUTBACK";
    PutawayTaskType[PutawayTaskType["TWOSETP_RACK_TRANSFER"] = 5] = "TWOSETP_RACK_TRANSFER";
})(PutawayTaskType || (PutawayTaskType = {}));
export var PutawayType;
(function (PutawayType) {
    PutawayType[PutawayType["ALL"] = 3] = "ALL";
    PutawayType[PutawayType["MASS_PUTAWAY"] = 1] = "MASS_PUTAWAY";
    PutawayType[PutawayType["STANDARD_PUTAWAY"] = 2] = "STANDARD_PUTAWAY";
})(PutawayType || (PutawayType = {}));
export var PutawayUrgent;
(function (PutawayUrgent) {
    PutawayUrgent[PutawayUrgent["NO"] = 0] = "NO";
    PutawayUrgent[PutawayUrgent["YES"] = 1] = "YES";
})(PutawayUrgent || (PutawayUrgent = {}));
export var QCRuleType;
(function (QCRuleType) {
    QCRuleType[QCRuleType["AQL"] = 3] = "AQL";
    QCRuleType[QCRuleType["PERCENTAGE__BASED"] = 2] = "PERCENTAGE__BASED";
    QCRuleType[QCRuleType["SKU__ATTRIBUTES"] = 1] = "SKU__ATTRIBUTES";
})(QCRuleType || (QCRuleType = {}));
export var QcMoveTransferInboundDamageType;
(function (QcMoveTransferInboundDamageType) {
    QcMoveTransferInboundDamageType[QcMoveTransferInboundDamageType["Damaged"] = 3] = "Damaged";
    QcMoveTransferInboundDamageType[QcMoveTransferInboundDamageType["Expired"] = 2] = "Expired";
    QcMoveTransferInboundDamageType[QcMoveTransferInboundDamageType["Expiring"] = 1] = "Expiring";
})(QcMoveTransferInboundDamageType || (QcMoveTransferInboundDamageType = {}));
export var QcPoInboundDamageType;
(function (QcPoInboundDamageType) {
    QcPoInboundDamageType[QcPoInboundDamageType["Shopee_Rejected_Other_Issues"] = 5] = "Shopee_Rejected_Other_Issues";
    QcPoInboundDamageType[QcPoInboundDamageType["Supplier_QC_Failed_Abnormal_Item"] = 4] = "Supplier_QC_Failed_Abnormal_Item";
    QcPoInboundDamageType[QcPoInboundDamageType["Supplier_QC_Failed_Damaged"] = 3] = "Supplier_QC_Failed_Damaged";
    QcPoInboundDamageType[QcPoInboundDamageType["Supplier_QC_Failed_Expired"] = 1] = "Supplier_QC_Failed_Expired";
    QcPoInboundDamageType[QcPoInboundDamageType["Supplier_QC_Failed_Expiring"] = 2] = "Supplier_QC_Failed_Expiring";
    QcPoInboundDamageType[QcPoInboundDamageType["Supplier_Rejected_Other_Issues"] = 6] = "Supplier_Rejected_Other_Issues";
})(QcPoInboundDamageType || (QcPoInboundDamageType = {}));
export var QcReturnInboundDamageType;
(function (QcReturnInboundDamageType) {
    QcReturnInboundDamageType[QcReturnInboundDamageType["Cannot_QC"] = 1] = "Cannot_QC";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Expired"] = 4] = "Expired";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Expiring"] = 3] = "Expiring";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Manufacturing_Defect"] = 2] = "Manufacturing_Defect";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Physical_Damage_3PL_Error"] = 6] = "Physical_Damage_3PL_Error";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Physical_Damage_WH_Error"] = 5] = "Physical_Damage_WH_Error";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Physical_Damage_WH_3PL_Error"] = 7] = "Physical_Damage_WH_3PL_Error";
    QcReturnInboundDamageType[QcReturnInboundDamageType["Wrong_description"] = 8] = "Wrong_description";
})(QcReturnInboundDamageType || (QcReturnInboundDamageType = {}));
export var QcTaskAssignMode;
(function (QcTaskAssignMode) {
    QcTaskAssignMode[QcTaskAssignMode["Auto_Assign"] = 1] = "Auto_Assign";
    QcTaskAssignMode[QcTaskAssignMode["Manual_Assign"] = 2] = "Manual_Assign";
})(QcTaskAssignMode || (QcTaskAssignMode = {}));
export var QcTaskModifyLogEvent;
(function (QcTaskModifyLogEvent) {
    QcTaskModifyLogEvent[QcTaskModifyLogEvent["Status"] = 1] = "Status";
})(QcTaskModifyLogEvent || (QcTaskModifyLogEvent = {}));
export var QcTaskOnHold;
(function (QcTaskOnHold) {
    QcTaskOnHold[QcTaskOnHold["NO"] = 0] = "NO";
    QcTaskOnHold[QcTaskOnHold["YES"] = 1] = "YES";
})(QcTaskOnHold || (QcTaskOnHold = {}));
export var QcTaskOrder;
(function (QcTaskOrder) {
    QcTaskOrder[QcTaskOrder["ASC"] = 0] = "ASC";
    QcTaskOrder[QcTaskOrder["DESC"] = 1] = "DESC";
})(QcTaskOrder || (QcTaskOrder = {}));
export var QcTaskOrderKey;
(function (QcTaskOrderKey) {
    QcTaskOrderKey[QcTaskOrderKey["CreateTime"] = 2] = "CreateTime";
    QcTaskOrderKey[QcTaskOrderKey["TimeSinceCreation"] = 1] = "TimeSinceCreation";
    QcTaskOrderKey[QcTaskOrderKey["TimeSinceLastModified"] = 3] = "TimeSinceLastModified";
})(QcTaskOrderKey || (QcTaskOrderKey = {}));
export var QcTaskPriority;
(function (QcTaskPriority) {
    QcTaskPriority[QcTaskPriority["HIGH"] = 2] = "HIGH";
    QcTaskPriority[QcTaskPriority["MEDIUM"] = 1] = "MEDIUM";
})(QcTaskPriority || (QcTaskPriority = {}));
export var QcTaskSheetType;
(function (QcTaskSheetType) {
    QcTaskSheetType[QcTaskSheetType["Move_Transfer_Inbound_Arriving"] = 3] = "Move_Transfer_Inbound_Arriving";
    QcTaskSheetType[QcTaskSheetType["Po_Inbound_Counting"] = 1] = "Po_Inbound_Counting";
    QcTaskSheetType[QcTaskSheetType["Return_Inbound_Arriving"] = 2] = "Return_Inbound_Arriving";
})(QcTaskSheetType || (QcTaskSheetType = {}));
export var QcTaskStatus;
(function (QcTaskStatus) {
    QcTaskStatus[QcTaskStatus["PENDING"] = 10] = "PENDING";
    QcTaskStatus[QcTaskStatus["ASSIGNED"] = 100] = "ASSIGNED";
    QcTaskStatus[QcTaskStatus["ONGOING"] = 20] = "ONGOING";
    QcTaskStatus[QcTaskStatus["DONE"] = 80] = "DONE";
    QcTaskStatus[QcTaskStatus["CANCELLED"] = 90] = "CANCELLED";
})(QcTaskStatus || (QcTaskStatus = {}));
export var QcTaskType;
(function (QcTaskType) {
    QcTaskType[QcTaskType["Move_Transfer_Inbound"] = 3] = "Move_Transfer_Inbound";
    QcTaskType[QcTaskType["Po_Inbound"] = 1] = "Po_Inbound";
    QcTaskType[QcTaskType["Return_Inbound"] = 2] = "Return_Inbound";
})(QcTaskType || (QcTaskType = {}));
export var QcTaskUrgent;
(function (QcTaskUrgent) {
    QcTaskUrgent[QcTaskUrgent["NO"] = 0] = "NO";
    QcTaskUrgent[QcTaskUrgent["YES"] = 1] = "YES";
})(QcTaskUrgent || (QcTaskUrgent = {}));
export var RTExceptionDealMethod;
(function (RTExceptionDealMethod) {
    RTExceptionDealMethod[RTExceptionDealMethod["CONFIRM_LOST"] = 1] = "CONFIRM_LOST";
    RTExceptionDealMethod[RTExceptionDealMethod["CREATE_RT_ORDER"] = 2] = "CREATE_RT_ORDER";
})(RTExceptionDealMethod || (RTExceptionDealMethod = {}));
export var RTExceptionDealTaskType;
(function (RTExceptionDealTaskType) {
    RTExceptionDealTaskType[RTExceptionDealTaskType["INVENTORY_ADJUST"] = 1] = "INVENTORY_ADJUST";
    RTExceptionDealTaskType[RTExceptionDealTaskType["RACK_TRANSFER"] = 2] = "RACK_TRANSFER";
})(RTExceptionDealTaskType || (RTExceptionDealTaskType = {}));
export var RTExceptionSkuStatus;
(function (RTExceptionSkuStatus) {
    RTExceptionSkuStatus[RTExceptionSkuStatus["CANCEL"] = 4] = "CANCEL";
    RTExceptionSkuStatus[RTExceptionSkuStatus["DONE"] = 3] = "DONE";
    RTExceptionSkuStatus[RTExceptionSkuStatus["ONGOING"] = 2] = "ONGOING";
    RTExceptionSkuStatus[RTExceptionSkuStatus["PENDING"] = 1] = "PENDING";
})(RTExceptionSkuStatus || (RTExceptionSkuStatus = {}));
export var RTExceptionSkuSubStatus;
(function (RTExceptionSkuSubStatus) {
    RTExceptionSkuSubStatus[RTExceptionSkuSubStatus["CANCEL"] = 4] = "CANCEL";
    RTExceptionSkuSubStatus[RTExceptionSkuSubStatus["DONE"] = 3] = "DONE";
    RTExceptionSkuSubStatus[RTExceptionSkuSubStatus["LOST"] = 1] = "LOST";
    RTExceptionSkuSubStatus[RTExceptionSkuSubStatus["ONGOING"] = 2] = "ONGOING";
})(RTExceptionSkuSubStatus || (RTExceptionSkuSubStatus = {}));
export var RTExceptionSkuUidStatus;
(function (RTExceptionSkuUidStatus) {
    RTExceptionSkuUidStatus[RTExceptionSkuUidStatus["N"] = 2] = "N";
    RTExceptionSkuUidStatus[RTExceptionSkuUidStatus["Y"] = 1] = "Y";
})(RTExceptionSkuUidStatus || (RTExceptionSkuUidStatus = {}));
export var RTSAllocateMode;
(function (RTSAllocateMode) {
    RTSAllocateMode[RTSAllocateMode["Locked_Mode"] = 1] = "Locked_Mode";
    RTSAllocateMode[RTSAllocateMode["No_Allocate"] = 0] = "No_Allocate";
    RTSAllocateMode[RTSAllocateMode["Pre_Picked_Mode"] = 2] = "Pre_Picked_Mode";
})(RTSAllocateMode || (RTSAllocateMode = {}));
export var RTSCreateChannel;
(function (RTSCreateChannel) {
    RTSCreateChannel[RTSCreateChannel["PMS"] = 1] = "PMS";
    RTSCreateChannel[RTSCreateChannel["WMS"] = 0] = "WMS";
})(RTSCreateChannel || (RTSCreateChannel = {}));
export var RTSDamageTYpe;
(function (RTSDamageTYpe) {
    RTSDamageTYpe[RTSDamageTYpe["DAMAGED"] = 1] = "DAMAGED";
    RTSDamageTYpe[RTSDamageTYpe["EXPIRED"] = 2] = "EXPIRED";
})(RTSDamageTYpe || (RTSDamageTYpe = {}));
export var RTSDeliveryMethod;
(function (RTSDeliveryMethod) {
    RTSDeliveryMethod[RTSDeliveryMethod["Pick_Up_By_Supplier_During_Inbound"] = 2] = "Pick_Up_By_Supplier_During_Inbound";
    RTSDeliveryMethod[RTSDeliveryMethod["Pick_Up_By_Suppliers"] = 1] = "Pick_Up_By_Suppliers";
    RTSDeliveryMethod[RTSDeliveryMethod["Shopee_Sends_Back"] = 3] = "Shopee_Sends_Back";
    RTSDeliveryMethod[RTSDeliveryMethod["Throw_Away"] = 4] = "Throw_Away";
})(RTSDeliveryMethod || (RTSDeliveryMethod = {}));
export var RTSOrderStatus;
(function (RTSOrderStatus) {
    RTSOrderStatus[RTSOrderStatus["Pending"] = 7] = "Pending";
    RTSOrderStatus[RTSOrderStatus["Allocated"] = 11] = "Allocated";
    RTSOrderStatus[RTSOrderStatus["Approving"] = 9] = "Approving";
    RTSOrderStatus[RTSOrderStatus["Approved"] = 10] = "Approved";
    RTSOrderStatus[RTSOrderStatus["Rejected"] = 8] = "Rejected";
    RTSOrderStatus[RTSOrderStatus["Pending_Pick"] = 12] = "Pending_Pick";
    RTSOrderStatus[RTSOrderStatus["Picking"] = 2] = "Picking";
    RTSOrderStatus[RTSOrderStatus["Picked"] = 3] = "Picked";
    RTSOrderStatus[RTSOrderStatus["Shipping"] = 13] = "Shipping";
    RTSOrderStatus[RTSOrderStatus["Outbound"] = 6] = "Outbound";
    RTSOrderStatus[RTSOrderStatus["Canceled"] = 1] = "Canceled";
})(RTSOrderStatus || (RTSOrderStatus = {}));
export var RTSPrintTag;
(function (RTSPrintTag) {
    RTSPrintTag[RTSPrintTag["Y"] = 1] = "Y";
    RTSPrintTag[RTSPrintTag["N"] = 0] = "N";
})(RTSPrintTag || (RTSPrintTag = {}));
export var RTSReasonType;
(function (RTSReasonType) {
    RTSReasonType["AGING"] = "R2";
    RTSReasonType["BUYER_RETURN"] = "R5";
    RTSReasonType["DAMAGED__EXPIRY"] = "R9";
    RTSReasonType["DAMAGED_ITEMS"] = "RP1";
    RTSReasonType["EXPIRED"] = "R4";
    RTSReasonType["EXPIRING"] = "R7";
    RTSReasonType["MANUFACTURING_DEFECTIVE"] = "R3";
    RTSReasonType["OTHERS"] = "R6,VR-IB1,VR-IB2,VR-IB3";
    RTSReasonType["SELLER_REQUEST"] = "R8";
    RTSReasonType["SUPPLIER_REQUEST"] = "R1";
})(RTSReasonType || (RTSReasonType = {}));
export var RTSType;
(function (RTSType) {
    RTSType[RTSType["Real_RTS"] = 20] = "Real_RTS";
    RTSType[RTSType["Replacement"] = 50] = "Replacement";
    RTSType[RTSType["Virtual_RTS_With_ReInbound"] = 30] = "Virtual_RTS_With_ReInbound";
})(RTSType || (RTSType = {}));
export var RackTransferAssignmentMode;
(function (RackTransferAssignmentMode) {
    RackTransferAssignmentMode[RackTransferAssignmentMode["AUTO_ASSIGNED"] = 2] = "AUTO_ASSIGNED";
    RackTransferAssignmentMode[RackTransferAssignmentMode["MANUAL_ASSIGNED"] = 3] = "MANUAL_ASSIGNED";
    RackTransferAssignmentMode[RackTransferAssignmentMode["SCAN_TASK"] = 1] = "SCAN_TASK";
})(RackTransferAssignmentMode || (RackTransferAssignmentMode = {}));
export var RackTransferExportStatus;
(function (RackTransferExportStatus) {
    RackTransferExportStatus[RackTransferExportStatus["Created"] = 1] = "Created";
    RackTransferExportStatus[RackTransferExportStatus["Pending"] = 2] = "Pending";
    RackTransferExportStatus[RackTransferExportStatus["Locked"] = 3] = "Locked";
    RackTransferExportStatus[RackTransferExportStatus["OnGoing"] = 4] = "OnGoing";
    RackTransferExportStatus[RackTransferExportStatus["Done"] = 5] = "Done";
    RackTransferExportStatus[RackTransferExportStatus["Cancel"] = 6] = "Cancel";
    RackTransferExportStatus[RackTransferExportStatus["Incomplete"] = 7] = "Incomplete";
})(RackTransferExportStatus || (RackTransferExportStatus = {}));
export var RackTransferOneStepSubTaskStatus;
(function (RackTransferOneStepSubTaskStatus) {
    RackTransferOneStepSubTaskStatus[RackTransferOneStepSubTaskStatus["CANCEL"] = 5] = "CANCEL";
    RackTransferOneStepSubTaskStatus[RackTransferOneStepSubTaskStatus["DONE"] = 4] = "DONE";
    RackTransferOneStepSubTaskStatus[RackTransferOneStepSubTaskStatus["LOCKED"] = 2] = "LOCKED";
    RackTransferOneStepSubTaskStatus[RackTransferOneStepSubTaskStatus["ONGOING"] = 3] = "ONGOING";
    RackTransferOneStepSubTaskStatus[RackTransferOneStepSubTaskStatus["PENDING"] = 1] = "PENDING";
})(RackTransferOneStepSubTaskStatus || (RackTransferOneStepSubTaskStatus = {}));
export var RackTransferTaskAssignStatus;
(function (RackTransferTaskAssignStatus) {
    RackTransferTaskAssignStatus[RackTransferTaskAssignStatus["ASSIGNED_TO_ME"] = 0] = "ASSIGNED_TO_ME";
    RackTransferTaskAssignStatus[RackTransferTaskAssignStatus["UNASSIGNED"] = 1] = "UNASSIGNED";
})(RackTransferTaskAssignStatus || (RackTransferTaskAssignStatus = {}));
export var RackTransferTaskBlockInv;
(function (RackTransferTaskBlockInv) {
    RackTransferTaskBlockInv[RackTransferTaskBlockInv["UNDEFINE"] = 0] = "UNDEFINE";
    RackTransferTaskBlockInv[RackTransferTaskBlockInv["N"] = 2] = "N";
    RackTransferTaskBlockInv[RackTransferTaskBlockInv["Y"] = 1] = "Y";
})(RackTransferTaskBlockInv || (RackTransferTaskBlockInv = {}));
export var RackTransferTaskDevice;
(function (RackTransferTaskDevice) {
    RackTransferTaskDevice[RackTransferTaskDevice["PC"] = 1] = "PC";
    RackTransferTaskDevice[RackTransferTaskDevice["PDA"] = 2] = "PDA";
})(RackTransferTaskDevice || (RackTransferTaskDevice = {}));
export var RackTransferTaskFrom;
(function (RackTransferTaskFrom) {
    RackTransferTaskFrom[RackTransferTaskFrom["MANUAL_CREATION"] = 1] = "MANUAL_CREATION";
    RackTransferTaskFrom[RackTransferTaskFrom["OB_TROUBLESHOOT"] = 6] = "OB_TROUBLESHOOT";
    RackTransferTaskFrom[RackTransferTaskFrom["PREHIT_RE_ARRANGEMENT"] = 8] = "PREHIT_RE_ARRANGEMENT";
    RackTransferTaskFrom[RackTransferTaskFrom["REARRANGEMENT_REPORT"] = 4] = "REARRANGEMENT_REPORT";
    RackTransferTaskFrom[RackTransferTaskFrom["REPLENISHMENT"] = 9] = "REPLENISHMENT";
    RackTransferTaskFrom[RackTransferTaskFrom["REPLENISHMENT_REPORT"] = 2] = "REPLENISHMENT_REPORT";
    RackTransferTaskFrom[RackTransferTaskFrom["REVERSE"] = 10] = "REVERSE";
    RackTransferTaskFrom[RackTransferTaskFrom["RT_TROUBLESHOOT"] = 5] = "RT_TROUBLESHOOT";
    RackTransferTaskFrom[RackTransferTaskFrom["SURPLUS_BALANCE"] = 7] = "SURPLUS_BALANCE";
    RackTransferTaskFrom[RackTransferTaskFrom["UPLOAD"] = 3] = "UPLOAD";
})(RackTransferTaskFrom || (RackTransferTaskFrom = {}));
export var RackTransferTaskIsOnHold;
(function (RackTransferTaskIsOnHold) {
    RackTransferTaskIsOnHold[RackTransferTaskIsOnHold["NO"] = 0] = "NO";
    RackTransferTaskIsOnHold[RackTransferTaskIsOnHold["YES"] = 1] = "YES";
})(RackTransferTaskIsOnHold || (RackTransferTaskIsOnHold = {}));
export var RackTransferTaskIsUrgent;
(function (RackTransferTaskIsUrgent) {
    RackTransferTaskIsUrgent[RackTransferTaskIsUrgent["NO"] = 0] = "NO";
    RackTransferTaskIsUrgent[RackTransferTaskIsUrgent["YES"] = 1] = "YES";
})(RackTransferTaskIsUrgent || (RackTransferTaskIsUrgent = {}));
export var RackTransferTaskMethod;
(function (RackTransferTaskMethod) {
    RackTransferTaskMethod[RackTransferTaskMethod["PARTIAL_LOCATION"] = 2] = "PARTIAL_LOCATION";
    RackTransferTaskMethod[RackTransferTaskMethod["WHOLE_LOCATION"] = 1] = "WHOLE_LOCATION";
})(RackTransferTaskMethod || (RackTransferTaskMethod = {}));
export var RackTransferTaskPriority;
(function (RackTransferTaskPriority) {
    RackTransferTaskPriority[RackTransferTaskPriority["HIGH"] = 1] = "HIGH";
    RackTransferTaskPriority[RackTransferTaskPriority["NORMAL"] = 0] = "NORMAL";
})(RackTransferTaskPriority || (RackTransferTaskPriority = {}));
export var RackTransferTaskStatus;
(function (RackTransferTaskStatus) {
    RackTransferTaskStatus[RackTransferTaskStatus["ASSIGNED"] = 8] = "ASSIGNED";
    RackTransferTaskStatus[RackTransferTaskStatus["CANCEL"] = 6] = "CANCEL";
    RackTransferTaskStatus[RackTransferTaskStatus["CREATED"] = 1] = "CREATED";
    RackTransferTaskStatus[RackTransferTaskStatus["DONE"] = 5] = "DONE";
    RackTransferTaskStatus[RackTransferTaskStatus["INCOMPLETE"] = 7] = "INCOMPLETE";
    RackTransferTaskStatus[RackTransferTaskStatus["LOCKED"] = 3] = "LOCKED";
    RackTransferTaskStatus[RackTransferTaskStatus["ON_GOING"] = 4] = "ON_GOING";
    RackTransferTaskStatus[RackTransferTaskStatus["PENDING"] = 2] = "PENDING";
    RackTransferTaskStatus[RackTransferTaskStatus["PENDING_PUTAWAY"] = 11] = "PENDING_PUTAWAY";
    RackTransferTaskStatus[RackTransferTaskStatus["PENDING_PUTBACK"] = 13] = "PENDING_PUTBACK";
    RackTransferTaskStatus[RackTransferTaskStatus["PICKED"] = 10] = "PICKED";
    RackTransferTaskStatus[RackTransferTaskStatus["PICKING"] = 9] = "PICKING";
    RackTransferTaskStatus[RackTransferTaskStatus["PUTAWAY"] = 12] = "PUTAWAY";
    RackTransferTaskStatus[RackTransferTaskStatus["PUTBACK"] = 14] = "PUTBACK";
})(RackTransferTaskStatus || (RackTransferTaskStatus = {}));
export var RackTransferTaskType;
(function (RackTransferTaskType) {
    RackTransferTaskType[RackTransferTaskType["ONE_STEP"] = 1] = "ONE_STEP";
    RackTransferTaskType[RackTransferTaskType["TWO_STEP"] = 2] = "TWO_STEP";
})(RackTransferTaskType || (RackTransferTaskType = {}));
export var RackTransferDeviceSubTaskStatus;
(function (RackTransferDeviceSubTaskStatus) {
    RackTransferDeviceSubTaskStatus[RackTransferDeviceSubTaskStatus["DONE"] = 4] = "DONE";
    RackTransferDeviceSubTaskStatus[RackTransferDeviceSubTaskStatus["PICKED"] = 2] = "PICKED";
    RackTransferDeviceSubTaskStatus[RackTransferDeviceSubTaskStatus["PICKING"] = 1] = "PICKING";
    RackTransferDeviceSubTaskStatus[RackTransferDeviceSubTaskStatus["PUTTING"] = 3] = "PUTTING";
})(RackTransferDeviceSubTaskStatus || (RackTransferDeviceSubTaskStatus = {}));
export var RackTransferTwoStepPickingTaskStatus;
(function (RackTransferTwoStepPickingTaskStatus) {
    RackTransferTwoStepPickingTaskStatus[RackTransferTwoStepPickingTaskStatus["CANCEL"] = 4] = "CANCEL";
    RackTransferTwoStepPickingTaskStatus[RackTransferTwoStepPickingTaskStatus["DONE"] = 3] = "DONE";
    RackTransferTwoStepPickingTaskStatus[RackTransferTwoStepPickingTaskStatus["ONGOING"] = 2] = "ONGOING";
    RackTransferTwoStepPickingTaskStatus[RackTransferTwoStepPickingTaskStatus["PENDING"] = 1] = "PENDING";
})(RackTransferTwoStepPickingTaskStatus || (RackTransferTwoStepPickingTaskStatus = {}));
export var RackTransferTwoStepStage;
(function (RackTransferTwoStepStage) {
    RackTransferTwoStepStage[RackTransferTwoStepStage["TWO_STEP_PICKING"] = 1] = "TWO_STEP_PICKING";
    RackTransferTwoStepStage[RackTransferTwoStepStage["TWO_STEP_PUTAWAY"] = 2] = "TWO_STEP_PUTAWAY";
})(RackTransferTwoStepStage || (RackTransferTwoStepStage = {}));
export var RackTransferTwoStepSubTaskStatus;
(function (RackTransferTwoStepSubTaskStatus) {
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["CANCEL"] = 7] = "CANCEL";
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["DONE"] = 6] = "DONE";
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["LOCKED"] = 2] = "LOCKED";
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["PENDING"] = 1] = "PENDING";
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["PICKED"] = 4] = "PICKED";
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["PICKING"] = 3] = "PICKING";
    RackTransferTwoStepSubTaskStatus[RackTransferTwoStepSubTaskStatus["PUTTING"] = 5] = "PUTTING";
})(RackTransferTwoStepSubTaskStatus || (RackTransferTwoStepSubTaskStatus = {}));
export var RackTransferWholeLocationSubTaskStatus;
(function (RackTransferWholeLocationSubTaskStatus) {
    RackTransferWholeLocationSubTaskStatus[RackTransferWholeLocationSubTaskStatus["DONE"] = 2] = "DONE";
    RackTransferWholeLocationSubTaskStatus[RackTransferWholeLocationSubTaskStatus["ONGOING"] = 1] = "ONGOING";
})(RackTransferWholeLocationSubTaskStatus || (RackTransferWholeLocationSubTaskStatus = {}));
export var ReQcType;
(function (ReQcType) {
    ReQcType[ReQcType["Change_SKU_quality"] = 1] = "Change_SKU_quality";
    ReQcType[ReQcType["Change_SKU_quantity"] = 2] = "Change_SKU_quantity";
    ReQcType[ReQcType["Others"] = 3] = "Others";
})(ReQcType || (ReQcType = {}));
export var RearrangementTaskStatus;
(function (RearrangementTaskStatus) {
    RearrangementTaskStatus[RearrangementTaskStatus["CREATED"] = 0] = "CREATED";
    RearrangementTaskStatus[RearrangementTaskStatus["DONE"] = 5] = "DONE";
    RearrangementTaskStatus[RearrangementTaskStatus["FAILED"] = 6] = "FAILED";
    RearrangementTaskStatus[RearrangementTaskStatus["NODETAIL"] = 2] = "NODETAIL";
    RearrangementTaskStatus[RearrangementTaskStatus["PARTIAL__CREATION"] = 4] = "PARTIAL__CREATION";
    RearrangementTaskStatus[RearrangementTaskStatus["PENDING"] = 3] = "PENDING";
    RearrangementTaskStatus[RearrangementTaskStatus["RUNNING"] = 1] = "RUNNING";
})(RearrangementTaskStatus || (RearrangementTaskStatus = {}));
export var RearrangementTaskType;
(function (RearrangementTaskType) {
    RearrangementTaskType[RearrangementTaskType["MIX__SKU__CONSOLIDATE"] = 2] = "MIX__SKU__CONSOLIDATE";
    RearrangementTaskType[RearrangementTaskType["SAME__SKU__CONSOLIDATE"] = 1] = "SAME__SKU__CONSOLIDATE";
    RearrangementTaskType[RearrangementTaskType["WRONG__ABC"] = 4] = "WRONG__ABC";
    RearrangementTaskType[RearrangementTaskType["WRONG__ACTIVITY__TYPE"] = 5] = "WRONG__ACTIVITY__TYPE";
    RearrangementTaskType[RearrangementTaskType["WRONG__BATCH"] = 6] = "WRONG__BATCH";
    RearrangementTaskType[RearrangementTaskType["WRONG__SUPER__A"] = 3] = "WRONG__SUPER__A";
})(RearrangementTaskType || (RearrangementTaskType = {}));
export var ReceiveTaskAssignMode;
(function (ReceiveTaskAssignMode) {
    ReceiveTaskAssignMode[ReceiveTaskAssignMode["AUTO_ASSIGN"] = 1] = "AUTO_ASSIGN";
    ReceiveTaskAssignMode[ReceiveTaskAssignMode["MANUAL_ASSIGN"] = 2] = "MANUAL_ASSIGN";
})(ReceiveTaskAssignMode || (ReceiveTaskAssignMode = {}));
export var ReceiveTaskModifyLogEvent;
(function (ReceiveTaskModifyLogEvent) {
    ReceiveTaskModifyLogEvent[ReceiveTaskModifyLogEvent["STATUS"] = 2] = "STATUS";
})(ReceiveTaskModifyLogEvent || (ReceiveTaskModifyLogEvent = {}));
export var ReceiveTaskOrder;
(function (ReceiveTaskOrder) {
    ReceiveTaskOrder[ReceiveTaskOrder["ASC"] = 0] = "ASC";
    ReceiveTaskOrder[ReceiveTaskOrder["DESC"] = 1] = "DESC";
})(ReceiveTaskOrder || (ReceiveTaskOrder = {}));
export var ReceiveTaskOrderKey;
(function (ReceiveTaskOrderKey) {
    ReceiveTaskOrderKey[ReceiveTaskOrderKey["CREATE_TIME"] = 2] = "CREATE_TIME";
    ReceiveTaskOrderKey[ReceiveTaskOrderKey["TIME_SINCE_CREATION"] = 1] = "TIME_SINCE_CREATION";
    ReceiveTaskOrderKey[ReceiveTaskOrderKey["TIME_SINCE_LAST_MODIFIED"] = 3] = "TIME_SINCE_LAST_MODIFIED";
})(ReceiveTaskOrderKey || (ReceiveTaskOrderKey = {}));
export var ReceiveTaskStatus;
(function (ReceiveTaskStatus) {
    ReceiveTaskStatus[ReceiveTaskStatus["PENDING"] = 10] = "PENDING";
    ReceiveTaskStatus[ReceiveTaskStatus["ASSIGNED"] = 100] = "ASSIGNED";
    ReceiveTaskStatus[ReceiveTaskStatus["ONGOING"] = 20] = "ONGOING";
    ReceiveTaskStatus[ReceiveTaskStatus["DONE"] = 80] = "DONE";
    ReceiveTaskStatus[ReceiveTaskStatus["CANCELLED"] = 90] = "CANCELLED";
})(ReceiveTaskStatus || (ReceiveTaskStatus = {}));
export var RecordingUnit;
(function (RecordingUnit) {
    RecordingUnit[RecordingUnit["BOXES"] = 2] = "BOXES";
    RecordingUnit[RecordingUnit["DRUMS"] = 5] = "DRUMS";
    RecordingUnit[RecordingUnit["PACKS"] = 4] = "PACKS";
    RecordingUnit[RecordingUnit["PCS"] = 1] = "PCS";
    RecordingUnit[RecordingUnit["ROLLS"] = 3] = "ROLLS";
    RecordingUnit[RecordingUnit["SETS"] = 7] = "SETS";
    RecordingUnit[RecordingUnit["UNITS"] = 6] = "UNITS";
})(RecordingUnit || (RecordingUnit = {}));
export var RefreshPlanQtyCalculateStrategy;
(function (RefreshPlanQtyCalculateStrategy) {
    RefreshPlanQtyCalculateStrategy[RefreshPlanQtyCalculateStrategy["AVAILABLE_STOCK"] = 1] = "AVAILABLE_STOCK";
    RefreshPlanQtyCalculateStrategy[RefreshPlanQtyCalculateStrategy["OOS_IN_PICKING_ONLY"] = 2] = "OOS_IN_PICKING_ONLY";
    RefreshPlanQtyCalculateStrategy[RefreshPlanQtyCalculateStrategy["TARGET_STOCK"] = 3] = "TARGET_STOCK";
    RefreshPlanQtyCalculateStrategy[RefreshPlanQtyCalculateStrategy["URGENT_OOS_IN_PICKING_ONLY"] = 4] = "URGENT_OOS_IN_PICKING_ONLY";
})(RefreshPlanQtyCalculateStrategy || (RefreshPlanQtyCalculateStrategy = {}));
export var RefreshOrderByStrategy;
(function (RefreshOrderByStrategy) {
    RefreshOrderByStrategy[RefreshOrderByStrategy["MINIMUM_REPL_QTY"] = 1] = "MINIMUM_REPL_QTY";
    RefreshOrderByStrategy[RefreshOrderByStrategy["OOS_IN_PICKING_ORDERS"] = 2] = "OOS_IN_PICKING_ORDERS";
    RefreshOrderByStrategy[RefreshOrderByStrategy["PLAN_REPL_QTY"] = 3] = "PLAN_REPL_QTY";
    RefreshOrderByStrategy[RefreshOrderByStrategy["PICKING_SEQUENCE"] = 4] = "PICKING_SEQUENCE";
})(RefreshOrderByStrategy || (RefreshOrderByStrategy = {}));
export var RefreshOrderByField;
(function (RefreshOrderByField) {
    RefreshOrderByField[RefreshOrderByField["DEFAULT"] = 0] = "DEFAULT";
    RefreshOrderByField[RefreshOrderByField["OOS_IN_PICKING_ORDER"] = 1] = "OOS_IN_PICKING_ORDER";
})(RefreshOrderByField || (RefreshOrderByField = {}));
export var RefreshOrderByType;
(function (RefreshOrderByType) {
    RefreshOrderByType[RefreshOrderByType["ASC"] = 1] = "ASC";
    RefreshOrderByType[RefreshOrderByType["DESC"] = 0] = "DESC";
})(RefreshOrderByType || (RefreshOrderByType = {}));
export var ReplPanIsOosInPicking;
(function (ReplPanIsOosInPicking) {
    ReplPanIsOosInPicking[ReplPanIsOosInPicking["N"] = 0] = "N";
    ReplPanIsOosInPicking[ReplPanIsOosInPicking["Y"] = 1] = "Y";
})(ReplPanIsOosInPicking || (ReplPanIsOosInPicking = {}));
export var ReplPanIsReverse;
(function (ReplPanIsReverse) {
    ReplPanIsReverse[ReplPanIsReverse["N"] = 0] = "N";
    ReplPanIsReverse[ReplPanIsReverse["Y"] = 1] = "Y";
})(ReplPanIsReverse || (ReplPanIsReverse = {}));
export var ReplPanIsUrgent;
(function (ReplPanIsUrgent) {
    ReplPanIsUrgent[ReplPanIsUrgent["N"] = 0] = "N";
    ReplPanIsUrgent[ReplPanIsUrgent["Y"] = 1] = "Y";
})(ReplPanIsUrgent || (ReplPanIsUrgent = {}));
export var ReplPlanStatus;
(function (ReplPlanStatus) {
    ReplPlanStatus[ReplPlanStatus["CREATED"] = 1] = "CREATED";
    ReplPlanStatus[ReplPlanStatus["PENDING"] = 2] = "PENDING";
    ReplPlanStatus[ReplPlanStatus["LOCKED"] = 3] = "LOCKED";
    ReplPlanStatus[ReplPlanStatus["ONGOING"] = 4] = "ONGOING";
    ReplPlanStatus[ReplPlanStatus["DONE"] = 5] = "DONE";
})(ReplPlanStatus || (ReplPlanStatus = {}));
export var ReplReverseConfigRule;
(function (ReplReverseConfigRule) {
    ReplReverseConfigRule["DEFAULT_RULE"] = "RI0005";
    ReplReverseConfigRule["LEAST_QUANTITY_LOCATION"] = "RI0004";
    ReplReverseConfigRule["LEFO"] = "RI0001";
    ReplReverseConfigRule["LIFO"] = "RI0002";
    ReplReverseConfigRule["REVERSE_PRIORITIZED_ZONE_GROUP"] = "RI0003";
})(ReplReverseConfigRule || (ReplReverseConfigRule = {}));
export var ReplReverseRuleSwitchStatus;
(function (ReplReverseRuleSwitchStatus) {
    ReplReverseRuleSwitchStatus[ReplReverseRuleSwitchStatus["N"] = 0] = "N";
    ReplReverseRuleSwitchStatus[ReplReverseRuleSwitchStatus["Y"] = 1] = "Y";
})(ReplReverseRuleSwitchStatus || (ReplReverseRuleSwitchStatus = {}));
export var ReplRunningTaskStatus;
(function (ReplRunningTaskStatus) {
    ReplRunningTaskStatus[ReplRunningTaskStatus["DONE"] = 2] = "DONE";
    ReplRunningTaskStatus[ReplRunningTaskStatus["FAIL"] = 3] = "FAIL";
    ReplRunningTaskStatus[ReplRunningTaskStatus["RUNNING"] = 1] = "RUNNING";
})(ReplRunningTaskStatus || (ReplRunningTaskStatus = {}));
export var ReplRunningTaskType;
(function (ReplRunningTaskType) {
    ReplRunningTaskType[ReplRunningTaskType["REPL_ADD_TO_ORDER"] = 1] = "REPL_ADD_TO_ORDER";
    ReplRunningTaskType[ReplRunningTaskType["REPL_CREATE_RUN_WAVE"] = 2] = "REPL_CREATE_RUN_WAVE";
    ReplRunningTaskType[ReplRunningTaskType["REPL_REFRESH"] = 3] = "REPL_REFRESH";
})(ReplRunningTaskType || (ReplRunningTaskType = {}));
export var ReplWaveStatus;
(function (ReplWaveStatus) {
    ReplWaveStatus[ReplWaveStatus["CANCEL"] = 4] = "CANCEL";
    ReplWaveStatus[ReplWaveStatus["DONE"] = 3] = "DONE";
    ReplWaveStatus[ReplWaveStatus["ONGOING"] = 2] = "ONGOING";
    ReplWaveStatus[ReplWaveStatus["PENDING"] = 1] = "PENDING";
})(ReplWaveStatus || (ReplWaveStatus = {}));
export var ReplRunWaveViewABCClassification;
(function (ReplRunWaveViewABCClassification) {
    ReplRunWaveViewABCClassification[ReplRunWaveViewABCClassification["A"] = 1] = "A";
    ReplRunWaveViewABCClassification[ReplRunWaveViewABCClassification["B"] = 2] = "B";
    ReplRunWaveViewABCClassification[ReplRunWaveViewABCClassification["C"] = 3] = "C";
    ReplRunWaveViewABCClassification[ReplRunWaveViewABCClassification["EXCLUDED"] = 5] = "EXCLUDED";
    ReplRunWaveViewABCClassification[ReplRunWaveViewABCClassification["SA"] = 4] = "SA";
})(ReplRunWaveViewABCClassification || (ReplRunWaveViewABCClassification = {}));
export var ReplenishmentForecastSalesType;
(function (ReplenishmentForecastSalesType) {
    ReplenishmentForecastSalesType[ReplenishmentForecastSalesType["CAMPAIGN__SALES"] = 2] = "CAMPAIGN__SALES";
    ReplenishmentForecastSalesType[ReplenishmentForecastSalesType["FLASH__SALES"] = 1] = "FLASH__SALES";
    ReplenishmentForecastSalesType[ReplenishmentForecastSalesType["ORGANIC__SALES"] = 3] = "ORGANIC__SALES";
    ReplenishmentForecastSalesType[ReplenishmentForecastSalesType["UNDEFINED"] = 0] = "UNDEFINED";
})(ReplenishmentForecastSalesType || (ReplenishmentForecastSalesType = {}));
export var ReplenishmentNeeded;
(function (ReplenishmentNeeded) {
    ReplenishmentNeeded[ReplenishmentNeeded["NO"] = 0] = "NO";
    ReplenishmentNeeded[ReplenishmentNeeded["YES"] = 1] = "YES";
})(ReplenishmentNeeded || (ReplenishmentNeeded = {}));
export var ReportingTeam;
(function (ReportingTeam) {
    ReportingTeam[ReportingTeam["INBOUND"] = 2] = "INBOUND";
    ReportingTeam[ReportingTeam["INVENTORY"] = 3] = "INVENTORY";
    ReportingTeam[ReportingTeam["MOVE__TRANSFER"] = 6] = "MOVE__TRANSFER";
    ReportingTeam[ReportingTeam["OTHERS"] = 7] = "OTHERS";
    ReportingTeam[ReportingTeam["OUTBOUND"] = 1] = "OUTBOUND";
    ReportingTeam[ReportingTeam["RETURN__INBOUND"] = 5] = "RETURN__INBOUND";
    ReportingTeam[ReportingTeam["RTS"] = 4] = "RTS";
})(ReportingTeam || (ReportingTeam = {}));
export var ReprocessTaskType;
(function (ReprocessTaskType) {
    ReprocessTaskType[ReprocessTaskType["LABELLING"] = 5] = "LABELLING";
    ReprocessTaskType[ReprocessTaskType["BUNDLING"] = 6] = "BUNDLING";
    ReprocessTaskType[ReprocessTaskType["REPACKAGING"] = 7] = "REPACKAGING";
})(ReprocessTaskType || (ReprocessTaskType = {}));
export var ReturnInboundType;
(function (ReturnInboundType) {
    ReturnInboundType[ReturnInboundType["BUYER_RETURN"] = 1] = "BUYER_RETURN";
    ReturnInboundType[ReturnInboundType["FAILED_DELIVERY"] = 2] = "FAILED_DELIVERY";
})(ReturnInboundType || (ReturnInboundType = {}));
export var ReturnQcCheckList;
(function (ReturnQcCheckList) {
    ReturnQcCheckList[ReturnQcCheckList["Complete_Product"] = 1] = "Complete_Product";
    ReturnQcCheckList[ReturnQcCheckList["Tags__Labels_Attached"] = 2] = "Tags__Labels_Attached";
    ReturnQcCheckList[ReturnQcCheckList["Unused_Product"] = 3] = "Unused_Product";
    ReturnQcCheckList[ReturnQcCheckList["Sealed"] = 4] = "Sealed";
})(ReturnQcCheckList || (ReturnQcCheckList = {}));
export var ReturnQcItemHandling;
(function (ReturnQcItemHandling) {
    ReturnQcItemHandling[ReturnQcItemHandling["Return_Accepted__3PL_claimed"] = 1] = "Return_Accepted__3PL_claimed";
    ReturnQcItemHandling[ReturnQcItemHandling["Return_Accepted__Reinbound_to_WH"] = 2] = "Return_Accepted__Reinbound_to_WH";
    ReturnQcItemHandling[ReturnQcItemHandling["Return_Accepted__Return_to_Seller"] = 3] = "Return_Accepted__Return_to_Seller";
    ReturnQcItemHandling[ReturnQcItemHandling["Return_Accepted__3PL_Claim_fail"] = 4] = "Return_Accepted__3PL_Claim_fail";
    ReturnQcItemHandling[ReturnQcItemHandling["_Reinbound_to_WH"] = 4] = "_Reinbound_to_WH";
    ReturnQcItemHandling[ReturnQcItemHandling["Return_Accepted__Replacement"] = 5] = "Return_Accepted__Replacement";
    ReturnQcItemHandling[ReturnQcItemHandling["Return_Rejected__Return_to_Buyer"] = 6] = "Return_Rejected__Return_to_Buyer";
})(ReturnQcItemHandling || (ReturnQcItemHandling = {}));
export var ReturnQcReturnReason;
(function (ReturnQcReturnReason) {
    ReturnQcReturnReason[ReturnQcReturnReason["Did_not_receive_the_order"] = 1] = "Did_not_receive_the_order";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_an_incomplete_product_missing_quantity_or_accessories"] = 103] = "Received_an_incomplete_product_missing_quantity_or_accessories";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_the_wrong_product_wrong_size"] = 104] = "Received_the_wrong_product_wrong_size";
    ReturnQcReturnReason[ReturnQcReturnReason["_wrong_colour"] = 105] = "_wrong_colour";
    ReturnQcReturnReason[ReturnQcReturnReason["_different_product"] = 2] = "_different_product";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_a_product_with_physical_damage_dented"] = 3] = "Received_a_product_with_physical_damage_dented";
    ReturnQcReturnReason[ReturnQcReturnReason["_scratched"] = 4] = "_scratched";
    ReturnQcReturnReason[ReturnQcReturnReason["_shattered"] = 106] = "_shattered";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_a_faulty_product_malfunction"] = 107] = "Received_a_faulty_product_malfunction";
    ReturnQcReturnReason[ReturnQcReturnReason["_does_not_work_as_intended"] = 107] = "_does_not_work_as_intended";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_a_counterfeit_product"] = 105] = "Received_a_counterfeit_product";
    ReturnQcReturnReason[ReturnQcReturnReason["Change_of_mind"] = 102] = "Change_of_mind";
    ReturnQcReturnReason[ReturnQcReturnReason["Product_is_significantly_different_from_description"] = 4] = "Product_is_significantly_different_from_description";
    ReturnQcReturnReason[ReturnQcReturnReason["Product_is_used_refurbished_rebuilt"] = 7] = "Product_is_used_refurbished_rebuilt";
    ReturnQcReturnReason[ReturnQcReturnReason["Product_does_not_meet_expectations"] = 104] = "Product_does_not_meet_expectations";
    ReturnQcReturnReason[ReturnQcReturnReason["Others"] = 6] = "Others";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_damaged_wrong_product"] = 101] = "Received_damaged_wrong_product";
    ReturnQcReturnReason[ReturnQcReturnReason["Received_damaged_faulty_product"] = 3] = "Received_damaged_faulty_product";
    ReturnQcReturnReason[ReturnQcReturnReason["Mutual_agreement_with_the_seller"] = 5] = "Mutual_agreement_with_the_seller";
})(ReturnQcReturnReason || (ReturnQcReturnReason = {}));
export var ReturnQcSkuType;
(function (ReturnQcSkuType) {
    ReturnQcSkuType[ReturnQcSkuType["GOOD"] = 1] = "GOOD";
    ReturnQcSkuType[ReturnQcSkuType["DAMAGE"] = 2] = "DAMAGE";
    ReturnQcSkuType[ReturnQcSkuType["ABNORMAL"] = 3] = "ABNORMAL";
})(ReturnQcSkuType || (ReturnQcSkuType = {}));
export var ReturnReason;
(function (ReturnReason) {
    ReturnReason[ReturnReason["RI_order_has_never_been_created_but_buyer_has_requested_R"] = 81] = "RI_order_has_never_been_created_but_buyer_has_requested_R";
    ReturnReason[ReturnReason["Buyer_never_request_R_but_WH_still_decide_to_process_offline"] = 82] = "Buyer_never_request_R_but_WH_still_decide_to_process_offline";
    ReturnReason[ReturnReason["RI_order_has_never_been_created_failed_delivery"] = 83] = "RI_order_has_never_been_created_failed_delivery";
    ReturnReason[ReturnReason["Others"] = 6] = "Others";
})(ReturnReason || (ReturnReason = {}));
export var ReverseSkuStatus;
(function (ReverseSkuStatus) {
    ReverseSkuStatus[ReverseSkuStatus["CREATED"] = 0] = "CREATED";
    ReverseSkuStatus[ReverseSkuStatus["RETURNED"] = 1] = "RETURNED";
})(ReverseSkuStatus || (ReverseSkuStatus = {}));
export var ReverseSourceType;
(function (ReverseSourceType) {
    ReverseSourceType[ReverseSourceType["SOURCE_CHECKING_TASK"] = 4] = "SOURCE_CHECKING_TASK";
    ReverseSourceType[ReverseSourceType["SOURCE_ORDER"] = 1] = "SOURCE_ORDER";
    ReverseSourceType[ReverseSourceType["SOURCE_PICKING_TASK"] = 2] = "SOURCE_PICKING_TASK";
    ReverseSourceType[ReverseSourceType["SOURCE_SORTING_TASK"] = 3] = "SOURCE_SORTING_TASK";
    ReverseSourceType[ReverseSourceType["SOURCE_TBS_TASK"] = 5] = "SOURCE_TBS_TASK";
})(ReverseSourceType || (ReverseSourceType = {}));
export var ReverseTaskStatus;
(function (ReverseTaskStatus) {
    ReverseTaskStatus[ReverseTaskStatus["DONE"] = 2] = "DONE";
    ReverseTaskStatus[ReverseTaskStatus["ONGOING"] = 1] = "ONGOING";
    ReverseTaskStatus[ReverseTaskStatus["PENDING"] = 0] = "PENDING";
})(ReverseTaskStatus || (ReverseTaskStatus = {}));
export var ReverseTaskStatusList;
(function (ReverseTaskStatusList) {
    ReverseTaskStatusList[ReverseTaskStatusList["Pending"] = 0] = "Pending";
    ReverseTaskStatusList[ReverseTaskStatusList["Ongoing"] = 1] = "Ongoing";
    ReverseTaskStatusList[ReverseTaskStatusList["Done"] = 2] = "Done";
    ReverseTaskStatusList[ReverseTaskStatusList["Cancel"] = 9] = "Cancel";
})(ReverseTaskStatusList || (ReverseTaskStatusList = {}));
export var RtsPickingTaskStatus;
(function (RtsPickingTaskStatus) {
    RtsPickingTaskStatus[RtsPickingTaskStatus["CANCEL"] = 9] = "CANCEL";
    RtsPickingTaskStatus[RtsPickingTaskStatus["DONE"] = 2] = "DONE";
    RtsPickingTaskStatus[RtsPickingTaskStatus["ONGOING"] = 1] = "ONGOING";
    RtsPickingTaskStatus[RtsPickingTaskStatus["PENDING"] = 0] = "PENDING";
})(RtsPickingTaskStatus || (RtsPickingTaskStatus = {}));
export var RtsQuotaStatus;
(function (RtsQuotaStatus) {
    RtsQuotaStatus[RtsQuotaStatus["OFF"] = 0] = "OFF";
    RtsQuotaStatus[RtsQuotaStatus["ON"] = 1] = "ON";
})(RtsQuotaStatus || (RtsQuotaStatus = {}));
export var RtsShipStatus;
(function (RtsShipStatus) {
    RtsShipStatus[RtsShipStatus["DONE"] = 2] = "DONE";
    RtsShipStatus[RtsShipStatus["ONGOING"] = 1] = "ONGOING";
    RtsShipStatus[RtsShipStatus["PENDING"] = 0] = "PENDING";
    RtsShipStatus[RtsShipStatus["SHIPPING"] = 3] = "SHIPPING";
})(RtsShipStatus || (RtsShipStatus = {}));
export var SKUBlockType;
(function (SKUBlockType) {
    SKUBlockType[SKUBlockType["EXPIRED"] = 3] = "EXPIRED";
    SKUBlockType[SKUBlockType["EXPIRING"] = 1] = "EXPIRING";
    SKUBlockType[SKUBlockType["NORMAL"] = 0] = "NORMAL";
})(SKUBlockType || (SKUBlockType = {}));
export var SKUQualityStatus;
(function (SKUQualityStatus) {
    SKUQualityStatus[SKUQualityStatus["DAMAGE"] = 1] = "DAMAGE";
    SKUQualityStatus[SKUQualityStatus["GOOD"] = 0] = "GOOD";
})(SKUQualityStatus || (SKUQualityStatus = {}));
export var SKUUnitOrderStatusType;
(function (SKUUnitOrderStatusType) {
    SKUUnitOrderStatusType[SKUUnitOrderStatusType["CHECKED"] = 2] = "CHECKED";
    SKUUnitOrderStatusType[SKUUnitOrderStatusType["CREATED"] = 0] = "CREATED";
    SKUUnitOrderStatusType[SKUUnitOrderStatusType["SHIPPED"] = 3] = "SHIPPED";
    SKUUnitOrderStatusType[SKUUnitOrderStatusType["STORED"] = 1] = "STORED";
})(SKUUnitOrderStatusType || (SKUUnitOrderStatusType = {}));
export var SKUUnitStatusType;
(function (SKUUnitStatusType) {
    SKUUnitStatusType[SKUUnitStatusType["INVALID"] = 2] = "INVALID";
    SKUUnitStatusType[SKUUnitStatusType["MISSING"] = 3] = "MISSING";
    SKUUnitStatusType[SKUUnitStatusType["SHIPPED"] = 5] = "SHIPPED";
    SKUUnitStatusType[SKUUnitStatusType["STORAGE_UNUSED"] = 4] = "STORAGE_UNUSED";
    SKUUnitStatusType[SKUUnitStatusType["UNUSED"] = 0] = "UNUSED";
    SKUUnitStatusType[SKUUnitStatusType["USED"] = 1] = "USED";
})(SKUUnitStatusType || (SKUUnitStatusType = {}));
export var SalesOrderExportStype;
(function (SalesOrderExportStype) {
    SalesOrderExportStype[SalesOrderExportStype["Partial_Cancel"] = 1] = "Partial_Cancel";
    SalesOrderExportStype[SalesOrderExportStype["Split_Parcel"] = 0] = "Split_Parcel";
})(SalesOrderExportStype || (SalesOrderExportStype = {}));
export var SalesOrderMassOrderFlag;
(function (SalesOrderMassOrderFlag) {
    SalesOrderMassOrderFlag[SalesOrderMassOrderFlag["Y"] = 1] = "Y";
    SalesOrderMassOrderFlag[SalesOrderMassOrderFlag["N"] = 0] = "N";
})(SalesOrderMassOrderFlag || (SalesOrderMassOrderFlag = {}));
export var SalesOrderMixShopFlag;
(function (SalesOrderMixShopFlag) {
    SalesOrderMixShopFlag[SalesOrderMixShopFlag["MIX_SHOPS"] = 1] = "MIX_SHOPS";
    SalesOrderMixShopFlag[SalesOrderMixShopFlag["SINGLE_SHOP"] = 0] = "SINGLE_SHOP";
})(SalesOrderMixShopFlag || (SalesOrderMixShopFlag = {}));
export var SalesOrderPendingOTFlag;
(function (SalesOrderPendingOTFlag) {
    SalesOrderPendingOTFlag[SalesOrderPendingOTFlag["Y"] = 1] = "Y";
    SalesOrderPendingOTFlag[SalesOrderPendingOTFlag["N"] = 0] = "N";
})(SalesOrderPendingOTFlag || (SalesOrderPendingOTFlag = {}));
export var SalesOrderSbsFlag;
(function (SalesOrderSbsFlag) {
    SalesOrderSbsFlag[SalesOrderSbsFlag["Y"] = 1] = "Y";
    SalesOrderSbsFlag[SalesOrderSbsFlag["N"] = 0] = "N";
})(SalesOrderSbsFlag || (SalesOrderSbsFlag = {}));
export var SalesOrderStatus;
(function (SalesOrderStatus) {
    SalesOrderStatus[SalesOrderStatus["Created"] = 0] = "Created";
    SalesOrderStatus[SalesOrderStatus["Pending_Pick"] = 9] = "Pending_Pick";
    SalesOrderStatus[SalesOrderStatus["Picking"] = 2] = "Picking";
    SalesOrderStatus[SalesOrderStatus["Picked"] = 3] = "Picked";
    SalesOrderStatus[SalesOrderStatus["Pick_Fail"] = 4] = "Pick_Fail";
    SalesOrderStatus[SalesOrderStatus["Checking"] = 5] = "Checking";
    SalesOrderStatus[SalesOrderStatus["Checked"] = 6] = "Checked";
    SalesOrderStatus[SalesOrderStatus["Sorting"] = 12] = "Sorting";
    SalesOrderStatus[SalesOrderStatus["Sorted"] = 13] = "Sorted";
    SalesOrderStatus[SalesOrderStatus["Packing"] = 10] = "Packing";
    SalesOrderStatus[SalesOrderStatus["Packed"] = 11] = "Packed";
    SalesOrderStatus[SalesOrderStatus["Shipping"] = 7] = "Shipping";
    SalesOrderStatus[SalesOrderStatus["Outbound"] = 8] = "Outbound";
    SalesOrderStatus[SalesOrderStatus["Cancel"] = 1] = "Cancel";
})(SalesOrderStatus || (SalesOrderStatus = {}));
export var SalesOrderUrgentFlag;
(function (SalesOrderUrgentFlag) {
    SalesOrderUrgentFlag[SalesOrderUrgentFlag["Y"] = 1] = "Y";
    SalesOrderUrgentFlag[SalesOrderUrgentFlag["N"] = 0] = "N";
})(SalesOrderUrgentFlag || (SalesOrderUrgentFlag = {}));
export var SalesPickingMethod;
(function (SalesPickingMethod) {
    SalesPickingMethod[SalesPickingMethod["UNDEFINE"] = 0] = "UNDEFINE";
    SalesPickingMethod[SalesPickingMethod["Batch_Picking"] = 1] = "Batch_Picking";
    SalesPickingMethod[SalesPickingMethod["Sorting_While_Picking"] = 2] = "Sorting_While_Picking";
})(SalesPickingMethod || (SalesPickingMethod = {}));
export var SalesPickingScanMethod;
(function (SalesPickingScanMethod) {
    SalesPickingScanMethod[SalesPickingScanMethod["PDA"] = 2] = "PDA";
    SalesPickingScanMethod[SalesPickingScanMethod["UNKNOWN"] = 0] = "UNKNOWN";
    SalesPickingScanMethod[SalesPickingScanMethod["WEB"] = 1] = "WEB";
})(SalesPickingScanMethod || (SalesPickingScanMethod = {}));
export var SalesPickingTaskCreateType;
(function (SalesPickingTaskCreateType) {
    SalesPickingTaskCreateType[SalesPickingTaskCreateType["Pick_To_Order"] = 0] = "Pick_To_Order";
    SalesPickingTaskCreateType[SalesPickingTaskCreateType["Pick_To_Wave"] = 1] = "Pick_To_Wave";
})(SalesPickingTaskCreateType || (SalesPickingTaskCreateType = {}));
export var ShiftRepeatType;
(function (ShiftRepeatType) {
    ShiftRepeatType[ShiftRepeatType["ALL_DAY"] = 0] = "ALL_DAY";
    ShiftRepeatType[ShiftRepeatType["WEEKDAY"] = 1] = "WEEKDAY";
    ShiftRepeatType[ShiftRepeatType["WEEKEND"] = 2] = "WEEKEND";
})(ShiftRepeatType || (ShiftRepeatType = {}));
export var ShipOrderStatus;
(function (ShipOrderStatus) {
    ShipOrderStatus[ShipOrderStatus["DONE"] = 1] = "DONE";
    ShipOrderStatus[ShipOrderStatus["SHIPPING"] = 0] = "SHIPPING";
})(ShipOrderStatus || (ShipOrderStatus = {}));
export var ShipTaskStatus;
(function (ShipTaskStatus) {
    ShipTaskStatus[ShipTaskStatus["Pending"] = 0] = "Pending";
    ShipTaskStatus[ShipTaskStatus["Ongoing"] = 1] = "Ongoing";
    ShipTaskStatus[ShipTaskStatus["Done"] = 2] = "Done";
})(ShipTaskStatus || (ShipTaskStatus = {}));
export var ShipTaskType;
(function (ShipTaskType) {
    ShipTaskType[ShipTaskType["PC"] = 1] = "PC";
    ShipTaskType[ShipTaskType["PDA"] = 2] = "PDA";
})(ShipTaskType || (ShipTaskType = {}));
export var ShopCbOptionType;
(function (ShopCbOptionType) {
    ShopCbOptionType[ShopCbOptionType["CB"] = 1] = "CB";
    ShopCbOptionType[ShopCbOptionType["LOCAL"] = 0] = "LOCAL";
})(ShopCbOptionType || (ShopCbOptionType = {}));
export var ShopSkuSyncStatusType;
(function (ShopSkuSyncStatusType) {
    ShopSkuSyncStatusType[ShopSkuSyncStatusType["SHOPID_INVALID"] = 2] = "SHOPID_INVALID";
    ShopSkuSyncStatusType[ShopSkuSyncStatusType["SHOPID_SYNCING"] = 3] = "SHOPID_SYNCING";
    ShopSkuSyncStatusType[ShopSkuSyncStatusType["SKUS_NOT_SYNCED"] = 0] = "SKUS_NOT_SYNCED";
    ShopSkuSyncStatusType[ShopSkuSyncStatusType["SKUS_SYNC_SUCCESSFUL"] = 1] = "SKUS_SYNC_SUCCESSFUL";
})(ShopSkuSyncStatusType || (ShopSkuSyncStatusType = {}));
export var ShopSnMgtType;
(function (ShopSnMgtType) {
    ShopSnMgtType[ShopSnMgtType["NO"] = 0] = "NO";
    ShopSnMgtType[ShopSnMgtType["YES"] = 1] = "YES";
})(ShopSnMgtType || (ShopSnMgtType = {}));
export var ShopStatusType;
(function (ShopStatusType) {
    ShopStatusType[ShopStatusType["BANNED"] = 2] = "BANNED";
    ShopStatusType[ShopStatusType["DELETED"] = 0] = "DELETED";
    ShopStatusType[ShopStatusType["NORMAL"] = 1] = "NORMAL";
})(ShopStatusType || (ShopStatusType = {}));
export var SkuBatchInfoRuleDetailInputControl;
(function (SkuBatchInfoRuleDetailInputControl) {
    SkuBatchInfoRuleDetailInputControl[SkuBatchInfoRuleDetailInputControl["OPTIONAL"] = 3] = "OPTIONAL";
    SkuBatchInfoRuleDetailInputControl[SkuBatchInfoRuleDetailInputControl["REQUIRED"] = 1] = "REQUIRED";
    SkuBatchInfoRuleDetailInputControl[SkuBatchInfoRuleDetailInputControl["SYNC_FROM_PMS"] = 4] = "SYNC_FROM_PMS";
})(SkuBatchInfoRuleDetailInputControl || (SkuBatchInfoRuleDetailInputControl = {}));
export var SkuBatchInfoRuleInputControl;
(function (SkuBatchInfoRuleInputControl) {
    SkuBatchInfoRuleInputControl[SkuBatchInfoRuleInputControl["OPTIONAL"] = 3] = "OPTIONAL";
    SkuBatchInfoRuleInputControl[SkuBatchInfoRuleInputControl["REQUIRED"] = 1] = "REQUIRED";
})(SkuBatchInfoRuleInputControl || (SkuBatchInfoRuleInputControl = {}));
export var SkuBatchOptionalValue;
(function (SkuBatchOptionalValue) {
    SkuBatchOptionalValue["GOOD_DAMAGE"] = "Good,Damage";
    SkuBatchOptionalValue["YES_NO"] = "Yes,No";
})(SkuBatchOptionalValue || (SkuBatchOptionalValue = {}));
export var SkuDuringBlackOutPeriod;
(function (SkuDuringBlackOutPeriod) {
    SkuDuringBlackOutPeriod[SkuDuringBlackOutPeriod["ACCUMULATED"] = 0] = "ACCUMULATED";
    SkuDuringBlackOutPeriod[SkuDuringBlackOutPeriod["EXEMPTED"] = 1] = "EXEMPTED";
})(SkuDuringBlackOutPeriod || (SkuDuringBlackOutPeriod = {}));
export var SkuIsSnMgtFlag;
(function (SkuIsSnMgtFlag) {
    SkuIsSnMgtFlag[SkuIsSnMgtFlag["N"] = 0] = "N";
    SkuIsSnMgtFlag[SkuIsSnMgtFlag["Y"] = 1] = "Y";
})(SkuIsSnMgtFlag || (SkuIsSnMgtFlag = {}));
export var SkuItemStatus;
(function (SkuItemStatus) {
    SkuItemStatus[SkuItemStatus["BLOCKED"] = 2] = "BLOCKED";
    SkuItemStatus[SkuItemStatus["DELETE"] = 0] = "DELETE";
    SkuItemStatus[SkuItemStatus["NORMAL"] = 1] = "NORMAL";
})(SkuItemStatus || (SkuItemStatus = {}));
export var SkuLockRelativeType;
(function (SkuLockRelativeType) {
    SkuLockRelativeType[SkuLockRelativeType["BLOCK"] = 8] = "BLOCK";
    SkuLockRelativeType[SkuLockRelativeType["CYCLE_COUNT"] = 9] = "CYCLE_COUNT";
    SkuLockRelativeType[SkuLockRelativeType["INVENTORY_ADJUST"] = 7] = "INVENTORY_ADJUST";
    SkuLockRelativeType[SkuLockRelativeType["MOVE_ORDER"] = 10] = "MOVE_ORDER";
    SkuLockRelativeType[SkuLockRelativeType["MOVE_TRANSFER"] = 6] = "MOVE_TRANSFER";
    SkuLockRelativeType[SkuLockRelativeType["OUTBOUND_CHECKED"] = 3] = "OUTBOUND_CHECKED";
    SkuLockRelativeType[SkuLockRelativeType["OUTBOUND_PICKING"] = 5] = "OUTBOUND_PICKING";
    SkuLockRelativeType[SkuLockRelativeType["RACK_TRANSFER"] = 2] = "RACK_TRANSFER";
    SkuLockRelativeType[SkuLockRelativeType["RETURN_ORDER_PACKED"] = 4] = "RETURN_ORDER_PACKED";
    SkuLockRelativeType[SkuLockRelativeType["SKU_CHECK"] = 1] = "SKU_CHECK";
})(SkuLockRelativeType || (SkuLockRelativeType = {}));
export var SkuQualityType;
(function (SkuQualityType) {
    SkuQualityType[SkuQualityType["DAMAGE"] = 1] = "DAMAGE";
    SkuQualityType[SkuQualityType["GOOD"] = 0] = "GOOD";
})(SkuQualityType || (SkuQualityType = {}));
export var SkuRuleCategory;
(function (SkuRuleCategory) {
    SkuRuleCategory[SkuRuleCategory["BATCH_INFO_RULE"] = 2] = "BATCH_INFO_RULE";
    SkuRuleCategory[SkuRuleCategory["UNIT_ID_RULE"] = 1] = "UNIT_ID_RULE";
})(SkuRuleCategory || (SkuRuleCategory = {}));
export var SkuRuleStatusType;
(function (SkuRuleStatusType) {
    SkuRuleStatusType[SkuRuleStatusType["N"] = 0] = "N";
    SkuRuleStatusType[SkuRuleStatusType["Y"] = 1] = "Y";
})(SkuRuleStatusType || (SkuRuleStatusType = {}));
export var SkuSellType;
(function (SkuSellType) {
    SkuSellType[SkuSellType["INVALID_SELLING_TYPE"] = 0] = "INVALID_SELLING_TYPE";
    SkuSellType[SkuSellType["PCS"] = 2] = "PCS";
    SkuSellType[SkuSellType["PACK"] = 3] = "PACK";
    SkuSellType[SkuSellType["CARTON"] = 1] = "CARTON";
    SkuSellType[SkuSellType["UNKNOWN"] = 4] = "UNKNOWN";
})(SkuSellType || (SkuSellType = {}));
export var SkuSizeType;
(function (SkuSizeType) {
    SkuSizeType[SkuSizeType["BULKY_ITEM"] = 10] = "BULKY_ITEM";
    SkuSizeType[SkuSizeType["EXTRA_BULKY_ITEM"] = 20] = "EXTRA_BULKY_ITEM";
    SkuSizeType[SkuSizeType["NON_BULKY_ITEM"] = 0] = "NON_BULKY_ITEM";
})(SkuSizeType || (SkuSizeType = {}));
export var SkuSizeTypeMode;
(function (SkuSizeTypeMode) {
    SkuSizeTypeMode[SkuSizeTypeMode["LONGEST_SIDE"] = 0] = "LONGEST_SIDE";
    SkuSizeTypeMode[SkuSizeTypeMode["SUM__LWH"] = 1] = "SUM__LWH";
})(SkuSizeTypeMode || (SkuSizeTypeMode = {}));
export var SkuWeightType;
(function (SkuWeightType) {
    SkuWeightType[SkuWeightType["HEAVY_ITEM"] = 1] = "HEAVY_ITEM";
    SkuWeightType[SkuWeightType["NON_HEAVY_ITEM"] = 0] = "NON_HEAVY_ITEM";
})(SkuWeightType || (SkuWeightType = {}));
export var SortByItemNextProcess;
(function (SortByItemNextProcess) {
    SortByItemNextProcess[SortByItemNextProcess["ABNORMALSUMMARY"] = 5] = "ABNORMALSUMMARY";
    SortByItemNextProcess[SortByItemNextProcess["FINISH"] = 4] = "FINISH";
    SortByItemNextProcess[SortByItemNextProcess["INCOMPLETE"] = 3] = "INCOMPLETE";
    SortByItemNextProcess[SortByItemNextProcess["PUTREMAINITEMTOTS"] = 6] = "PUTREMAINITEMTOTS";
    SortByItemNextProcess[SortByItemNextProcess["SCANSKU"] = 1] = "SCANSKU";
    SortByItemNextProcess[SortByItemNextProcess["TROUBLESHOOTING"] = 2] = "TROUBLESHOOTING";
})(SortByItemNextProcess || (SortByItemNextProcess = {}));
export var SortByOrderGridStatus;
(function (SortByOrderGridStatus) {
    SortByOrderGridStatus[SortByOrderGridStatus["ABNORMAL"] = 1] = "ABNORMAL";
    SortByOrderGridStatus[SortByOrderGridStatus["NORMAL"] = 0] = "NORMAL";
})(SortByOrderGridStatus || (SortByOrderGridStatus = {}));
export var SortByOrderNextProcess;
(function (SortByOrderNextProcess) {
    SortByOrderNextProcess[SortByOrderNextProcess["BINDBASKET"] = 0] = "BINDBASKET";
    SortByOrderNextProcess[SortByOrderNextProcess["COMPLETE"] = 2] = "COMPLETE";
    SortByOrderNextProcess[SortByOrderNextProcess["GETSORTINGINFO"] = 1] = "GETSORTINGINFO";
})(SortByOrderNextProcess || (SortByOrderNextProcess = {}));
export var SortingOrderBasketSortStatus;
(function (SortingOrderBasketSortStatus) {
    SortingOrderBasketSortStatus[SortingOrderBasketSortStatus["COMPLETE"] = 1] = "COMPLETE";
    SortingOrderBasketSortStatus[SortingOrderBasketSortStatus["INCOMPLETE"] = 3] = "INCOMPLETE";
    SortingOrderBasketSortStatus[SortingOrderBasketSortStatus["ONGOING"] = 0] = "ONGOING";
})(SortingOrderBasketSortStatus || (SortingOrderBasketSortStatus = {}));
export var SortingTaskSortingMethod;
(function (SortingTaskSortingMethod) {
    SortingTaskSortingMethod[SortingTaskSortingMethod["Merging_While_Sorting"] = 6] = "Merging_While_Sorting";
    SortingTaskSortingMethod[SortingTaskSortingMethod["PC_Sorting"] = 1] = "PC_Sorting";
    SortingTaskSortingMethod[SortingTaskSortingMethod["PDA_Sorting_by_Item"] = 3] = "PDA_Sorting_by_Item";
    SortingTaskSortingMethod[SortingTaskSortingMethod["PDA_Sorting_by_Order"] = 2] = "PDA_Sorting_by_Order";
})(SortingTaskSortingMethod || (SortingTaskSortingMethod = {}));
export var SortingTaskStatus;
(function (SortingTaskStatus) {
    SortingTaskStatus[SortingTaskStatus["Pending"] = 0] = "Pending";
    SortingTaskStatus[SortingTaskStatus["Ongoing"] = 1] = "Ongoing";
    SortingTaskStatus[SortingTaskStatus["Done"] = 2] = "Done";
    SortingTaskStatus[SortingTaskStatus["Cancel"] = 9] = "Cancel";
})(SortingTaskStatus || (SortingTaskStatus = {}));
export var StagingLocationType;
(function (StagingLocationType) {
    StagingLocationType[StagingLocationType["ALL"] = 0] = "ALL";
    StagingLocationType[StagingLocationType["BULKY"] = 5] = "BULKY";
    StagingLocationType[StagingLocationType["HEAVY"] = 3] = "HEAVY";
    StagingLocationType[StagingLocationType["HV_HIGH_VALUE"] = 2] = "HV_HIGH_VALUE";
    StagingLocationType[StagingLocationType["TC_TEMPERATURE_CONTROL"] = 4] = "TC_TEMPERATURE_CONTROL";
    StagingLocationType[StagingLocationType["URGENT"] = 1] = "URGENT";
})(StagingLocationType || (StagingLocationType = {}));
export var StationGridStatus;
(function (StationGridStatus) {
    StationGridStatus[StationGridStatus["NORMAL"] = 0] = "NORMAL";
    StationGridStatus[StationGridStatus["USING"] = 1] = "USING";
})(StationGridStatus || (StationGridStatus = {}));
export var StationProcess;
(function (StationProcess) {
    StationProcess[StationProcess["CHECKING"] = 0] = "CHECKING";
    StationProcess[StationProcess["CHECKING_AND_PACKING"] = 4] = "CHECKING_AND_PACKING";
    StationProcess[StationProcess["MERGE_LANE"] = 7] = "MERGE_LANE";
    StationProcess[StationProcess["MERGING_WHILE_SORTING"] = 3] = "MERGING_WHILE_SORTING";
    StationProcess[StationProcess["PACKING"] = 2] = "PACKING";
    StationProcess[StationProcess["SORTING_WHILE_CHECKING"] = 1] = "SORTING_WHILE_CHECKING";
    StationProcess[StationProcess["SORTING"] = 5] = "SORTING";
    StationProcess[StationProcess["PRE_SORTING"] = 9] = "PRE_SORTING";
})(StationProcess || (StationProcess = {}));
export var StationProcessInbound;
(function (StationProcessInbound) {
    StationProcessInbound[StationProcessInbound["BOXING"] = 11] = "BOXING";
    StationProcessInbound[StationProcessInbound["QC"] = 10] = "QC";
})(StationProcessInbound || (StationProcessInbound = {}));
export var StationSettingKey;
(function (StationSettingKey) {
    StationSettingKey[StationSettingKey["RECORD_CHECKING_STATION_ID"] = 0] = "RECORD_CHECKING_STATION_ID";
    StationSettingKey[StationSettingKey["RECORD_PACKING_STATION_ID"] = 2] = "RECORD_PACKING_STATION_ID";
    StationSettingKey[StationSettingKey["RECORD_SORTING_WHILE_CHECKING_STATION_ID"] = 1] = "RECORD_SORTING_WHILE_CHECKING_STATION_ID";
})(StationSettingKey || (StationSettingKey = {}));
export var StationStatus;
(function (StationStatus) {
    StationStatus[StationStatus["NORMAL"] = 0] = "NORMAL";
    StationStatus[StationStatus["USING"] = 1] = "USING";
})(StationStatus || (StationStatus = {}));
export var StationType;
(function (StationType) {
    StationType[StationType["SALES_OUTBOUND"] = 0] = "SALES_OUTBOUND";
    StationType[StationType["MOVE_TRANSFER"] = 1] = "MOVE_TRANSFER";
    StationType[StationType["INBOUND"] = 2] = "INBOUND";
})(StationType || (StationType = {}));
export var StationEventType;
(function (StationEventType) {
    StationEventType[StationEventType["CREATE"] = 0] = "CREATE";
    StationEventType[StationEventType["DELETE"] = 4] = "DELETE";
    StationEventType[StationEventType["EDIT"] = 1] = "EDIT";
    StationEventType[StationEventType["ENABLE_DISABLE"] = 2] = "ENABLE_DISABLE";
    StationEventType[StationEventType["REMOVE_WEIGHT_VALIDATION"] = 3] = "REMOVE_WEIGHT_VALIDATION";
})(StationEventType || (StationEventType = {}));
export var StorageType;
(function (StorageType) {
    StorageType[StorageType["DRIVE_IN_RACK"] = 6] = "DRIVE_IN_RACK";
    StorageType[StorageType["GROUND_STACKED"] = 3] = "GROUND_STACKED";
    StorageType[StorageType["HEAVY_RACK"] = 2] = "HEAVY_RACK";
    StorageType[StorageType["LIGHT_RACK"] = 5] = "LIGHT_RACK";
    StorageType[StorageType["ROLLING_RACK"] = 4] = "ROLLING_RACK";
    StorageType[StorageType["UNDEFINED"] = 0] = "UNDEFINED";
    StorageType[StorageType["VAN"] = 1] = "VAN";
})(StorageType || (StorageType = {}));
export var SupplierSkuSourcingStatusType;
(function (SupplierSkuSourcingStatusType) {
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["AVAILABLE"] = 0] = "AVAILABLE";
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["CATEGORY_MANAGED"] = 6] = "CATEGORY_MANAGED";
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["DISCONTINUED"] = 2] = "DISCONTINUED";
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["DO_NOT_ORDER"] = 3] = "DO_NOT_ORDER";
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["OUT_OF_STOCK"] = 1] = "OUT_OF_STOCK";
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["OVERSTOCK"] = 5] = "OVERSTOCK";
    SupplierSkuSourcingStatusType[SupplierSkuSourcingStatusType["PRE_SALE"] = 4] = "PRE_SALE";
})(SupplierSkuSourcingStatusType || (SupplierSkuSourcingStatusType = {}));
export var SupplierType;
(function (SupplierType) {
    SupplierType["B2B2C"] = "B2B2C";
    SupplierType["B2C"] = "B2C";
    SupplierType["CB"] = "CB";
    SupplierType["Consignment"] = "Consignment";
    SupplierType["SBS_Supplier"] = "SBS Supplier";
})(SupplierType || (SupplierType = {}));
export var SupplierVendorType;
(function (SupplierVendorType) {
    SupplierVendorType[SupplierVendorType["CONSIGNMENT"] = 2] = "CONSIGNMENT";
    SupplierVendorType[SupplierVendorType["FULFILLMENT"] = 0] = "FULFILLMENT";
    SupplierVendorType[SupplierVendorType["OUTRIGHT"] = 1] = "OUTRIGHT";
})(SupplierVendorType || (SupplierVendorType = {}));
export var SyncZktStatus;
(function (SyncZktStatus) {
    SyncZktStatus[SyncZktStatus["UNSYNCHRONIZED"] = 0] = "UNSYNCHRONIZED";
    SyncZktStatus[SyncZktStatus["SYNC_SUCCESSFULLY"] = 3] = "SYNC_SUCCESSFULLY";
    SyncZktStatus[SyncZktStatus["CREATED_FAILED"] = 1] = "CREATED_FAILED";
    SyncZktStatus[SyncZktStatus["SYNC_WHS_FAILED"] = 2] = "SYNC_WHS_FAILED";
})(SyncZktStatus || (SyncZktStatus = {}));
export var TaskTrackingTimeType;
(function (TaskTrackingTimeType) {
    TaskTrackingTimeType[TaskTrackingTimeType["TODAY"] = 1] = "TODAY";
    TaskTrackingTimeType[TaskTrackingTimeType["LAST24_HOURS"] = 2] = "LAST24_HOURS";
    TaskTrackingTimeType[TaskTrackingTimeType["WEEK"] = 3] = "WEEK";
})(TaskTrackingTimeType || (TaskTrackingTimeType = {}));
export var TbsTaskStatus;
(function (TbsTaskStatus) {
    TbsTaskStatus[TbsTaskStatus["ADD_PICKING"] = 4] = "ADD_PICKING";
    TbsTaskStatus[TbsTaskStatus["COUNTED"] = 3] = "COUNTED";
    TbsTaskStatus[TbsTaskStatus["DONE"] = 10] = "DONE";
    TbsTaskStatus[TbsTaskStatus["ONGOING"] = 2] = "ONGOING";
    TbsTaskStatus[TbsTaskStatus["PENDING"] = 1] = "PENDING";
    TbsTaskStatus[TbsTaskStatus["REVERSE_PICKING"] = 5] = "REVERSE_PICKING";
})(TbsTaskStatus || (TbsTaskStatus = {}));
export var TbsTaskType;
(function (TbsTaskType) {
    TbsTaskType[TbsTaskType["IN_INVENTORY"] = 1] = "IN_INVENTORY";
    TbsTaskType[TbsTaskType["WHILE_HANDLING"] = 2] = "WHILE_HANDLING";
    TbsTaskType[TbsTaskType["TAKEOVER"] = 3] = "TAKEOVER";
})(TbsTaskType || (TbsTaskType = {}));
export var TimeZoneType;
(function (TimeZoneType) {
    TimeZoneType[TimeZoneType["INDONESIA_STANDARD_TIME"] = 1] = "INDONESIA_STANDARD_TIME";
    TimeZoneType[TimeZoneType["THAILAND_STANDARD_TIME"] = 0] = "THAILAND_STANDARD_TIME";
    TimeZoneType[TimeZoneType["VIETNAM_STANDARD_TIME"] = 2] = "VIETNAM_STANDARD_TIME";
    TimeZoneType[TimeZoneType["MALAYSIA_STANDARD_TIME"] = 4] = "MALAYSIA_STANDARD_TIME";
    TimeZoneType[TimeZoneType["PHILIPPINE_STANDARD_TIME"] = 5] = "PHILIPPINE_STANDARD_TIME";
    TimeZoneType[TimeZoneType["SINGAPORE_STANDARD_TIME"] = 6] = "SINGAPORE_STANDARD_TIME";
    TimeZoneType[TimeZoneType["TAIPEI_STANDARD_TIME"] = 3] = "TAIPEI_STANDARD_TIME";
})(TimeZoneType || (TimeZoneType = {}));
export var TopPickingAuditCountBase;
(function (TopPickingAuditCountBase) {
    TopPickingAuditCountBase[TopPickingAuditCountBase["LOCATION_BASED"] = 0] = "LOCATION_BASED";
    TopPickingAuditCountBase[TopPickingAuditCountBase["SKU_BASED"] = 1] = "SKU_BASED";
})(TopPickingAuditCountBase || (TopPickingAuditCountBase = {}));
export var TopPutawayAuditCountBase;
(function (TopPutawayAuditCountBase) {
    TopPutawayAuditCountBase[TopPutawayAuditCountBase["LOCATION_BASED"] = 0] = "LOCATION_BASED";
    TopPutawayAuditCountBase[TopPutawayAuditCountBase["SKU_BASED"] = 1] = "SKU_BASED";
})(TopPutawayAuditCountBase || (TopPutawayAuditCountBase = {}));
export var TopRackTransferAuditCountBase;
(function (TopRackTransferAuditCountBase) {
    TopRackTransferAuditCountBase[TopRackTransferAuditCountBase["LOCATION_BASED"] = 0] = "LOCATION_BASED";
    TopRackTransferAuditCountBase[TopRackTransferAuditCountBase["SKU_BASED"] = 1] = "SKU_BASED";
})(TopRackTransferAuditCountBase || (TopRackTransferAuditCountBase = {}));
export var TrInboundDeviceDisplayStatus;
(function (TrInboundDeviceDisplayStatus) {
    TrInboundDeviceDisplayStatus[TrInboundDeviceDisplayStatus["USED"] = 1] = "USED";
    TrInboundDeviceDisplayStatus[TrInboundDeviceDisplayStatus["NORMAL"] = 2] = "NORMAL";
})(TrInboundDeviceDisplayStatus || (TrInboundDeviceDisplayStatus = {}));
export var TransactionDirectFlagType;
(function (TransactionDirectFlagType) {
    TransactionDirectFlagType[TransactionDirectFlagType["CR"] = 2] = "CR";
    TransactionDirectFlagType[TransactionDirectFlagType["DR"] = 1] = "DR";
})(TransactionDirectFlagType || (TransactionDirectFlagType = {}));
export var TransactionSheetType;
(function (TransactionSheetType) {
    TransactionSheetType[TransactionSheetType["BATCH__TRANSFER"] = 12] = "BATCH__TRANSFER";
    TransactionSheetType[TransactionSheetType["BLOCK__INVENTORY"] = 13] = "BLOCK__INVENTORY";
    TransactionSheetType[TransactionSheetType["CYCLE__COUNT"] = 5] = "CYCLE__COUNT";
    TransactionSheetType[TransactionSheetType["INBOUND__ORDER"] = 1] = "INBOUND__ORDER";
    TransactionSheetType[TransactionSheetType["INVENTORY__ADJUST"] = 9] = "INVENTORY__ADJUST";
    TransactionSheetType[TransactionSheetType["MOVE__ORDER"] = 15] = "MOVE__ORDER";
    TransactionSheetType[TransactionSheetType["MOVE__ORDER__INBOUND"] = 7] = "MOVE__ORDER__INBOUND";
    TransactionSheetType[TransactionSheetType["MOVE__ORDER__OUTBOUND"] = 8] = "MOVE__ORDER__OUTBOUND";
    TransactionSheetType[TransactionSheetType["MOVE__TRANSFER__INBOUND"] = 10] = "MOVE__TRANSFER__INBOUND";
    TransactionSheetType[TransactionSheetType["MOVE__TRANSFER__OUTBOUND"] = 11] = "MOVE__TRANSFER__OUTBOUND";
    TransactionSheetType[TransactionSheetType["MTI__ARRIVAL"] = 14] = "MTI__ARRIVAL";
    TransactionSheetType[TransactionSheetType["OUTBOUND__ORDER"] = 2] = "OUTBOUND__ORDER";
    TransactionSheetType[TransactionSheetType["RACK__TRANSFER"] = 3] = "RACK__TRANSFER";
    TransactionSheetType[TransactionSheetType["RETURN__INBOUND__ORDER"] = 6] = "RETURN__INBOUND__ORDER";
    TransactionSheetType[TransactionSheetType["RETURN__ORDER"] = 4] = "RETURN__ORDER";
})(TransactionSheetType || (TransactionSheetType = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType[TransactionType["ADJUST__DECREASE"] = 12] = "ADJUST__DECREASE";
    TransactionType[TransactionType["ADJUST__INCREASE"] = 13] = "ADJUST__INCREASE";
    TransactionType[TransactionType["BATCH__TRANSFER"] = 15] = "BATCH__TRANSFER";
    TransactionType[TransactionType["MOVE__INBOUND"] = 20] = "MOVE__INBOUND";
    TransactionType[TransactionType["MOVE__OUTBOUND"] = 21] = "MOVE__OUTBOUND";
    TransactionType[TransactionType["OVERAGE"] = 5] = "OVERAGE";
    TransactionType[TransactionType["PICKING"] = 8] = "PICKING";
    TransactionType[TransactionType["PUT__AWAY"] = 1] = "PUT__AWAY";
    TransactionType[TransactionType["RACK__TRANSFER"] = 3] = "RACK__TRANSFER";
    TransactionType[TransactionType["RACK__TRANSFER__CYCLECOUNT"] = 10] = "RACK__TRANSFER__CYCLECOUNT";
    TransactionType[TransactionType["RECEIVED"] = 7] = "RECEIVED";
    TransactionType[TransactionType["RECEIVED__MODIFY"] = 14] = "RECEIVED__MODIFY";
    TransactionType[TransactionType["RETURN__TO__SUPPLIER"] = 4] = "RETURN__TO__SUPPLIER";
    TransactionType[TransactionType["REVERSE"] = 9] = "REVERSE";
    TransactionType[TransactionType["RE__CHECKING"] = 11] = "RE__CHECKING";
    TransactionType[TransactionType["RE__RECEIVED"] = 22] = "RE__RECEIVED";
    TransactionType[TransactionType["SHIP__ORDER"] = 2] = "SHIP__ORDER";
    TransactionType[TransactionType["SHORTAGE"] = 6] = "SHORTAGE";
})(TransactionType || (TransactionType = {}));
export var TransitingStatus;
(function (TransitingStatus) {
    TransitingStatus[TransitingStatus["Pending"] = 0] = "Pending";
    TransitingStatus[TransitingStatus["Ongoing"] = 1] = "Ongoing";
    TransitingStatus[TransitingStatus["Done"] = 2] = "Done";
    TransitingStatus[TransitingStatus["Cancel"] = 9] = "Cancel";
})(TransitingStatus || (TransitingStatus = {}));
export var TransitingTaskStatus;
(function (TransitingTaskStatus) {
    TransitingTaskStatus[TransitingTaskStatus["Pending"] = 0] = "Pending";
    TransitingTaskStatus[TransitingTaskStatus["Ongoing"] = 1] = "Ongoing";
    TransitingTaskStatus[TransitingTaskStatus["Done"] = 2] = "Done";
    TransitingTaskStatus[TransitingTaskStatus["Cancel"] = 9] = "Cancel";
})(TransitingTaskStatus || (TransitingTaskStatus = {}));
export var TurnoverRuleRuleType;
(function (TurnoverRuleRuleType) {
    TurnoverRuleRuleType[TurnoverRuleRuleType["MT__OUTBOUND"] = 2] = "MT__OUTBOUND";
    TurnoverRuleRuleType[TurnoverRuleRuleType["RTS__OUTBOUND"] = 1] = "RTS__OUTBOUND";
    TurnoverRuleRuleType[TurnoverRuleRuleType["SALES__OUTBOUND"] = 0] = "SALES__OUTBOUND";
})(TurnoverRuleRuleType || (TurnoverRuleRuleType = {}));
export var UIDRuleType;
(function (UIDRuleType) {
    UIDRuleType[UIDRuleType["Only_WMS_generated_UID_Allowed"] = 0] = "Only_WMS_generated_UID_Allowed";
    UIDRuleType[UIDRuleType["User_defined"] = 1] = "User_defined";
})(UIDRuleType || (UIDRuleType = {}));
export var UserAccessStatusType;
(function (UserAccessStatusType) {
    UserAccessStatusType[UserAccessStatusType["ABNORMAL"] = 100] = "ABNORMAL";
    UserAccessStatusType[UserAccessStatusType["NORMAL"] = 0] = "NORMAL";
})(UserAccessStatusType || (UserAccessStatusType = {}));
export var UserActiveLevelType;
(function (UserActiveLevelType) {
    UserActiveLevelType[UserActiveLevelType["DAYS_WITHIN15"] = 1] = "DAYS_WITHIN15";
    UserActiveLevelType[UserActiveLevelType["DAYS_WITHIN16_TO30"] = 2] = "DAYS_WITHIN16_TO30";
    UserActiveLevelType[UserActiveLevelType["DAYS_WITHIN31_TO60"] = 3] = "DAYS_WITHIN31_TO60";
    UserActiveLevelType[UserActiveLevelType["DAYS_WITHIN61_TO90"] = 4] = "DAYS_WITHIN61_TO90";
    UserActiveLevelType[UserActiveLevelType["DAYS_BEFORE91"] = 5] = "DAYS_BEFORE91";
})(UserActiveLevelType || (UserActiveLevelType = {}));
export var UserDefinedType;
(function (UserDefinedType) {
    UserDefinedType[UserDefinedType["Allow_all_characters"] = 1] = "Allow_all_characters";
    UserDefinedType[UserDefinedType["Allow_letters_and_numbers"] = 0] = "Allow_letters_and_numbers";
    UserDefinedType[UserDefinedType["Allow_letters"] = 1] = "Allow_letters";
    UserDefinedType[UserDefinedType["_numbers_and_specified_characters"] = 2] = "_numbers_and_specified_characters";
})(UserDefinedType || (UserDefinedType = {}));
export var UserSoupStatusType;
(function (UserSoupStatusType) {
    UserSoupStatusType[UserSoupStatusType["DELETE"] = 100] = "DELETE";
    UserSoupStatusType[UserSoupStatusType["NORMAL"] = 0] = "NORMAL";
})(UserSoupStatusType || (UserSoupStatusType = {}));
export var UserSourcingFlagType;
(function (UserSourcingFlagType) {
    UserSourcingFlagType[UserSourcingFlagType["OWN"] = 1] = "OWN";
    UserSourcingFlagType[UserSourcingFlagType["OUTSOURCING"] = 5] = "OUTSOURCING";
})(UserSourcingFlagType || (UserSourcingFlagType = {}));
export var UserStatusType;
(function (UserStatusType) {
    UserStatusType[UserStatusType["DELETE"] = 100] = "DELETE";
    UserStatusType[UserStatusType["NORMAL"] = 0] = "NORMAL";
})(UserStatusType || (UserStatusType = {}));
export var WarehouseCategory;
(function (WarehouseCategory) {
    WarehouseCategory[WarehouseCategory["CROSS_BORDER"] = 1] = "CROSS_BORDER";
    WarehouseCategory[WarehouseCategory["LOCAL"] = 0] = "LOCAL";
})(WarehouseCategory || (WarehouseCategory = {}));
export var WarehouseFlags;
(function (WarehouseFlags) {
    WarehouseFlags[WarehouseFlags["BATCH_INBOUND"] = 4] = "BATCH_INBOUND";
    WarehouseFlags[WarehouseFlags["NO_SKU_LABEL"] = 2] = "NO_SKU_LABEL";
    WarehouseFlags[WarehouseFlags["NO_UNIT"] = 1] = "NO_UNIT";
    WarehouseFlags[WarehouseFlags["WITH_UNIT"] = 0] = "WITH_UNIT";
})(WarehouseFlags || (WarehouseFlags = {}));
export var WaveRuleType;
(function (WaveRuleType) {
    WaveRuleType[WaveRuleType["Single_SKU_Single_QTY"] = 1] = "Single_SKU_Single_QTY";
    WaveRuleType[WaveRuleType["Single_SKU_Any_QTY"] = 4] = "Single_SKU_Any_QTY";
    WaveRuleType[WaveRuleType["Mix_SKU_Single_QTY"] = 5] = "Mix_SKU_Single_QTY";
    WaveRuleType[WaveRuleType["Same_SKU_Same_QTY"] = 2] = "Same_SKU_Same_QTY";
    WaveRuleType[WaveRuleType["Mix_SKU_Any_QTY"] = 3] = "Mix_SKU_Any_QTY";
})(WaveRuleType || (WaveRuleType = {}));
export var WaveStatus;
(function (WaveStatus) {
    WaveStatus[WaveStatus["PENDING"] = 0] = "PENDING";
    WaveStatus[WaveStatus["DONE"] = 3] = "DONE";
    WaveStatus[WaveStatus["CANCELING"] = 4] = "CANCELING";
    WaveStatus[WaveStatus["CANCEL"] = 5] = "CANCEL";
    WaveStatus[WaveStatus["FAILED"] = 9] = "FAILED";
})(WaveStatus || (WaveStatus = {}));
export var WeightValidationOperation;
(function (WeightValidationOperation) {
    WeightValidationOperation[WeightValidationOperation["PROCEED"] = 1] = "PROCEED";
    WeightValidationOperation[WeightValidationOperation["RE__CHECKING"] = 2] = "RE__CHECKING";
})(WeightValidationOperation || (WeightValidationOperation = {}));
export var WmsV2Module;
(function (WmsV2Module) {
    WmsV2Module[WmsV2Module["INBOUND"] = 1] = "INBOUND";
    WmsV2Module[WmsV2Module["OUTBOUND"] = 2] = "OUTBOUND";
    WmsV2Module[WmsV2Module["INVENTORY"] = 3] = "INVENTORY";
})(WmsV2Module || (WmsV2Module = {}));
export var ZoneActivityType;
(function (ZoneActivityType) {
    ZoneActivityType[ZoneActivityType["DAMAGE"] = 3] = "DAMAGE";
    ZoneActivityType[ZoneActivityType["HIGH_VALUE"] = 6] = "HIGH_VALUE";
    ZoneActivityType[ZoneActivityType["NORMAL"] = 2] = "NORMAL";
    ZoneActivityType[ZoneActivityType["RETURN"] = 4] = "RETURN";
    ZoneActivityType[ZoneActivityType["UNDEFINED"] = 0] = "UNDEFINED";
})(ZoneActivityType || (ZoneActivityType = {}));
export var ZoneLimitOptionType;
(function (ZoneLimitOptionType) {
    ZoneLimitOptionType[ZoneLimitOptionType["Purchase_Type"] = 1] = "Purchase_Type";
})(ZoneLimitOptionType || (ZoneLimitOptionType = {}));
export var ZoneLimitRuleFieldType;
(function (ZoneLimitRuleFieldType) {
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["CONSIGNMENT"] = 4] = "CONSIGNMENT";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["DAMAGED"] = 1] = "DAMAGED";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["GOOD"] = 0] = "GOOD";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["HIGH_VALUE"] = 3] = "HIGH_VALUE";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["OUTRIGHT"] = 2] = "OUTRIGHT";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["SKUTEMP_COLD"] = 7] = "SKUTEMP_COLD";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["SKUTEMP_CONSTANT"] = 6] = "SKUTEMP_CONSTANT";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["SKUTEMP_FREEZING"] = 8] = "SKUTEMP_FREEZING";
    ZoneLimitRuleFieldType[ZoneLimitRuleFieldType["SKUTEMP_NORMAL"] = 5] = "SKUTEMP_NORMAL";
})(ZoneLimitRuleFieldType || (ZoneLimitRuleFieldType = {}));
export var ZoneLimitRuleType;
(function (ZoneLimitRuleType) {
    ZoneLimitRuleType[ZoneLimitRuleType["PURCHASE_TYPE"] = 1] = "PURCHASE_TYPE";
    ZoneLimitRuleType[ZoneLimitRuleType["SKU_HIGH_VALUE"] = 2] = "SKU_HIGH_VALUE";
    ZoneLimitRuleType[ZoneLimitRuleType["SKU_QUALITY"] = 0] = "SKU_QUALITY";
    ZoneLimitRuleType[ZoneLimitRuleType["SKU_TEMPERATURE"] = 3] = "SKU_TEMPERATURE";
})(ZoneLimitRuleType || (ZoneLimitRuleType = {}));
export var ZoneOptionType;
(function (ZoneOptionType) {
    ZoneOptionType[ZoneOptionType["Consignment"] = 1] = "Consignment";
    ZoneOptionType[ZoneOptionType["Fulfillment"] = 2] = "Fulfillment";
    ZoneOptionType[ZoneOptionType["Outright"] = 0] = "Outright";
})(ZoneOptionType || (ZoneOptionType = {}));
export var ZoneSoldoutType;
(function (ZoneSoldoutType) {
    ZoneSoldoutType[ZoneSoldoutType["BULK"] = 3] = "BULK";
    ZoneSoldoutType[ZoneSoldoutType["CASE"] = 2] = "CASE";
    ZoneSoldoutType[ZoneSoldoutType["PALLET"] = 1] = "PALLET";
    ZoneSoldoutType[ZoneSoldoutType["UNDEFINED"] = 0] = "UNDEFINED";
})(ZoneSoldoutType || (ZoneSoldoutType = {}));
export var ZoneStorageAttr;
(function (ZoneStorageAttr) {
    ZoneStorageAttr[ZoneStorageAttr["UNDEFINED"] = 0] = "UNDEFINED";
    ZoneStorageAttr[ZoneStorageAttr["WORKING_AREA"] = 1] = "WORKING_AREA";
})(ZoneStorageAttr || (ZoneStorageAttr = {}));
export var FSCOrderIsDiff;
(function (FSCOrderIsDiff) {
    FSCOrderIsDiff[FSCOrderIsDiff["-"] = 2] = "-";
    FSCOrderIsDiff[FSCOrderIsDiff["N"] = 0] = "N";
    FSCOrderIsDiff[FSCOrderIsDiff["Y"] = 1] = "Y";
})(FSCOrderIsDiff || (FSCOrderIsDiff = {}));
export var FSCOrderSourceFrom;
(function (FSCOrderSourceFrom) {
    FSCOrderSourceFrom[FSCOrderSourceFrom["FULL_STOCK_COUNT"] = 4] = "FULL_STOCK_COUNT";
})(FSCOrderSourceFrom || (FSCOrderSourceFrom = {}));
export var FSCOrderStatus;
(function (FSCOrderStatus) {
    FSCOrderStatus[FSCOrderStatus["PENDING"] = 1] = "PENDING";
    FSCOrderStatus[FSCOrderStatus["ONGOING"] = 2] = "ONGOING";
    FSCOrderStatus[FSCOrderStatus["DONE"] = 4] = "DONE";
    FSCOrderStatus[FSCOrderStatus["CANCEL"] = 3] = "CANCEL";
})(FSCOrderStatus || (FSCOrderStatus = {}));
export var FSCOrderType;
(function (FSCOrderType) {
    FSCOrderType[FSCOrderType["LOCATION_CC"] = 2] = "LOCATION_CC";
})(FSCOrderType || (FSCOrderType = {}));
export var FSCSplittingType;
(function (FSCSplittingType) {
    FSCSplittingType[FSCSplittingType["ZONE"] = 1] = "ZONE";
    FSCSplittingType[FSCSplittingType["PATHWAY"] = 2] = "PATHWAY";
    FSCSplittingType[FSCSplittingType["SEGMENT_OF_PATHWAY"] = 3] = "SEGMENT_OF_PATHWAY";
})(FSCSplittingType || (FSCSplittingType = {}));
export var FSCTaskStage;
(function (FSCTaskStage) {
    FSCTaskStage[FSCTaskStage["01ST_COUNT"] = 1] = "01ST_COUNT";
    FSCTaskStage[FSCTaskStage["02ND_COUNT"] = 2] = "02ND_COUNT";
    FSCTaskStage[FSCTaskStage["03RD_COUNT"] = 3] = "03RD_COUNT";
    FSCTaskStage[FSCTaskStage["04TH_COUNT"] = 4] = "04TH_COUNT";
    FSCTaskStage[FSCTaskStage["05TH_COUNT"] = 5] = "05TH_COUNT";
    FSCTaskStage[FSCTaskStage["06TH_COUNT"] = 6] = "06TH_COUNT";
    FSCTaskStage[FSCTaskStage["07TH_COUNT"] = 7] = "07TH_COUNT";
    FSCTaskStage[FSCTaskStage["08TH_COUNT"] = 8] = "08TH_COUNT";
    FSCTaskStage[FSCTaskStage["09TH_COUNT"] = 9] = "09TH_COUNT";
    FSCTaskStage[FSCTaskStage["10TH_COUNT"] = 10] = "10TH_COUNT";
})(FSCTaskStage || (FSCTaskStage = {}));
export var FSCTaskStatusForExport;
(function (FSCTaskStatusForExport) {
    FSCTaskStatusForExport[FSCTaskStatusForExport["CANCEL"] = 3] = "CANCEL";
    FSCTaskStatusForExport[FSCTaskStatusForExport["DONE"] = 4] = "DONE";
})(FSCTaskStatusForExport || (FSCTaskStatusForExport = {}));
export var FSCTaskStatus;
(function (FSCTaskStatus) {
    FSCTaskStatus[FSCTaskStatus["ASSIGNED"] = 5] = "ASSIGNED";
    FSCTaskStatus[FSCTaskStatus["CANCEL"] = 3] = "CANCEL";
    FSCTaskStatus[FSCTaskStatus["DONE"] = 4] = "DONE";
    FSCTaskStatus[FSCTaskStatus["ONGOING"] = 2] = "ONGOING";
    FSCTaskStatus[FSCTaskStatus["PENDING"] = 1] = "PENDING";
})(FSCTaskStatus || (FSCTaskStatus = {}));
export var FSCCreateStatus;
(function (FSCCreateStatus) {
    FSCCreateStatus[FSCCreateStatus["FSC_CANCELLED_DONE"] = 5] = "FSC_CANCELLED_DONE";
    FSCCreateStatus[FSCCreateStatus["FSC_CREATE_CANCELLING"] = 4] = "FSC_CREATE_CANCELLING";
    FSCCreateStatus[FSCCreateStatus["FSC_CREATE_DONE"] = 2] = "FSC_CREATE_DONE";
    FSCCreateStatus[FSCCreateStatus["FSC_CREATE_FAIL"] = 3] = "FSC_CREATE_FAIL";
    FSCCreateStatus[FSCCreateStatus["FSC_CREATE_RUNNING"] = 1] = "FSC_CREATE_RUNNING";
    FSCCreateStatus[FSCCreateStatus["FSC_CREATE_UNDEFINED"] = 0] = "FSC_CREATE_UNDEFINED";
})(FSCCreateStatus || (FSCCreateStatus = {}));
export var FSCTaskCompleteStatus;
(function (FSCTaskCompleteStatus) {
    FSCTaskCompleteStatus[FSCTaskCompleteStatus["COMPLETING"] = 1] = "COMPLETING";
    FSCTaskCompleteStatus[FSCTaskCompleteStatus["FAIL"] = 3] = "FAIL";
    FSCTaskCompleteStatus[FSCTaskCompleteStatus["INITIAL"] = 0] = "INITIAL";
    FSCTaskCompleteStatus[FSCTaskCompleteStatus["SUCCESS"] = 2] = "SUCCESS";
})(FSCTaskCompleteStatus || (FSCTaskCompleteStatus = {}));
export var SKUBusinessType;
(function (SKUBusinessType) {
    SKUBusinessType[SKUBusinessType["FBS"] = 2] = "FBS";
    SKUBusinessType[SKUBusinessType["FRS"] = 1] = "FRS";
    SKUBusinessType[SKUBusinessType["NORMAL_RETAIL"] = 3] = "NORMAL_RETAIL";
    SKUBusinessType[SKUBusinessType["SCS"] = 4] = "SCS";
})(SKUBusinessType || (SKUBusinessType = {}));
/** 打印类型 */
export var PrintType;
(function (PrintType) {
    PrintType[PrintType["waybill"] = 0] = "waybill";
    PrintType[PrintType["Exception"] = 1] = "Exception";
    PrintType[PrintType["DANFE"] = 2] = "DANFE";
})(PrintType || (PrintType = {}));
export var Judge;
(function (Judge) {
    Judge[Judge["Truthy"] = 1] = "Truthy";
    Judge[Judge["Falsy"] = 0] = "Falsy";
})(Judge || (Judge = {}));
