import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getCycleCountSwitch, operateFullStockCount, } from '@/api/inventory-management/fsc-common';
let CycleCount = class CycleCount extends VuexModule {
    constructor() {
        super(...arguments);
        this.isFullStockCount = false;
    }
    setFullCycleCountFlag(payload = { isOpen: false }) {
        this.isFullStockCount = payload.isOpen;
    }
    async loadOpenFullStockCountSign() {
        try {
            const { data } = await getCycleCountSwitch();
            return {
                isOpen: !!data.is_open_full_stock_count,
                from: 'loadOpenFullStockCountSign',
            };
        }
        catch (err) {
            return false;
        }
    }
    async operateFullStockCountSign(isOpen) {
        /* try { */
        const { data } = await operateFullStockCount({
            is_open_full_stock_count: +isOpen, // 关闭全仓盘点
        });
        console.log(data);
        return {
            isOpen: !!isOpen,
            from: 'operateFullStockCountSign',
        };
        /* } catch (err) {
          // return this.;
        } */
    }
};
__decorate([
    Mutation
], CycleCount.prototype, "setFullCycleCountFlag", null);
__decorate([
    Action({ commit: 'setFullCycleCountFlag' })
], CycleCount.prototype, "loadOpenFullStockCountSign", null);
__decorate([
    Action({ commit: 'setFullCycleCountFlag' })
], CycleCount.prototype, "operateFullStockCountSign", null);
CycleCount = __decorate([
    Module
], CycleCount);
export default CycleCount;
