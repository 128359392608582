import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getUserInfo } from '../../api/basic/user';
let User = class User extends VuexModule {
    constructor() {
        super(...arguments);
        this.userInfo = {};
    }
    setUserInfo(data = {}) {
        this.userInfo = data;
    }
    clearUserInfo() {
        this.userInfo = {};
    }
    async getUserInfo() {
        const resp = await getUserInfo();
        return resp.data;
    }
};
__decorate([
    Mutation
], User.prototype, "setUserInfo", null);
__decorate([
    Mutation
], User.prototype, "clearUserInfo", null);
__decorate([
    Action({ commit: 'setUserInfo' })
], User.prototype, "getUserInfo", null);
User = __decorate([
    Module
], User);
export default User;
