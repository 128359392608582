import request from '@/utils/request';
export const getABCLevelConfig = () => {
    /* PermissionKey: PC.RuleCenter.ABCSetting */
    return request.get('/api/v2/apps/basic/abc/search_new_abc_config');
};
export const updateABCLevelConfig = (params) => {
    /* PermissionKey: PC.RuleCenter.ABCSetting */
    return request.post('/api/v2/apps/basic/abc/update_new_abc_config', {
        ...params,
    });
};
export const searchABCConfigModifyLog = (params = {}) => {
    /* PermissionKey: PC.RuleCenter.ABCSetting */
    return request.get('/api/v2/apps/basic/abc/search_abc_config_modify_log', {
        ...params,
    });
};
export const searchABCConfig = () => {
    /* PermissionKey: ALL */
    return request.get('/api/v2/apps/basic/location/search_abc_config');
};
export const getCategoryL1ByPrefix = (keyword) => {
    /* PermissionKey: PC.RuleCenter.ABCSetting */
    return request.get('/api/v2/apps/basic/abc/get_category_l1_by_prefix', {
        params: {
            prefix: keyword,
        },
    });
};
