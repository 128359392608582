import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';
const Transfer = {
    path: '/transfer',
    name: 'transfer',
    meta: {
        title: 'Transfer',
        icon: 'Transfer',
    },
    redirect: '/transfer/transferRequest',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'transferRequest',
            name: 'transfer.transferrequest',
            meta: {
                title: 'Transfer Request',
            },
            redirect: 'transferRequest/request',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'request',
                    name: 'PC.Transfer.TransferRequest.Request',
                    meta: {
                        title: 'Transfer Request',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'transfer',
                                title: 'Transfer',
                            },
                            {
                                routeName: 'transfer.transferrequest',
                                title: 'Transfer Request',
                            },
                        ],
                    },
                    component: reactContainerFactory({
                        name: 'PC.Transfer.TransferRequest.Request',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/transfer/transfer-request/index')),
                    },
                },
                {
                    path: 'dashboard',
                    name: 'PC.Transfer.TransferRequest.Dashboard',
                    meta: {
                        title: 'Transfer Dashboard',
                        menuHide: true,
                        navList: [
                            {
                                routeName: 'transfer',
                                title: 'Transfer',
                            },
                            {
                                routeName: 'transfer.transferrequest',
                                title: 'Transfer Request',
                            },
                        ],
                    },
                    component: reactContainerFactory({
                        name: 'PC.Transfer.TransferRequest.Dashboard',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/transfer/transfer-request/index')),
                    },
                },
            ],
        },
        {
            path: 'transferTask',
            name: 'transfer.transfertask',
            meta: {
                title: 'Transfer Task',
            },
            redirect: 'transferTask/list',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'list',
                    name: 'transfer.transfertask',
                    meta: {
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'transfer.transferrequest',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/transfer/transfer-task/transfer-task-list')),
                    },
                },
                {
                    path: 'detail/:id',
                    name: 'transfer.transfertask.detail',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'transfer.transfertask.detail',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/transfer/transfer-task/transfer-task-detail')),
                    },
                },
            ],
        },
        {
            path: 'transitTask',
            name: 'transfer.transittask',
            meta: {
                title: 'Transit Task',
            },
            redirect: 'transitTask/list',
            component: () => import('../../views/layout/subPage.vue'),
            children: [
                {
                    path: 'list',
                    name: 'transfer.transittask',
                    meta: {
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'transfer.transferrequest',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/transfer/transit-task/transit-task-list')),
                    },
                },
                {
                    path: 'detail/:id',
                    name: 'transfer.transittask.detail',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                    },
                    component: reactContainerFactory({
                        name: 'transfer.transittask.detail',
                    }),
                    props: {
                        component: () => lazy(() => import('@/react-app/pages/transfer/transit-task/transit-task-detail')),
                    },
                },
            ],
        },
    ],
};
export default Transfer;
