const deviceManagement = {
    path: '/deviceManagement',
    name: 'deviceManagement',
    meta: {
        title: 'Device Management',
        icon: 'RTS Outbound',
    },
    redirect: '/deviceManagement',
    component: () => import('@/views/layout/index.vue'),
    children: [
        {
            path: 'device',
            name: 'deviceManagement.deviceManagement',
            meta: {
                title: 'Device Management',
                filterSave: true,
            },
            component: () => import('@/views/device-management/device/index.vue'),
        },
        {
            path: 'device/create',
            name: 'deviceManagement.deviceManagement.create',
            meta: {
                menuHide: true,
                title: 'Create',
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceManagement',
                        title: 'Device Management',
                    },
                ],
            },
            component: () => import('@/views/device-management/device/detail.vue'),
        },
        {
            path: 'device/detail/:id',
            name: 'deviceManagement.deviceManagement.detail',
            meta: {
                menuHide: true,
                title: 'Detail',
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceManagement',
                        title: 'Device Management',
                    },
                ],
            },
            component: () => import('@/views/device-management/device/detail.vue'),
        },
        {
            path: 'device/edit/:id',
            name: 'deviceManagement.deviceManagement.edit',
            meta: {
                menuHide: true,
                title: 'Edit',
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceManagement',
                        title: 'Device Management',
                    },
                ],
            },
            component: () => import('@/views/device-management/device/detail.vue'),
        },
        {
            path: 'deviceTransfer',
            name: 'deviceManagement.deviceTransfer',
            meta: {
                title: 'Device Transfer',
                filterSave: true,
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceTransfer',
                        title: 'Device Transfer',
                    },
                ],
            },
            component: () => import('@/views/device-management/device-transfer/index.vue'),
        },
        {
            path: 'deviceTransfer/detail',
            name: 'deviceManagement.deviceTransfer.detail',
            meta: {
                title: 'Device Transfer Task Detail',
                menuHide: true,
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceTransfer',
                        title: 'Device Transfer',
                    },
                ],
            },
            component: () => import('@/views/device-management/device-transfer/detail.vue'),
        },
        {
            path: 'deviceTransfer/deviceMap',
            name: 'deviceManagement.deviceTransfer.deviceMap',
            meta: {
                title: 'Device Map',
                menuHide: true,
                filterSave: true,
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceTransfer',
                        title: 'Device Transfer',
                    },
                ],
            },
            component: () => import('@/views/device-management/device-transfer/map/index.vue'),
        },
        {
            path: 'deviceTemplate',
            name: 'deviceManagement.deviceTemplate',
            meta: {
                title: 'Device Template',
                filterSave: true,
            },
            component: () => import('@/views/device-management/device-template'),
            children: [
                {
                    path: 'view',
                    name: 'deviceManagement.deviceTemplate.view',
                    meta: {
                        title: 'Detail',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/device-management/device-template/detail'),
                },
                {
                    path: 'create',
                    name: 'deviceManagement.deviceTemplate.create',
                    meta: {
                        title: 'Create',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/device-management/device-template/detail'),
                },
                {
                    path: 'edit',
                    name: 'deviceManagement.deviceTemplate.edit',
                    meta: {
                        title: 'Edit',
                        menuHide: true,
                    },
                    exact: true,
                    component: () => import('@/views/device-management/device-template/detail'),
                },
            ],
        },
        {
            path: 'deviceHistory',
            name: 'deviceManagement.deviceHistory',
            meta: {
                title: 'Device History',
            },
            component: () => import('@/views/device-management/device-history/index.vue'),
        },
        {
            path: 'deviceUsing',
            name: 'deviceManagement.deviceInUsing',
            meta: {
                title: 'Device In Using',
                filterSave: true,
            },
            component: () => import('@/views/device-management/device-using/index.vue'),
        },
        {
            path: 'deviceUsing/detail',
            name: 'deviceManagement.deviceInUsing.detail',
            meta: {
                menuHide: true,
                title: 'Device Detail',
                navList: [
                    {
                        routeName: 'deviceManagement',
                        title: 'Device Management',
                    },
                    {
                        routeName: 'deviceManagement.deviceInUsing',
                        title: 'Device In Using',
                    },
                ],
            },
            component: () => import('@/views/device-management/device-using/detail.vue'),
        },
    ],
};
export default deviceManagement;
