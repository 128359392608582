import { render, staticRenderFns } from "./cycle-count-dash-menu-item.vue?vue&type=template&id=68e05fb0&scoped=true"
import script from "./cycle-count-dash-menu-item.vue?vue&type=script&lang=ts"
export * from "./cycle-count-dash-menu-item.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68e05fb0",
  null
  
)

export default component.exports